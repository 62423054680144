import {
  compose, getContext, lifecycle, withHandlers, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import PropTypes from 'prop-types';
import { openModal } from '../../../../state/ui/actions';
import BotsList from './botsList';
import { MESSENGER_DETAIL } from '../../../../constants/bots';
import { settingBotsSelectors, settingBotsActions } from '../../../../state/settingBots';


const mapStateToProps = state => ({
  listOfBots: settingBotsSelectors.getGroupedBotsList(state),
});

const mapDispatchToProps = ({
  setOpenModal: openModal,
  getSettingBotsRequest: settingBotsActions.getSettingBotsRequest,
});


const openModalHandler = ({
  setBotData, messengerDetail, setOpenModal,
}) => () => {
  setBotData({
    type: messengerDetail.id,
  });
  if (messengerDetail.id === 3) {
    setOpenModal('addBotsModalFb');
  } else {
    setOpenModal('addBotsModal');
  }
};

const editModalHandler = ({
  setBotData, messengerDetail, setOpenModal,
}) => (id) => {
  setBotData({
    id,
    type: messengerDetail.id,

  });
  if (messengerDetail.id === 3) {
    setOpenModal('editBotsModalFb');
  } else {
    setOpenModal('editBotsModal');
  }
};

const deleteModalHandler = ({
  setBotData, messengerDetail, setOpenModal,
}) => (id) => {
  setBotData({
    id,
    type: messengerDetail.id,
  });
  setOpenModal('deleteBotsModal');
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces('bots'),
  withProps(({ messengerId, listOfBots }) => ({
    listOfConnections: listOfBots[messengerId],
    messengerDetail: MESSENGER_DETAIL[messengerId],
  })),
  getContext({
    setBotData: PropTypes.func.isRequired,
  }),
  withHandlers({
    openModalHandler,
    editModalHandler,
    deleteModalHandler,
  }),
  lifecycle({
    componentDidMount() {
      this.props.getSettingBotsRequest();
    },
  }),
);

export default enhance(BotsList);

import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '../../../../../../components';
import HistoryItem from '../historyItem';
import './style.sass';


const HistoryList = ({ t, paymentLogs }) => (
  <Grid.Wrapper
      // isLoading={!isLoading}
      // isKeepOptions
    name="history-list"
    className="history-payment__table"
  >
    <>
      <Grid.Row className="history-payment__row history-payment__row-controls">
        <Grid.Col
          filter="id"
          name={t('Date')}
          className="history-payment__col"
        />
        <Grid.Col
          filter="type"
          name={t('Type')}
          className="history-payment__col"
        />
        <Grid.Col
          filter="type"
          name={t('Card number')}
          className="history-payment__col"
        />
        <Grid.Col
          name={t('Amount')}
          className="history-payment__col"
        />
        <Grid.Col
          name={t('Currency')}
          className="history-payment__col"
        />
        <Grid.Col
          filter="tariff"
          name={t('Tariff')}
          className="history-payment__col history-payment__col--tariff"
        />
        <Grid.Col
          name={t('Receipt')}
          className="history-payment__col history-payment__col--receipt"
        />
      </Grid.Row>
      {
         paymentLogs.map(log => (
           <HistoryItem
             log={log}
           />
         ))}
    </>
  </Grid.Wrapper>
);

HistoryList.propTypes = {
  t: PropTypes.func.isRequired,
  paymentLogs: PropTypes.instanceOf(Array).isRequired,
};

export default HistoryList;

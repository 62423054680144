import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const ScrollToBottomButton = ({ onScrollToBottom, isShow, t }) => (
  <button
    type="button"
    className="messenger_scrollToBottom__button"
    title={t('Scroll to bottom')}
    onClick={onScrollToBottom}
    style={{ bottom: isShow ? '120px' : '-200px', opacity: isShow ? 1 : 0 }}
  >
    <span className="icon-expand-arrow-dn" />
  </button>
);

ScrollToBottomButton.propTypes = {
  onScrollToBottom: PropTypes.func.isRequired,
  isShow: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default ScrollToBottomButton;

import {
  compose, withState,
} from 'recompose';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { pathOr } from 'ramda';
import { withNamespaces } from 'react-i18next';
import rules from '../rules';
import { customFieldsSelectors, customFieldsActions } from '../../../../../../state/customFields';
import FieldItem from './fieldItem';
import { callNotification } from '../../../../../../utils/helpers/notifies';

const initialValues = ({ item: { title } }) => ({
  title,
});


const submitForm = (formValues, {
  props: {
    fieldId, setFieldId, editCustomField, setErrorFromBack,
  },
}) => {
  const { title } = formValues;
  if (title.trim()) {
    editCustomField({
      id: fieldId,
      title,
    },
    {
      callbacks: {
        success: () => setFieldId(null),
        error: compose(
          (e) => {
            callNotification.error(e);
            setErrorFromBack(e);
          },
          pathOr({}, ['response', 'data', 'result', 'error']),
        ),
      },
    });
  }
};

const mapStateToProps = (state, { id }) => ({
  item: customFieldsSelectors.getCustomField(state)(id),
});

const mapDispatchToProps = ({
  editCustomField: customFieldsActions.editCustomFieldRequest,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces('common'),
  withState('errorFromBack', 'setErrorFromBack', {}),
  withFormik({
    handleSubmit: submitForm,
    mapPropsToValues: initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: rules,
    enableReinitialize: true,
  }),
);
export default enhance(FieldItem);

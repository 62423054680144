import {
  compose, withState,
} from 'recompose';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { withFormik } from 'formik';
import { withNamespaces } from 'react-i18next';

import { helpers } from '../../../utils';
import { userActions, userSelectors } from '../../../state/user';
import { uiActions, uiSelectors } from '../../../state/ui';

import SignIn from './signIn';

const { translateHelpers } = helpers;
const { loginFetch } = userActions;

const mapDispatchToProps = ({
  userLoginRequest: loginFetch,
  displayNotification: uiActions.displayNotification,
});

const mapStateToProps = state => ({
  authNotification: uiSelectors.getAuthNotification(state),
  pending: userSelectors.getPendingLoginRequest(state),
});

const rules = yup.object().shape({
  password: yup
    .string()
    .required({
      field: 'password',
      message: 'required',
      params: {
        key: 'Password',
      },
    }),
  username: yup
    .string()
    .required({
      field: 'username',
      message: 'required',
      params: {
        key: 'Email address',
      },
    }),
});

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common', 'validation']),
  withState('underline', 'setUnderline', false),
  withFormik({
    validate: async (values, props) => {
      const { displayNotification } = props;
      try {
        await rules.validate(values);
        displayNotification(null);
      } catch (fail) {
        const { message } = fail;
        displayNotification({
          [message.field]: {
            message: message.message,
            params: { ...message.params, key: translateHelpers.t('common', message.params.key) },
          },
        });
      }
    },
    mapPropsToValues: props => ({ username: props.username, password: props.password }),
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: rules,
    handleSubmit: (values, { props: { userLoginRequest } }) => {
      userLoginRequest(values);
    },
  }),
);

export default enhancer(SignIn);

import * as yup from 'yup';
import * as translateHelpers from '../../utils/helpers/translateHelpers';

const rules = yup.object().shape({
  first_name: yup
    .string()
    .required({
      field: 'first_name',
      message: 'required',
      params: {
        key: translateHelpers.t('common', 'First Name'),
      },
    }),
  last_name: yup
    .string()
    .required({
      field: 'last_name',
      message: 'required',
      params: {
        key: translateHelpers.t('common', 'Sure Name'),
      },
    }),
  username: yup
    .string()
    .required({
      field: 'username',
      message: 'required',
      params: {
        key: translateHelpers.t('common', 'Username'),
      },
    }),
});

export default rules;

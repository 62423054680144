import { compose, pure } from 'recompose';

import { withNamespaces } from 'react-i18next';
import { withForwardingRef, withWindowWidth } from '../../utils/enchancers';

import MessageButtons from './messageButtons';

const enhance = compose(
  withNamespaces('common'),
  withWindowWidth(),
  withForwardingRef,
  pure,
);

export default enhance(MessageButtons);

import {
  branch, compose, renderNothing, withProps, withStateHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { path, splitEvery } from 'ramda';
import { withNamespaces } from 'react-i18next';
import { MESSENGER_DETAIL } from '../../../../constants/bots';

import ClientsItem from './clientsitem';
import { clientsSelectors } from '../../../../state/clients';
import { customFieldsSelectors } from '../../../../state/customFields';

const mapStateToProps = (state, { id }) => ({
  client: clientsSelectors.getClientById(state)(id),
  customFieldEntities: customFieldsSelectors.getCustomFieldsEntities(state),
});

// eslint-disable-next-line max-len
const toggleDisplayDetailInfo = (({ displayDetailInfo }) => () => ({ displayDetailInfo: !displayDetailInfo }));

const enhance = compose(
  connect(mapStateToProps),
  branch(({ client }) => !client.customFields, renderNothing),
  withNamespaces(['common', 'clients']),
  withStateHandlers(() => ({ displayDetailInfo: false }), {
    displayDetailInfoStateHandler: toggleDisplayDetailInfo,
  }),
  withProps(({ clientsEntities, id, client }) => ({
    nameSocial: MESSENGER_DETAIL[path([id, 'service_type'], clientsEntities)].nameSocial,
    customField: splitEvery(2, client.customFields),
  })),
);

export default enhance(ClientsItem);

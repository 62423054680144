import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { ChannelItem, BotGroupItem } from './components';
import './style.sass';

const DirectChannels = ({
  t, botIds, bots, mappedBots, onCloseLeftSideBarAfterClick,
}) => (
  <div className="direct-nav">
    <div className="direct-nav__header">
      <NavLink
        to="/messenger/directs/"
        className="direct-nav__title"
        onClick={onCloseLeftSideBarAfterClick}
      >
        {t('chat:My clients')}
      </NavLink>
    </div>
    <nav className="direct-nav__nav">
      {botIds.map(botId => (
        <BotGroupItem
          title={(bots[botId] && bots[botId].title) || t('chat:Unknown bot')}
          botId={botId}
          key={botId}
          channels={mappedBots}
        >
          {mappedBots[botId].map(channel => (
            <ChannelItem
              key={channel.id}
              channelId={channel.id}
              onCloseLeftSideBarAfterClick={onCloseLeftSideBarAfterClick}
            />
          ))}
        </BotGroupItem>
      ))}
    </nav>
  </div>
);

DirectChannels.propTypes = {
  // channelsList: PropTypes.arrayOf(PropTypes.number).isRequired,
  t: PropTypes.func.isRequired,
  botIds: PropTypes.arrayOf(PropTypes.string),
  bots: PropTypes.shape({}).isRequired,
  mappedBots: PropTypes.instanceOf(Object),
  onCloseLeftSideBarAfterClick: PropTypes.func.isRequired,
};

DirectChannels.defaultProps = {
  botIds: [],
  mappedBots: {},
};

export default DirectChannels;

import {
  compose, lifecycle, withHandlers, branch, renderComponent, renderNothing,
} from 'recompose';
import { connect } from 'react-redux';
import { memo } from 'react';
import { withRouter } from 'react-router';
import { equals } from 'ramda';

import { withNamespaces } from 'react-i18next';
import { withRefs } from '../../../utils/enchancers';
import { debounceFunc } from '../../../utils/helpers/commonHelpers';
import { messengerActions, messengerSelectors } from '../../../state/messenger';
import View from './view';
import { PageError } from '../../../pages';
import { uiActions, uiSelectors } from '../../../state/ui';
import withNotification from '../../../utils/enchancers/withNotification';
import { CHANNELS_ROUTES } from '../../../constants/ui';
import { socketActions } from '../../../utils/helpers/webSocket/state';
import withSideBarSwipe from '../../../utils/enchancers/withSideBarSwipe';

const mapStateToProps = state => ({
  isPageNotFound: uiSelectors.getIsPageNotFoundStatus(state),
  urlBeforeChannels: uiSelectors.getLastUrlBeforeChannels(state),
  totalUnreadCount: messengerSelectors.getTotalUnreadCount(state),
});

const mapDispatchToProps = {
  resetModals: uiActions.resetModals,
  setUrlBeforeChannels: uiActions.setUrlBeforeChannels,
  getTotalUnreadCountRequest: messengerActions.getTotalUnreadCountRequest,
  setActiveChannel: messengerActions.setActiveChannel,
  onReconnectSocketConnection: socketActions.reconnectSocketToServer,
};

const onAnimationHandler = () => ({ propertyName }) => {
  if (propertyName === 'padding-left'
    || propertyName === 'padding-right'
    || propertyName === 'transform') {
    /* TODO:
    * It breaks drag'n'drop.
    * Might break some animations! Be careful!
    * */
    // window.dispatchEvent(new Event('resize'));
  }
  return propertyName;
};

const setDocumentTitleAndIcon = (unreadMessagesCount) => {
  const faviconElement = document.querySelector('link[rel="shortcut icon"]');
  const countEqual = equals(unreadMessagesCount);
  if (countEqual(0)) {
    document.title = 'SmartEx App';
    faviconElement.setAttribute('href', '/favicon.ico');
  } else {
    document.title = `${unreadMessagesCount} unread ${countEqual(1) ? 'message' : 'messages'}`;
    faviconElement.setAttribute('href', '/favicon_newmessage.ico');
  }
};

const onSetUrlBeforeChannelHandler = ({
  setUrlBeforeChannels,
  match,
}) => () => {
  if (match.url !== CHANNELS_ROUTES.CHANNELS && match.url !== CHANNELS_ROUTES.DIRECTS) {
    setUrlBeforeChannels(match.url);
  }
};

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  branch(({ isPageNotFound }) => isPageNotFound, renderComponent(PageError)),
  branch(({ isRender = true }) => !isRender, renderNothing),
  withRouter,
  withRefs(),
  withSideBarSwipe({ range: 100 }),
  withNamespaces(['common']),
  withHandlers({
    onAnimation: onAnimationHandler,
    onSetUrlBeforeChannel: onSetUrlBeforeChannelHandler,
  }),
  withHandlers({
    onSetCorrectHeightPageOnMobileDevices: () => () => {
      const fixHeightOnMobileDevicesFunc = () => {
        const doc = document.documentElement;
        doc.style.setProperty('--app-height', `${window.innerHeight}px`);
      };
      debounceFunc(fixHeightOnMobileDevicesFunc, 60, false, 'fixHeightOnMobileDevicesFunc');
    },
  }),
  withNotification,
  lifecycle({
    componentDidMount() {
      const {
        getRef, onAnimation, resetModals, totalUnreadCount, onSetUrlBeforeChannel,
      } = this.props;
      this.props.getTotalUnreadCountRequest();
      resetModals();
      this.props.setActiveChannel(null);
      this.props.onSetCorrectHeightPageOnMobileDevices();
      window.addEventListener('resize', this.props.onSetCorrectHeightPageOnMobileDevices);
      getRef('main-container').addEventListener('transitionend', onAnimation);
      setDocumentTitleAndIcon(totalUnreadCount);
      onSetUrlBeforeChannel();
    },
    componentDidUpdate(prevProps) {
      const {
        match, resetModals, totalUnreadCount, onSetUrlBeforeChannel, isSocketError,
      } = this.props;
      if (prevProps.match.url !== match.url) {
        resetModals();
        onSetUrlBeforeChannel();
      }
      if (isSocketError !== prevProps.isSocketError) {
        setTimeout(() => window.dispatchEvent(new Event('resize')), 1000);
      }
      if (prevProps.totalUnreadCount !== totalUnreadCount) {
        setDocumentTitleAndIcon(totalUnreadCount);
      }
    },
    componentWillUnmount() {
      const { getRef, onAnimation } = this.props;
      this.props.setActiveChannel(null);
      window.removeEventListener('resize', this.props.onSetCorrectHeightPageOnMobileDevices);
      getRef('main-container').removeEventListener('transitionend', onAnimation);
    },
    memo,
  }),
);
export default enhancer(View);

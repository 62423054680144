import {
  compose, withHandlers, branch, renderNothing, pure, lifecycle, flattenProp,
} from 'recompose';
import { pipe } from 'ramda';
import { withNamespaces } from 'react-i18next';

import Pagination from './pagination';
import { withUrlParams } from '../../utils/enchancers';

const goNextPage = ({
  offset, count, limit, onPageChange, getUrlParam, onSetUrlParam,
}) => () => {
  if (offset + limit >= count) return;
  const page = pipe(
    getUrlParam,
    parseInt,
  )(['page'], 1);
  const nextPage = page + 1;
  const nextOffset = limit * (nextPage - 1);

  onSetUrlParam({ page: nextPage });
  if (onPageChange) onPageChange({ offset: nextOffset });
};

const goPreviousPage = ({
  offset, getUrlParam, onSetUrlParam, limit, onPageChange,
}) => () => {
  if (offset === 0) return;
  const page = pipe(
    getUrlParam,
    parseInt,
  )(['page'], 1);
  const previousPage = page - 1 || 1;
  const previousOffset = limit * (previousPage - 1);

  onSetUrlParam({ page: previousPage });
  if (onPageChange) onPageChange({ offset: previousOffset });
};

const enhance = compose(
  pure,
  flattenProp('pagination'),
  branch(
    ({ limit, count }) => limit >= count,
    renderNothing,
  ),
  withNamespaces(['common']),
  withUrlParams({}),
  withHandlers({
    goNextPage,
    goPreviousPage,
  }),
  lifecycle({
    componentWillUnmount() {
      this.props.onResetUrlParam({ page: null });
    },
  }),
);

export default enhance(Pagination);

import React from 'react';
import PropTypes from 'prop-types';
import { find, propEq } from 'ramda';
import { TabWrapper } from '../../../../../../components';
import './style.sass';

const BotGroupItem = ({
  title, children, unreadCount, channels, botId, lastChannel,
}) => (
  <div
    className="bot-group-item"
  >
    <TabWrapper
      title={title}
      unreadCount={unreadCount}
      isBotGroup
      defaultStatusTab={!!find(propEq('id', +lastChannel), channels[botId])}
    >
      {children}
    </TabWrapper>
  </div>
);

BotGroupItem.propTypes = {
  title: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  unreadCount: PropTypes.number.isRequired,
  channels: PropTypes.instanceOf(Object).isRequired,
  botId: PropTypes.string.isRequired,
  lastChannel: PropTypes.string.isRequired,
};

BotGroupItem.defaultProps = {
  title: '',
};

export default BotGroupItem;

import {
  compose, pure, withHandlers, withProps,
} from 'recompose';
import { connect } from 'react-redux';

import { withNamespaces } from 'react-i18next';
import ReplayedMessage from './replayedMessage';
import { usersSelectors } from '../../state/users';
import { getFullName } from '../../utils/helpers/userHelpers';
import { messengerActions } from '../../state/messenger';

const mapStateToProps = (state, { authorId }) => ({
  author: usersSelectors.getUser(state)(authorId),
});

const mapDispatchToProps = ({
  setScrollToMessage: messengerActions.setScrollToMessage,
});

// eslint-disable-next-line no-empty-pattern
const onScrollToMessageHandler = ({
  setScrollToMessage,
  message: { parent_id: parentId, id, ...message },
}) => () => {
  setScrollToMessage({ ...message, id: id || id });
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces('chat'),
  withProps(({ author }) => ({
    userName: getFullName(author),
  }
  )),
  withHandlers({
    onScrollToMessage: onScrollToMessageHandler,
  }),
  pure,
);

export default enhance(ReplayedMessage);

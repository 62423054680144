import {
  branch,
  compose, renderNothing, withProps, defaultProps,
} from 'recompose';
import { withRouter } from 'react-router';
import {
  lte, path, objOf, equals, or,
} from 'ramda';

import ModuleWrapper from './moduleWrapper';

const MIN_VERSION_FOR_PRODUCTION = 1;
const isDevelopMode = equals(process.env.NODE_ENV, 'development');

const enhance = compose(
  defaultProps({
    isRenderNothing: false,
  }),
  withRouter,
  withProps(compose(objOf('isAvailable'),
    or(Boolean(process.env.REACT_APP_ALLOW_DEMO)),
    or(Boolean(isDevelopMode)),
    lte(MIN_VERSION_FOR_PRODUCTION), Number, path(['config', 'version']))),
  branch(({ isRenderNothing, isAvailable }) => isRenderNothing && !isAvailable, renderNothing),
);

export default enhance(ModuleWrapper);

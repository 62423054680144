import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmModal } from '../../../../../components';

const DeleteClientsModal = ({
  isOpen, onCloseModal, onDelete, t,
}) => (
  <ConfirmModal
    actionTitle="Delete"
    isOpen={isOpen}
    onClose={onCloseModal}
    title={t('Delete client')}
    onAction={onDelete}
  >{t('common:Are you sure?')}
  </ConfirmModal>
);

DeleteClientsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default DeleteClientsModal;

import { compose, hoistStatics, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { withFormik } from 'formik';
import { withNamespaces } from 'react-i18next';

import { userActions, userSelectors } from '../../../state/user';
import ForgotPassword from './forgotPassword';
import { helpers } from '../../../utils';
import { uiActions, uiSelectors } from '../../../state/ui';

const { forgotPasswordFetch } = userActions;
const { displayNotification } = uiActions;

const { translateHelpers } = helpers;

const mapDispatchToProps = ({
  forgotPasswordRequest: forgotPasswordFetch,
  displayNotification,
});

const mapStateToProps = state => ({
  authNotification: uiSelectors.getAuthNotification(state),
  pending: userSelectors.getPendingForgotPasswordRequest(state),
});

const rules = yup.object().shape({
  email: yup
    .string()
    .email({
      field: 'email',
      message: 'email',
      params: {
        key: 'Email address',
      },
    })
    .required({
      field: 'email',
      message: 'required',
      params: {
        key: 'Email address',
      },
    }),
});

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common', 'validation']),
  withFormik({
    validate: async (values, props) => {
      const { displayNotification: showNotification } = props;
      try {
        await rules.validate(values);
        showNotification(null);
      } catch (fail) {
        const { message } = fail;
        showNotification({
          [message.field]: {
            message: message.message,
            params: { ...message.params, key: translateHelpers.t('common', message.params.key) },
          },
        });
      }
    },
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: () => ({ email: '' }),
    validationSchema: rules,
    handleSubmit: (values, { props: { forgotPasswordRequest } }) => {
      forgotPasswordRequest(values);
    },
  }),
  lifecycle({
    componentWillUnmount() {
      this.props.displayNotification(null);
    },
  }),
);

export default hoistStatics(enhancer)(ForgotPassword);

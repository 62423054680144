import React from 'react';
import PropTypes from 'prop-types';

import { ButtonsCollection } from '../index';
import { translateHelpers } from '../../utils/helpers';

const MessageButtons = ({
  onClickSaveContent, discardButton, onClickDiscardButton, sendLabel, windowWidth, isDisabled,
  title, t,
}) => (
  <div className="message-textArea__col">
    {
      windowWidth < 768 && !discardButton ? (
        <button
          type="submit"
          disabled={isDisabled}
          title={title}
          onClick={onClickSaveContent}
          onMouseDown={e => e.preventDefault()}
          className="button--xs message-textArea__button-send-short button-brill--fill"
        >
          <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 535.5 535.5"
            style={{ enableBackground: 'new 0 0 535.5 535.5' }}
            xmlSpace="preserve"
          >
            <g>
              <g id="send">
                <polygon points="0,497.25 535.5,267.75 0,38.25 0,216.75 382.5,267.75 0,318.75" />
              </g>
            </g>
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
            <g />
          </svg>
        </button>
      ) : (

        <ButtonsCollection.ButtonBrill
          type="submit"
          disabled={isDisabled}
          title={title}
          onClick={onClickSaveContent}
          onMouseDown={e => e.preventDefault()}
          className="button--xs message-textArea__button-send button-brill--fill"
        >
          { sendLabel }
        </ButtonsCollection.ButtonBrill>
      )
    }
    { discardButton && (
      <ButtonsCollection.ButtonBrill
        type="button"
        onClick={onClickDiscardButton}
        className="button--xs message-textArea__button-discard button-brill"
      >

        {t('Cancel')}
      </ButtonsCollection.ButtonBrill>
    )}
  </div>
);

MessageButtons.defaultProps = {
  sendLabel: translateHelpers.t('chat', 'Send'),
  onClickDiscardButton: () => {},
};

MessageButtons.propTypes = {
  onClickSaveContent: PropTypes.func.isRequired,
  onClickDiscardButton: PropTypes.func,
  discardButton: PropTypes.bool.isRequired,
  sendLabel: PropTypes.string,
  windowWidth: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};


export default MessageButtons;

import React from 'react';
import { propOr } from 'ramda';
import PropTypes from 'prop-types';

import './style.sass';
import { ButtonsCollection, FormsCollection } from '../../../../components';
import { Header } from '../../../../containers';
import { USER_STATUSES } from '../../../../constants/users';
import { AsyncAutoComplete } from '../../../../components/autoCompleteField';

const UserInvite = ({
  values, getRolesAutocomplete, setFieldValue, handleSubmit, handleChange, getErrorField, isPending,
}) => (
  <>
    <Header hideToggleRightSideBar className="user-header">
      <h1 className="weight--medium title--xl title-page">
        <div className="xs-hidden">User management - </div>
        {' '}

        &nbsp;Invite user
      </h1>
      <ButtonsCollection.ButtonBrill
        asLink
        href="/preferences/users/invite-user"
        type="button"
        renderMobileButton
        className="user-header__button-invite"
      >

        Create user
      </ButtonsCollection.ButtonBrill>
    </Header>
    <div className="main-container__content main-container__content--profile">
      <div className="userEdit">
        <FormsCollection.FormWrapper classNameForm="userFormInvite" handleSubmit={handleSubmit}>
          <div className="userFormInvite__row">
            <div className="userFormInvite__col">
              <label className="userFormInvite__label" htmlFor="userEdit_email">Email</label>
              <FormsCollection.Input
                value={propOr('', 'email', values)}
                id="userEdit_email"
                error={getErrorField('email')}
                touched
                name="email"
                onChange={handleChange}
                placeholder="example@gmail.com"
              />
            </div>
            <div className="userFormInvite__col">
              <label className="userFormInvite__label" htmlFor="userEdit_username">Username</label>
              <FormsCollection.Input
                id="userEdit_username"
                value={propOr('', 'username', values)}
                error={getErrorField('username')}
                touched
                name="username"
                onChange={handleChange}
                placeholder="Username"
              />
            </div>
          </div>
          <div className="userFormInvite__row">
            <div className="userFormInvite__col">
              <label className="userFormInvite__label" htmlFor="userEdit_firstName">First name</label>
              <FormsCollection.Input
                value={propOr('', 'first_name', values)}
                error={getErrorField('first_name')}
                id="userEdit_firstName"
                touched
                name="first_name"
                onChange={handleChange}
                placeholder="First name"
              />
            </div>
            <div className="userFormInvite__col">
              <label className="userFormInvite__label" htmlFor="userEdit_lastName">Last name</label>
              <FormsCollection.Input
                value={propOr('', 'last_name', values)}
                error={getErrorField('last_name')}
                id="userEdit_lastName"
                touched
                name="last_name"
                onChange={handleChange}
                placeholder="Last name"
              />
            </div>
          </div>
          <div className="userFormInvite__row">
            <div className="userFormInvite__col">
              <span className="userFormInvite__label">Role</span>
              <AsyncAutoComplete
                name="role_id"
                placeholder="Role"
                error={getErrorField('role_id')}
                closeMenuOnSelect
                touched
                value={values.role_id}
                defaultValue={values.role_id}
                loadOptions={getRolesAutocomplete}
                onChange={value => setFieldValue('role_id', value)}
              />
            </div>
            <div className="userFormInvite__col">
              <span className="userFormInvite__label">Status</span>
              <FormsCollection.Select
                disabled
                error={getErrorField('status')}
                value={0}
                name="status"
                onChange={handleChange}
                data={[USER_STATUSES[3]]}
              />
            </div>
          </div>
          <div className="userFormInvite__row userFormInvite__row--buttons">
            <ButtonsCollection.ButtonBrill
              type="submit"
              isPending={isPending}
              className="userFormInvite__submit-button button-brill--fill"
            >

              Create user
            </ButtonsCollection.ButtonBrill>
          </div>
        </FormsCollection.FormWrapper>
      </div>
    </div>
  </>
);

UserInvite.propTypes = {
  values: PropTypes.instanceOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  getErrorField: PropTypes.func.isRequired,
  isPending: PropTypes.bool.isRequired,
  getRolesAutocomplete: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};


export default UserInvite;

import React from 'react';
import PropTypes from 'prop-types';

import {
  ModalWindow, ButtonsCollection,
} from '../../../../../components';
import './style.sass';

const DeleteMessageModal = ({
  isOpen, closeModal, onDeleteMessage, t,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={() => closeModal('deleteMessageModal')}
    modalName="deleteMessageModal"
    title={t('chat:Delete Message')}
    className="modal-window__delete-message"
  >
    <>
      <div className="row  text-align--left">
        <span className="delete-message__title title-md">
          {t('chat:Are you sure you want to delete this message?')}
        </span>
      </div>
      <div className="delete-message__button-row">
        <ButtonsCollection.ButtonBrill
          type="button"
          onClick={onDeleteMessage}
          className="button--md delete-message__submit-button button-brill--fill"
        >
          { t('Delete')}
        </ButtonsCollection.ButtonBrill>
        <button
          type="button"
          onClick={() => closeModal('deleteMessageModal')}
          className="delete-message__cancel-button"
        >
          {' '}
          {t('Cancel')}
        </button>
      </div>
    </>
  </ModalWindow>
);

DeleteMessageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onDeleteMessage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
export default React.memo(DeleteMessageModal);

import React from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';

import { ButtonsCollection, ScrollWrapper } from '..';
import './style.sass';

const ModalWindow = ({
  title,
  children,
  position,
  isOpen,
  onCloseModal,
  className,
  setRef,
  zIndex,
  t,
}) => (
  <Portal>
    {isOpen
      && (
        <div
          style={{ zIndex }}
          className={`modal-window ${className}`}
          ref={e => setRef('modalContainer', e)}
        >
          <ScrollWrapper className="modal-window__scroll-wrapper">
            <div
              className={`modal-window__container ${
                position ? `modal-window__container--align-${position}` : ''
              }`}
              ref={e => setRef('bodyModal', e)}
            >
              <div className="modal-window__header">
                <h3 className="modal-window__title text--center title--big">
                  {t(title)}
                </h3>
                <ButtonsCollection.ButtonClose onClick={onCloseModal} />
              </div>
              <div className="modal-window__body">{children}</div>
            </div>
          </ScrollWrapper>
        </div>
      )
    }
  </Portal>
);

ModalWindow.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  position: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  className: PropTypes.string,
  zIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setRef: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
ModalWindow.defaultProps = {
  zIndex: 99,
  position: '',
  className: '',
};
export default ModalWindow;

import {
  compose, withHandlers, withStateHandlers,
} from 'recompose';
import { memo } from 'react';

import ScrollWrapper from './scrollWrapper';

const setVisibleStatusStateHandler = () => value => ({ isVisible: value });

const onVisibleStatusHandler = ({
  setVisibleStatus,
  isVisible,
}) => ({ clientHeight, scrollHeight }) => {
  const newIsVisibleStatus = clientHeight !== scrollHeight;
  if (isVisible !== newIsVisibleStatus) {
    setVisibleStatus(!isVisible);
  }
};

const onCustomUpdateHandler = ({ onVisibleStatus, onUpdate }) => (e) => {
  if (onVisibleStatus) onVisibleStatus(e);
  if (onUpdate) onUpdate(e);
};

const enhance = compose(
  withStateHandlers(() => ({ isVisible: false }), {
    setVisibleStatus: setVisibleStatusStateHandler,
  }),
  withHandlers({
    onVisibleStatus: onVisibleStatusHandler,
  }),
  withHandlers({
    onCustomUpdate: onCustomUpdateHandler,
  }),
  memo,
);
export default enhance(ScrollWrapper);

import { withStateHandlers, compose } from 'recompose';
import Header from './header';

const toggleDisplaySearch = () => val => ({ displaySearch: val });

const enhance = compose(
  withStateHandlers(
    () => ({ displaySearch: false }), {
      displaySearchStateHandler: toggleDisplaySearch,
    },
  ),
);
export default enhance(Header);

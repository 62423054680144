import { handleActions } from 'redux-actions';
import types from './types';
import { mergeDeep, mergeIn } from '../../utils/helpers/ramdaStateHelpers';

const reducer = handleActions({
  [types.SET_INFO_ABOUT_SUBSCRIBER]: mergeDeep(({
    payload: { subscriber },
  }) => ({
    subscriber,
  })),
  [types.SEND_PAYMENT_INFO_SUCCESS]: mergeDeep(({
    payload,
  }) => ({
    payment: payload,
  })),
  [types.GET_STATUS_SUBSCRIPTION_SUCCESS]: mergeDeep(({
    payload: { status: { status } },
  }) => ({
    status,
  })),
  [types.CLEAR_PAYMENT_DATA]: mergeIn(() => ({
    payment: null,
  })),
  [types.SET_PAYMENT_HISTORY]: mergeDeep(({ payload: { paymentLogs } }) => ({
    paymentLogs,
  })),
},
{});

export default reducer;

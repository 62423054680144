import React from 'react';
import { find, propEq } from 'ramda';
import PropTypes from 'prop-types';
import { tasks } from '../../constants';

import { getArrayFromEntities } from '../../utils/helpers/uiHelpers';
import './style.sass';

const getPriority = id => find(propEq('value', id))(getArrayFromEntities(tasks.PRIORITY));

const LabelPriorityTask = ({ className, status }) => {
  const { label, color } = getPriority(status);

  return (
    <div
      title={label}
      className={`priority-label ${className}`}
      style={{ color }}
    />
  );
};

LabelPriorityTask.propTypes = {
  className: PropTypes.string,
  status: PropTypes.number,
};
LabelPriorityTask.defaultProps = {
  className: '',
  status: 0,
};

export default LabelPriorityTask;

import {
  compose, lifecycle, withHandlers, withStateHandlers,
} from 'recompose';
import React from 'react';
import { connect } from 'react-redux';
import {
  always,
  concat, includes, objOf, identity, ifElse, uniq, keys, reduce, prop,
} from 'ramda';

import { uiActions } from '../../state/ui';
import { AttachFilesModal } from '../../components';

const MODALS_UPLOADING_FILES = {
  FILES_UPLOADING_MODAL: 'FILES_UPLOADING_MODAL',
  FILE_DELETE_MODAL: 'FILE_DELETE_MODAL',
};

const mapDispatchToProps = {
  setOpenModal: uiActions.openModal,
  closeModal: uiActions.closeModal,
};

const onOpenUploadFilesModalHandler = ({ setOpenModal }) => compose(setOpenModal,
  always(MODALS_UPLOADING_FILES.FILES_UPLOADING_MODAL));

const onOpenResetFileModalHandler = ({ setOpenModal }) => compose(setOpenModal,
  always(MODALS_UPLOADING_FILES.FILE_DELETE_MODAL));


const onCloseUploadFilesModalHandler = ({ closeModal }) => compose(closeModal,
  always(MODALS_UPLOADING_FILES.FILES_UPLOADING_MODAL));

const onCloseResetFileModalHandler = ({ closeModal }) => compose(closeModal,
  always(MODALS_UPLOADING_FILES.FILE_DELETE_MODAL));


const setFilesIdListStateHandler = ({ filesListIds }) => list => compose(objOf('filesListIds'), uniq,
  concat(filesListIds))(list);

const resetFilesIdListStateHandler = ({ filesListIds }) => list => compose(objOf('filesListIds'),
  ifElse(() => list.length, identity, always([])),
  reduce((accum, key) => (includes(prop('id', filesListIds[key]), list)
    ? accum
    : accum.concat(filesListIds[key])), []), keys)(filesListIds);

const onSetFilesContinueHandler = ({
  filesListIds, onSubmitFiles,
  onCloseUploadFilesModal,
}) => (e) => {
  e.preventDefault();
  const filesList = reduce((accum, { id }) => accum.concat(id), [], filesListIds);
  onSubmitFiles(filesList);
  onCloseUploadFilesModal();
};


const enhance = compose(
  connect(null, mapDispatchToProps),
  withStateHandlers(() => ({ filesListIds: [] }),
    {
      setFilesIdList: setFilesIdListStateHandler,
      resetFilesIdList: resetFilesIdListStateHandler,
    }),
  withHandlers({
    onOpenUploadFilesModal: onOpenUploadFilesModalHandler,
    onOpenResetFileModal: onOpenResetFileModalHandler,
    onCloseUploadFilesModal: onCloseUploadFilesModalHandler,
    onCloseResetFilesModal: onCloseResetFileModalHandler,
  }),
  withHandlers({
    onSetFilesContinue: onSetFilesContinueHandler,
  }),
  lifecycle({
    componentDidUpdate() {
      // console.log(this.props.filesListIds);
    },
  }),
);

const withFilesUploading = (BaseComponent) => {
  const FilesUploadingComponent = props => (
    <>
      <AttachFilesModal {...props} />
      <BaseComponent {...props} />
    </>
  );

  return enhance(FilesUploadingComponent);
};

export default withFilesUploading;

export {
  MODALS_UPLOADING_FILES,
};

/* eslint-disable camelcase */
import {
  compose,
  lifecycle,
  withContext,
  withHandlers,
  withProps,
  withStateHandlers,
  getContext, withState,
} from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  cond, identity, includes, isNil, path, T, ifElse, always, prop, isEmpty,
} from 'ramda';
import {
  isNotNil, notEqual, notEqualToZero,
} from 'ramda-extension';
import { memo } from 'react';
import { withRouter } from 'react-router';
import { messengerActions, messengerSelectors } from '../../state/messenger';
import { userSelectors } from '../../state/user';

import preloaderWhileLoading from '../../utils/enchancers/preloaderWhileLoading';
import {
  convertMessageBeforeSubmit,
  convertToHtml,
} from '../../utils/helpers/messengerHelpers/messages';
import withRefs from '../../utils/enchancers/withRefs';
import { getMessageTimeInMs } from '../../utils/helpers/dateHelpers';
import { encryptString } from '../../utils/helpers/stringHelpers/common';
import { debounceFunc, propIdOrNull } from '../../utils/helpers/commonHelpers';
import {
  getIsShowUnreadMessage,
  getUnreadCount,
} from '../../utils/helpers/messengerHelpers/channelHelpers';

import { PRELOADER_DIMENSION } from '../../constants/ui';
import Chat from './chat';
import { setGlobalVariableForMention } from '../../utils/helpers/mentionHelpers/lookup';
import { DIRECT_CHANNELS, PRIVATE_CHANNEL, OWN_CHANNEL } from '../../constants/messenger';
import { uiSelectors } from '../../state/ui';
import withWsReconnection from '../../utils/enchancers/withWsReconnection';
import { setCaretFocusByOffset } from '../../utils/helpers/uiComponentHelpers/caretHelpers';

const mapStateToProps = (state, { channelId }) => ({
  isChannelLoaded: messengerSelectors.getLoadedChannels(state)(channelId),
  activeChannel: messengerSelectors.getActiveChannel(state),
  channelMembers: messengerSelectors.getActiveChannelMembers(state),
  user: userSelectors.getUserData(state),
  members: messengerSelectors.getMembers(state),
  channel: messengerSelectors.getChannelById(state)(channelId),
  isPending: messengerSelectors.getMessagesRequest(state),
  bootData: userSelectors.getUserData(state),
  isChannelPending: messengerSelectors.getGroupChannelPending(state),
  lastUserOwnerMessage: messengerSelectors.getLastUserOwnerMessage(state)(
    path(['messenger', 'activeChannel', 'id'], state),
  ),
  isWsError: uiSelectors.getIsWsError(state),
  ownChannelId: userSelectors.getOwnChannelId(state),
  channelId,
});

const mapDispatchToProps = ({
  setMessage: messengerActions.setNewMessage,
  readMessageRequest: messengerActions.readMessagesRequest,
  submitMessage: messengerActions.submitMessageRequest,
  textareaChanged: messengerActions.textareaChanged,
  getLatestMessagesRequest: messengerActions.getLatestMessagesRequest,
  getDirectChannelsRequest: messengerActions.getDirectChannelsRequest,
  getGroupChannelsRequest: messengerActions.getGroupChannelsRequest,
  getManagersChannelsRequest: messengerActions.getManagersChannelsRequest,
  getTotalUnreadCountRequest: messengerActions.getTotalUnreadCountRequest,
  getGeneralUnreadCountRequest: messengerActions.getGeneralUnreadCountRequest,
});

const setContainerSize = () => size => ({ containerSize: size });
const setContainerWidthStateHandler = () => size => ({ containerWidth: size });
const setIsScrollToBottomStateHandler = () => value => ({ isScrollToBottom: value });
const updateHeightMessengersArea = () => val => ({ heightMessengersArea: val });
const setIsSizeChangedStateHandler = () => val => ({ isSizeChanged: val });

const updateHeightChatContainer = ({
  setContainerSizeStateHandler,
  getRef,
}) => ({ height }) => {
  const container = getRef('container');
  const heightMessengerField = getRef('fieldMessenger').offsetHeight;
  setContainerSizeStateHandler(container.offsetHeight - (height || heightMessengerField));
};

const onSubmitMessageHandler = ({
  submitMessage,
  setIsScrollToBottom,
  channelId,
  // setMessage,
  // user,
  replyMessage,
  setReplyMessage,
}) => (content, files) => {
  const messageMs = getMessageTimeInMs();
  const clientMsgId = encryptString(messageMs);
  submitMessage({
    content: convertMessageBeforeSubmit(content),
    client_msg_id: clientMsgId,
    id: channelId,
    parent_id: propIdOrNull(replyMessage),
    files,
  }, {
    callbacks: {
      success: () => {
        setIsScrollToBottom(true);
      },
    },
  });
  setReplyMessage(null);
};

const updateMessageContainerHeightHandler = ({
  setContainerSizeStateHandler,
  getRef,
}) => () => {
  requestAnimationFrame(() => {
    const messengerElement = getRef('container');
    const fieldElement = getRef('fieldContainer');
    if (messengerElement && fieldElement) {
      setContainerSizeStateHandler(messengerElement.offsetHeight - fieldElement.offsetHeight);
    }
  });
};

const onResizeHandler = ({
  setContainerWidth,
  updateMessageContainerHeight,
}) => (width) => {
  const sizeScreenWatcher = () => {
    updateMessageContainerHeight();
    requestAnimationFrame(() => {
      setContainerWidth(width);
    });
  };
  debounceFunc(sizeScreenWatcher, 500, false, 'sizeScreenWatcher');
};

const setEditableMessageStateHandler = () => id => ({ editableMessage: id });

const onTextAreaKeyDownHandler = ({
  textareaChanged,
  unreadIndex,
  setUnreadIndex,
  channel: { unread_count },
  match: { params: { id } },
}) => () => {
  textareaChanged({ channelId: id, hasUnreadMessag: Boolean(unread_count) });
  if (isNotNil(unreadIndex) && notEqualToZero(unreadIndex)) {
    setUnreadIndex(null);
  }
};

const onKeyDownPureTextAreaHandler = ({
  lastUserOwnerMessageIndex,
  lastUserOwnerMessage,
  setScrollToIndex,
  setEditableMessage,
  editableMessage,
}) => (event) => {
  if (isNil(editableMessage) && event.keyCode === 38) {
    requestAnimationFrame(() => {
      setEditableMessage(lastUserOwnerMessage);
      requestAnimationFrame(() => {
        setScrollToIndex(lastUserOwnerMessageIndex);
      });
    });
  }
};

const onTextAreaFocusHandler = ({ getRef }) => (isInFocus) => {
  const messageTextArea = getRef('messageTextArea');
  document.addEventListener('resize', () => {
    messageTextArea.style.position = isInFocus ? 'fixed' : 'absolute';
    document.querySelector('.messenger').style.transform = isInFocus ? 'translateZ(0)' : '';
  }, { once: true, passive: true });
};

const onRenderContentHandler = ({ members, bootData }) => (content,
  onHandler, messageId, options) => convertToHtml(
  bootData.id,
  members,
  onHandler,
  messageId,
  options,
)(content);

const onReadMessageThisChannelHandler = ({
  readMessageRequest,
  match: { params: { id } },
}) => ({ delay = 250 }) => {
  const callReadMessagesRequest = () => setTimeout(() => {
    readMessageRequest({ channelId: id });
  }, delay);
  debounceFunc(callReadMessagesRequest, delay, false, 'callReadMessagesRequest');
};
// `onReadMessageThisChannelHandler` 1200 ms waiting , user must see unread labels when
// come from other tabs

const onSetInputRefHandler = ({ setRef }) => e => setRef('typeMessageElement', e);

// eslint-disable-next-line max-len
const readMessagesWhenMountMessageHistoryHandler = ({ onReadMessageThisChannel }) => () => onReadMessageThisChannel({ delay: 250 });

const enhance = compose(
  withState('isPuck', 'setIsPuck', false),
  getContext({
    isLoadingChannel: PropTypes.bool,
    setSelectedMessage: PropTypes.func,
  }),
  preloaderWhileLoading({
    dimension: PRELOADER_DIMENSION.MIDDLE,
    alignContainerCenter: true,
    delay: 800,
    isLoading: ({ isLoadingChannel }) => isLoadingChannel,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  preloaderWhileLoading({
    dimension: PRELOADER_DIMENSION.MIDDLE,
    alignContainerCenter: true,
    delay: 800,
    isLoading: ({ isPending }) => isPending,
    onAction: ({ isChannelPending, isPending }) => isChannelPending || isPending,
  }),
  withWsReconnection(({
    getLatestMessagesRequest,
    channelId,
    getGroupChannelsRequest,
    getDirectChannelsRequest,
  }) => getLatestMessagesRequest({
    channelId,
    offset: 0,
    limit: 25,
    isAfterSocketReconnect: true,
  }, {
    callbacks: {
      success: () => {
        getGroupChannelsRequest();
        getDirectChannelsRequest();
      },
    },
  })),
  withRefs(),
  withRouter,
  withState('isTryReadChannel', 'setTryReadChannel', false),
  withState('scrollToIndex', 'setScrollToIndex', ({ countMessages }) => countMessages),
  withState('lastUserOwnerMessageIndex', 'setLastUserOwnerMessageIndex', null),
  withState('replyMessage', 'setReplyMessage', null),
  withState('isReplyEdited', 'setIsReplyEdited', false),
  memo,
  withContext(
    {
      messageDays: PropTypes.object,
      channelId: PropTypes.number,
      setLastUserOwnerMessageIndex: PropTypes.func,
      lastUserOwnerMessage: PropTypes.string,
      setSelectedMessageG: PropTypes.func,
      setIsReplyEdited: PropTypes.func,
      isReplyEdited: PropTypes.bool,
    },
    props => ({
      messageDays: props.messageDays,
      channelId: props.channelId,
      setLastUserOwnerMessageIndex: props.setLastUserOwnerMessageIndex,
      lastUserOwnerMessage: props.lastUserOwnerMessage,
      setSelectedMessageG: props.setSelectedMessage,
      setIsReplyEdited: props.setIsReplyEdited,
      isReplyEdited: props.isReplyEdited,
    }),
  ),
  withProps(({
    user: { id }, channelMembers, channel, members, ownChannelId, channelId,
  }) => ({
    // isUserMember: includes(id, channelMembers),
    membersForMention: ifElse(includes(prop('type', channel)),
      always([]),
      () => setGlobalVariableForMention(members))(DIRECT_CHANNELS),
    unreadCount: compose(
      cond([
        [isNil, () => 0],
        [T, identity],
      ]),
      getUnreadCount,
    )(channel),
    isMember: (isEmpty(channel) && channelId === ownChannelId)
      || includes(channel.type, [...DIRECT_CHANNELS, 0])
      || includes(channel.type, [PRIVATE_CHANNEL, 0])
      || includes(id, channelMembers)
      || includes(channel.type, [OWN_CHANNEL, 0]),
    isShowUnreadMessage: getIsShowUnreadMessage(channel),
  })),
  withStateHandlers(() => ({
    heightMessengersArea: 0,
    itemSize: {},
    containerSize: 0,
    containerWidth: 0,
    isScrollToBottom: false,
    isResetDimensions: false,
    isHideUnreadLabel: false,
    editableMessage: null,
  }), {
    updateHeightMessengersArea,
    setContainerSizeStateHandler: setContainerSize,
    setContainerWidth: setContainerWidthStateHandler,
    setIsScrollToBottom: setIsScrollToBottomStateHandler,
    setIsSizeChanged: setIsSizeChangedStateHandler,
    setEditableMessage: setEditableMessageStateHandler,
  }),
  withHandlers({
    updateMessageContainerHeight: updateMessageContainerHeightHandler,
  }),
  withHandlers({
    updateHeightChatContainerHandler: updateHeightChatContainer,
    onSubmitMessage: onSubmitMessageHandler,
    onResize: onResizeHandler,
    onTextAreaKeyDown: onTextAreaKeyDownHandler,
    onKeyDownPureTextArea: onKeyDownPureTextAreaHandler,
    onTextAreaFocus: onTextAreaFocusHandler,
    onReadMessageThisChannel: onReadMessageThisChannelHandler,
    onRenderContent: onRenderContentHandler,
    onSetInputRef: onSetInputRefHandler,
  }),
  withHandlers({
    readMessagesWhenMountMessageHistory: readMessagesWhenMountMessageHistoryHandler,
  }),
  withContext(
    {
      members: PropTypes.instanceOf(Object),
      getHeightMessagesArea: PropTypes.func,
      onRenderContent: PropTypes.func,
    },
    ({
      members, getRef, onRenderContent,
    }) => ({
      members,
      onRenderContent,
      getHeightMessagesArea: () => getRef('scroll'),
    }),
  ),
  lifecycle({
    shouldComponentUpdate({
      isChannelLoaded, activeChannel, containerSize, replyMessage, editableMessage,
      idMessageWithUnreadLabel, isPuck, selectedMessage, isMember, members, channel,
    }) {
      return isChannelLoaded !== this.props.isChannelLoaded
        || activeChannel.id !== this.props.activeChannel.id
        || containerSize !== this.props.containerSize
        || replyMessage !== this.props.replyMessage
        || editableMessage !== this.props.editableMessage
        || idMessageWithUnreadLabel !== this.props.idMessageWithUnreadLabel
        || isPuck !== this.props.isPuck
        || selectedMessage !== this.props.selectedMessage
        || isMember !== this.props.isMember
        || members !== this.props.members
        || notEqual(channel.unread_count, this.props.channel.unread_count);
    },
    componentDidMount() {
      const {
        updateMessageContainerHeight,
        setIsScrollToBottom,
        onReadMessageThisChannel,
        getTotalUnreadCountRequest,
        getGeneralUnreadCountRequest,
      } = this.props;
      updateMessageContainerHeight();
      getTotalUnreadCountRequest();
      getGeneralUnreadCountRequest();
      setIsScrollToBottom(true);
      window.addEventListener('resize', updateMessageContainerHeight);
      window.addEventListener('focus', onReadMessageThisChannel);
    },
    componentDidUpdate(prevProps) {
      const {
        channelId, readMessageRequest, replyMessage, onResize, editableMessage, getRef,
      } = this.props;
      const {
        channelId: prevChannelId,
        replyMessage: prevReplyMessage,
      } = prevProps;
      const typeMessageElement = getRef('typeMessageElement');
      if (editableMessage !== prevProps.editableMessage && !editableMessage && typeMessageElement) {
        setCaretFocusByOffset(typeMessageElement, 0);
      }
      if (notEqual(
        propIdOrNull(replyMessage),
        propIdOrNull(prevReplyMessage),
      )) {
        onResize();
      }
      if (notEqual(channelId, prevChannelId)) {
        readMessageRequest({ channelId: prevChannelId });
      }
    },
    componentWillUnmount() {
      const { updateMessageContainerHeight, onReadMessageThisChannel } = this.props;
      window.removeEventListener('resize', updateMessageContainerHeight);
      window.removeEventListener('focus', onReadMessageThisChannel);
    },
  }),
  memo,
);

export default enhance(Chat);

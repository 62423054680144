/* eslint-disable */
import {
  branch,
  compose, withProps, renderNothing, withHandlers, renderComponent,
} from 'recompose';
import { connect } from 'react-redux';
import React from 'react';
import { pathOr, map, includes, is, ifElse, propEq, always, isNil, propOr } from 'ramda';

import { userSelectors } from '../../state/user';
import PermissionElement from './permissionElement';

const mapStateToProps = state => ({
  myPermissions: userSelectors.getMyPermissions(state),
  user: userSelectors.getUserData(state),
});

const enhance = compose(
  connect(mapStateToProps),
  withProps(({
    module, action, myPermissions, entity = {}, onlyOwner, user
  }) => ({
    isNotAccess: is(Array, action) ? !includes(1, map(actionItem => pathOr(0, [module, actionItem], myPermissions), action)) : pathOr(0, [module, action], myPermissions) === 0,
    isNotOwner: onlyOwner ? ifElse(propEq('created_by', user.id), always(false), always(true))(entity) : true,
  })),
  branch(({ isNotAccess, isNotOwner, module }) => isNotAccess && isNotOwner && module,
    renderComponent(
      ({ insteadRender = { id: 1} }) => <>{
        ifElse(isNil,
          renderNothing,
          () => <div {...insteadRender}>{propOr('', 'children', insteadRender)}</div>)
        (insteadRender)}</>)),
);

const withPermissionHandlers = compose(
  connect(mapStateToProps),
  withHandlers({
    P: ({ myPermissions }) => (module, action, renderWhenHasAccess) =>{
      const isAccess = !!pathOr(0, [module, action], myPermissions);
      return renderWhenHasAccess ? (isAccess ? renderWhenHasAccess : '') : isAccess
    },
  }),
);

export default enhance(PermissionElement);

export {
  withPermissionHandlers,
};

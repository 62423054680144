import {
  compose, getContext, hoistStatics, lifecycle, pure, withHandlers, withProps,
} from 'recompose';
import PropTypes from 'prop-types';

import {
  always, cond, equals, ifElse, includes, not, prop, T,
} from 'ramda';
import { withNamespaces } from 'react-i18next';
import { KEYS } from '../../../../../../../../constants/ui';
import { withRefs } from '../../../../../../../../utils/enchancers';

import EditableMessage from './editableMessage';
import { setGlobalVariableForMention } from '../../../../../../../../utils/helpers/mentionHelpers/lookup';
import { DIRECT_CHANNELS } from '../../../../../../../../constants/messenger';

const onSetTextAreaRefHandler = ({ setRef }) => ref => setRef('textArea', ref);
const onKeyDownEditableMessageHandler = ({
  onCloseEditMessage,
}) => event => cond([
  [equals(KEYS.ESCAPE), onCloseEditMessage],
  [T, always(true)],
])(event.keyCode);

const onClickOutsideHandler = ({ messageRef, onCloseEditMessage }) => (e) => {
  if (not(messageRef.contains(e.target))) {
    onCloseEditMessage();
  }
};

const enhance = compose(
  pure,
  withNamespaces('common'),
  withRefs(),
  withHandlers({
    onSetTextAreaRef: onSetTextAreaRefHandler,
    onKeyDownEditableMessage: onKeyDownEditableMessageHandler,
  }),
  getContext({
    members: PropTypes.instanceOf(Object).isRequired,
  }),
  withProps(({ channel, members }) => ({
    membersForMention: ifElse(includes(prop('type', channel)),
      always([]),
      () => setGlobalVariableForMention(members))(DIRECT_CHANNELS),
  })),
  withHandlers({
    onClickOutside: onClickOutsideHandler,
  }),
  withRefs(),
  lifecycle({
    componentDidMount() {
      const {
        onMount, id, onKeyDownEditableMessage, onClickOutside,
      } = this.props;
      document.addEventListener('keyup', onKeyDownEditableMessage);
      document.addEventListener('mousedown', onClickOutside);
      document.addEventListener('touchend', onClickOutside);
      onMount(id);
    },
    componentWillUnmount() {
      const { onWillUnMount, onKeyDownEditableMessage, onClickOutside } = this.props;
      document.removeEventListener('keyup', onKeyDownEditableMessage);
      document.removeEventListener('mousedown', onClickOutside);
      document.removeEventListener('touchend', onClickOutside);
      onWillUnMount();
    },
  }),
  hoistStatics,
);

export default enhance(EditableMessage);

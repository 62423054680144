import React from 'react';
import { pathOr, path } from 'ramda';

import PropTypes from 'prop-types';
import {
  ButtonsCollection, FormsCollection, ModalWindow,
} from '../../../../../components';

import './style.sass';

const EditClientsModal = ({
  isOpen,
  onCloseModalHandler,
  handleChange,
  handleSubmit,
  values,
  touched,
  errors,
  customFields,
  customFieldEntities,
  t,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={onCloseModalHandler}
    modalName="editClientsModal"
    title={t('Edit client')}
    className="modal-window__clients"
  >
    <FormsCollection.FormWrapper handleSubmit={handleSubmit} className="modal-clients__wrapper">
      <div className="modal-clients__row">
        <div className="modal-clients__col">
          <span className="modal-clients__text">{t('First name')}</span>
          <FormsCollection.Input
            placeholder={t('First name')}
            id="client_first-name"
            onChange={handleChange}
            name="first_name"
            value={values.first_name}
            touched={touched.first_name}
            error={errors.first_name}
          />
        </div>
        <div className="modal-clients__col">
          <span className="modal-clients__text">{t('Last name')}</span>
          <FormsCollection.Input
            placeholder={t('Last name')}
            id="client_last-name"
            touched={touched.last_name}
            onChange={handleChange}
            name="last_name"
            value={values.last_name}
            error={errors.last_name}
          />
        </div>
      </div>
      <div className="modal-clients__row">
        <div className="modal-clients__col">
          <span className="modal-clients__text">{t('Phone')}</span>
          <FormsCollection.Input
            placeholder={t('Phone')}
            id="client_phone"
            onChange={handleChange}
            name="phone"
            value={values.phone}
            touched={touched.phone}
            error={errors.phone}
          />
        </div>
        <div className="modal-clients__col">
          <span className="modal-clients__text">{t('Telegram username')}</span>
          <FormsCollection.Input
            placeholder={t('Telegram username')}
            id="client_telegram-username"
            touched={touched.username}
            onChange={handleChange}
            name="username"
            value={values.username}
            error={errors.username}
          />
        </div>
      </div>
      <div className="modal-clients__row">
        <div className="modal-clients__col">
          <span className="modal-clients__text">{t('Viber phone')}</span>
          <FormsCollection.Input
            placeholder={t('Viber phone')}
            id="client_viber-phone"
            onChange={handleChange}
            name="viber_phone"
            value={values.viber_phone}
            touched={touched.viber_phone}
            error={errors.viber_phone}
          />
        </div>
        <div className="modal-clients__col">
          <span className="modal-clients__text">{t('Facebook link')}</span>
          <FormsCollection.Input
            placeholder={t('Facebook link')}
            id="client_facebook-link"
            touched={touched.facebook_link}
            onChange={handleChange}
            name="facebook_link"
            value={values.facebook_link}
            error={errors.facebook_link}
          />
        </div>
      </div>
      {
        customFields.map((array, index) => (
          <div
            className="modal-clients__row"
            key={`clients-fields-row-${path([index, 'id'], array)}`}
          >
            {array.map(({ id, field_id: fieldId }) => (
              <div
                className="modal-clients__col"
                key={`clients-fields-col-${id}`}
              >
                <span className="modal-clients__text">{pathOr('N/A', [fieldId, 'title'], customFieldEntities)}</span>
                <FormsCollection.Input
                  placeholder={pathOr('N/A', [fieldId, 'title'], customFieldEntities)}
                  id={`client-${pathOr('N/A', [fieldId, 'title'], customFieldEntities)}`}
                  onChange={handleChange}
                  name={pathOr('N/A', [fieldId, 'title'], customFieldEntities)}
                  value={values[pathOr('N/A', [fieldId, 'title'], customFieldEntities)]}
                  touched={touched[pathOr('N/A', [fieldId, 'title'], customFieldEntities)]}
                />
              </div>
            ))
            }
          </div>
        ))
      }
      <div className="modal-clients__btn-container">
        <ButtonsCollection.ButtonBrill
          className="modal-clients__btn button--sm"
          type="submit"
        >{t('SUBMIT')}
        </ButtonsCollection.ButtonBrill>
      </div>
    </FormsCollection.FormWrapper>
  </ModalWindow>
);

EditClientsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModalHandler: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  touched: PropTypes.instanceOf(Object).isRequired,
  customFields: PropTypes.instanceOf(Array),
  customFieldEntities: PropTypes.instanceOf(Object).isRequired,
  values: PropTypes.instanceOf(Object),
  errors: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    username: PropTypes.string,
    phone: PropTypes.string,
    viber_phone: PropTypes.string,
    facebook_link: PropTypes.string,
  }),
  t: PropTypes.func.isRequired,
};

EditClientsModal.defaultProps = {
  values: PropTypes.shape({
    first_name: '',
    last_name: '',
    username: '',
    phone: '',
    viber_phone: '',
    facebook_link: '',
    customFields: '',
  }),
  errors: PropTypes.shape({
    first_name: '',
    last_name: '',
    username: '',
    phone: '',
    viber_phone: '',
    facebook_link: '',
  }),
  customFields: [],
};

export default EditClientsModal;

import * as settingBotsActions from './actions';
import * as settingBotsSelectors from './selectors';
import * as settingBotsOperations from './operations';
import settingBotsTypes from './types';
import reducer from './reducers';

export {
  settingBotsActions,
  settingBotsTypes,
  settingBotsSelectors,
  settingBotsOperations,
};

export default reducer;

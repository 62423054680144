import { compose, pure } from 'recompose';

import { withNamespaces } from 'react-i18next';
import ConfirmModal from './confirmModal';

const enhance = compose(
  pure,
  withNamespaces('common'),
);
export default enhance(ConfirmModal);

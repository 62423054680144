import { compose, withState, withContext } from 'recompose';
import PropTypes from 'prop-types';
import { values } from 'ramda';
import BotsGrid from './botsGrid';
import { MESSENGER_TYPES } from '../../../../constants/bots';

const enhance = compose(
  withState('botData', 'setBotData', null),
  withContext({
    setBotData: PropTypes.func.isRequired,
    botData: PropTypes.oneOfType([PropTypes.shape({
      type: PropTypes.oneOf(values(MESSENGER_TYPES)),
      id: PropTypes.number,
    })], PropTypes.oneOf([null])),
  },
  ({
    setBotData, botData,
  }) => ({
    setBotData, botData,
  })),
);


export default enhance(BotsGrid);

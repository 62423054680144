/* eslint-disable no-mixed-spaces-and-tabs,no-tabs */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { identity } from 'ramda';

import { ToggleButtonLeftSideNav, MainNav } from './components';
import {
  ScrollWrapper, Avatar, BrillMask,
} from '../../components';
import './style.sass';
import { getApiUrl } from '../../utils/helpers/requestHelpers';

const imageLogoUrl = `${getApiUrl(window.location)}/images/wetelo.png`;

const LeftSideBar = ({
  children, onScroll, setLeftSideBarRef, isOpenLeftSideBar, onCloseLeftSideBarAfterClick,
}) => (
  <aside className="left-side-bar" ref={e => setLeftSideBarRef('leftSideBar', e)}>
    <div className="left-side-bar__logo">
      <Link to="/" className="left-side-bar__logo-link" onClick={onCloseLeftSideBarAfterClick}>
        { isOpenLeftSideBar ? 'SmartEx App' : 'S' }
      </Link>
      <ToggleButtonLeftSideNav />
    </div>
    <ScrollWrapper
      className="scroll-wrapper--dark left-side-bar__scroll-wrapper"
      autoHide
      onUpdate={onScroll}
    >
      <div className="short-project-info">
        <BrillMask className="short-project-info__icon">
          <Avatar src={imageLogoUrl} alt="Wetelo, Inc." />
        </BrillMask>
        <div className="short-project-info__title  hide-element--flex">

        Wetelo, Inc.
        </div>
      </div>
      <MainNav onCloseLeftSideBarAfterClick={onCloseLeftSideBarAfterClick} />
      <div className="left-side-bar__container">
        <div className="row hide-element--block">
          {children}
        </div>
      </div>
    </ScrollWrapper>
  </aside>
);

LeftSideBar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.array,
  ]),
  onScroll: PropTypes.func,
  isOpenLeftSideBar: PropTypes.bool.isRequired,
  setLeftSideBarRef: PropTypes.func.isRequired,
  onCloseLeftSideBarAfterClick: PropTypes.func.isRequired,
};
LeftSideBar.defaultProps = {
  onScroll: identity,
  children: '',
};
export default LeftSideBar;

import * as yup from 'yup';

const rules = yup.object().shape({
  title: yup
    .string()
    .min(5, {
      field: 'title',
      message: 'Title is too short',
    })
    .required({
      field: 'title',
      message: 'Title is required',
    }),
  token: yup
    .string()
    .required({
      field: 'token',
      message: 'Token is required',
    }),
});

export default rules;

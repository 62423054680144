import React from 'react';
import PropTypes from 'prop-types';

import { ModalWindow, FormsCollection, ButtonsCollection } from '../../../../../components';
import FieldItem from './fieldItem';
import FIELD_NAME from './constants';
import './style.sass';


const getFieldName = idField => `${FIELD_NAME}${idField}`;

const InviteUsersModal = ({
  isOpen,
  isPending,
  handleChange,
  handleBlur,
  handleSubmit,
  values,
  closeModalHandler,
  fieldsArray,
  deleteFieldItemHandler,
  addFieldItemHandler,
  getError,
  t,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={closeModalHandler}
    modalName="createChannel"
    title={t('Invite users')}
    className="invite-users__modal"
  >
    <FormsCollection.FormWrapper className="invite-users" handleSubmit={handleSubmit}>
      {
        fieldsArray.map(id => (
          <FieldItem
            key={id}
            id={id}
            t={t}
            value={values[getFieldName(id)]}
            name={getFieldName(id)}
            error={t(`user:${getError(id)}`)}
            touched
            onChange={handleChange}
            onBlur={handleBlur}
            onDelete={fieldsArray.length > 1 ? () => deleteFieldItemHandler(id) : null}
          />
        ))
      }
      <div className="invite-users__add-fields-row">
        <span className="invite-users__icon--in-link icon-add-user" />
        <button
          className="invite-users__button-as-link link"
          onClick={() => addFieldItemHandler({ many: false })}
          type="button"
        >
          {t('chat:Add another')}
        </button>
        {t('chat:or add')}
        <button
          onClick={() => addFieldItemHandler({ many: true })}
          className="invite-users__button-as-link link"
          type="button"
        >
          {t('chat:many at once')}
        </button>
      </div>
      <div className="button-group">
        <ButtonsCollection.ButtonBrill
          pending={isPending}
          type="submit"
          className="button--md invite-users__button-save button-brill--fill"
        >
          {t('Invite')}
        </ButtonsCollection.ButtonBrill>
      </div>
    </FormsCollection.FormWrapper>
  </ModalWindow>
);

InviteUsersModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.shape().isRequired,
  closeModalHandler: PropTypes.func.isRequired,
  fieldsArray: PropTypes.arrayOf(PropTypes.number).isRequired,
  deleteFieldItemHandler: PropTypes.func.isRequired,
  addFieldItemHandler: PropTypes.func.isRequired,
  getError: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

InviteUsersModal.defaultProps = {
  isPending: false,
};

export default InviteUsersModal;

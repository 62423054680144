import { handleActions } from 'redux-actions';
import types from './types';
import { mergeDeep, mergeIn } from '../../utils/helpers/stateHelpers';

const reducer = handleActions({
  [types.LOGOUT]: () => {},
  [types.LOGIN_REQUEST_SUCCESS]: mergeIn(action => action.payload),
  [types.CHECK_CODE_SUCCESS]: mergeIn(action => ({ code: action.payload })),
  [types.FORGOT_PASSWORD_SUCCESS]: mergeIn(action => ({ forgotPassword: action.payload })),
  [types.RESET_PASSWORD_SUCCESS]: mergeIn(action => ({ resetPassword: action.payload })),
  [types.SIGN_UP_REQUEST_SUCCESS]: mergeIn(action => ({ signUp: action.payload })),
  [types.CONTINUE_SIGN_UP_REQUEST_SUCCESS]: mergeIn(action => ({ continueSignUp: action.payload })),
  [types.SET_AUTHENTICATED]: mergeIn(action => ({ authenticated: action.payload })),
  [types.SET_USER_IS_VALID]: mergeDeep(action => action.payload),
  [types.SET_USER]: mergeDeep(({ payload: { user } }) => ({ user })),
  [types.SET_USER_IS_VALID_FLAG]: mergeDeep(() => ({ isValidUser: true })),
}, {
  isValidUser: false,
});

export default reducer;

import React from 'react';
import PropTypes from 'prop-types';
import { BrillWithIndex } from '..';

const SummaryCard = ({
  count, colorBorder, title, className,
}) => (
  <div className={`summary-card__item ${className || ''}`}>
    <BrillWithIndex colorBorder={colorBorder}>{count}</BrillWithIndex>
    <h4 className="summary-card__title">{title}</h4>
  </div>
);

SummaryCard.propTypes = {
  count: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  colorBorder: PropTypes.string,
};

SummaryCard.defaultProps = {
  colorBorder: '#000',
  className: '',
};

export default SummaryCard;

export default {
  GET_INFO_ABOUT_SUBSCRIBER: 'GET_INFO_ABOUT_SUBSCRIBER',
  SET_INFO_ABOUT_SUBSCRIBER: 'SET_INFO_ABOUT_SUBSCRIBER',
  UPDATE_INFO_ABOUT_SUBSCRIBER_REQUEST: 'UPDATE_INFO_ABOUT_SUBSCRIBER_REQUEST',
  SEND_PAYMENT_INFO_REQUEST: 'SEND_PAYMENT_INFO_REQUEST',
  SEND_PAYMENT_INFO_SUCCESS: 'SEND_PAYMENT_INFO_SUCCESS',
  GET_STATUS_SUBSCRIPTION_REQUEST: 'GET_STATUS_SUBSCRIPTION_REQUEST',
  GET_STATUS_SUBSCRIPTION_SUCCESS: 'GET_STATUS_SUBSCRIPTION_SUCCESS',
  CLEAR_PAYMENT_DATA: 'CLEAR_PAYMENT_DATA',
  DELETE_ACCOUNT_REQUEST: 'DELETE_ACCOUNT_REQUEST',
  CANCEL_SUBSCRIPTION_REQUEST: 'CANCEL_SUBSCRIPTION_REQUEST',
  CANCEL_SUBSCRIPTION_SUCCESS: 'CANCEL_SUBSCRIPTION_SUCCESS',
  GET_PAYMENT_HISTORY: 'GET_PAYMENT_HISTORY',
  SET_PAYMENT_HISTORY: 'SET_PAYMENT_HISTORY',
  SEND_PAYMENT_RECEIPT: 'SEND_PAYMENT_RECEIPT',
};

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SUBSCRIPTION_ERRORS } from '../../../../constants/errors';
import { ModalWindow } from '../../../../components';
import './style.sass';


const ErrorModal = ({
  isOpen, onCloseModal, t, errorFromSubscriptionMiddleware,
}) => (
  <ModalWindow
    modalName="errorModal"
    isOpen={isOpen}
    onClose={onCloseModal}
    title="Error"
    className="error-modal"
  >
    <div className="error-modal__wrapper">
      <span className="error-modal__text">
        {t('You can use only')}
        {SUBSCRIPTION_ERRORS.type.ENTREPRENEUR === errorFromSubscriptionMiddleware.type ? `3 ${SUBSCRIPTION_ERRORS.model.BOT === errorFromSubscriptionMiddleware.model ? t('bots-entrepreneur') : t('accounts-entrepreneur')}` : `10 ${SUBSCRIPTION_ERRORS.model.BOT === errorFromSubscriptionMiddleware.model ? t('bots-business') : t('accounts-business')}`}
        {t('in the plan')}
      </span>
      <span className="error-modal__text--red">{SUBSCRIPTION_ERRORS.type.ENTREPRENEUR === errorFromSubscriptionMiddleware.type ? t('Business') : ''}</span>
      <span className="error-modal__text">{SUBSCRIPTION_ERRORS.type.ENTREPRENEUR === errorFromSubscriptionMiddleware.type ? t('or') : ''}</span>
      <span className="error-modal__text--red">{t('Virtuoso')}</span>
      <span className="error-modal__text">{t('plan on the')}</span>
      <Link className="error-modal__link" to="/preferences/subscription/">{t('payment page')}.</Link>
    </div>
  </ModalWindow>
);

ErrorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  errorFromSubscriptionMiddleware: PropTypes.instanceOf(Object),
};

ErrorModal.defaultProps = {
  errorFromSubscriptionMiddleware: {
    business: '',
    virtuoso: '',
    first_part: '',
    or: '',
    plan: '',
    payment_page: '',
  },
};

export default ErrorModal;

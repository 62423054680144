import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { Label, OnlineStatusLabel } from '../../../../../../components';

const ChannelItem = ({
  channelId, managersChannels, style, channelStatusOnline,
}) => (
  <NavLink
    to={`/messenger/${channelId}/`}
    activeClassName="chanel-nav-group__item--active"
    className={`chanel-nav-group__item text--cut
    ${managersChannels[channelId].unread_count ? 'channel--has-unread chanel-nav-group__item--new' : ''}`}
    style={{ ...style }}
  >
    <OnlineStatusLabel status={channelStatusOnline} />
    <span className="text--cut">{managersChannels[channelId].name}</span>
    {managersChannels[channelId].unread_count ? (
      <Label
        className="label--xs label--fill"
      >
        {managersChannels[channelId].unread_count}
      </Label>
    ) : null}
  </NavLink>
);

ChannelItem.propTypes = {
  channelId: PropTypes.number.isRequired,
  managersChannels: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  style: PropTypes.shape({}),
  channelStatusOnline: PropTypes.number.isRequired,
};

ChannelItem.defaultProps = {
  style: {},
};

export default ChannelItem;

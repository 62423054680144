import {
  compose, withHandlers, getContext, withState, lifecycle,
} from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import { when } from 'ramda';
import { isNotNil } from 'ramda-extension';
import SearchMessages from './searchMessages';
import { messengerSelectors, messengerActions } from '../../../../../../state/messenger';
import { uiActions } from '../../../../../../state/ui';
import { withUserProfile, withWindowWidth } from '../../../../../../utils/enchancers';
import { userSelectors } from '../../../../../../state/user';
import { debounceFunc } from '../../../../../../utils/helpers/commonHelpers';

const mapStateToProps = (state) => {
  const channelId = messengerSelectors.getActiveChannel(state);
  return {
    result: messengerSelectors.getSearchMessagesResult(state),
    entities: messengerSelectors.getSearchMessagesEntities(state),
    channelId: channelId.id,
    channel: messengerSelectors.getChannelById(state)(channelId.id),
    user: userSelectors.getUserData(state),
    count: messengerSelectors.getSearchMessagesCount(state),
    hasMore: messengerSelectors.getSearchMessagesHasMore(state),
    isPendingSearchMessagesMore: messengerSelectors.getPendingSearchMessages(state),
  };
};

const mapDispatchToProps = ({
  closeRightSideBar: () => uiActions.changeRightSidebarStatus(false),
  setScrollToMessage: messengerActions.setScrollToMessage,
  searchMessagesRequest: messengerActions.searchMessagesRequest,
  resetSearchMessages: messengerActions.resetSearchMessages,
});

const onShowUserProfileHandler = ({
  onSetUserProfile,
}) => onSetUserProfile;

const onChangeSearchHandler = ({ setSearchValue, onSearchMessages }) => (e) => {
  setSearchValue(e.target.value);
  debounceFunc(onSearchMessages, 800, false, 'onSearchMessages');
};

const onSearchMessagesHandler = ({
  searchMessagesRequest, searchValue,
  channel,
}) => () => when(isNotNil, () => searchMessagesRequest({
  content: searchValue,
  channelId: channel.id,
}))(searchValue);

const onLoadMoreSearchMessagesHandler = ({
  searchMessagesRequest, searchValue, channel, result,
}) => () => searchMessagesRequest({
  content: searchValue, channelId: channel.id, limit: 10, offset: result.length,
});

const enhance = compose(
  getContext({
    setSelectedMessageGlobal: PropTypes.func,
  }),
  withUserProfile,
  withWindowWidth(),
  connect(mapStateToProps, mapDispatchToProps),
  withState('searchValue', 'setSearchValue', ''),
  withNamespaces(['common', 'chat']),
  withHandlers({
    onSearchMessages: onSearchMessagesHandler,
  }),
  withHandlers({
    onShowUserProfile: onShowUserProfileHandler,
    onChangeSearch: onChangeSearchHandler,
    onLoadMoreSearchMessages: onLoadMoreSearchMessagesHandler,
  }),
  lifecycle({
    componentDidMount() {
      this.props.resetSearchMessages();
    },
    componentDidUpdate({ channel: { id } }) {
      if (id !== this.props.channel.id) {
        this.props.setSearchValue('');
        this.props.resetSearchMessages();
      }
    },
  }),
);
export default enhance(SearchMessages);

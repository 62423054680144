/* eslint-disable camelcase */
import { createActions } from 'redux-actions';
import {
  compose, identity, mergeLeft, omit, pathOr,
} from 'ramda';

import types from './types';
import { messengerSchemas } from './index';
import { setUsersUpdateMiddleware } from '../../utils/helpers/stateHelpers';
import { getIsShowUnreadMessage } from '../../utils/helpers/messengerHelpers/channelHelpers';
import { setErrorPage } from '../ui/actions';
import { getClientsRequest } from '../clients/actions';
import { getNormalizeErrorObject } from '../../utils/helpers/requestHelpers';

const getSelectorNameForGetMessages = ({ offset, isAfterSocketReconnect }) => {
  if (isAfterSocketReconnect) return 'getMessagesAfterBrokenSocket';
  return offset <= 0 ? 'getMessagesRequest' : 'getMessagesMoreRequest';
};

const errorPageDataWhenGetChannel = compose(mergeLeft({ href: '/messenger/1', label: 'Go back' }),
  getNormalizeErrorObject);


export const {
  assignManagerRequest,
  getLatestMessagesRequest,
  updateMessageRequest,
  getChannelRequest,
  getGroupChannelsRequest,
  getDirectChannelsRequest,
  getManagersChannelsRequest,
  addPinMessageRequest,
  getPinnedMessagesRequest,
  deletePinnedMessageRequest,
  submitMessageRequest,
  addMemberToChannelRequest,
  joinToChannelRequest,
  deleteMemberFromChannelRequest,
  deleteMessageRequest,
  readMessagesRequest,
  deleteChannelRequest,
  updateChannelRequest,
  getTotalUnreadCountRequest,
  getGeneralUnreadCountRequest,
  getDirectChannelByUserIdRequest,
  leaveOfChannelRequest,
  searchMessagesRequest,
  getLatestUnreadMessageRequest,
  addChannel,
  deletePinMessage,
  setLatestMessages,
  setGroupChannels,
  updateGroupChannels,
  setDirectChannels,
  setManagersChannels,
  setMatchedDirectChannels,
  setYouBelongToChannels,
  setYouCanJoinChannels,
  connectUserToChannel,
  setActiveChannel,
  changeChannel,
  cancelMessageRequest,
  setMoreMessages,
  setNewMessage,
  emitNewMessage,
  setHasNewMessages,
  setTimestamps,
  setLastMessageTime,
  setPinMessage,
  setLoadedChannel,
  setChannel,
  setPinnedMessages,
  setUnreadCount,
  setLastMessageId,
  setCountLoadedMessage,
  updateChannels,
  setMemberToChannel,
  deleteMemberFromChannel,
  redirectChannelNotFound,
  deleteChannel,
  deleteGroupChannel,
  emitUpdateMessage,
  updateMessage,
  deleteMessage,
  setReadChannel,
  textareaChanged,
  setMuteChannel,
  deleteMuteChannel,
  updateChannelBySocket,
  deleteChannelBySocketWatcher,
  setTotalUnreadCount,
  updateLastUserOwnerMessage,
  openDirectChannelByUserId,
  getDirectChannelByUserIdSuccess,
  setMessage,
  emitDeleteMessage,
  resetMessage,
  setMessagesPuck,
  setScrollToMessage,
  leaveOfChannel,
  resetPinMessage,
  setYouCanJoinDirectChannels,
  setSearchMessages,
  resetSearchMessages,
  setSearchMessagesMore,
  setLatestUnreadMessage,
  getChannelError,
  readMessagesWs,
  setUnreadCountChannel,
  setGeneralUnreadCount,
} = createActions(
  {
    [types.GET_LATEST_MESSAGES_REQUEST]: [
      // TODO: В ідеалі меседжі також мають мати просто обєкт юзера
      identity,
      ({
        limit, channelId, offset, isAfterSocketReconnect, ...params
      }, meta) => ({
        async: true,
        params: {
          limit, offset, ...params,
        },
        route: `/channels/${channelId}/messages`,
        selectorName: getSelectorNameForGetMessages({ offset, isAfterSocketReconnect }),
        method: 'GET',
        ...omit(['isUsersUpdate', meta]),
        actions: {
          success: offset <= 0 ? setLatestMessages : setMoreMessages,
          meta: { channelId, ...omit(['isUsersUpdate', meta]) },
        },
        ...meta,
        schema: {
          rules: messengerSchemas.messagesSchema,
          pathData: ['data'],
        },
      }),
    ],
    [types.UPDATE_MESSAGE_REQUEST]: [
      identity,
      ({ channelId, message_id, content }, meta) => ({
        async: true,
        route: `/channels/${channelId}/messages/${message_id}`,
        selectorName: 'updateMessage',
        method: 'PUT',
        params: {
          content,
          id: channelId,
          message_id,
        },
        ...meta,
      }),
    ],
    [types.ASSIGN_MANAGER_REQUEST]: [
      identity,
      ({ channelId, manager_id }, meta) => ({
        async: true,
        route: `/channels/${channelId}/assign-manager`,
        selectorName: 'assignManagerRequest',
        method: 'PUT',
        schema: {
          rules: { channel: { members: messengerSchemas.membersSchema } },
          pathData: [],
        },
        params: {
          manager_id,
        },
        // actions: {
        //   success: () => console.log('success', manager_id),
        //   error: () => console.log('error', manager_id),
        // },
        ...meta,
      }),
    ],
    [types.GET_CHANNEL_REQUEST]: [
      () => ({}),
      (id, meta = { isSetActive: true }) => ({
        async: true,
        route: `/channels/${id}`,
        selectorName: 'getChannelRequest',
        method: 'GET',
        schema: {
          rules: { channel: { members: messengerSchemas.membersSchema } },
          pathData: [],
        },
        actions: {
          success: setUsersUpdateMiddleware(setChannel, { ...meta, pathEntities: ['data', 'entities', 'members'] }),
          error: compose(setErrorPage, errorPageDataWhenGetChannel),
        },
        ...meta,
      }),
    ],
    [types.GET_GROUP_CHANNELS_REQUEST]: [
      identity,
      (payload, meta = {}) => ({
        async: true,
        route: '/channels',
        params: {
          limit: meta.limit,
          offset: meta.offset,
          name: meta.search,
          order: meta.order,
          sortBy: meta.sortBy,
          notMember: meta.notMember,
        },
        selectorName: 'getGroupChannelsRequest',
        method: 'GET',
        schema: {
          rules: messengerSchemas.groupChannelsSchema,
          pathData: ['data'],
        },
        actions: {
          success: meta.success || (meta.isUpdate ? updateGroupChannels : setGroupChannels),
        },
        ...meta,
      }),
    ],
    [types.GET_DIRECT_CHANNELS_REQUEST]: [
      identity,
      (payload, meta = {}) => ({
        async: true,
        route: meta.notMember ? '/channels/users-without-direct' : '/channels/direct',
        params: {
          limit: meta.limit,
          offset: meta.offset,
          order: meta.order,
          name: meta.search,
          sortBy: meta.sortBy,
        },
        selectorName: 'getDirectChannelsRequest',
        method: 'GET',
        schema: {
          rules: messengerSchemas.directChannelsSchema,
          pathData: ['data'],
        },
        actions: {
          success: meta.success || setDirectChannels,
        },
        ...meta,
      }),
    ],
    [types.GET_MANAGERS_CHANNELS_REQUEST]: [
      identity,
      (payload, meta = {}) => ({
        async: true,
        route: '/channels/managers',
        params: {
          limit: meta.limit,
          offset: meta.offset,
          name: meta.search,
          order: meta.order,
          sortBy: meta.sortBy,
          notMember: meta.notMember,
        },
        selectorName: 'getManagersChannelsRequest',
        method: 'GET',
        schema: {
          rules: messengerSchemas.managersChannelsSchema,
          pathData: ['data'],
        },
        actions: {
          success: meta.success || setManagersChannels,
        },
        ...meta,
      }),
    ],
    [types.ADD_PIN_MESSAGE_REQUEST]: [
      identity,
      ({ channelId, message_id }, meta) => ({
        async: true,
        params: {
          id: channelId,
          message_id,
        },
        route: `/channels/${channelId}/pinned-messages/${message_id}`,
        selectorName: 'addPinMessage',
        method: 'POST',
        ...meta,
      }),
    ],
    [types.GET_PINNED_MESSAGES_REQUEST]: [
      identity,
      ({ channelId }, meta) => ({
        async: true,
        params: { channelId },
        actions: {
          success: setPinnedMessages,
          meta: {
            channelId,
          },
        },
        schema: {
          rules: messengerSchemas.pinnedMessagesSchema,
          pathData: [],
          flat: true,
        },
        route: `/channels/${channelId}/pinned-messages`,
        selectorName: 'getPinnedMessages',
        method: 'GET',
        ...meta,
      }),
    ],
    [types.DELETE_PINNED_MESSAGE_REQUEST]: [
      identity,
      ({ channelId, message_id }, meta) => ({
        async: true,
        params: {
          id: channelId,
          message_id,
        },
        route: `/channels/${channelId}/pinned-messages/${message_id}`,
        selectorName: 'deletePinMessage',
        method: 'DELETE',
        ...meta,
      }),
    ],
    [types.SUBMIT_MESSAGES_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        params,
        route: `/channels/${params.id}/messages`,
        callbacks: {
          success: pathOr(['callbacks', 'success'], meta),
        },
        selectorName: 'submitMessageRequest',
        method: 'POST',
      }),
    ],
    [types.ADD_MEMBER_TO_CHANNEL_REQUEST]: [
      identity,
      ({ channelId, ...params }, meta) => ({
        async: true,
        params,
        route: `/channels/${channelId}/members/add`,
        callbacks: {
          success: pathOr(['callbacks', 'success'], meta),
        },
        selectorName: 'addMemberToChannelRequest',
        method: 'PUT',
        ...meta,
      }),
    ],
    [types.JOIN_TO_CHANNEL_REQUEST]: [
      identity,
      ({ id, ...params }, meta) => ({
        async: true,
        params,
        route: `/channels/${id}/members/join`,
        selectorName: 'joinToChannelRequest',
        schema: {
          rules: { channel: { members: messengerSchemas.membersSchema } },
          pathData: [],
        },
        actions: {
          success: [updateChannels, getClientsRequest],
        },
        callbacks: {
          success: () => {
            pathOr(identity, ['callbacks', 'success'], meta);
          },
        },
        method: 'PUT',
        ...meta,
      }),
    ],
    [types.DELETE_MEMBER_FROM_CHANNEL_REQUEST]: [
      identity,
      ({ channelId, memberId, ...params }, meta) => ({
        async: true,
        params,
        route: `/channels/${channelId}/members/${memberId}`,
        selectorName: 'deleteMemberFromChannelRequest',
        callbacks: {
          success: pathOr(['callbacks', 'success'], meta),
        },
        schema: {
          rules: { channel: { members: messengerSchemas.membersSchema } },
          pathData: [],
        },
        actions: {
          success: deleteMemberFromChannel,
        },
        method: 'DELETE',
        ...meta,
      }),
    ],
    [types.DELETE_MESSAGE_REQUEST]: [
      identity,
      ({ channelId, messageId }, meta) => ({
        async: true,
        params: ({
          message_id: messageId,
          channel_id: channelId,
        }),
        route: `/channels/${channelId}/messages/${messageId}`,
        selectorName: 'deleteMessageRequest',
        // actions: {
        //   success: getLatestMessagesRequest,
        // },
        callbacks: {
          success: pathOr(['callbacks', 'success'], meta),
        },
        actions: {
          success: deleteMessage,
        },
        method: 'DELETE',
        ...meta,
      }),
    ],
    [types.READ_MESSAGES_REQUEST]: [
      identity,
      ({ channelId, ...params }, meta) => ({
        async: true,
        params,
        route: `/channels/${channelId}/read`,
        selectorName: 'readMessagesRequest',
        actions: {
          success: setUnreadCount,
          meta: ({
            isShowUnreadMessage: getIsShowUnreadMessage(meta),
          }),
        },
        method: 'GET',
        ...meta,
      }),
    ],
    [types.DELETE_CHANNEL_REQUEST]: [
      identity,
      ({ channelId, ...params }, meta) => ({
        async: true,
        params,
        route: `/channels/${channelId}`,
        selectorName: 'deleteChannelRequest',
        callbacks: {
          success: pathOr(['callbacks', 'success'], meta),
        },
        method: 'DELETE',
        ...meta,
      }),
    ],
    [types.ADD_CHANNEL]: [
      identity,
      ({ params }, meta) => ({
        async: true,
        params,
        route: '/channels',
        selectorName: 'setNewChannel',
        method: 'POST',
        ...meta,
      }),
    ],
    [types.UPDATE_CHANNEL_REQUEST]: [
      identity,
      ({ channelId, ...params }, meta) => ({
        async: true,
        ...params,
        route: `/channels/${channelId}/`,
        selectorName: 'updateChannelRequest',
        callbacks: {
          success: pathOr(['callbacks', 'success'], meta),
        },
        method: 'PUT',
        ...meta,
      }),
    ],
    [types.GET_TOTAL_UNREAD_COUNT_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        params,
        route: '/channels/unread-messages-count',
        selectorName: 'getTotalUnreadCountRequest',
        actions: {
          success: setTotalUnreadCount,
        },
        method: 'GET',
        ...meta,
      }),
    ],
    [types.GET_GENERAL_UNREAD_COUNT_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        params,
        route: '/channels/unread-messages-count?channel_id=1',
        selectorName: 'getGeneralUnreadCountRequest',
        actions: {
          success: setGeneralUnreadCount,
        },
        method: 'GET',
        ...meta,
      }),
    ],
    [types.GET_DIRECT_CHANNEL_BY_USER_ID_REQUEST]: [
      identity,
      ({ userId, ...params }, meta) => ({
        async: true,
        params,
        route: `/channels/direct/${userId}`,
        selectorName: 'getDirectChannelByUserId',
        method: 'GET',
        actions: {
          success: getDirectChannelByUserIdSuccess,
        },
        ...meta,
      }),
    ],
    [types.LEAVE_OF_CHANNEL_REQUEST]: [
      identity,
      ({ channelId, ...params }, meta) => ({
        async: true,
        params,
        route: `/channels/${channelId}/members/leave`,
        selectorName: 'leaveOfChannelRequest',
        method: 'DELETE',
        actions: {
          success: leaveOfChannel,
        },
        ...meta,
      }),
    ],
    [types.SEARCH_MESSAGES_REQUEST]: [
      identity,
      ({ channelId, ...params }, meta) => ({
        async: true,
        params,
        route: `/channels/${channelId}/messages`,
        selectorName: 'searchMessagesRequest',
        method: 'GET',
        actions: {
          success: params.offset ? setSearchMessagesMore : setSearchMessages,
        },
        schema: {
          rules: messengerSchemas.searchMessagesSchema,
          pathData: ['data'],
        },
        ...meta,
      }),
    ],
    [types.GET_LATEST_UNREAD_MESSAGE_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        params,
        route: '/channels/last-unread-message',
        selectorName: 'getLatestUnreadMessageRequest',
        method: 'GET',
        actions: {
          success: action => setLatestUnreadMessage({ ...action, meta: params }),
        },
        ...meta,
      }),
    ],
  },
  types.DELETE_PIN_MESSAGE,
  types.SET_LATEST_MESSAGES,
  types.SET_GROUP_CHANNELS,
  types.UPDATE_GROUP_CHANNELS,
  types.SET_DIRECT_CHANNELS,
  types.SET_MATCHED_DIRECT_CHANNELS,
  types.SET_YOU_BELONG_TO_CHANNELS,
  types.SET_YOU_CAN_JOIN_CHANNELS,
  types.CONNECT_USER_TO_CHANNEL,
  types.SET_ACTIVE_CHANNEL,
  types.CHANGE_CHANNEL,
  types.CANCEL_MESSAGE_REQUEST,
  types.SET_MORE_MESSAGES,
  types.SET_NEW_MESSAGE,
  types.EMIT_NEW_MESSAGE,
  types.SET_HAS_NEW_MESSAGES,
  types.SET_TIMESTAMPS,
  types.SET_LAST_MESSAGE_TIME,
  types.SET_PIN_MESSAGE,
  types.SET_LOADED_CHANNEL,
  types.SET_CHANNEL,
  types.SET_PINNED_MESSAGES,
  types.SET_UNREAD_COUNT,
  types.SET_LAST_MESSAGE_ID,
  types.SET_COUNT_LOADED_MESSAGE,
  types.UPDATE__CHANNELS,
  types.SET_MEMBER_TO_CHANNEL,
  types.DELETE_MEMBER_FROM_CHANNEL,
  types.REDIRECT_CHANNEL_NOT_FOUND,
  types.DELETE_CHANNEL,
  types.DELETE_GROUP_CHANNEL,
  types.EMIT_UPDATE_MESSAGE,
  types.UPDATE_MESSAGE,
  types.DELETE_MESSAGE,
  types.SET_READ_CHANNEL,
  types.TEXTAREA_CHANGED,
  types.SET_MUTE_CHANNEL,
  types.DELETE_MUTE_CHANNEL,
  types.UPDATE_CHANNEL_BY_SOCKET,
  types.DELETE_CHANNEL_BY_SOCKET_WATCHER,
  types.SET_TOTAL_UNREAD_COUNT,
  types.UPDATE_LAST_USER_OWNER_MESSAGE,
  types.OPEN_DIRECT_CHANNEL_BY_USER_ID,
  types.GET_DIRECT_CHANNEL_BY_USER_ID_SUCCESS,
  types.SET_MESSAGE,
  types.EMIT_DELETE_MESSAGE,
  types.RESET_MESSAGE,
  types.SET_MESSAGES_PUCK,
  types.SET_SCROLL_TO_MESSAGE,
  types.LEAVE_OF_CHANNEL,
  types.RESET_PIN_MESSAGE,
  types.SET_YOU_CAN_JOIN_DIRECT_CHANNELS,
  types.SET_SEARCH_MESSAGES,
  types.RESET_SEARCH_MESSAGES,
  types.SET_SEARCH_MESSAGES__MORE,
  types.SET_LATEST_UNREAD_MESSAGE,
  types.GET_CHANNEL_ERROR,
  types.READ_MESSAGES_WS,
  types.SET_UNREAD_COUNT_CHANNEL,
  types.SET_GENERAL_UNREAD_COUNT,
  types.SET_MANAGERS_CHANNELS,
);

import { createSelector } from 'reselect';
import {
  pathOr, identity, prop,
} from 'ramda';
import { memoizeWithIdentity } from 'ramda-extension';

export const getClientsList = createSelector(pathOr([], ['clients', 'result']), identity);
export const getClientsEntities = createSelector(pathOr({}, ['clients', 'entities']), identity);
export const getClientsCount = createSelector(pathOr(0, ['clients', 'count']), identity);
export const getClientsHasMore = createSelector(pathOr(false, ['clients', 'hasMore']), identity);
export const getClientsPending = createSelector(pathOr(false, ['pending', 'getClientsRequest']), identity);

// export const getGroupedBotsList = createSelector(
//   getClientsList,
//   getClientsEntities,
//   (list, entities) => groupBy((id) => {
//     const client = prop(id, entities);
//     return bot.type;
//   }, list),
// );


export const getClientById = createSelector(
  getClientsEntities,
  clients => memoizeWithIdentity(clientId => prop(clientId, clients)),
);

import {
  compose,
} from 'recompose';
import { push as routerPush } from 'connected-react-router';
import { connect } from 'react-redux';

import { withNamespaces } from 'react-i18next';
import UsersList from './usersList';
import { permissionsSelectors } from '../../../../state/permissions';

const mapStateToProps = state => ({
  roles: permissionsSelectors.getRolesEntities(state),
});

const mapDispatchToProps = {
  push: routerPush,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces('common'),
);

export default enhance(UsersList);

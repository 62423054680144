import React from 'react';
import PropTypes from 'prop-types';

import { ScrollWrapper, FormsCollection } from '../../components';
import { ClientsList } from './components';

import './style.sass';


const ClientsContainer = ({
  searchValue,
  setSearchValue,
  onSearch,
  clientsList,
  clientsEntities,
  clientsHasMore,
  clientsCount,
  gridName,
  pagination,
  sort,
  order,
  onSortBy,
  isPending,
  botsEntities,
  managersEntities,
  t,
}) => (
  <ScrollWrapper>
    <div className="clients">
      <div className="row clients__search-row">
        <FormsCollection.Search
          className="search-field--fill"
          placeholder={t('Search')}
          name="search-clients"
          value={searchValue}
          onChange={setSearchValue}
          onClick={onSearch}
          onSearchByEnter={onSearch}
        />
      </div>
      <ClientsList
        clientsList={clientsList}
        managersEntities={managersEntities}
        clientsEntities={clientsEntities}
        botsEntities={botsEntities}
        clientsHasMore={clientsHasMore}
        clientsCount={clientsCount}
        gridName={gridName}
        pagination={pagination}
        sort={sort}
        order={order}
        onSortBy={onSortBy}
        isLoading={isPending}
      />
    </div>
  </ScrollWrapper>
);

ClientsContainer.propTypes = {
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  clientsEntities: PropTypes.instanceOf(Object).isRequired,
  botsEntities: PropTypes.instanceOf(Object).isRequired,
  managersEntities: PropTypes.instanceOf(Object).isRequired,
  clientsList: PropTypes.instanceOf(Array).isRequired,
  clientsHasMore: PropTypes.bool.isRequired,
  clientsCount: PropTypes.number.isRequired,
  gridName: PropTypes.string.isRequired,
  pagination: PropTypes.instanceOf(Object).isRequired,
  sort: PropTypes.string,
  order: PropTypes.string,
  onSortBy: PropTypes.func.isRequired,
  isPending: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

ClientsContainer.defaultProps = {
  sort: '',
  order: '',
};

export default ClientsContainer;

import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { ButtonBrill } from '../../../../components/buttonsCollection';
import BotsItem from '../botsItem';

import './style.sass';


const BotsList = ({
  listOfConnections,
  messengerDetail,
  openModalHandler,
  editModalHandler,
  deleteModalHandler,
  messengerId,
  t,
}) => (
  <li className="bots-list">
    <div className="bots-list__head">
      <div className="bots-list__head-container">
        <img className="bots-list__img" src={messengerDetail.pathImage} alt={messengerDetail.nameSocial} />
        <h3 className="bots-list__name">{messengerDetail.nameSocial}</h3>
      </div>
      <NavLink className="bots-list__link-info" to={`/guide/${messengerDetail.nameSocial.toLowerCase()}/`}><span className="icon-notify-info bots-list__info-icon" /></NavLink>
    </div>
    <ul className="bots-item">
      {listOfConnections.map(id => (
        <BotsItem
          key={id}
          id={id}
          messengerId={messengerId}
          editModalHandler={() => editModalHandler(id)}
          deleteModalHandler={() => deleteModalHandler(id)}
        />
      ))}
    </ul>
    <div className="bots-list__body">
      <ButtonBrill className="bots-list__add-bot button--sm button-brill--fill" onClick={openModalHandler} title={t('ADD ACCOUNT')}>{t('ADD ACCOUNT')}</ButtonBrill>
    </div>
  </li>
);

BotsList.propTypes = {
  messengerDetail: PropTypes.instanceOf(Object),
  listOfConnections: PropTypes.instanceOf(Array),
  openModalHandler: PropTypes.func.isRequired,
  editModalHandler: PropTypes.func.isRequired,
  deleteModalHandler: PropTypes.func.isRequired,
  messengerId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

BotsList.defaultProps = {
  listOfConnections: [],
  messengerDetail: {},
};

export default BotsList;

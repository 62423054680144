import {
  values, compose, assoc, map, path, curry, prop, set, lensProp, pathOr, test,
} from 'ramda';
import {
  renameEachObjectKeys, renameKeys, safeNotNil, map as mapFunctor,
} from './commonHelpers';
import { getFullName } from './userHelpers';
import { getImageUrl } from './requestHelpers';
import { placeCaretAfterNode } from './uiComponentHelpers/caretHelpers';
import { IMAGES_SIZE } from '../../constants/ui';

const setLabelAsUsername = taskData => assoc('label', getFullName(taskData), taskData);

const renameKeysForUsers = renameEachObjectKeys({ username: 'label', id: 'value' });
const renameKeysTitleIntoLabel = renameEachObjectKeys({ title: 'label' });
const renameKeysTitleIntoValue = renameKeys({ title: 'label', id: 'value' });

const makeSelectArrayFromEntities = curry((transform, source) => compose(
  transform,
  values,
)(source));

const setFullUsernameAsLabel = compose(
  map(setLabelAsUsername),
  values,
);

const makeObjectWithAvatars = user => ({
  alt: getFullName(user),
  id: prop('id', user),
  select: false,
  src: pathOr(null, ['avatar'], user) ? getImageUrl(path(['avatar'], user), IMAGES_SIZE.sm) : null,
  importantBackground: user.importantBackground,
});

const avatarLens = lensProp('active');

const toggleAvatar = curry((selector, item) => {
  const avatar = item;
  if (avatar.id === selector) {
    return set(avatarLens, !avatar.active, item);
  }
  return avatar;
});

const setAvatarActive = curry(id => map(toggleAvatar(id)));
const getImageFrom = path(['image', 'id']);
const getArrayFromEntities = makeSelectArrayFromEntities(renameKeysTitleIntoLabel);

const setCaretToTheEndPosition = (element) => {
  element.focus();
  mapFunctor(placeCaretAfterNode)(safeNotNil(prop('lastChild', element)));
};

const correctLink = href => (test(/^(http|https)/g, href) ? href : `//${href}`);

export {
  setAvatarActive,
  renameKeysForUsers,
  renameKeysTitleIntoValue,
  getImageFrom,
  setCaretToTheEndPosition,
  getArrayFromEntities,
  setFullUsernameAsLabel,
  makeSelectArrayFromEntities,
  renameKeysTitleIntoLabel,
  makeObjectWithAvatars,
  correctLink,
};

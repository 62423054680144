export const USER_INACTIVE = 0;
export const USER_ACTIVE = 1;
export const USER_UNDER_REVIEW = 2;
export const USER_PENDING = 3;

export const USER_STATUSES = {
  0: {
    title: 'Inactive',
    label: 'Inactive',
    color: '#eee',
    value: 0,
  },
  1: {
    title: 'Active',
    label: 'Active',
    color: '#005b06',
    value: 1,
  },
  2: {
    title: 'Under Review',
    label: 'Under Review',
    color: '#ffc53d',
    value: 2,
  },
  3: {
    title: 'Pending',
    label: 'Pending',
    color: '#ffc53d',
    value: 3,
  },
};

import { compose, getContext } from 'recompose';
import PropTypes from 'prop-types';

import { withNamespaces } from 'react-i18next';
import GuideTelegramContainer from './guideTelegramContainer';


const enhance = compose(
  withNamespaces('common'),
  getContext({
    goBackHandler: PropTypes.func.isRequired,
  }),
);

export default enhance(GuideTelegramContainer);

import {
  messagesNormalize,
  unreadMessageMiddleware,
  newMessageNormalize,
  pinMessageMiddleware,
  subscribeChannelMiddleware,
  // eslint-disable-next-line no-unused-vars
  getMessagesMiddleware,
  updateMessagesMiddleware,
  updateFocusedChatMiddleware,
  deleteChannelMiddleWare,
  deleteMessageMiddleware,
  deleteChannelSocketWatcher,
  deleteMessageMiddleWare,
  readMessagesByWsMiddleware,
} from './messenger';

import { reconnectSocketToServer } from './websocket';

import clearGridsMiddleware, { showNotificationMiddleware, setWsErrorMiddleware } from './ui';

import {
  setUserProfileMiddleware,
  setUsersMiddleware,
} from './users';

const middleware = [
  messagesNormalize,
  updateMessagesMiddleware,
  pinMessageMiddleware,
  subscribeChannelMiddleware,
  deleteMessageMiddleware,
  unreadMessageMiddleware,
  updateFocusedChatMiddleware,
  // getMessagesMiddleware,
  // TODO for this middleware we need to developing one instance with all dynamic message dimensions
  /*
  *  Because if we have a lot of items and render them in one pack it was slowly
  * */
  deleteChannelMiddleWare,


  clearGridsMiddleware,
  showNotificationMiddleware,

  setUserProfileMiddleware,

  setUsersMiddleware,
  deleteChannelSocketWatcher,
  newMessageNormalize,
  deleteMessageMiddleWare,
  reconnectSocketToServer,

  setWsErrorMiddleware,
  readMessagesByWsMiddleware,
];

export default middleware;

import React from 'react';
import PropTypes from 'prop-types';

import {
  ConfirmModal,
} from '../../../../components';
import { getFullName } from '../../../../utils/helpers/userHelpers';

const DeleteUserModal = ({
  isOpen,
  isPending,
  onClose,
  selectedUser,
  onDelete,
  t,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    isPending={isPending}
    actionTitle="Delete"
    onAction={onDelete}
    onClose={onClose}
    title={t('user:Delete user')}
  >

    {t('user:Are you sure you want delete')}
    <b>{getFullName(selectedUser) || selectedUser.username}</b>
    {' '}
    {
      getFullName(selectedUser) && (
      <small>
        {' '}

        (
        {selectedUser.username}
        {' '}

        )
        {' '}
      </small>
      )
    }

    {t('user:user?')}
  </ConfirmModal>
);

DeleteUserModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  selectedUser: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
};

export default DeleteUserModal;

import React from 'react';
import PropTypes from 'prop-types';
import './style.sass';

const Checkbox = ({
  label, id, name, className, disabled,
}) => (
  <div
    className={`checkbox-field  ${disabled ? 'checkbox-field--disabled' : ''} ${className}`}
  >
    <input checked className="checkbox-field__input" type="checkbox" disabled={disabled} name={name} id={id} />
    <label htmlFor={id} className="checkbox-field__pseudo-input" />
    <label className="checkbox-field__label text" htmlFor={id}>{label}</label>
  </div>
);
Checkbox.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};
Checkbox.defaultProps = {
  label: '',
  className: '',
  disabled: false,
};
export default Checkbox;

import React from 'react';
import PropTypes from 'prop-types';

import { ScrollWrapper } from '../../..';
import './style.sass';

const TextArea = ({
  name,
  onChange,
  error,
  value,
  touched,
  onBlur,
  placeholder,
  placeholderJump,
  setRef,
  pattern,
  inFocus,
  autoHeight,
  maxHeight,
  className,
  scrollHeight,
  children,
}) => (
  <div className={`textarea-group ${className} ${
    inFocus ? 'textarea-group--pseudo-focus' : ''} ${autoHeight ? 'textarea-group--auto-height' : ''}`}
  >
    <div className="textarea-group__wrap">
      {
        maxHeight ? (

          <ScrollWrapper refCustom={e => setRef('scrollWrapper', e)} className="textarea-group__scroll-wrapper" autoHide heightIs={scrollHeight}>
            <textarea
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              pattern={pattern}
              className="textarea-group__place-write"
              placeholder={placeholder}
              ref={e => setRef('input', e)}
            />
          </ScrollWrapper>
        ) : (
          <textarea
            name={name}
            id={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            pattern={pattern}
            className="textarea-group__place-write"
            placeholder={placeholder}
            ref={e => setRef('input', e)}
          />
        )
      }
      {children}
      {placeholderJump && (<label htmlFor={name} className="textarea-group__placeholder-jump">{placeholderJump}</label>)}
    </div>
    {error && touched && <span>{error}</span>}
  </div>
);
TextArea.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
  ]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  error: PropTypes.string,
  value: PropTypes.string,
  touched: PropTypes.bool,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  placeholderJump: PropTypes.string,
  setRef: PropTypes.func,
  pattern: PropTypes.string,
  inFocus: PropTypes.bool,
  className: PropTypes.string,
  autoHeight: PropTypes.bool,
  scrollHeight: PropTypes.number,
  maxHeight: PropTypes.number,
};
TextArea.defaultProps = {
  children: '',
  onChange: () => {},
  error: '',
  value: '',
  touched: false.valueOf(),
  onBlur: () => {},
  placeholder: '',
  placeholderJump: '',
  setRef: () => {},
  pattern: '',
  inFocus: false,
  className: '',
  autoHeight: false,
  scrollHeight: 0,
  maxHeight: 0,


};
export default TextArea;

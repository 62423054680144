import React from 'react';
import PropTypes from 'prop-types';

import {
  Layouts, NoInternetConnection,
} from '../../components';
import {
  LeftSideBar, Header, ProfileContainer,
} from '../../containers';
import './style.sass';

const Profile = ({ t }) => (
  <Layouts.Main>
    <LeftSideBar />
    <div className="main-container">
      <NoInternetConnection>
        <Header hideToggleRightSideBar>
          <h1 className="weight--medium title--xl title-page">
            {t('Profile settings')}
          </h1>
        </Header>
        <div className="main-container__content main-container__content--profile">
          <ProfileContainer />
        </div>
      </NoInternetConnection>
    </div>
  </Layouts.Main>
);

Profile.propTypes = {
  t: PropTypes.func.isRequired,
};

export default Profile;

import React from 'react';
import PropTypes from 'prop-types';
import './style.sass';
import NavTabs from '../navTabs';
import { Header, SettingsBotsContainer } from '../../../../containers';

const BotsWrapper = ({ t }) => (
  <>
    <Header hideToggleRightSideBar className="user-header">
      <>
        <h1 className="weight--medium title--xl title-page user-nav__title">

          {`${t('Settings')} - ${t('bots:Bots')}`}
        </h1>
        <NavTabs />
        </>
    </Header>
    <div className="main-container__content main-container__content--roles">
      <SettingsBotsContainer />
    </div>
  </>
);

BotsWrapper.propTypes = {
  t: PropTypes.func.isRequired,
};

export default BotsWrapper;

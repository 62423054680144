import {
  omit,
  filter,
  curry,
  compose,
  cond,
  includes,
  path,
  objOf,
  T,
  lensPath,
  equals,
  always,
  prop,
  propOr, assoc, pathOr,
} from 'ramda';
import { Either, Tuple } from 'ramda-fantasy';
import { equalsToTrue, flipIncludes, notEqualToFalse } from 'ramda-extension';

import { messengerActions, messengerSelectors } from '../../../state/messenger';
import {
  DIRECT_CHANNEL,
  DIRECT_CHANNEL_STORE, GENERAL_CHANNEL, GROUP_CHANNEL,
  GROUP_CHANNEL_STORE, GROUP_CHANNELS, MANAGER_CHANNEL_STORE, PRIVATE_CHANNEL,
} from '../../../constants/messenger';
import { getCreatedBy } from './messages';
import {
  alwaysFalse, isConditionRight, map, safeNotNil,
} from '../commonHelpers';

const deleteChannel = (channelId, state, isActiveChannel, next) => {
  const result = messengerSelectors.getGroupChannelsList(state);
  const entities = messengerSelectors.getGroupChannels(state);
  const count = messengerSelectors.getGroupChannelsCount(state);

  const newState = {
    entities: omit([channelId], entities),
    result: filter(id => id !== channelId, result),
    count: count - 1,
  };

  next(messengerActions.deleteGroupChannel(newState));
  next(messengerActions.getDirectChannelsRequest());
  next(messengerActions.getTotalUnreadCountRequest());
};

const getChannelId = prop('channel_id');
const getChannelProp = prop('channel');
const getUnreadCount = prop('unread_count');
const getId = prop('id');
const pathToChannelName = path(['channel', 'name']);
const pathToChannelType = ['channel', 'type'];
const pathToActiveChannelId = path(['messenger', 'activeChannel', 'id']);
const getIsShowUnreadMessage = propOr(true, 'isShowUnreadMessage');

const isChannelPrivate = equals(PRIVATE_CHANNEL);

const modifyChannel = curry((channels, modify, channelId) => compose(
  cond([
    [compose(
      notEqualToFalse,
      includes(channelId),
      path([GROUP_CHANNEL_STORE, 'result']),
    ), compose(
      objOf(GROUP_CHANNEL_STORE),
      modify,
      path([GROUP_CHANNEL_STORE]),
    )],
    [compose(
      notEqualToFalse,
      includes(channelId),
      path([MANAGER_CHANNEL_STORE, 'result']),
    ), compose(
      objOf(MANAGER_CHANNEL_STORE),
      modify,
      path([MANAGER_CHANNEL_STORE]),
    )],
    [T, compose(
      objOf(DIRECT_CHANNEL_STORE),
      modify,
      path([DIRECT_CHANNEL_STORE]),
    )],

  ]),
)(channels));

const getChannel = curry(channel => cond([
  [compose(
    notEqualToFalse,
    includes(channel),
    pathOr([], [GROUP_CHANNEL_STORE, 'result']),
  ), pathOr({}, [GROUP_CHANNEL_STORE, 'entities', Number(channel)])],
  [compose(
    notEqualToFalse,
    includes(channel),
    pathOr([], [MANAGER_CHANNEL_STORE, 'result']),
  ), pathOr({}, [MANAGER_CHANNEL_STORE, 'entities', Number(channel)])],
  [T, pathOr({}, [DIRECT_CHANNEL_STORE, 'entities', Number(channel)])],
]));

const unreadCountLens = channelId => lensPath(['entities', channelId, 'unread_count']);
const isShowUnreadMessageLens = channelId => lensPath(['entities', channelId, 'isShowUnreadMessage']);

const isEqualChannel = channelId => cond([
  [equals(channelId), Either.Right],
  [T, Either.Left],
]);

const checkIsWindowFocused = curry(isWindowFocus => compose(
  () => equals(isWindowFocus, true),
));

const actionIfChannelEqual = rightAction => Either.either(always(false), rightAction);

const isUserFocusOnChat = curry((isWindowFocus, channel, pathToChannel) => cond([
  [compose(
    actionIfChannelEqual(checkIsWindowFocused(isWindowFocus)),
    isEqualChannel(channel),
    channelId => Number(channelId),
    pathToChannel,
  ), Either.Left],
  [T, Either.Right],
]));

const senderUserEqual = senderUserId => cond([
  [compose(equals(senderUserId), getCreatedBy), Either.Left],
  [T, Either.Right],
]);

const compareChannelType = curry((type, pathTo) => compose(
  equals(type),
  path(pathTo),
));

const isChannelGroup = compareChannelType(GROUP_CHANNEL);
const isChannelCommon = pathTo => compose(
  cond([
    [equals(GENERAL_CHANNEL), () => true],
    [equals(GROUP_CHANNEL), () => true],
    [T, () => false],
  ]),
  path(pathTo),
);

const isChannelDirect = pathTo => compose(
  cond([
    [equals(DIRECT_CHANNEL), () => true],
    [equals(PRIVATE_CHANNEL), () => true],
    [T, () => false],
  ]),
  path(pathTo),
);

const objWithChannelIdAndUnread = unreadCount => compose(
  assoc('unread_count', unreadCount),
  objOf('channel'),
  objOf('id'),
  getId,
);

const mapSafeChannel = func => compose(
  map(func),
  safeNotNil,
);

const isGroupAndUserOwnsChannel = Either.either(alwaysFalse, (compose(
  Either.either(alwaysFalse, Tuple.snd),
  isConditionRight(compose(
    equalsToTrue,
    Tuple.snd,
  )),
)));

const isNotDirectOrGeneral = isConditionRight(compose(
  flipIncludes(GROUP_CHANNELS),
  Tuple.fst,
));

const isRenderChannelControl = compose(
  isGroupAndUserOwnsChannel,
  isNotDirectOrGeneral,
);

export {
  deleteChannel,
  getChannel,
  isShowUnreadMessageLens,
  pathToChannelType,
  isRenderChannelControl,
  isNotDirectOrGeneral,
  mapSafeChannel,
  getIsShowUnreadMessage,
  senderUserEqual,
  getChannelProp,
  getId,
  objWithChannelIdAndUnread,
  isChannelCommon,
  unreadCountLens,
  isChannelGroup,
  getUnreadCount,
  isChannelDirect,
  modifyChannel,
  pathToActiveChannelId,
  isChannelPrivate,
  pathToChannelName,
  getChannelId,
  isUserFocusOnChat,
};

// eslint-disable-next-line no-unused-vars
import React from 'react';
import {
  compose, pure, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { pathOr, prop } from 'ramda';

import ChannelItem from './channelItem';
import { messengerSelectors } from '../../../../../../state/messenger';
import { settingUsersSelectors } from '../../../../../../state/settingUsers';
import { userSelectors } from '../../../../../../state/user';
import { withRefs } from '../../../../../../utils/enchancers';

const mapStateToProps = (state, { channelId }) => ({
  channel: messengerSelectors.getDirectChannels(state)[channelId],
  usersList: settingUsersSelectors.getSettingUsersEntities(state),
  currentUser: userSelectors.getUserData(state),
});

const enhance = compose(
  pure,
  withRefs(),
  connect(mapStateToProps),
  withProps(({ usersList, channel, currentUser }) => ({
    channelStatusOnline: pathOr(pathOr(0, ['user', 'is_online'], channel),
      [(prop('user_id', channel)), 'is_online'], usersList),
    channelName: channel.name,
    unreadCount: channel.unread_count,
    username: currentUser.username,
    serviceId: channel.service_id,
  })),
);

export default enhance(ChannelItem);

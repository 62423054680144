import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';
import { prop } from 'ramda';

const MessageElements = ({ content, options }) => (
  <>
    {
    prop('parseToPlainText', options) ? (content) : (
      <span dangerouslySetInnerHTML={{ __html: content }} />)
  }
  </>
);

MessageElements.propTypes = {
  content: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Object),
};

MessageElements.defaultProps = {
  options: Object,
};

export default React.memo(MessageElements);

import { compose } from 'recompose';

import { withNamespaces } from 'react-i18next';
import DescriptionChannel from './descriptionChannel';


const enhance = compose(
  withNamespaces(['common', 'chat']),
);
export default enhance(DescriptionChannel);

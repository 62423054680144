import React from 'react';
import PropTypes from 'prop-types';
import { SubscriptionCard, HistoryPayment, TariffsContainer } from './components';
import './style.sass';
import ButtonBrill from '../../components/buttonsCollection/buttonBrill';
import { PAYMENTS_STATUSES, TARIFFS_NAMES } from '../../constants/subscription';
import { ConfirmPaymentModal, DeleteAccountModal, CancelSubscriptionModal } from './modals';


const SubscriptionContainer = ({
  t,
  onPay,
  onOpenPriceList,
  isOpenPricesList,
  onDeleteAccount,
  daysForEndPeriod,
  tariffTitle,
  tariffCost,
  tariff,
  paymentStatus,
  periodEndDate,
  cardNumber,
  lastNumberDays,
}) => (
  <>
    <div className="subscription__container">
      {!isOpenPricesList ? (
        <>
          <div className="subscription__row">
            <SubscriptionCard
              title={t('Current subscription')}
            >
              <div className="subscription-card__wrapper">
                <p className="subscription-card__plan">
                  {`${t(tariffTitle)}${tariff !== TARIFFS_NAMES.ENTREPRENEUR ? `(${t(tariffCost)})` : ''}`}
                </p>
                { tariff !== TARIFFS_NAMES.ENTREPRENEUR
                && paymentStatus === PAYMENTS_STATUSES.PAYMENT_STATUS_FREE ? (
                    <>
                      <p className="subscription-card__reminder">{t('You have some days of free trial!', {
                        key: daysForEndPeriod,
                        days: lastNumberDays === 1 ? t('Days-end-one') : lastNumberDays === 2 || lastNumberDays === 3 || lastNumberDays === 4 ? t('Days-end-until-four') : t('Days'),
                      })}
                      </p>
                      <p className="subscription-card__reminder">{t('Your free trial ends on', { key: periodEndDate })}
                      </p>
                    </>
                  ) : ('')}
                { tariff !== TARIFFS_NAMES.ENTREPRENEUR ? (
                  <p className="subscription-card__billing-date">
                    <span className="subscription-card__billing-date--bold">{t('Next billing date')}: </span>
                    {periodEndDate}
                  </p>
                ) : ''}
              </div>
              <div className="subscription-card__row">
                <ButtonBrill
                  className="subscription-card__btn button--sm button-xs"
                  onClick={onOpenPriceList}
                  title={t('Change plan')}
                >
                  {t('Change plan')}
                </ButtonBrill>
                { tariff !== TARIFFS_NAMES.ENTREPRENEUR
          && paymentStatus !== PAYMENTS_STATUSES.PAYMENT_STATUS_PAID
          && paymentStatus !== PAYMENTS_STATUSES.PAYMENT_STATUS_UNSUBSCRIBED
          && (
            <ButtonBrill
              className="subscription-card__btn button--sm button-xs"
              onClick={onPay}
              title={t('Proceed to payment')}
            >
              {t('Proceed to payment')}
            </ButtonBrill>
          )}
              </div>
            </SubscriptionCard>
            <SubscriptionCard
              title={t('Current payment method')}
            >
              <div className="subscription-card__wrapper">
                { tariff === TARIFFS_NAMES.ENTREPRENEUR
                || paymentStatus === PAYMENTS_STATUSES.PAYMENT_STATUS_FREE ? (
                  <p className="subscription-card__method">{t('You haven\'t made a payment yet')}</p>
                  ) : (
                    <>
                      <p className="subscription-card__method">{t('Debit or credit card')}</p>
                      <p className="subscription-card__card-number">{cardNumber}</p>
                    </>
                  )}
              </div>
            </SubscriptionCard>
          </div>
          <div className="subscription__row">
            <ButtonBrill
              className="subscription__btn-delete button-sm button-xs"
              onClick={onDeleteAccount}
            >{t('Delete account')}
            </ButtonBrill>
          </div>
          <div className="subscription__row">
            <h2 className="subscription__history">{t('Subscription history')}</h2>
          </div>
          <div className="subscription__row">
            <HistoryPayment />
          </div>
        </>
      ) : (
        <TariffsContainer />
      )}
    </div>
    <ConfirmPaymentModal />
    <DeleteAccountModal />
    <CancelSubscriptionModal />
  </>
);

SubscriptionContainer.propTypes = {
  onPay: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  subscriber: PropTypes.instanceOf(Object).isRequired,
  onOpenPriceList: PropTypes.func.isRequired,
  isOpenPriceList: PropTypes.bool,
  onDeleteAccount: PropTypes.func.isRequired,
  daysForEndPeriod: PropTypes.number.isRequired,
  tariffTitle: PropTypes.string.isRequired,
  tariffCost: PropTypes.number.isRequired,
  tariff: PropTypes.number.isRequired,
  paymentStatus: PropTypes.number.isRequired,
  periodEndDate: PropTypes.string.isRequired,
  cardNumber: PropTypes.string.isRequired,
  lastNumberDays: PropTypes.number.isRequired,
};

SubscriptionContainer.propTypes = {
  isOpenPricesList: () => {},
};

export default SubscriptionContainer;

import { memo } from 'react';
import { compose } from 'recompose';
import { withNamespaces } from 'react-i18next';

import SettingsBotsContainer from './settingsBotsContainer';

const enhance = compose(
  memo,
  withNamespaces('bots'),
);

export default enhance(SettingsBotsContainer);

import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';

import { FormsCollection, ButtonsCollection, AutoCompleteField } from '../../../../components';
import './style.sass';

const FormProfile = ({
  t, values, errors, handleChange, pending, onRemoveDisabled, isDisabled, onCancel, langList,
  defaultLang, changeLanguage,
}) => (
  <div className="form-profile">
    <div className="form-profile__row">
      <FormsCollection.Input
        name="first_name"
        value={values.first_name}
        error={propOr('', 'message', errors.first_name)}
        touched
        disabled={isDisabled}
        onChange={handleChange}
        id="form-profile__field-first-name"
        placeholderJump={t('First name')}
      />
    </div>
    <div className="form-profile__row">
      <FormsCollection.Input
        name="last_name"
        value={values.last_name}
        error={propOr('', 'message', errors.last_name)}
        touched
        disabled={isDisabled}
        onChange={handleChange}
        id="form-profile__field-last-name"
        placeholderJump={t('Last name')}
      />
    </div>
    <div className="form-profile__row">
      <FormsCollection.Input
        name="username"
        disabled={isDisabled}
        value={values.username}
        error={propOr('', 'message', errors.username)}
        touched
        onChange={handleChange}
        id="form-profile__field-user-name"
        placeholderJump={t('Username')}
      />
    </div>
    <div className="form-profile__row">
      <FormsCollection.Input
        name="email"
        disabled={isDisabled}
        value={values.email}
        error={propOr('', 'message', errors.email)}
        touched
        onChange={handleChange}
        id="form-profile__field-email"
        placeholderJump={t('Email')}
      />
    </div>
    <div className="form-profile__row">
      <div className="field-group field-group--pseudo-focus">
        <div className="field-group__wrap">
          <AutoCompleteField
            name="langs"
            t={t}
            options={langList}
            defaultValue={defaultLang}
            onChange={({ value }) => changeLanguage(value)}
          />
          <span className="field-group__placeholder-jump">{t('App language')}</span>
        </div>
      </div>
    </div>
    <div className="form-profile__row form-profile__row--buttons">
      <ButtonsCollection.ButtonBrill
        type="submit"
        pending={pending}
        delay={800}
        onEndDelay={onRemoveDisabled}
        className="button-brill--fill form-profile__control-button form-profile__submit-button button--md"
      >
        {t('Save')}
      </ButtonsCollection.ButtonBrill>
      <button
        type="button"
        onClick={onCancel}
        className="form-profile__cancel-button form-profile__control-button"
      >
        {t('Cancel')}
      </button>
    </div>
  </div>
);

FormProfile.propTypes = {
  t: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  handleChange: PropTypes.func.isRequired,
  pending: PropTypes.bool.isRequired,
  onRemoveDisabled: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  langList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  defaultLang: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  changeLanguage: PropTypes.func.isRequired,
};

export default FormProfile;

import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import CancelSubscriptionModal from './cancelSubscriptionModal';
import { subscriptionSelectors, subscriptionActions } from '../../../../state/subscription';
import { uiActions, uiSelectors } from '../../../../state/ui';
import { callNotification } from '../../../../utils/helpers/notifies';
import { translateHelpers } from '../../../../utils/helpers';

const mapStateToProps = state => ({
  subscriber: subscriptionSelectors.getSubscriberData(state),
  isOpen: uiSelectors.getModal(state)('cancelSubscriptionModal'),
  isPending: subscriptionSelectors.cancelSubscriptionPending(state),
});

const mapDispatchToProps = {
  cancelSubscription: subscriptionActions.cancelSubscriptionRequest,
  updateSubscriberData: subscriptionActions.getInfoAboutSubscriber,
  onCloseModal: () => uiActions.closeModal('cancelSubscriptionModal'),
};

const onCancelSubscriptionHandler = ({
  cancelSubscription,
  subscriber,
  onCloseModal,
}) => () => {
  cancelSubscription({
    name: subscriber.name,
  }, {
    callbacks: {
      success: () => callNotification.success(translateHelpers.t('common', 'Subscription deactivated')),
      error: () => callNotification.error(translateHelpers.t('common', 'Something went wrong')),
    },
  });
  onCloseModal();
};

const onCloseModalHandler = ({ onCloseModal }) => () => {
  onCloseModal();
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common']),
  withHandlers({
    onCancelSubscription: onCancelSubscriptionHandler,
    onClose: onCloseModalHandler,
  }),
);

export default enhance(CancelSubscriptionModal);

import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { Label, ServiceIcon } from '../../../../../../components';

const ChannelItem = ({
  channelId, unreadCount, channelName, username, serviceId, onCloseLeftSideBarAfterClick,
}) => (
  <NavLink
    to={`/messenger/${channelId}/`}
    activeClassName="direct-nav__item--active"
    className={`direct-nav__item ${unreadCount ? 'channel--has-unread' : ''}`}
    onClick={onCloseLeftSideBarAfterClick}
  >
    <ServiceIcon
      serviceId={serviceId}
    />
    <span
      className="text--cut"
      title={channelName}
    >
      <div
        className="channel-title"
      >
        {
          (username === channelName)
            ? `${channelName} (you)`
            : `${channelName}`
        }
      </div>
    </span>
    {unreadCount ? (<Label className="label--xs label--fill">{unreadCount}</Label>) : ''}
  </NavLink>
);

ChannelItem.propTypes = {
  channelId: PropTypes.number.isRequired,
  unreadCount: PropTypes.number,
  // channelStatusOnline: PropTypes.number.isRequired,
  channelName: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  serviceId: PropTypes.number.isRequired,
  onCloseLeftSideBarAfterClick: PropTypes.func.isRequired,
};

ChannelItem.defaultProps = {
  unreadCount: 0,
};

export default ChannelItem;

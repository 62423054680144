import {
  compose, cond, curry, path, prop, pathOr,
} from 'ramda';

import { map, renameKeys } from '../commonHelpers';
import {
  checkCurrentUserNotAuthor,
  contentNotificationForGroup,
  setContent,
  contentNotificationForDirect,
} from './messages';
import { getAvatar, setAvatar } from '../userHelpers';
import {
  isChannelCommon,
  isChannelDirect,
  pathToChannelType,
} from './channelHelpers';

const createMessageNotification = compose(
  renameKeys({ avatar: 'icon', content: 'body' }),
  setContent(prop('content')),
  setAvatar(getAvatar),
);

const setupMessageNotification = curry(showNotification => compose(
  showNotification,
  createMessageNotification,
));

// const mentionWhenReply = curry((message, userId, idList) => {
//   const isReplyToMe = compose(pathEq(['message', 'parent', 'created_by'], userId))(message);
//   return isReplyToMe ? idList.map((val = []) => val.concat(Tuple(userId, ''))) : idList;
// });

const channelNotification = (userId, notification) => message => cond([
  [isChannelCommon(pathToChannelType), compose(
    setupMessageNotification(notification),
    contentNotificationForGroup,
  )],
  [isChannelDirect(pathToChannelType), compose(
    setupMessageNotification(notification),
    contentNotificationForDirect,
  )],
])(message);

const showMessageWhenUserNotAuthor = (userId, notification) => payload => compose(
  map(channelNotification(userId, notification)),
  checkCurrentUserNotAuthor(userId, pathOr(
    path(['message', 'created_by'], payload),
    ['message', 'client_id'],
  )),
)(payload);


export {
  createMessageNotification, setupMessageNotification, showMessageWhenUserNotAuthor,
};

import {
  compose, lifecycle, setPropTypes, defaultProps,
} from 'recompose';
import PropTypes from 'prop-types';

import { withRefs } from '../../utils/enchancers';
import Preloader from './preloader';

// TODO: For VP refactor code
const drawElement = ({
  getRef, colorFill, duration,
}) => {
  const activeColor = colorFill || getComputedStyle(getRef('preloader')).fill;

  const canvasSquare = getRef('preloaderCanvasCentral');
  const canvasCircle = getRef('preloaderCanvas');
  const middlePart = getRef('preloaderMiddlePart');

  if (!canvasCircle || !canvasSquare) return false;

  const contextSquare = canvasSquare.getContext('2d');
  const contextCircle = canvasCircle.getContext('2d');

  canvasCircle.height = canvasCircle.offsetHeight;
  canvasCircle.width = canvasCircle.offsetWidth;

  const centerXCircle = canvasCircle.width / 2;
  const centerYCircle = canvasCircle.height / 2;
  const radiusCircle = canvasCircle.width / 4;

  const durationStep = 10;
  let animationInterval;

  let amountCircle = 0;
  const maxAmountCircle = 366;
  const amountToIncreaseCircle = maxAmountCircle / (duration / durationStep);

  let amountSquare = 0;
  const maxAmountSquare = 110;
  const amountToIncreaseSquare = maxAmountSquare / (duration / durationStep);

  const iteratorDrawSquare = () => {
    amountSquare += amountToIncreaseSquare;
    contextSquare.beginPath();
    contextSquare.rect(50, 130 - amountSquare, 200, amountSquare);
    contextSquare.fillStyle = activeColor;
    contextSquare.fill();
    contextSquare.closePath();
  };

  const iteratorDrawCircle = () => {
    contextCircle.beginPath();
    contextCircle.arc(
      centerXCircle,
      centerYCircle,
      radiusCircle,
      0,
      amountCircle * Math.PI / 180,
      false,
    );
    contextCircle.fillStyle = activeColor;
    contextCircle.fill();
    contextCircle.lineWidth = canvasCircle.width / 2;
    contextCircle.strokeStyle = activeColor;
    contextCircle.stroke();
    amountCircle += amountToIncreaseCircle;
    contextCircle.closePath();
    if (amountCircle >= maxAmountCircle) {
      if (middlePart) middlePart.setAttribute('data-animation-heart-tick', true);
      clearInterval(animationInterval);
    }
  };

  animationInterval = setInterval(() => {
    iteratorDrawCircle();
    iteratorDrawSquare();
  }, durationStep);

  return true;
};

const enhance = compose(
  withRefs(),
  defaultProps({
    duration: 1000,
    colorFill: false,
  }),
  setPropTypes({
    duration: PropTypes.number,
    colorFill: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
  }),
  lifecycle({
    componentDidMount() {
      drawElement({ ...this.props });
    },
  }),
);
export default enhance(Preloader);

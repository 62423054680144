import {
  compose, withHandlers, getContext, branch, renderNothing, withState,
} from 'recompose';
import connect from 'react-redux/es/connect/connect';

import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import { pathOr, propOr, values } from 'ramda';
import EditBotsModalFb from './editBotsModalFb';
import { translateHelpers } from '../../../../../utils/helpers';

import rules from '../modalViewFb/rules';
import { closeModal } from '../../../../../state/ui/actions';
import { getModal } from '../../../../../state/ui/selectors';
import { settingBotsActions, settingBotsSelectors } from '../../../../../state/settingBots';
import { MESSENGER_TYPES } from '../../../../../constants/bots';
import { callNotification } from '../../../../../utils/helpers/notifies';


const submitForm = (formValues, {
  props: {
    onCloseModal, editBot, botData, setErrorFromBack,
  }, resetForm,
}) => {
  const {
    title, token, page_id, page_access_token,
  } = formValues;
  if (title.trim() && token.trim() && page_id.trim() && page_access_token.trim()) {
    editBot({
      ...botData, title, token, page_id, page_access_token,
    }, {
      callbacks: {
        success: () => {
          onCloseModal();
          resetForm();
          callNotification.success(translateHelpers.t('bots', 'Edit your bot in Facebook developer account'));
        },
        error:
          compose(
            (e) => {
              callNotification.error(translateHelpers.t('bots', `${e}`));
              setErrorFromBack(e);
            },
            pathOr({}, ['response', 'data', 'result', 'error']),
          ),
      },
    });
    resetForm();
    onCloseModal();
  } else {
    callNotification.error(translateHelpers.t('bots', 'Title and token are required'));
  }
};

const mapStateToProps = (state, { botData: { id } }) => ({
  isOpen: getModal(state)('editBotsModalFb'),
  bot: settingBotsSelectors.getBotById(state)(id),
  editError: settingBotsSelectors.getEditRequestError(state),
});

const mapDispatchToProps = ({
  editBot: settingBotsActions.editBotRequest,
  onCloseModal: () => closeModal('editBotsModalFb'),
});

const onCloseModalHandler = ({ onCloseModal, resetForm }) => () => {
  resetForm();
  onCloseModal();
};


const enhance = compose(getContext({
  botData: PropTypes.oneOfType([PropTypes.shape({
    type: PropTypes.oneOf(values(MESSENGER_TYPES)),
    id: PropTypes.number,
  })], PropTypes.oneOf([null])),
}),
branch(({ botData }) => !propOr(false, 'id', botData), renderNothing),
connect(mapStateToProps, mapDispatchToProps),
withState('errorFromBack', 'setErrorFromBack', {}),
withFormik({
  validateOnChange: false,
  validateOnBlur: false,
  validationSchema: rules,
  handleSubmit: submitForm,
  mapPropsToValues: ({ bot }) => bot,
  enableReinitialize: true,
}),
withHandlers({
  onCloseModalHandler,
}));


export default enhance(EditBotsModalFb);

import React from 'react';
import { prop } from 'ramda';
import PropTypes from 'prop-types';

import { ScrollWrapper, TabWrapper } from '../../../../components';
import {
  AboutConversation,
  DescriptionChannel,
  NotificationPreferences,
  AssignManagerDropdown,
  InfoAboutClient,
} from '..';
import { PinnedItems } from './components';
import { RightSideBar } from '../../../../containers';
import SearchMessages from './components/searchMessages';
import { ROLE_IDS } from '../../../../constants/pemissions';
import { DIRECT_CHANNEL, GENERAL_CHANNEL_ID } from '../../../../constants/messenger';

const InformationRightSideBar = ({
  channel,
  title,
  createdBy,
  isMessengerDetailsTabOpen,
  // isMessengerMediaTabOpen,
  // isMessengerPinnedItemsTabOpen,
  isMessengerNotificationTabOpen,
  changeMessengerDetailsTabStatus,
  // changeMessengerMediaTabStatus,
  // changeMessengerPinnedItemsTabStatus,
  changeMessengerNotificationTabStatus,
  userRoleId,
  ownChannelId,
  t,
}) => (
  <RightSideBar
    className="right-side-bar--messenger messenger__right-side-bar"
    title={title}
  >
    <ScrollWrapper className="right-side-bar__scroll-wrapper">
      <AboutConversation
        userId={prop('user_id', channel)}
      />
      <TabWrapper
        title={<h3 className="title">{t('chat:Details')}</h3>}
        onChangeStatusTab={changeMessengerDetailsTabStatus}
        defaultStatusTab={isMessengerDetailsTabOpen}
      >
        <DescriptionChannel
          createdAt={prop('created_at', channel)}
          description={prop('description', channel)}
          createdBy={createdBy}
        >
          <>{(channel.type === 2 || channel.type === 1) && <InfoAboutClient channel={channel} />}</>
        </DescriptionChannel>
      </TabWrapper>
      <TabWrapper
        title={(
          <h3 className="title">

            {t('chat:Search messages')}
          </h3>
        )}
        defaultStatusTab={false}
      >
        <SearchMessages />
      </TabWrapper>
      <TabWrapper
        title={(
          <h3 className="title">
            {t('chat:Pinned Items')}
          </h3>
        )}
      >
        <PinnedItems />
      </TabWrapper>
      <TabWrapper
        title={(
          <h3 className="title">
            {t('chat:Notification preferences')}
          </h3>
        )}
        onChangeStatusTab={changeMessengerNotificationTabStatus}
        defaultStatusTab={isMessengerNotificationTabOpen}
      >
        <NotificationPreferences channel={channel} />
      </TabWrapper>
      {
        (userRoleId === ROLE_IDS.ADMIN
          && channel.id !== GENERAL_CHANNEL_ID
          && channel.id !== ownChannelId && channel.type !== DIRECT_CHANNEL) && (
          <TabWrapper
            title={(
              <h3 className="title">
                {t('Manager')}
              </h3>
            )}
            onChangeStatusTab={changeMessengerNotificationTabStatus}
            defaultStatusTab={isMessengerNotificationTabOpen}
          >
            <AssignManagerDropdown />
          </TabWrapper>
        )
      }
    </ScrollWrapper>
  </RightSideBar>
);

InformationRightSideBar.propTypes = {
  channel: PropTypes.instanceOf(Object).isRequired,
  createdBy: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isMessengerDetailsTabOpen: PropTypes.bool.isRequired,
  // isMessengerMediaTabOpen: PropTypes.bool.isRequired,
  // isMessengerPinnedItemsTabOpen: PropTypes.bool.isRequired,
  isMessengerNotificationTabOpen: PropTypes.bool.isRequired,
  changeMessengerDetailsTabStatus: PropTypes.func.isRequired,
  // changeMessengerMediaTabStatus: PropTypes.func.isRequired,
  // changeMessengerPinnedItemsTabStatus: PropTypes.func.isRequired,
  changeMessengerNotificationTabStatus: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  userRoleId: PropTypes.number.isRequired,
  ownChannelId: PropTypes.number.isRequired,
};

export default InformationRightSideBar;

import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';


import './style.sass';
import { Route, Switch } from 'react-router';
import { ButtonsCollection } from '../../../../components';
import { Header, SettingUserContainer } from '../../../../containers';

const UserWrapper = ({
  match: { params: { userId } }, userName, push, t,
}) => (
  <>
    <Header hideToggleRightSideBar className="user-header">
      <>
        <h1 className="weight--medium title--xl title-page user-nav__title">
          <ButtonsCollection.ButtonIcons
            title={t('user:Go back to users')}
            renderMobileButton
            className="user-head-back"
            onClick={() => push('/preferences/users/')}
          >
            <span className="icon-expand-arrow-left" />
          </ButtonsCollection.ButtonIcons>

          {t('Settings')} - {t('Users')} -
          {' '}
          {userName}
        </h1>
        <nav className="header-nav header-nav--user">
          <NavLink
            activeClassName="header-nav__link--active"
            className="header-nav__link"
            to={`/preferences/users/${userId}/`}
          >

            {t('user:Edit user')}
          </NavLink>
        </nav>
      </>
    </Header>
    <div className="main-container__content main-container__content--profile">
      <Switch>
        <Route path="/preferences/users/:userId/" component={SettingUserContainer} />
      </Switch>
    </div>
  </>
);

UserWrapper.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  userName: PropTypes.string,
  push: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

UserWrapper.defaultProps = {
  userName: '',
};


export default UserWrapper;

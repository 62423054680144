import { compose } from 'recompose';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { subscriptionSelectors } from '../../../../../../state/subscription';
import HistoryList from './historyList';

const mapStateToProps = state => ({
  paymentLogs: subscriptionSelectors.getPaymentLogs(state),
});

const enhance = compose(
  connect(mapStateToProps),
  withNamespaces(['common']),
);

export default enhance(HistoryList);

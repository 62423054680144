import * as yup from 'yup';
import { translateHelpers } from '../../../../../utils/helpers';

const rules = yup.object().shape({
  first_name: yup
    .string()
    .required(translateHelpers.t('clients', 'First name is required')),
});

export default rules;

import {
  compose, lifecycle, withHandlers, withStateHandlers, pure, withState,
} from 'recompose';

import withRefs from '../../utils/enchancers/withRefs';
import DropDown from './dropDown';
import { getItem } from '../../utils/helpers/commonHelpers';
import { WINDOW_WIDTH } from '../../constants/ui';
import { withWindowWidth } from '../../utils/enchancers';

const clickOutside = ({ getRef, toggleDropDown }) => (e) => {
  const dropDown = getRef('dropDown');
  if (!dropDown.contains(e.target)) {
    toggleDropDown(false);
  }
};

const changePositionDropDown = ({ positionDropDown }) => (e) => {
  if (e && e.getBoundingClientRect().right
    > e.parentNode.parentNode.parentNode.getBoundingClientRect().width) {
    positionDropDown(true);
  }
};

const onChangeValueHandler = ({
  onChange, list, toggleDropDown, isMulti,
}) => (e, item) => {
  e.stopPropagation();
  const { id, onClick } = item;
  if (onChange) {
    onChange({ val: getItem('id', id, list) });
  }
  if (isMulti) {
    onClick();
  }
  if (!isMulti) toggleDropDown(false, () => onClick && onClick());
};

const onResetValueHandler = ({ onReset, toggleDropDown }) => () => {
  if (onReset) onReset();
  toggleDropDown(false);
};

const positionDropDownStateHandler = () => val => ({ positionElement: val });

const enhance = compose(
  withRefs(),
  withStateHandlers(
    () => ({
      positionElement: false,
    }), {
      positionDropDown: positionDropDownStateHandler,
    },
  ),
  withState('isOpen', 'toggleDropDown', false),
  withHandlers({
    positionDropDownHandler: changePositionDropDown,
    onChangeValue: onChangeValueHandler,
    clickOutsideHandler: clickOutside,
    onResetValue: onResetValueHandler,
  }),
  withWindowWidth(),
  lifecycle({
    componentDidUpdate(prevProps) {
      const {
        clickOutsideHandler, isOpen, getRef, positionDropDown, windowWidth,
      } = this.props;
      if (isOpen && prevProps.isOpen !== isOpen) {
        const dropDownRef = getRef('dropDown');
        const dropDownBodyRef = getRef('dropDownBody');
        const dropDownBodyBlockValue = dropDownBodyRef.getBoundingClientRect();
        const checkCoordinates = () => (windowWidth > WINDOW_WIDTH.MEDIUM ? (
          dropDownBodyBlockValue.left > WINDOW_WIDTH.MIN_WIDTH
        ) : (
          dropDownBodyBlockValue.left > WINDOW_WIDTH.MIN_WIDTH / 2
        ));
        if (checkCoordinates() && dropDownBodyBlockValue.right
          > dropDownRef.parentNode.getBoundingClientRect().width) {
          positionDropDown(true);
        }
      }
      document.addEventListener('click', clickOutsideHandler, true);
    },
    componentWillUnmount() {
      const { clickOutsideHandler } = this.props;
      document.removeEventListener('click', clickOutsideHandler, true);
    },
  }),
  pure,

);

export default enhance(DropDown);

// eslint-disable-next-line import/prefer-default-export
export const LARGE_FILE_ERROR = 'File size should be less than 20mb';

export const SUBSCRIPTION_ERRORS = {
  type: {
    ENTREPRENEUR: 1,
    BUSINESS: 2,
  },
  model: {
    BOT: 1,
    USER: 2,
  },
};

import React from 'react';
import PropTypes from 'prop-types';

import { ConfirmModal } from '../../../../../../components';
import './style.sass';

const DeleteCustomFieldModal = ({
  isOpen, onClose, isPending, onDelete, t,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    isPending={isPending}
    actionTitle="Delete"
    zIndex="100"
    onAction={onDelete}
    onClose={onClose}
    title={t('Delete custom field')}
  >

    {t('Are you sure you want to delete this custom field?')}
  </ConfirmModal>
);

DeleteCustomFieldModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isPending: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

DeleteCustomFieldModal.defaultProps = {
  isPending: false,
  isOpen: false,
};

export default DeleteCustomFieldModal;

import { createActions } from 'redux-actions';
import { identity } from 'ramda';
import types from './types';
import { clientsSchema } from './schema';

export const {
  setClients,
  getClientsRequest,
  deleteClientRequest,
  editClientRequest,
  editClientSuccess,
} = createActions({
  [types.GET_CLIENTS_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/clients',
      selectorName: 'getClientsRequest',
      method: 'GET',
      params,
      schema: {
        rules: clientsSchema,
        pathData: ['data'],
      },
      actions: {
        success: setClients,
      },
      ...meta,
    }),
  ],
  [types.DELETE_CLIENT_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/deleteClient',
      selectorName: 'deleteClientRequest',
      method: 'DELETE',
      params,
      actions: {
        success: () => getClientsRequest(),
      },
      ...meta,
    }),
  ],
  [types.EDIT_CLIENT_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/editClient',
      selectorName: 'editClientRequest',
      method: 'PUT',
      params,
      actions: {
        success: editClientSuccess,
      },
      ...meta,
    }),
  ],
},
types.SET_CLIENTS,
types.EDIT_CLIENT_SUCCESS);

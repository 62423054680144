import { handleActions } from 'redux-actions';
import types from './types';
import { mergeDeep, mergeIn } from '../../utils/helpers/ramdaStateHelpers';

const reducer = handleActions({
  [types.ADD_BOT_SUCCESS]: mergeDeep(({
    payload: bot,
  }, { result }) => ({
    entities: {
      [bot.id]: bot,
    },
    result: [...result, bot.id],
  })),
  [types.SET_SETTING_BOTS]: mergeIn(({
    payload: {
      data: { entities, result },
    },
  }) => ({
    entities: entities.settingBots,
    result,
  })),
  [types.EDIT_BOT_SUCCESS]: mergeDeep(({
    payload: bot,
  }) => ({
    entities: {
      [bot.id]: bot,
    },
  })),
},
{
});

export default reducer;

import { compose, withHandlers } from 'recompose';

import { withNamespaces } from 'react-i18next';
import ModalViewFb from './modalViewFb';

const generateRandomString = (iterations) => {
  let str = '';

  for (let i = 0; i < iterations; i += 1) {
    str += Math.random().toString(36).substring(2, 15);
  }

  return str;
};

const onGenerateButtonClickHandler = ({ setFieldValue }) => () => {
  const randomToken = generateRandomString(4);
  setFieldValue('token', randomToken);
};

const enhance = compose(
  withNamespaces('bots'),
  withHandlers({
    onGenerateButtonClick: onGenerateButtonClickHandler,
  }),
);


export default enhance(ModalViewFb);

import React from 'react';
import PropTypes from 'prop-types';

import { Avatar, BrillMask, ButtonsCollection } from '../../../../components';
import './style.sass';

const AvatarProfile = ({
  onChange, fileUrl, value, name, alt, onResetAvatar, errorMessage, t,
}) => (
  <div className="avatar-profile">
    <div className="avatar-profile__title">{t('Profile photo')}</div>
    <div
      className="avatar-profile__label"
    >
      <input
        id="field-profile__avatar-profile"
        className="avatar-profile__input"
        onChange={onChange}
        name={name}
        type="file"
        accept="image/*"
      />
      <BrillMask>
        <Avatar src={fileUrl || value} alt={alt}>
          <label
            className="field-profile__pseudo-overlay brill-mask__ignore"
            htmlFor="field-profile__avatar-profile"
          >
            <span className="field-profile__pseudo-overlay-icon brill-mask__ignore icon-camera" />
            <div className="field-profile__pseudo-overlay-title brill-mask__ignore">{t('Change photo')}</div>
          </label>
        </Avatar>
      </BrillMask>
    </div>
    {
      (fileUrl || value) && (

        <ButtonsCollection.ButtonBrill
          type="button"
          className="button--xs avatar-profile__button-remove"
          onClick={onResetAvatar}
        >

          {t('Remove Avatar')}
        </ButtonsCollection.ButtonBrill>
      )
    }
    {
      errorMessage && (<small className="avatar-profile__error-message">{errorMessage}</small>)
    }
  </div>
);


AvatarProfile.propTypes = {
  onChange: PropTypes.func.isRequired,
  fileUrl: PropTypes.string,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  onResetAvatar: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

AvatarProfile.defaultProps = {
  fileUrl: null,
};

export default AvatarProfile;

export default {
  ADD_CUSTOM_FIELD_REQUEST: 'ADD_CUSTOM_FIELD_REQUEST',
  ADD_CUSTOM_FIELD_SUCCESS: 'ADD_CUSTOM_FIELD_SUCCESS',
  GET_CUSTOM_FIELDS_REQUEST: 'GET_CUSTOM_FIELDS_REQUEST',
  GET_CUSTOM_FIELDS_SUCCESS: 'GET_CUSTOM_FIELDS_SUCCESS',
  EDIT_CUSTOM_FIELD_REQUEST: 'EDIT_CUSTOM_FIELD_REQUEST',
  EDIT_CUSTOM_FIELD_SUCCESS: 'EDIT_CUSTOM_FIELD_SUCCESS',
  EDIT_CUSTOM_FIELD_VALUE_REQUEST: 'EDIT_CUSTOM_FIELD_VALUE_REQUEST',
  EDIT_CUSTOM_FIELD_VALUE_SUCCESS: 'EDIT_CUSTOM_FIELD_VALUE_SUCCESS',
  DELETE_CUSTOM_FIELD_REQUEST: 'DELETE_CUSTOM_FIELD_REQUEST',
};

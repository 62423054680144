import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';
import { Preload } from '..';

const ShowMoreLazyLoad = ({
  data,
  onFetchItems,
  className,
  currentCount,
  currentVector,
  pending,
  limit,
  count,
  itemList,
  emptyText,
}) => (
  <div className={`show-more-lazy ${className}`}>
    {
        data.length ? (
          <>
            <div className="show-more-lazy__body">
              {
                data.slice(0, currentCount)
                  .map(idItem => itemList({ idItem }))
              }
            </div>
            {
              count > limit && (
                <div className="show-more-lazy__row">
                  {pending ? (
                    <Preload />
                  ) : (
                    <button type="button" className="show-more-lazy__button" onClick={() => onFetchItems()}>
                      <span className="show-more-lazy__button-text">{currentVector ? 'Show more' : 'Show less'}</span>
                      <span
                        className={`show-more-lazy__button-icon icon-expand-arrow-dn ${currentVector ? '' : 'icon--up'}`}
                      />
                    </button>
                  )}
                </div>
              )
            }
          </>
        ) : (
          <>
            {
              pending ? (
                <Preload />
              ) : (

                <div className="show-more-lazy__is-empty">
                  {emptyText}
                </div>
              )
            }
          </>
        )
      }
  </div>
);

ShowMoreLazyLoad.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  onFetchItems: PropTypes.func.isRequired,
  className: PropTypes.string,
  currentCount: PropTypes.number.isRequired,
  currentVector: PropTypes.bool,
  pending: PropTypes.bool,
  count: PropTypes.number.isRequired,
  itemList: PropTypes.func,
  limit: PropTypes.number.isRequired,
  emptyText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

ShowMoreLazyLoad.defaultProps = {
  className: '',
  currentVector: false,
  pending: false,
  itemList: () => {},
  emptyText: 'There are no items yet',
};

export default ShowMoreLazyLoad;

import { createActions } from 'redux-actions';
import { identity } from 'ramda';
import types from './types';
import { settingBotsSchema } from './schema';


export const {
  getSettingBotsRequest,
  setSettingBots,
  addBotRequest,
  addBotSuccess,
  editBotRequest,
  editBotSuccess,
  deleteBotRequest,
} = createActions(
  {
    [types.GET_SETTING_BOTS_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/bots',
        selectorName: 'getSettingBotsRequest',
        method: 'GET',
        params,
        schema: {
          rules: settingBotsSchema,
          pathData: ['data'],
        },
        actions: {
          success: setSettingBots,
        },
        ...meta,
      }),
    ],
    [types.ADD_BOT_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/addBot',
        selectorName: 'addBotRequest',
        method: 'POST',
        params,
        actions: {
          success: addBotSuccess,
        },
        ...meta,
      }),
    ],
    [types.EDIT_BOT_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/editBot',
        selectorName: 'editBotRequest',
        method: 'PUT',
        params,
        actions: {
          success: editBotSuccess,
        },
        ...meta,
      }),
    ],
    [types.DELETE_BOT_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/deleteBot',
        selectorName: 'deleteBotRequest',
        method: 'DELETE',
        params,
        actions: {
          success: () => getSettingBotsRequest(),
        },
        ...meta,
      }),
    ],
  },
  types.SET_SETTING_BOTS,
  types.ADD_BOT_SUCCESS,
  types.EDIT_BOT_SUCCESS,
);

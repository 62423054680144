import {
  path, compose, prop, pathOr,
} from 'ramda';
import { IMAGES_SIZE } from '../../constants/ui';
import { SERVER_ERRORS } from '../../constants/config';

const getErrors = errors => path(['response', 'data', 'result', 'errors'], errors);

const getApiUrl = () => {
  if (process.env.REACT_APP_API_URL) {
    return process.env.REACT_APP_API_URL;
  }
  return `https://api.${window.location.hostname}`;
};

const getWsApiUrl = () => {
  if (process.env.REACT_APP_WS_URL) {
    return process.env.REACT_APP_WS_URL;
  }
  return `wss://api.${window.location.hostname}`;
};

const getImageUrl = (id, size = IMAGES_SIZE.original) => {
  const url = getApiUrl(window.location);
  const userSalt = localStorage.getItem('userSalt');
  return (id ? `${url}/files/${id}/${size}${size ? '&' : '?'}salt=${userSalt}` : '');
};

const getFileUrl = (id) => {
  const url = getApiUrl(window.location);
  const userSalt = localStorage.getItem('userSalt');
  return (id ? `${url}/files/${id}/?salt=${userSalt}` : '');
};


const getQueryVariable = (variable) => {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return null;
};

const getNormalizeErrorObject = compose(code => prop(code, SERVER_ERRORS), pathOr(0, ['error', 'response', 'status']));

const getError = errors => path(['response', 'data', 'result', 'error'], errors);

export {
  getApiUrl,
  getWsApiUrl,
  getErrors,
  getError,
  getImageUrl,
  getQueryVariable,
  getFileUrl,
  getNormalizeErrorObject,
};

/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import { getShortName, stringToColor } from './utils';
import './style.sass';
import {trim} from "ramda";

const Avatar = ({
  children, src, colorText, alt, onClickAvatar, isErrorLoading, onSetErrorLoading, importantBackground,
}) => {
  const isEmptyAvatar = !src && !trim(alt);
  return (<div
    className={`avatar-group ${isEmptyAvatar ? 'avatar-group--empty' : ''}`}
    title={alt}
    onClick={onClickAvatar}
    style={!isEmptyAvatar ? { backgroundColor: importantBackground
        ? importantBackground
        : (src && !isErrorLoading ? 'transparent' : stringToColor(alt)) } : {}}
  >
    {src && !isErrorLoading
      ? <div className="avatar-group__pseudo-image" style={{ backgroundImage: `url(${src})` }}>
        <img src={src} onError={onSetErrorLoading} style={{ display: 'none'}}/>
      </div>
      : (
        <div className="avatar-group__short-name" style={{ color: colorText }}>
          <span data-testid="shortName">{getShortName(alt) || 'UN'}</span>
        </div>
      )}
    {children}
  </div>)
};
Avatar.propTypes = {
  children: PropTypes.oneOfType(
    [PropTypes.element,
      PropTypes.string,
      PropTypes.array],
  ),
  colorText: PropTypes.string,
  src: PropTypes.string,
  onClickAvatar: PropTypes.func,
  importantBackground: PropTypes.string,
  alt: PropTypes.string,
};
Avatar.defaultProps = {
  children: '',
  importantBackground: '',
  colorText: '#fff',
  alt: '',
  onClickAvatar: null,
  src: null,
};
// For usage
export default Avatar;
// For tests
export {
  getShortName,
};

import { compose } from 'recompose';
import { withNamespaces } from 'react-i18next';

import InviteMember from './inviteMember';

const enhance = compose(
  withNamespaces(['common', 'chat']),
);

export default enhance(InviteMember);

import React from 'react';
import PropTypes from 'prop-types';

import { ButtonsCollection } from '../../../../components';
import './style.sass';

const InviteMember = ({
  className, onClick, t,
}) => (
  <div className={`invite-member ${className}`}>
    <div className="invite-member__row">
      <ButtonsCollection.ButtonPlus
        onClick={onClick}
        className="invite-member__button"
      />
      <button
        type="button"
        onClick={onClick}
        className="invite-member__label"
      >
        {t('Invite members')}
      </button>
    </div>
  </div>
);

InviteMember.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

InviteMember.defaultProps = {
  className: '',
};

export default InviteMember;

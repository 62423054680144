import { compose } from 'recompose';

import { withNamespaces } from 'react-i18next';
import ModalView from './modalView';

const enhance = compose(
  withNamespaces('bots'),
);


export default enhance(ModalView);

import React from 'react';
import PropTypes from 'prop-types';

import ComingSoon from './comingSoon';
import './style.sass';

const ModuleWrapper = ({
  children, title, isAvailable, history,
}) => (
  <>
    {
      isAvailable ? (children)
        : (<ComingSoon title={title} history={history} />)
    }
  </>
);

ModuleWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.element,
  ]),
  title: PropTypes.string,
  isAvailable: PropTypes.bool.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

ModuleWrapper.defaultProps = {
  children: '',
  title: '',
};

export default ModuleWrapper;

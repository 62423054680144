import React from 'react';
import PropTypes from 'prop-types';

import { ButtonsCollection, OnlineStatusLabel } from '../../../../components/index';
import './style.sass';

const UserProfile = ({
  user, t, onGoToSendMessage, shortName, backgroundShortName, userAvatar,
}) => (
  <div className="user-profile">
    <div className="user-profile__profile-image">
      {
        userAvatar ? (
          <img src={userAvatar} alt={user.username} />) : (
            <div
              style={{ backgroundColor: backgroundShortName }}
              className="user-profile__empty-image"
            >
              {shortName}
            </div>
        )
      }
    </div>
    <div className="user-profile__user-info">
      <div className="top-block-info">
        <div className="user-status">
          <div className={`user-status-online
          ${user.is_online
            ? 'user-status-online--online'
            : 'user-status-online--offline'}`}
          >
            <OnlineStatusLabel status={user.is_online} />
            {user.is_online ? t('Online') : t('Offline')}
          </div>
        </div>
        <div className="user-name">
          <h4 className="user-name__title">{`${user.first_name} ${user.last_name}`}</h4>
        </div>
        <div className="user-nickname">
          {`@${user.username}`}
        </div>
        {/*<ButtonsCollection.ButtonBrill*/}
        {/*  className="user-send-message-button"*/}
        {/*  onClick={onGoToSendMessage}*/}
        {/*>*/}
        {/*  {t('Send a message')}*/}
        {/*</ButtonsCollection.ButtonBrill>*/}
      </div>

      <div className="bottom-block-info">
        <ul className="user-info-list">
          {
            user.position && (
              <li className="user-info-item">
                <div className="user-info-label">{t('Position')}</div>
                <div className="position-name">{user.position}</div>
              </li>
            )
          }
          {
            user.phone && (
              <li className="user-info-item">
                <div className="user-info-label">{t('Phone number')}</div>
                <div className="phone-number">{user.phone}</div>
              </li>
            )
          }
          {
            user.email && (
            <li className="user-info-item">
              <div className="user-info-label">{t('Email address')}</div>
              <div className="email-address">{user.email}</div>
            </li>
            )
          }
          {
            user.timezone && (
            <li className="user-info-item">
              <div className="user-info-label">{t('Time zone')}</div>
              <div className="timezone">{user.timezone}</div>
            </li>
            )
          }
        </ul>
      </div>
    </div>
  </div>
);

UserProfile.propTypes = {
  user: PropTypes.shape({
    is_online: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    username: PropTypes.string,
    position: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    timezone: PropTypes.string,
  }).isRequired,
  onGoToSendMessage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  shortName: PropTypes.string.isRequired,
  backgroundShortName: PropTypes.string.isRequired,
  userAvatar: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default UserProfile;

import { compose, withStateHandlers, lifecycle } from 'recompose';
import { withWindowWidth } from '../../utils/enchancers';
import MobileFiltersContainer from './mobileFiltersContainer';

const changeContainerStatus = () => val => ({ isOpen: val });
const enhance = compose(
  withWindowWidth(),
  withStateHandlers(
    () => ({ isOpen: false }), {
      changeContainerStatusStateHandler: changeContainerStatus,
    },
  ),
  lifecycle({
    componentDidUpdate({ isOpenDefault }) {
      // todo: this component need fix logic
      // I can not change status outside
      if (this.props.isOpenDefault !== isOpenDefault) {
        this.props.changeContainerStatusStateHandler(false);
      }
    },
  }),
);

export default enhance(MobileFiltersContainer);

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { identity, pathOr } from 'ramda';

import {
  Avatar, BrillMask, Label,
} from '../../../../components';
import { getFullName } from '../../../../utils/helpers/userHelpers';

const DirectsItem = ({
  channel,
  clientsEntities,
  channel: { bot_id: botId, client_id: clientId, manager_id: managerId },
  managersEntities,
  botsEntities,
  t,
}) => (
  <li key={channel.id} className="directs-list__item">
    <Link
      to={`/messenger/${channel.id}`}
      onClick={identity}
      className="directs-list__link"
    >
      <div className="directs-list__title-group">
        <BrillMask
          asLink
          className="directs-list__avatar"
        >
          <Avatar
            src={pathOr('', [clientId, 'avatar'], clientsEntities)}
            alt={getFullName(clientsEntities[clientId])}
          />
        </BrillMask>
        <h3 className="directs-list__title-group-name">{channel.name}</h3>
        <h3 className="directs-list__title-group-manager">{pathOr(t('N/A'), [managerId, 'first_name'], managersEntities)}{' '}{pathOr('', [managerId, 'last_name'], managersEntities)}</h3>
        <h3 className="directs-list__title-group-bot">{pathOr(t('N/A'), [botId, 'title'], botsEntities)}</h3>
      </div>
      <Label className="directs-list__link-to label--xs">
        <span
          className="directs-list__link-to-icon icon-argon-arrow"
        />
      </Label>
    </Link>
  </li>
);

DirectsItem.propTypes = {
  channel: PropTypes.instanceOf(Object).isRequired,
  clientsEntities: PropTypes.instanceOf(Object).isRequired,
  botsEntities: PropTypes.instanceOf(Object).isRequired,
  managersEntities: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
};

export default DirectsItem;

import React from 'react';
import PropTypes from 'prop-types';

import ChannelsItem from '../channelsItem';
import './style.sass';

const ChannelsList = ({
  entities, result, t,
}) => (
  <div className="channels-list">
    <div className="channels-list__head">
      <h2 className="channels-list__title">{t('chat:New clients')}</h2>
      <h2 className="channels-list__bot">{t('chat:Bot title')}</h2>
    </div>
    <ul className="channels-list__list">
      {
        result && result.length && entities && entities.length
          ? result.map(channelId => (
            <ChannelsItem
              channel={entities[channelId]}
              key={entities[channelId].id}
            />
          ))
          : (
            <li className="channels-list__empty">
              {t('chat:There are no channels yet')}
            </li>
          )
      }
    </ul>
  </div>
);

ChannelsList.propTypes = {
  entities: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
  result: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default ChannelsList;

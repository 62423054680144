import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { ButtonsCollection } from '../../..';

import './style.sass';
import SERVER_TIMEZONE from '../../../../constants/config';
// TODO: touched from formik return shape after send

const DatePickerComponent = ({
  inFocus, id,
  isPicker, className, setRef, togglePickerHandler, setFocusStateHandler, onChange, name,
  onBlur, touched, error, minDate, maxDate, value, isNotUseTimeZone,
}) => (
  <div
    className={`date-picker-field
    ${isPicker && !inFocus ? 'date-picker-field--is-picker' : ''}
    ${inFocus ? 'date-picker-field--focus' : ''}
    ${(error && touched) ? 'date-picker-field--has-error' : ''}
     ${className}`}
    ref={e => setRef('field', e)}
  >
    <DatePicker
      {...{
        // eslint-disable-next-line no-nested-ternary
        selected: value
          ? (isNotUseTimeZone ? value : moment(value).tz(SERVER_TIMEZONE).toDate())
          : null,
      }}
      onChange={(e) => {
        if (e >= minDate) {
          onChange({
            target: {
              value: e,
              name,
            },
          });
        } else {
          onChange({
            target: {
              value: minDate,
              name,
            },
          });
        }
      }}
      name={name}
      minDate={minDate}
      maxDate={maxDate}
      className={`datePicker-field ${className}`}
      dateFormat="dd MMM yyyy"
      placeholderText="DD Mon YYYY"
      id={id}
      customInput={(
        <MaskedInput
          mask={[/\d/, /\d/, ' ', /[A-Z]/, /[a-z]/, /[a-z]/, ' ', /\d/, /\d/, /\d/, /\d/]}
        />
)}
      onFocus={() => setFocusStateHandler(true)}
      onBlur={() => { onBlur({ target: { name } }); setFocusStateHandler(false); }}
      ref={e => setRef('input', e)}
    />

    {typeof error === 'string' && error.length !== 0 && touched && (
      <span className="field-group__error field__error-text">
        {error}
      </span>
    )}
    <ButtonsCollection.ButtonIcons onClick={() => togglePickerHandler()} title="Show datepicker">
      <span className="icon-datepick" />
    </ButtonsCollection.ButtonIcons>
  </div>
);
DatePickerComponent.propTypes = {
  inFocus: PropTypes.bool,
  isPicker: PropTypes.bool,
  id: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  setRef: PropTypes.func.isRequired,
  name: PropTypes.string,
  togglePickerHandler: PropTypes.func.isRequired,
  setFocusStateHandler: PropTypes.func.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  isNotUseTimeZone: PropTypes.bool,
  touched: PropTypes.oneOfType(
    [
      PropTypes.shape(),
      PropTypes.bool,
    ],
  ),
  error: PropTypes.string,
};

DatePickerComponent.defaultProps = {
  className: '',
  inFocus: false,
  isPicker: false,
  isNotUseTimeZone: false,
  name: '',
  id: '',
  onChange: () => {},
  onBlur: () => {},
  error: '',
  value: '',
  minDate: null,
  maxDate: null,
  touched: false,
};

export default DatePickerComponent;

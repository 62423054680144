import React from 'react';
import PropTypes from 'prop-types';
import { path } from 'ramda';

import {
  ButtonsCollection,
  FormsCollection,
  Layouts,
} from '../../../components';

import NotificationsForAuthForm from '../components/errorNotification';

import './style.sass';

const ForgotPassword = ({
  handleSubmit,
  handleChange,
  handleBlur,
  touched,
  authNotification,
  values,
  t,
  pending,
}) => (
  <Layouts.Auth>
    <NotificationsForAuthForm />
    <div className="auth-wrapper__wrap">
      <FormsCollection.FormWrapper handleSubmit={handleSubmit}>
        <div className="auth-wrapper__form-contain">
          <h2 className="title weight--bold title--md text-align--center">{t('Reset password')}</h2>
          <FormsCollection.Input
            type="email"
            name="email"
            placeholderJump={t('Email address')}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!path(['email'], authNotification)}
            touched={touched.email}
            value={values.email}
            id="forgot-password__email"
          />
          <div className="button-group">
            <ButtonsCollection.ButtonBrill
              type="submit"
              className="auth-wrapper__button--with-border button--fill"
              borderColor="#26b5ed"
              borderWidth="3"
              pending={pending}
            >
              {t('Get reset link')}
            </ButtonsCollection.ButtonBrill>
          </div>
        </div>
      </FormsCollection.FormWrapper>
    </div>
  </Layouts.Auth>
);

ForgotPassword.propTypes = {
  pending: PropTypes.bool,
  values: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  touched: PropTypes.shape({
    email: PropTypes.bool,
  }).isRequired,
  authNotification: PropTypes.shape({
    email: PropTypes.object,
  }),
};

ForgotPassword.defaultProps = {
  pending: false,
  authNotification: {},
};

export default ForgotPassword;

import React from 'react';
import AsyncSelect from 'react-select/lib/Async';
import PropTypes from 'prop-types';
import { prop, identity } from 'ramda';
import { components as componentsNative } from 'react-select';

import * as components from '../components';
import '../style.sass';
import { debounceFunc, toTitle } from '../../../utils/helpers/commonHelpers';

const customStyles = {
  option: () => {},
};

const OptionComponent = ({ label, data, ...props }) => (
  <>
    <componentsNative.Option {...props} className="auto-complete__option-custom">
      <div className="auto-complete__option-content">
        {label}
        {' '}
        {prop('username', data) ? (
          <span className="auto-complete__help-option-text text--cut">

          (
            {prop('username', data)}

)
          </span>
        ) : ''
        }
      </div>
    </componentsNative.Option>
  </>
);

const AsyncAutoComplete = ({
  error, onBlur, disabled, touched, onFocus, name, t, loadOptions, onInputChange, ...props
}) => (
  <div className={`auto-complete ${error && touched ? 'auto-complete--has-error' : ''}`}>
    <AsyncSelect
      cacheOptions
      styles={customStyles}
      components={{
        ...components,
        Option: OptionComponent,
      }}
      className="auto-complete__field"
      classNamePrefix="auto-complete"
      loadOptions={(e, result) => debounceFunc(() => {
        loadOptions(e, result);
      }, 400, true, `asyncCompleteDebounce${name}`)}
      {...props}
      onInputChange={onInputChange}
      isDisabled={disabled}
      defaultOptions
    />
    {error.length !== 0 && touched && (
      <span className="auto-complete__error field__error-text">
        {t(error, { key: toTitle(name) })}
      </span>
    )}
  </div>
);

OptionComponent.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
};

AsyncAutoComplete.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  onBlur: PropTypes.func,
  t: PropTypes.func,
  loadOptions: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  touched: PropTypes.bool,
};

AsyncAutoComplete.defaultProps = {
  error: '',
  onFocus: identity,
  onBlur: identity,
  onInputChange: identity,
  t: identity,
  touched: false,
  disabled: false,
};

export default AsyncAutoComplete;

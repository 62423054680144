import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const PermissionElement = ({ children }) => (
  <React.Fragment>
    {children}
  </React.Fragment>
);

PermissionElement.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)]).isRequired,
};

export default PermissionElement;

import {
  compose, withHandlers, getContext,
} from 'recompose';
import connect from 'react-redux/es/connect/connect';

import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import { path, pathOr, values } from 'ramda';
import AddBotsModalFb from './addBotsModalFb';
import { translateHelpers } from '../../../../../utils/helpers';

import rules from '../modalViewFb/rules';
import { closeModal, openModal } from '../../../../../state/ui/actions';
import { getModal } from '../../../../../state/ui/selectors';
import { addBotRequest } from '../../../../../state/settingBots/actions';
import { MESSENGER_TYPES } from '../../../../../constants/bots';
import { callNotification } from '../../../../../utils/helpers/notifies';


const submitForm = (formValues, {
  props: {
    onCloseModal, addBot, botData, setOpenModal, setErrorFromSubscriptionMiddleware,
  }, resetForm,
}) => {
  const {
    title, token, page_id, page_access_token,
  } = formValues;
  if (title.trim() && token.trim() && page_id.trim() && page_access_token.trim()) {
    addBot({
      ...botData, title, token, page_id, page_access_token,
    }, {
      callbacks: {
        success: () => {
          onCloseModal();
          resetForm();
          callNotification.success('Add webhook token in Facebook developer account');
        },
        error: (response) => {
          if (path(['response', 'data', 'status'], response) !== 402) {
            callNotification.error(translateHelpers.t('bots', `${pathOr({}, ['response', 'data', 'result', 'error'], response)}`));
          } else {
            setErrorFromSubscriptionMiddleware(pathOr({}, ['response', 'data', 'result', 'error'], response));
            setOpenModal('errorModal');
          }
        },
      },
    });
  } else {
    callNotification.error(translateHelpers.t('bots', 'Title and token are required'));
  }
};

const mapStateToProps = state => ({
  isOpen: getModal(state)('addBotsModalFb'),
});

const mapDispatchToProps = ({
  addBot: addBotRequest,
  onCloseModal: () => closeModal('addBotsModalFb'),
  setOpenModal: openModal,
});

const onCloseModalHandler = ({ onCloseModal, resetForm }) => () => {
  resetForm({
    title: '',
    token: '',
    page_id: '',
    page_access_token: '',
  });
  onCloseModal();
};


const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  getContext({
    botData: PropTypes.oneOfType([PropTypes.shape({
      type: PropTypes.oneOf(values(MESSENGER_TYPES)),
      id: PropTypes.number,
    })], PropTypes.oneOf([null])),
    setErrorFromSubscriptionMiddleware: PropTypes.func.isRequired,
  }),
  withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: rules,
    handleSubmit: submitForm,
    mapPropsToValues: () => ({
      title: '',
      token: '',
      page_id: '',
      page_access_token: '',
    }),
    enableReinitialize: true,
  }),
  withHandlers({
    onCloseModalHandler,
  }),
);


export default enhance(AddBotsModalFb);

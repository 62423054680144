import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';

import {
  ModalWindow, FormsCollection, ButtonsCollection,
} from '../../..';
import { AsyncAutoComplete } from '../../../autoCompleteField';
import './style.sass';

const inviteMembersModal = ({
  isOpen,
  isPending,
  onCloseModal,
  handleSubmit,
  setFieldValue,
  getAutocomplete,
  errors,
  values,
  titleMain,
  titleItem,
  t,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={onCloseModal}
    modalName="inviteChannelMembersModal"
    title={`Invite ${titleItem}s to ${titleMain.toLowerCase()}`}
    className="modal-window__create-chanel"
  >
    <FormsCollection.FormWrapper handleSubmit={handleSubmit} className="invite-channel-members">
      <div className="invite-channel-members__row invite-channel-members__row--members">
        <div className="invite-channel-members__col-label">
          {t('Members')}
        </div>
        <div className="invite-channel-members__col-fields">
          <AsyncAutoComplete
            isMulti
            name="members"
            loadOptions={getAutocomplete}
            placeholder={`${t('Choose members')}...`}
            closeMenuOnSelect={false}
            value={values.members}
            placeholderJump={t('Members')}
            error={propOr('', 'message', errors.members)}
            onChange={value => setFieldValue('members', value)}
          />
        </div>
      </div>
      <div className="button-group">
        <ButtonsCollection.ButtonBrill
          pending={isPending}
          className="button--md invite-channel-members__button-save button-brill--fill"
          type="submit"
        >
          {t('Invite')}
        </ButtonsCollection.ButtonBrill>
      </div>
    </FormsCollection.FormWrapper>
  </ModalWindow>
);

inviteMembersModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  getAutocomplete: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  titleMain: PropTypes.string.isRequired,
  titleItem: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default inviteMembersModal;

import React from 'react';
import { propOr } from 'ramda';
import PropTypes from 'prop-types';

import { AsyncAutoComplete } from '../../../../components/autoCompleteField';
import { ButtonsCollection, FormsCollection } from '../../../../components';

import './style.sass';

const AssignManagerDropdown = ({
  handleSubmit,
  setFieldValue,
  getAutocomplete,
  errors,
  values,
  isPending,
  t,
}) => (
  <FormsCollection.FormWrapper handleSubmit={handleSubmit} className="invite-channel-members assign-manager">
    <div className="invite-channel-members__row invite-channel-members__row--members">
      <div className="invite-channel-members__col-fields">
        <AsyncAutoComplete
          // isMulti
          name="members"
          loadOptions={getAutocomplete}
          placeholder={`${t('chat:Assign manager')}...`}
          closeMenuOnSelect
          value={values.members}
          placeholderJump={t('Managers')}
          error={propOr('', 'message', errors.members)}
          onChange={value => setFieldValue('members', value)}
          touched
        />
      </div>
    </div>
    <div className="button-group">
      <ButtonsCollection.ButtonBrill
        pending={isPending}
        className="button--md invite-channel-members__button-save button-brill--fill"
        type="submit"
      >
        {t('chat:Assign')}
      </ButtonsCollection.ButtonBrill>
    </div>
  </FormsCollection.FormWrapper>
);

AssignManagerDropdown.propTypes = {
  isPending: PropTypes.bool.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  getAutocomplete: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default AssignManagerDropdown;

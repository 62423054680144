import React from 'react';
import PropTypes from 'prop-types';

import { Header, Directs } from '../../../../containers';
import './style.sass';

const DirectsContainer = ({ t, onGoBack }) => (
  <div className="main-container">
    <Header
      hideToggleRightSideBar
    >
      <h3 className="weight--medium title--xl title-page">
        {t('chat:My clients')}
      </h3>
    </Header>
    <div className="main-container__content main-container__content--profile">
      <Directs onGoBack={onGoBack} />
    </div>
  </div>
);

DirectsContainer.propTypes = {
  t: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
};

export default DirectsContainer;

import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Members from './members';
import { uiActions } from '../../../../state/ui';

const mapDispatchToProps = {
  setUserProfileId: uiActions.setUserProfileId,
};

const onClickMemberItemHandler = ({
  setUserProfileId, id,
}) => () => setUserProfileId(id);


const enhance = compose(
  connect(null, mapDispatchToProps),
  withNamespaces(['chat', 'common']),
  withHandlers({
    onClickMemberItem: onClickMemberItemHandler,
  }),
);
export default enhance(Members);

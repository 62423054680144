import {
  compose, getContext, withHandlers, withProps,
} from 'recompose';
import { connect } from 'react-redux';

import { path } from 'ramda';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import InfoAboutClient from './infoAboutCLient';
import { clientsSelectors } from '../../../../state/clients';
import { settingBotsSelectors } from '../../../../state/settingBots';
import { messengerSelectors } from '../../../../state/messenger';
import { settingUsersSelectors } from '../../../../state/settingUsers';
import { customFieldsSelectors } from '../../../../state/customFields';
import { MESSENGER_DETAIL } from '../../../../constants/bots';
import { openModal } from '../../../../state/ui/actions';


const mapStateToProps = (state, { typeChannel }) => ({
  clientsEntities: clientsSelectors.getClientsEntities(state),
  channelsEntities: typeChannel === 1
    ? messengerSelectors.getGroupChannels(state) : messengerSelectors.getDirectChannels(state),
  managersEntities: settingUsersSelectors.getSettingUsersEntities(state),
  botsEntities: settingBotsSelectors.getBotsEntities(state),
  customFieldsEntities: customFieldsSelectors.getCustomFieldsEntities(state),
});

const mapDispatchToProps = ({
  setOpenModal: openModal,
});

const editModalHandler = ({
  setCurrentClientId, setOpenModal,
}) => (id) => {
  setCurrentClientId(
    id,
  );
  setOpenModal('editCurrentClientModal');
};


const enhance = compose(
  withNamespaces('common'),
  withProps(({ channel: { type } }) => ({
    typeChannel: type,
  })),
  connect(mapStateToProps, mapDispatchToProps),
  withProps(({ channel: { id }, channelsEntities, clientsEntities }) => ({
    nameSocial: MESSENGER_DETAIL[path([id, 'service_id'], channelsEntities)].nameSocial,
    customFields: path([path([id, 'client_id'], channelsEntities), 'customFields'], clientsEntities),
  })),
  getContext({
    currentClientId: PropTypes.number,
    setCurrentClientId: PropTypes.func,
  }),
  withHandlers({
    editModalHandler,
  }),
);

export default enhance(InfoAboutClient);

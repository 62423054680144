import {
  compose, getContext, withHandlers, withState,
} from 'recompose';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { pathOr, values } from 'ramda';
import { withNamespaces } from 'react-i18next';
import DeleteBotsModal from './deleteBotsModal';
import { uiActions, uiSelectors } from '../../../../../state/ui';
import { MESSENGER_TYPES } from '../../../../../constants/bots';
import { settingBotsActions } from '../../../../../state/settingBots';
import { callNotification } from '../../../../../utils/helpers/notifies';
import { translateHelpers } from '../../../../../utils/helpers';

const mapStateToProps = state => ({
  isOpen: uiSelectors.getModal(state)('deleteBotsModal'),
});

const mapDispatchToProps = {
  onCloseModal: () => uiActions.closeModal('deleteBotsModal'),
  deleteBot: settingBotsActions.deleteBotRequest,
  // getBots: settingBotsActions.getSettingBotsRequest,
};

const onDeleteHandler = ({
  onCloseModal, botData, botData: { type }, deleteBot, setErrorFromBack,
}) => () => {
  deleteBot({ ...botData }, {
    callbacks: {
      error: compose(
        (e) => {
          callNotification.error(translateHelpers.t('bots', `${e}`));
          setErrorFromBack(e);
        },
        pathOr({}, ['response', 'data', 'result', 'error']),
      ),
      success: type === 3 ? () => callNotification.success(translateHelpers.t('bots', 'Delete your bot in Facebook developer account')) : () => {},
    },
  });
  onCloseModal();
};


const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces('bots'),
  withState('errorFromBack', 'setErrorFromBack', {}),
  getContext({
    botData: PropTypes.oneOfType([PropTypes.shape({
      type: PropTypes.oneOf(values(MESSENGER_TYPES)),
      id: PropTypes.number,
    })], PropTypes.oneOf([null])),
    setBotData: PropTypes.func.isRequired,
  }),
  withHandlers({
    onDelete: onDeleteHandler,
  }),
);
export default enhance(DeleteBotsModal);

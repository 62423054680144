import { compose } from 'recompose';
import { withNamespaces } from 'react-i18next';

import ChannelsList from './channelsList';

const enhance = compose(
  withNamespaces(['common', 'chat']),
);

export default enhance(ChannelsList);

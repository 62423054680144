import React from 'react';
import PropTypes from 'prop-types';

import { Header } from '../../../containers';
import Copyright from '../../copyright';

import './style.sass';

const Auth = ({ children }) => (
  <div className="page-wrapper page-auth">
    <Header empty />
    <div className="page-auth__container">
      <div className="auth-wrapper">
        {children}
      </div>
    </div>
    <Copyright />
  </div>
);

Auth.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
  ]),
};

Auth.defaultProps = {
  children: '',
};

export default Auth;

import { connect } from 'react-redux';
import { memo } from 'react';
import PropTypes from 'prop-types';
import {
  compose, withStateHandlers, withHandlers, getContext,
} from 'recompose';
import { withRouter } from 'react-router';
import { withNamespaces } from 'react-i18next';
import { openModal } from '../../../../../../../../state/ui/actions';

import { withRefs, withWindowWidth } from '../../../../../../../../utils/enchancers';
import ControlsMessageCard from './controlsMessageCard';
import { messengerActions, messengerSelectors } from '../../../../../../../../state/messenger';

const mapDispatchToProps = ({
  addPinMessage: messengerActions.addPinMessageRequest,
  openModal,
});

const mergeProps = (fromState, fromDispatch, ownProps) => ({
  ...ownProps,
  addPinMessage: () => fromDispatch.addPinMessage({
    message_id: ownProps.messageId,
    id: fromState.channelId.id,
  }),
  openModal: fromDispatch.openModal,
  isPinned: fromState.isPinned,
});

const mapStateToProps = (state, { message }) => ({
  isPinned: !!messengerSelectors.getPinnedMessages(state)(message.channel_id)[message.id],
});

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  withNamespaces('common'),
  withRefs(),
  withWindowWidth(),
  withStateHandlers(() => ({ vectorDropDown: false }), {
    handlerVectorDropDown: ({ vectorDropDown }) => val => ({
      vectorDropDown: val || !vectorDropDown,
    }),
  }),
  getContext({ getHeightMessagesArea: PropTypes.func }),
  withHandlers({
    chooseVector: ({ vectorDropDown, getHeightMessagesArea, handlerVectorDropDown }) => (e) => {
      if (!vectorDropDown && e && getHeightMessagesArea()
          && (e.getBoundingClientRect().y > getHeightMessagesArea().getClientHeight())) {
        handlerVectorDropDown();
      }
    },
  }),
  memo,
);
export default enhance(ControlsMessageCard);

import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose';
import { connect } from 'react-redux';
import SettingUsersContainer from './settingUsersContainer';
import { settingUsersActions, settingUsersSelectors } from '../../state/settingUsers';
import withGrid from '../../utils/enchancers/withGrid';
import { permissionsActions } from '../../state/permissions';
import { uiActions } from '../../state/ui';

const mapStateToProps = state => ({
  usersList: settingUsersSelectors.getSettingUsersList(state),
  usersEntities: settingUsersSelectors.getSettingUsersEntities(state),
  usersHasMore: settingUsersSelectors.getSettingUsersHasMore(state),
  usersCount: settingUsersSelectors.getSettingUsersCount(state),
  isPending: settingUsersSelectors.getSettingUsersPending(state),
});

const mapDispatchToProps = ({
  getSettingUsers: settingUsersActions.getSettingUsersRequest,
  getRolesRequest: permissionsActions.getRolesRequest,
  setOpenModal: uiActions.openModal,
});


const LIMIT_OF_LIST_USERS = 20;


const onDeleteUserHandler = ({ setOpenModal, setSelectedUser }) => (user) => {
  setOpenModal('deleteUserModal');
  setSelectedUser(user);
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('selectedUser', 'setSelectedUser', {}),
  withHandlers({
    onDeleteUser: onDeleteUserHandler,
  }),
  withGrid({
    limit: LIMIT_OF_LIST_USERS,
    gridName: 'settingUsersList',
    hasMore: ({ usersHasMore }) => usersHasMore,
    count: ({ usersCount }) => usersCount,
    action: ({ getSettingUsers }) => getSettingUsers,
    sortKey: 'sortBy',
  }),
  lifecycle({
    componentDidMount() {
      this.props.getRolesRequest();
    },
  }),
);
export default enhance(SettingUsersContainer);

import { handleActions } from 'redux-actions';

import types from './types';
import { mergeDeep } from '../../utils/helpers/ramdaStateHelpers';

const reducer = handleActions({
  [types.SET_ROLES]: mergeDeep(({ payload: { data: { entities, result }, count, hasMore } }) => ({
    entities: { roles: entities.roles },
    result: { roles: result },
    countRoles: count,
    hasMoreRoles: hasMore,
  })),
}, {
  isValidUser: false,
});

export default reducer;

import {
  compose, hoistStatics, pure,
} from 'recompose';

import Option from './option';

const enhance = compose(
  pure,
);

export default hoistStatics(enhance)(Option);

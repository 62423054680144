import React from 'react';
import PropTypes from 'prop-types';
import {
  pathOr, path, compose, isEmpty,
} from 'ramda';

import './style.sass';
import {
  Pagination, Grid, ButtonsCollection, Avatar, BrillMask,
} from '../../../../components';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import { USER_STATUSES } from '../../../../constants/users';
import { getImageUrl } from '../../../../utils/helpers/requestHelpers';

const UsersList = ({
  usersEntities, usersList, usersCount, gridName, pagination, order, sort, onSortBy,
  roles, isLoading = true, onDeleteUser, push, t,
}) => (
  <>
    <Grid.Wrapper
      isLoading={!isLoading}
      isKeepOptions
      name={gridName}
      className="table-users"
      pagination={(
        <Pagination
          count={usersCount}
          component={gridName}
          pagination={pagination}
          className="users-list__pagination"
        />
      )}
    >
      <Grid.Row className="table-users__row table-users__row-controls">
        <Grid.Col
          sort={sort}
          order={order}
          filter="id"
          onClick={() => onSortBy({ sort: 'id' })}
          name="#"
          className="table-users__col"
        />
        <div className="table-users__col--avatar" />
        <Grid.Col
          sort={sort}
          order={order}
          filter="name"
          onClick={() => onSortBy({ sort: 'name' })}
          name={t('Name')}
          className="table-users__col table-users__col--name"
        />
        <Grid.Col
          order={order}
          sort={sort}
          filter="username"
          name={t('Username')}
          onClick={() => onSortBy({ sort: 'username' })}
          className="table-users__col"
        />
        <Grid.Col
          order={order}
          sort={sort}
          onClick={() => onSortBy({ sort: 'email' })}
          filter="email"
          name={t('Email')}
          className="table-users__col"
        />
        <Grid.Col
          order={order}
          sort={sort}
          onClick={() => onSortBy({ sort: 'role_id' })}
          filter="role_id"
          name={t('Role')}
          className="table-users__col"
        />
        <Grid.Col
          order={order}
          sort={sort}
          onClick={() => onSortBy({ sort: 'status' })}
          filter="done"
          name={t('Status')}
          className="table-users__col"
        />
        <Grid.Col className="table-users__col" />
        <Grid.Col className="table-users__col" />
      </Grid.Row>
      {
        usersList.map(userId => (
          <Grid.Row
            key={`project-grid  - ${userId}`}
            className="table-users__row project-row"
            onClick={() => push(`/preferences/users/${userId}`)}
          >
            <Grid.ColData className="table-users__col">{userId}</Grid.ColData>
            <Grid.ColData className="table-users__col--avatar">
              <BrillMask>
                <Avatar
                  src={getImageUrl(path([userId, 'avatar'], usersEntities))}
                  alt={getFullName(usersEntities[userId])}
                />
              </BrillMask>
            </Grid.ColData>
            <Grid.ColData className="table-users__col table-users__col--name">
              <div className="text--cut">{isEmpty(getFullName(usersEntities[userId]).trim()) ? t('N/A') : getFullName(usersEntities[userId])}</div>
            </Grid.ColData>
            <Grid.ColData className="table-users__col table-users__col--username">
              <div className="text--cut">{pathOr(t('N/A'), [userId, 'username'], usersEntities)}</div>
            </Grid.ColData>
            <Grid.ColData className="table-users__col table-users__col--email">
              <div className="text--cut p-xs text--breaker">
                {pathOr(t('N/A'), [userId, 'email'], usersEntities)}
              </div>
            </Grid.ColData>
            <Grid.ColData className="table-users__col table-users__col--role">
              <div className="text--cut">
                {compose(roleId => pathOr('-', [roleId, 'name'], roles),
                  pathOr(t('N/A'), [userId, 'role_id']))(usersEntities)}
              </div>
            </Grid.ColData>
            <Grid.ColData className="table-users__col">
              <span
                className="user-status text--cut"
                style={{
                  color: pathOr('-',
                    [pathOr(-1, [userId, 'status'], usersEntities), 'color'],
                    USER_STATUSES),
                }}
              >
                {t(`user:${pathOr('-', [pathOr(-1, [userId, 'status'], usersEntities), 'title'],
                  USER_STATUSES)}`)}
              </span>
            </Grid.ColData>
            <Grid.ColData className="table-users__col table-users__col--controls">
              <ButtonsCollection.ButtonIcons
                onClick={() => push(`/preferences/users/${userId}/`)}
                title={t('user:Edit user')}
              >
                <span className="icon-edit-icon" />
              </ButtonsCollection.ButtonIcons>
            </Grid.ColData>
            <Grid.ColData className="table-users__col table-users__col--controls">
              <ButtonsCollection.ButtonIcons
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onDeleteUser(usersEntities[userId]);
                }}
                title={t('user:Delete user')}
              >
                <span className="icon-trash" />
              </ButtonsCollection.ButtonIcons>
            </Grid.ColData>
          </Grid.Row>
        ))}
    </Grid.Wrapper>
  </>
);

UsersList.propTypes = {
  usersEntities: PropTypes.instanceOf(Object).isRequired,
  usersList: PropTypes.instanceOf(Array).isRequired,
  gridName: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  sort: PropTypes.string,
  order: PropTypes.string,
  push: PropTypes.func.isRequired,
  roles: PropTypes.instanceOf(Object).isRequired,
  usersCount: PropTypes.number.isRequired,
  onSortBy: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    limit: PropTypes.number,
    offset: PropTypes.number,
  }).isRequired,
  onDeleteUser: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

UsersList.defaultProps = {
  order: '',
  sort: '',
};

export default UsersList;

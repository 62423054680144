import {
  compose, withStateHandlers, lifecycle,
} from 'recompose';

import { withNamespaces } from 'react-i18next';
import TabWrapper from './tabWrapper';

const changeStatusTab = ({ statusTab }) => val => ({ statusTab: val || !statusTab });

const enhance = compose(
  withNamespaces('common'),
  withStateHandlers(({ defaultStatusTab }) => ({ statusTab: defaultStatusTab }), {
    changeStatusTabStateHandler: changeStatusTab,
  }),
  lifecycle({
    componentDidUpdate(prevState) {
      const { statusTab: prevStatusTab } = prevState;
      const { statusTab, onChangeStatusTab } = this.props;
      if (onChangeStatusTab && prevStatusTab !== statusTab) {
        onChangeStatusTab(statusTab);
      }
    },
  }),
);

export default enhance(TabWrapper);

import { compose, getContext } from 'recompose';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import ErrorModal from './errorModal';
import { uiActions, uiSelectors } from '../../../../state/ui';

const mapStateToProps = state => ({
  isOpen: uiSelectors.getModal(state)('errorModal'),
});

const mapDispatchToProps = {
  onCloseModal: () => uiActions.closeModal('errorModal'),
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common']),
  getContext({
    errorFromSubscriptionMiddleware: PropTypes.instanceOf(Object),
  }),
);

export default enhance(ErrorModal);

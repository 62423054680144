import {
  compose, withHandlers, getContext, withState, withProps,
} from 'recompose';
import connect from 'react-redux/es/connect/connect';

import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import {
  path, pathOr, splitEvery, prop,
} from 'ramda';
import { withNamespaces } from 'react-i18next';
import EditClientsModal from './editClientsModal';
import { translateHelpers } from '../../../../../utils/helpers';

import rules from './rules';
import { closeModal } from '../../../../../state/ui/actions';
import { getModal } from '../../../../../state/ui/selectors';
import { callNotification } from '../../../../../utils/helpers/notifies';
import { clientsActions, clientsSelectors } from '../../../../../state/clients';
import { customFieldsSelectors, customFieldsActions } from '../../../../../state/customFields';


const submitForm = (formValues, {
  props: {
    onCloseModal,
    editClient,
    clientData,
    setErrorFromBack,
    customFieldEntities,
    customFieldList,
    editCustomFieldValue,
  }, resetForm,
}) => {
  const {
    first_name, last_name, phone, username, viber_phone, facebook_link,
  } = formValues;
  // eslint-disable-next-line no-restricted-syntax
  for (const id of customFieldList) {
    let customValues = { model_id: clientData, field_id: id };
    const customFieldTitle = path([id, 'title'], customFieldEntities);
    const customFieldValue = prop(`${customFieldTitle}`, formValues);
    customValues = { ...customValues, value: customFieldValue };
    editCustomFieldValue({
      ...customValues,
    });
  }
  if (first_name.trim()) {
    editClient({
      id: clientData, first_name, last_name, phone, username, viber_phone, facebook_link,
    }, {
      callbacks: {
        error:
          compose(
            (e) => {
              callNotification.error(e);
              setErrorFromBack(e);
            },
            pathOr({}, ['response', 'data', 'result', 'error']),
          ),
      },
    });
    resetForm();
    onCloseModal();
  } else {
    callNotification.error(translateHelpers.t('clients', 'First name is required'));
  }
};

const getInitialValues = ({
  client: {
    first_name, last_name, username, phone, viber_phone, facebook_link, customFields = [],
  } = {}, customFieldEntities,
}) => {
  let initialCustomValues = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const customValue of customFields) {
    const customFieldTitle = path([customValue.field_id, 'title'], customFieldEntities);
    initialCustomValues = { ...initialCustomValues, [customFieldTitle]: customValue.value };
  }
  return {
    first_name, last_name, username, phone, viber_phone, facebook_link, ...initialCustomValues,
  };
};

const mapStateToProps = (state, { clientData }) => ({
  isOpen: getModal(state)('editClientsModal'),
  client: clientsSelectors.getClientById(state)(clientData),
  customFieldEntities: customFieldsSelectors.getCustomFieldsEntities(state),
  customFieldList: customFieldsSelectors.getCustomFieldsList(state),
});

const mapDispatchToProps = ({
  onCloseModal: () => closeModal('editClientsModal'),
  editClient: clientsActions.editClientRequest,
  editCustomFieldValue: customFieldsActions.editCustomFieldValueRequest,
});

const onCloseModalHandler = ({ onCloseModal, resetForm }) => () => {
  resetForm();
  onCloseModal();
};


const enhance = compose(
  getContext({
    clientData: PropTypes.number,
    setClientData: PropTypes.func,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces('clients'),
  withState('errorFromBack', 'setErrorFromBack', {}),
  withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: rules,
    handleSubmit: submitForm,
    mapPropsToValues: getInitialValues,
    enableReinitialize: true,
  }),
  withHandlers({
    onCloseModalHandler,
  }),
  withProps(({ client }) => ({
    customFields: splitEvery(2, client ? client.customFields : []),
  })),
);


export default enhance(EditClientsModal);

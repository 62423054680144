import {
  compose, lifecycle, pure, withContext, withHandlers, withState, withStateHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import {
  values, path, prop, lte, gte, flip, isNil, equals,
} from 'ramda';
import { memo } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router';
import { notEqual } from 'ramda-extension';

import { withNamespaces } from 'react-i18next';
import { messengerActions, messengerSelectors } from '../../state/messenger';
import { withGroupChannels, withSubscription } from '../../utils/enchancers';
import Messenger from './messenger';
import { CHANNELS_URL, DIRECTS_URL } from '../../constants/messenger';
import { debounceFunc } from '../../utils/helpers/commonHelpers';
import { DOWN_CHANNEL, UPPER_CHANNEL } from './consts';
import { clientsActions } from '../../state/clients';
import { settingBotsActions } from '../../state/settingBots';
import { settingUsersActions } from '../../state/settingUsers';
import { customFieldsActions } from '../../state/customFields';
import { uiActions } from '../../state/ui';
import { WINDOW_WIDTH } from '../../constants/ui';

const mapStateToProps = state => ({
  activeChannel: messengerSelectors.getActiveChannelEntity(state),
  groupChannels: messengerSelectors.getGroupChannels(state),
});

const mapDispatchToProps = ({
  getChannelRequest: messengerActions.getChannelRequest,
  readMessageRequest: messengerActions.readMessagesRequest,
  setActiveChannel: messengerActions.setActiveChannel,
  getDirectChannelsRequest: messengerActions.getDirectChannelsRequest,
  getManagersChannelsRequest: messengerActions.getManagersChannelsRequest,
  getGroupChannelsRequest: messengerActions.getGroupChannelsRequest,
  getClientsRequest: clientsActions.getClientsRequest,
  getBotsRequest: settingBotsActions.getSettingBotsRequest,
  getManagersRequest: settingUsersActions.getSettingUsersRequest,
  getCustomFieldsRequest: customFieldsActions.getCustomFieldsRequest,
  changeLeftSideBar: uiActions.changeLeftSidebarStatus,
});

const topOfElement = compose(prop('top'), el => el.getBoundingClientRect());

const onCloseLeftSideBarAfterClickHandler = ({ changeLeftSideBar }) => () => {
  if (window.innerWidth < WINDOW_WIDTH.MEDIUM) changeLeftSideBar(false);
};

const getNearestChannelsElements = () => {
  const unreadChannelsElementsByList = document.querySelectorAll('.channel--has-unread');
  const isNotVisibleInBottom = compose(flip(gte)(prop('innerHeight', window)), topOfElement);
  const isNotVisibleInTop = compose(flip(lte)(50), topOfElement);
  const nearestElements = { [UPPER_CHANNEL]: null, [DOWN_CHANNEL]: null };

  unreadChannelsElementsByList.forEach((element) => {
    if (isNotVisibleInBottom(element) || isNotVisibleInTop(element)) {
      const vector = !isNotVisibleInBottom(element);
      if (isNil(nearestElements[vector ? UPPER_CHANNEL : DOWN_CHANNEL])) {
        nearestElements[vector ? UPPER_CHANNEL : DOWN_CHANNEL] = element;
      }
    }
  });
  return nearestElements;
};

const onScrollToUnreadChannelHandler = () => (scrollTo) => {
  const toElement = getNearestChannelsElements()[scrollTo];
  if (toElement) toElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
};

const onWatchUnreadChannelsHandler = ({ setShownButtonScrollToChannel }) => () => {
  debounceFunc(() => {
    const {
      [UPPER_CHANNEL]: upperElement,
      [DOWN_CHANNEL]: downElement,
    } = getNearestChannelsElements();
    setShownButtonScrollToChannel({
      [UPPER_CHANNEL]: !!upperElement,
      [DOWN_CHANNEL]: !!downElement,
    });
  }, 100, false, 'onWatchUnreadChannels');
};

const enhancer = compose(
  withSubscription(),
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces('chat'),
  withGroupChannels({}),
  withRouter,
  withState('selectedChannelId', 'setSelectedChannelId', 0),
  withState('selectedMessage', 'setSelectedMessageGlobal', {}),
  withState('shownButtonScrollToChannel', 'setShownButtonScrollToChannel', {
    [UPPER_CHANNEL]: false,
    [DOWN_CHANNEL]: false,
  }),
  withState('currentClientId', 'setCurrentClientId', null),
  memo,
  withContext({
    isLoadingChannel: PropTypes.bool,
    selectedChannelId: PropTypes.number,
    setSelectedChannelId: PropTypes.func,
    selectedMessage: PropTypes.shape({}),
    setSelectedMessageGlobal: PropTypes.func,
    currentClientId: PropTypes.number,
    setCurrentClientId: PropTypes.func,
  }, ({
    groupChannels,
    match: { url },
    selectedChannelId,
    setSelectedChannelId,
    selectedMessage,
    setSelectedMessageGlobal,
    setCurrentClientId,
    currentClientId,
  }) => ({
    isLoadingChannel: !values(groupChannels)[0]
      || !url === CHANNELS_URL || !url === DIRECTS_URL,
    selectedChannelId,
    setSelectedChannelId,
    selectedMessage,
    setSelectedMessageGlobal,
    setCurrentClientId,
    currentClientId,
  })),
  withStateHandlers(() => ({ userProfileId: null }), {
    setUserProfileId: () => val => ({
      userProfileId: val || null,
    }),
  }),
  withHandlers({
    onScrollToUnreadChannel: onScrollToUnreadChannelHandler,
    onWatchUnreadChannels: onWatchUnreadChannelsHandler,
    onCloseLeftSideBarAfterClick: onCloseLeftSideBarAfterClickHandler,
  }),
  pure,
  lifecycle({
    shouldComponentUpdate({
      shownButtonScrollToChannel: shownButtonScrollToChannelPrev,
      ...prevProps
    }) {
      const { shownButtonScrollToChannel, ...props } = this.props;
      return notEqual(this.props.shownButtonScrollToChannel, shownButtonScrollToChannelPrev)
          && equals(props, prevProps);
    },
    componentDidMount() {
      const {
        getDirectChannelsRequest,
        match,
        readMessageRequest,
        getClientsRequest,
        getBotsRequest,
        getManagersRequest,
        getManagersChannelsRequest,
        getCustomFieldsRequest,
      } = this.props;
      const channelId = path(['params', 'id'], match);
      if (channelId) setTimeout(() => readMessageRequest({ channelId }), 1000);
      getDirectChannelsRequest(null);
      getManagersChannelsRequest(null);
      getClientsRequest();
      getBotsRequest();
      getManagersRequest();
      getCustomFieldsRequest();
    },
    componentWillUnmount() {
      this.props.readMessageRequest({ channelId: this.props.match.params.id });
      this.props.setActiveChannel(null);
    },
  }),
);

// TODO issue with double request. Request should fire in MessagerContainer

export default enhancer(Messenger);

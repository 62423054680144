import { compose, withHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
import HistoryItem from './historyItem';
import { subscriptionActions } from '../../../../../../state/subscription';
import { callNotification } from '../../../../../../utils/helpers/notifies';
import { translateHelpers } from '../../../../../../utils/helpers';

const mapDispatchToProps = ({
  sendPaymentReceipt: subscriptionActions.sendPaymentReceipt,
});

const onSendReceiptHandler = ({ sendPaymentReceipt }) => (id) => {
  sendPaymentReceipt({
    id,
  }, {
    callbacks: {
      success: () => callNotification.success(translateHelpers.t('common', 'Receipt was sent to your email')),
      error: () => callNotification.error(translateHelpers.t('common', 'Something went wrong')),
    },
  });
};


const enhance = compose(
  connect(null, mapDispatchToProps),
  withNamespaces(['common']),
  withProps(({ log }) => ({
    transactionDate: moment(log.transaction_date).format('ll'),
  })),
  withHandlers({
    onSendReceipt: onSendReceiptHandler,
  }),
);

export default enhance(HistoryItem);

import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose';

import NoInternetConnection from './noInternetConnection';
import { subscribeOnEvent, unSubscribeFromEvent } from '../../utils/helpers/DOMHelper/listeners';

const enhance = compose(
  withState('isOnline', 'setIsOnline', true),
  withHandlers({
    setOffline: ({ setIsOnline }) => () => setIsOnline(false),
    setOnline: ({ setIsOnline }) => () => setIsOnline(true),
  }),
  lifecycle({
    componentDidMount() {
      this.props.setIsOnline(window.navigator.onLine);
      subscribeOnEvent(window, 'online', this.props.setOnline);
      subscribeOnEvent(window, 'offline', this.props.setOffline);
    },
    componentWillUnmount() {
      unSubscribeFromEvent(window, 'online', this.props.setOnline);
      unSubscribeFromEvent(window, 'offline', this.props.setOffline);
    },
  }),
);
export default enhance(NoInternetConnection);

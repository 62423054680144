import React from 'react';
import PropTypes from 'prop-types';
import {
  GROUP_CHANNEL,
  PRIVATE_CHANNEL,
  GENERAL_CHANNEL,
  OWN_CHANNEL, DIRECT_CHANNEL,
} from '../../../../constants/messenger';
import './style.sass';

const BreadCrumbs = ({
  channelType, title, botName, t,
}) => (
  <div className="bread-crumbs bread-crumbs--messenger text--cut">
    {
      channelType === GENERAL_CHANNEL && (
        <div className="bread-crumbs__privacy">
          <span className="icon-chanel-icon" />
        </div>
      )
    }
    {
      channelType === GROUP_CHANNEL && (
        <div className="bread-crumbs__privacy">
          <span className="icon-chanel-icon" />
        </div>
      )
    }
    {
      channelType === PRIVATE_CHANNEL && (
        <div className="bread-crumbs__privacy">
          <span className="icon-chanel-lock-icon" />
        </div>
      )
    }
    {/* { */}
    {/*  channelType === OWN_CHANNEL && ( */}
    {/*    <div className="bread-crumbs__privacy"> */}
    {/*      <span className="icon-chanel-lock-icon" /> */}
    {/*    </div> */}
    {/*  ) */}
    {/* } */}
    <h2 className="bread-crumbs__current-page text--cut weight--medium">{ title === 'General' ? t(`${title} chat`) : title }</h2>
    { botName && channelType !== OWN_CHANNEL && channelType !== DIRECT_CHANNEL && (
      <span
        className="bread-crumbs__current-page text--cut"
        style={{
          marginLeft: '0.5rem',
        }}
      >
        ({ botName })
      </span>
    )}
  </div>
);
BreadCrumbs.propTypes = {
  channelType: PropTypes.number,
  title: PropTypes.string,
  botName: PropTypes.string,
  t: PropTypes.func.isRequired,
};
BreadCrumbs.defaultProps = {
  channelType: 1,
  title: '',
  botName: '',
};
export default BreadCrumbs;

import * as yup from 'yup';
import { translateHelpers } from '../../../../utils/helpers';

const rules = yup.object().shape({
  first_name: yup
    .string()
    .required(translateHelpers.t('common', 'First name is required')),
  last_name: yup
    .string()
    .required(translateHelpers.t('common', 'Last name is required')),
  username: yup
    .string()
    .required(translateHelpers.t('common', 'Username is required')),
});

export default rules;

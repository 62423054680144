import {
  compose, getContext, withHandlers, withState,
} from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import { withNamespaces } from 'react-i18next';
import DeleteClientsModal from './deleteClientsModal';
import { uiActions, uiSelectors } from '../../../../../state/ui';
import { clientsActions } from '../../../../../state/clients';
import { messengerActions } from '../../../../../state/messenger';
import { callNotification } from '../../../../../utils/helpers/notifies';

const mapStateToProps = state => ({
  isOpen: uiSelectors.getModal(state)('deleteClientsModal'),
});

const mapDispatchToProps = {
  onCloseModal: () => uiActions.closeModal('deleteClientsModal'),
  deleteClient: clientsActions.deleteClientRequest,
  getDirectChannels: messengerActions.getDirectChannelsRequest,
  getGroupChannels: messengerActions.getGroupChannelsRequest,
  getUnreadCount: messengerActions.getTotalUnreadCountRequest,
};

const onDeleteHandler = ({
  onCloseModal,
  clientData,
  deleteClient,
  setErrorFromBack,
  getDirectChannels,
  getGroupChannels,
  getUnreadCount,
}) => () => {
  deleteClient({ id: clientData }, {
    callbacks: {
      error: compose(
        (e) => {
          callNotification.error(e);
          setErrorFromBack(e);
        },
        pathOr({}, ['response', 'data', 'result', 'error']),
      ),
      success: () => {
        getDirectChannels();
        getGroupChannels();
        getUnreadCount();
      },
    },
  });
  onCloseModal();
};


const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces('clients'),
  withState('errorFromBack', 'setErrorFromBack', {}),
  getContext({
    clientData: PropTypes.number,
    setClientData: PropTypes.func,
  }),
  withHandlers({
    onDelete: onDeleteHandler,
  }),
);

export default enhance(DeleteClientsModal);

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// import {
//   ButtonsCollection, FormsCollection,
// } from '../../components';
import { ToggleRightSideBarButton, UserControls } from './components';

import './style.sass';

const Header = ({
  children, empty, className, hideToggleRightSideBar,
  // displaySearch, displaySearchStateHandler,
}) => (
  <>
    {
      empty ? (
        <header className={`header header--empty ${className}`}>
          <div className="header__row">
            <Link to="/" className="header__logo header__auth-logo">
             Smartex app
            </Link>
          </div>
        </header>
      ) : (
        <header className={`header ${className}`}>
          <div className="header__col--left header__col">
            { children[0] || children }
          </div>
          {
            (children[1] || !hideToggleRightSideBar) && (
              <div className="header__col--central header__col">
                {children[1]}
                {
                  !hideToggleRightSideBar && <ToggleRightSideBarButton />
                }
              </div>
            )
          }
          <div className="header__col--right header__col">
            {/* <ButtonsCollection.ButtonIcons */}
            {/*  className="toggle-display-search" */}
            {/*  title="Search" */}
            {/*  onClick={() => displaySearchStateHandler(!displaySearch)} */}
            {/* > */}
            {/*  <span className={`${displaySearch ? 'icon-close' : 'icon-search-icon2'}`} /> */}
            {/* </ButtonsCollection.ButtonIcons> */}
            {/* <div className={`search-field-wrapper ${displaySearch ? '' : 'js-hidden'}`}> */}
            {/*  <FormsCollection.Search name="search-by " /> */}
            {/* </div> */}
            <UserControls />
          </div>
        </header>
      )
    }
  </>
);

Header.propTypes = {
  hideToggleRightSideBar: PropTypes.bool,
  empty: PropTypes.bool,
  className: PropTypes.string,
  // displaySearch: PropTypes.bool.isRequired,
  // displaySearchStateHandler: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
  ]),
};

Header.defaultProps = {
  empty: false,
  hideToggleRightSideBar: false,
  className: '',
  children: '',
};

export default Header;

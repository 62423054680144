import { compose } from 'recompose';
import { withNamespaces } from 'react-i18next';
import TariffsItem from './tariffsItem';


const enhance = compose(
  withNamespaces(['common']),
);

export default enhance(TariffsItem);

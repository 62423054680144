import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import bootData from './user';
import users from './users';
import messenger from './messenger';
import settingUsers from './settingUsers';
import permissions from './permissions';
import settingBots from './settingBots';
import clients from './clients';
import customFields from './customFields';
import subscription from './subscription';

import ui from './ui';
import { state } from '../utils/middlewares/sagaRequestApi';
import { state as socketConnection } from '../utils/helpers/webSocket';

const uiPersistConfig = {
  key: 'ui',
  storage,
  whitelist: ['pagination', 'routeTypes', 'leftSidebar', 'rightSidebar', 'tabs', 'fieldValues', 'urlFilters'],
};

const messengerPersistConfig = {
  key: 'messenger',
  storage,
};

const rootReducer = history => combineReducers({
  subscription,
  customFields,
  clients,
  settingBots,
  settingUsers,
  users,
  messenger: persistReducer(messengerPersistConfig, messenger),
  bootData,
  permissions,
  ui: persistReducer(uiPersistConfig, ui),
  router: connectRouter(history),
  ...state,
  socketConnection,
});

export default rootReducer;

import {
  take,
  fork,
  put,
  takeLatest,
  select,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { delay } from 'redux-saga';

import { sagasManager } from '../../utils';
import { userActions, userSelectors, userTypes } from '.';
import { uiActions } from '../ui';
import { ROUTE_TYPES } from '../../constants/ui';
import { userHelpers } from '../../utils/helpers';
import { messengerActions } from '../messenger';
import { usersActions } from '../users';
import {
  connectSocketToServer,
  subscribeChannel,
  listenEvent,
} from '../../utils/helpers/webSocket/state/actions';
import { LAST_CHANNEL_MESSENGER_STORAGE } from '../../constants/messenger';
import { setUsersUpdateMiddleware } from '../../utils/helpers/stateHelpers';
import { settingBotsActions } from '../settingBots';

function* loginFlow() {
  while (true) {
    const { payload: { token } } = yield take([
      userTypes.LOGIN_REQUEST_SUCCESS,
      userTypes.CONTINUE_SIGN_UP_REQUEST_SUCCESS,
    ]);
    const dataToken = token.token;
    localStorage.setItem('authToken', dataToken);
    yield put(push('/'));
    yield put(userActions.setAuthenticated(true));
    yield put(uiActions.setRoutesType(ROUTE_TYPES.AUTHENTICATED));
  }
}

function* logoutFlow() {
  while (true) {
    yield take(userTypes.LOGOUT);
    yield localStorage.removeItem(LAST_CHANNEL_MESSENGER_STORAGE);
    yield userHelpers.userLogout();
  }
}

function* forgotPasswordFlow() {
  while (true) {
    yield take(userTypes.FORGOT_PASSWORD_SUCCESS);
    yield put(push('/forgot-password-success/'));
  }
}

function* resetPasswordFlow() {
  while (true) {
    yield take(userTypes.RESET_PASSWORD_SUCCESS);
    yield put(push('/'));
  }
}

function* signUpFlow() {
  while (true) {
    yield take(userTypes.SIGN_UP_REQUEST_SUCCESS);
    yield put(push('/sign-up-success/'));
  }
}

function subscribeToUserWsChannels() {
  sagasManager.addSagaToRoot(function* subscribeToCommon() {
    yield put(subscribeChannel({ topic: 'common' }));
    yield put(listenEvent({
      topic: 'common',
      event: 'newMessage',
      action: messengerActions.emitNewMessage,
    }));
    yield put(listenEvent({
      topic: 'common',
      event: 'readMessages',
      action: messengerActions.readMessagesWs,
    }));
    yield put(listenEvent({
      topic: 'common',
      event: 'deleteMessage',
      action: messengerActions.emitDeleteMessage,
    }));
    yield put(listenEvent({
      topic: 'common',
      event: 'userOnlineStatus',
      action: usersActions.emitUsersStatus,
    }));
    yield put(listenEvent({
      topic: 'common',
      event: 'updateChannel',
      action: setUsersUpdateMiddleware(messengerActions.updateChannelBySocket, { isNotClear: true, pathEntities: ['channel', 'members'] }),
    }));
    yield put(listenEvent({
      topic: 'common',
      event: 'newChannel',
      action: () => messengerActions.getGroupChannelsRequest({}),
    }));
    yield put(listenEvent({
      topic: 'common',
      event: 'deleteChannel',
      action: messengerActions.deleteChannelBySocketWatcher,
    }));
    yield put(listenEvent({
      topic: 'common',
      event: 'updateBots',
      action: () => settingBotsActions.getSettingBotsRequest(),
    }));
  });
}

function* userIsValidFlow({ payload }) {
  const { salt } = payload;
  yield localStorage.setItem('userSalt', salt);
  yield delay(100);
  const isUser = yield select(userSelectors.getIsValidUser);
  if (!isUser) {
    yield put(connectSocketToServer());
    yield put(userActions.setUserIsValidFlag({}));
  }
}

function* userIsInvalidFlow() {
  while (true) {
    yield take(userTypes.SET_USER_IS_INVALID);
    yield userHelpers.userLogout();
  }
}

function* signUpIsSuccessFlow() {
  while (true) {
    yield take(userTypes.CONTINUE_SIGN_UP_REQUEST_SUCCESS);
    yield put(push('/messenger/1/'));
  }
}

function* userIsValidFlowWatcher() {
  yield takeLatest(userTypes.SET_USER_IS_VALID, userIsValidFlow);
}

sagasManager.addSagaToRoot(function* watcher() {
  yield fork(loginFlow);
  yield fork(forgotPasswordFlow);
  yield fork(signUpFlow);
  yield fork(resetPasswordFlow);
  yield fork(logoutFlow);
  yield fork(userIsInvalidFlow);
  yield fork(userIsValidFlowWatcher);
  yield fork(signUpIsSuccessFlow);
});

export {
  // eslint-disable-next-line import/prefer-default-export
  subscribeToUserWsChannels,
};

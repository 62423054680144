import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';

import { pathOr, prop } from 'ramda';
import ChannelItem from './channelItem';
import { messengerSelectors } from '../../../../../../state/messenger';
import { usersSelectors } from '../../../../../../state/users';

const mapStateToProps = state => ({
  managersChannels: messengerSelectors.getManagersChannels(state),
  usersList: usersSelectors.getUsersEntities(state),
});

const enhance = compose(
  connect(mapStateToProps),
  withProps(({ usersList, channel }) => ({
    channelStatusOnline: pathOr(pathOr(0, ['user', 'is_online'], channel),
      [(prop('user_id', channel)), 'is_online'], usersList),
  })),
);

export default enhance(ChannelItem);

/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './style.sass';

const BrillMask = ({
  children, className, to, asLink, disableHighlight, outsideChildren, onClick,
}) => (
  <div className={`brill-mask ${className}`} onClick={onClick}>
    {asLink && (
      <div className={`brill-mask__link ${disableHighlight ? 'brill-mask__link--highlight-disabled' : ''}`}>
        <div className="brill-mask__pseudo-link">
          {to ? <Link to={to} className="brill-mask__link-item" /> : (<div className="brill-mask__link-item" />)}
        </div>
      </div>
    )}
    <div className="brill-mask__mask">
      <div className="brill-mask__pseudo-wrap">
        {children}
      </div>
    </div>
    { outsideChildren }
  </div>
);
BrillMask.propTypes = {
  children: PropTypes.oneOfType(
    [PropTypes.element,
      PropTypes.string,
      PropTypes.array],
  ),
  outsideChildren: PropTypes.oneOfType(
    [PropTypes.element,
      PropTypes.string,
      PropTypes.array],
  ),
  className: PropTypes.string,
  to: PropTypes.string,
  asLink: PropTypes.bool,
  disableHighlight: PropTypes.bool,
  onClick: PropTypes.func,
};
BrillMask.defaultProps = {
  children: '',
  className: '',
  outsideChildren: '',
  onClick: null,
  asLink: false,
  disableHighlight: false,
  to: '/',

};
export default React.memo(BrillMask);

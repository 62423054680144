import { compose, withContext, withState } from 'recompose';
import { withNamespaces } from 'react-i18next';

import PropTypes from 'prop-types';
import { MODULES } from '../../constants/pemissions';
import withPermission from '../../utils/enchancers/withPemission';
import Settings from './settings';

const enhancer = compose(
  withPermission(MODULES.SETTINGS),
  withNamespaces(['common', 'bots']),
  withState('errorFromSubscriptionMiddleware', 'setErrorFromSubscriptionMiddleware', {
    model: null,
    type: null,
  }),
  withContext({
    setErrorFromSubscriptionMiddleware: PropTypes.func.isRequired,
    errorFromSubscriptionMiddleware: PropTypes.instanceOf(Object),
  },
  ({
    setErrorFromSubscriptionMiddleware, errorFromSubscriptionMiddleware,
  }) => ({
    setErrorFromSubscriptionMiddleware, errorFromSubscriptionMiddleware,
  })),
);

export default enhancer(Settings);

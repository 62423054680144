import {
  branch, compose, renderNothing,
} from 'recompose';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { withFormik } from 'formik';
import SubscriberEditForm from './subscriberEditForm';
import { subscriptionSelectors, subscriptionActions } from '../../../../state/subscription';
import { preloaderWhileLoading } from '../../../../utils/enchancers';
import { PRELOADER_DIMENSION } from '../../../../constants/ui';
import rules from './rules';
import { pendingSelectors } from '../../../../utils/middlewares/sagaRequestApi/state';
import { convertNullIntoEmptyString } from '../../../../utils/helpers/formHelpers';


const mapStateToProps = state => ({
  subscriberData: subscriptionSelectors.getSubscriberData(state),
  isUpdatePending: subscriptionSelectors.updateSubscriberPending(state),
  isSubscriberDataPending: pendingSelectors.getPendingRequest(state, 'updateInfoAboutSubscriberRequest'),
});

const mapDispatchToProps = ({
  updateSubscriber: subscriptionActions.updateInfoAboutSubscriberRequest,
});

const getInitialValues = ({
  subscriberData: {
    first_name, last_name, username, name, title, email, tariff, country, address, phone,
  },
}) => compose(convertNullIntoEmptyString)({
  first_name,
  last_name,
  username,
  name,
  title,
  tariff,
  country,
  email,
  address,
  phone,
});
const onSend = (formValues, { props: { updateSubscriber } }) => {
  updateSubscriber({
    ...formValues,
  });
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  branch(({ subscriberData }) => !subscriberData,
    renderNothing),
  withNamespaces(['common']),
  withFormik({
    mapPropsToValues: getInitialValues,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: rules,
    handleSubmit: onSend,
  }),
  preloaderWhileLoading({
    dimension: PRELOADER_DIMENSION.MIDDLE,
    alignContainerCenter: true,
    delay: 600,
    isLoading: ({ isSubscriberDataPending }) => isSubscriberDataPending,
  }),
);

export default enhance(SubscriberEditForm);

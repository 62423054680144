import {
  branch, withProps,
  compose, lifecycle, renderNothing, withContext, withHandlers, withState,
} from 'recompose';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { prop, propOr } from 'ramda';
import { subscriptionSelectors, subscriptionActions } from '../../state/subscription';
import SubscriptionContainer from './subscriptionContainer';
import { openModal } from '../../state/ui/actions';
import { TARIFFS } from '../../constants/subscription';
import { withSuperAdmin } from '../../utils/enchancers';


const mapStateToProps = state => ({
  subscriber: subscriptionSelectors.getSubscriberData(state),
  payment: subscriptionSelectors.getSubscriberPayment(state),
});

const mapDispatchToProps = ({
  getPaymentData: subscriptionActions.sendPaymentInfoRequest,
  getSubscriberData: subscriptionActions.getInfoAboutSubscriber,
  getPaymentHistory: subscriptionActions.getPaymentHistory,
  setOpenModal: openModal,
});

const onPayHandler = ({ subscriber, getPaymentData, setOpenModal }) => () => {
  getPaymentData({
    tariff: subscriber.tariff,
    name: subscriber.name,
  },
  {
    callbacks: {
      success: () => setOpenModal('payment-modal'),
    },
  });
};

const onDeleteAccountHandler = ({ setOpenModal }) => () => {
  setOpenModal('deleteAccountModal');
};

const onOpenPriceListHandler = ({ setOpenPricesList }) => () => {
  setOpenPricesList(true);
};

const getLastNumberDate = amount => amount.toString().split('').pop();

const enhance = compose(
  withSuperAdmin(),
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common']),
  withState('isOpenPricesList', 'setOpenPricesList', false),
  withContext({
    setOpenPricesList: PropTypes.func.isRequired,
  }, ({ setOpenPricesList }) => ({
    setOpenPricesList,
  })),
  withHandlers({
    onPay: onPayHandler,
    onOpenPriceList: onOpenPriceListHandler,
    onDeleteAccount: onDeleteAccountHandler,
  }),
  lifecycle({
    componentDidMount() {
      const { getSubscriberData, getPaymentHistory } = this.props;
      getSubscriberData({},
        {
          callbacks: {
            success: ({ subscriber: { id } }) => getPaymentHistory({ id }),
          },
        });
    },
  }),
  branch(({ subscriber }) => !subscriber, renderNothing),
  withProps(({
    subscriber,
  }) => ({
    daysForEndPeriod: Math.round(moment.duration(moment(subscriber.end_date, 'YYYY-MM-DD').diff(moment(new Date(), 'YYYY-MM-DD'))).asDays()),
    tariffTitle: TARIFFS[prop('tariff', subscriber)].title,
    tariffCost: TARIFFS[prop('tariff', subscriber)].cost,
    tariff: prop('tariff', subscriber),
    paymentStatus: prop('payment_status', subscriber),
    periodEndDate: moment(subscriber.end_date).format('ll'),
    cardNumber: propOr('N/A', 'sender_card_mask2', subscriber),
    lastNumberDays: parseInt(getLastNumberDate(Math.round(moment.duration(moment(subscriber.end_date, 'YYYY-MM-DD').diff(moment(new Date(), 'YYYY-MM-DD'))).asDays())), 10),
  })),
);

export default enhance(SubscriptionContainer);

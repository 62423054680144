import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const NavTabs = ({ t, match: { path } }) => (
  <nav className="header-nav header-nav--user">
    <NavLink
      activeClassName="header-nav__link--active"
      className="header-nav__link"
      to="/preferences/users/"
      isActive={() => path === '/preferences/' || path === '/preferences/users/'}
    >
      {t('Users')}
    </NavLink>
    <NavLink
      activeClassName="header-nav__link--active"
      className="header-nav__link"
      to="/preferences/bots"
    >
      {t('bots:Bots')}
    </NavLink>
    <NavLink
      activeClassName="header-nav__link--active"
      className="header-nav__link"
      to="/preferences/subscription"
    >
      {t('Subscription')}
    </NavLink>
    <NavLink
      activeClassName="header-nav__link--active"
      className="header-nav__link"
      to="/preferences/subscriber"
    >
      {t('Subscriber')}
    </NavLink>
  </nav>
);

NavTabs.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
};

export default NavTabs;

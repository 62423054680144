import {
  compose, withHandlers, getContext, withProps,
} from 'recompose';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import ImageModal from './imageModal';
import { getModal } from '../../../state/ui/selectors';
import { closeModal } from '../../../state/ui/actions';


const mapStateToProps = state => ({
  isOpen: getModal(state)('imageModal'),
});
const mapDispatchToProps = ({
  onCloseModal: closeModal,
});


const onCloseModalHandler = ({ onCloseModal }) => () => onCloseModal('imageModal');


const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  getContext({
    image: PropTypes.string,
  }),
  withProps(({ image }) => ({
    image,
  })),
  withHandlers({
    onCloseModalHandler,
  }),
);


export default enhance(ImageModal);

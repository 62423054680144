import React from 'react';
import PropTypes from 'prop-types';
import { identity } from 'ramda';

import './style.sass';
import MessageInnerContent from './components/messageInnerContent';

const MessageItem = ({
  isPinned, onMouseLeave, onWillUnMountEditable, channelId, onMountEditable,
  message, content, setMessageAsEditable, isEditable, onEditMessage, onClickSaveContent,
  onShowUserProfile, isShowActions, onClickShowActions, isEdited,
  onChangeContent, onKeyDownSaveContent, isOwner, id, onClickMention,
  userAvatar, userName, onRenderContent,
  onClickDelete, isUnread, isNewDay, isOwnerChannel,
  userOnlineStatus, onCloseEditMessage, getRef, onPinMessage, onUnpinMessage, onSetReplyMessage,
  parentMessage, windowWidth, isPending, onKeyDownEdit, onSetRefMessage, scrollToMessageId, channel,
}) => (
  <div
    ref={onSetRefMessage}
    id={`message-item-${message.id}`}
    className={`messenger__list_item ${isPinned ? 'messenger__list_item--pinned' : ''}
    ${scrollToMessageId === message.id ? 'messenger__list_item--scrollTo' : ''}
    `}
    onMouseLeave={onMouseLeave}
  >
    <MessageInnerContent
      channelId={channelId}
      isPending={isPending}
      onMountEditable={onMountEditable}
      onWillUnMountEditable={onWillUnMountEditable}
      message={message}
      content={content}
      setMessageAsEditable={setMessageAsEditable}
      isEditable={isEditable}
      onEditMessage={onEditMessage}
      onClickSaveContent={onClickSaveContent}
      onShowUserProfile={onShowUserProfile}
      isShowActions={isShowActions}
      onClickShowActions={onClickShowActions}
      isEdited={isEdited}
      onChangeContent={onChangeContent}
      onKeyDownSaveContent={onKeyDownSaveContent}
      isOwner={isOwner}
      id={id}
      onClickMention={onClickMention}
      userAvatar={userAvatar}
      userName={userName}
      onRenderContent={onRenderContent}
      onClickDelete={onClickDelete}
      isUnread={isUnread}
      isNewDay={isNewDay}
      isOwnerChannel={isOwnerChannel}
      userOnlineStatus={userOnlineStatus}
      onCloseEditMessage={onCloseEditMessage}
      getRef={getRef}
      onPinMessage={onPinMessage}
      onUnpinMessage={onUnpinMessage}
      onSetReplyMessage={onSetReplyMessage}
      parentMessage={parentMessage}
      windowWidth={windowWidth}
      onKeyDown={onKeyDownEdit}
      channel={channel}
    />
  </div>
);

MessageItem.propTypes = {
  onEditMessage: PropTypes.func.isRequired,
  onClickSaveContent: PropTypes.func.isRequired,
  onClickShowActions: PropTypes.func.isRequired,
  onMountEditable: PropTypes.func.isRequired,
  onWillUnMountEditable: PropTypes.func.isRequired,
  onKeyDownSaveContent: PropTypes.func,
  onChangeContent: PropTypes.func.isRequired,
  onClickMention: PropTypes.func.isRequired,
  onRenderContent: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  setMessageAsEditable: PropTypes.func.isRequired,
  onShowUserProfile: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  channelId: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isShowActions: PropTypes.bool.isRequired,
  isEdited: PropTypes.bool.isRequired,
  isUnread: PropTypes.bool.isRequired,
  isNewDay: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
  userAvatar: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  message: PropTypes.instanceOf(Object).isRequired,
  parentMessage: PropTypes.oneOfType([PropTypes.instanceOf(Object), () => null]),
  isPinned: PropTypes.bool,
  isOwnerChannel: PropTypes.bool.isRequired,
  userOnlineStatus: PropTypes.number.isRequired,
  onCloseEditMessage: PropTypes.func.isRequired,
  getRef: PropTypes.func.isRequired,
  onPinMessage: PropTypes.func.isRequired,
  onUnpinMessage: PropTypes.func.isRequired,
  onSetReplyMessage: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
  isPending: PropTypes.bool.isRequired,
  onKeyDownEdit: PropTypes.func.isRequired,
  onSetRefMessage: PropTypes.func.isRequired,
  scrollToMessageId: PropTypes.oneOfType([PropTypes.number, () => null]),
  channel: PropTypes.instanceOf(Object).isRequired,
};

MessageItem.defaultProps = {
  scrollToMessageId: null,
  isPinned: false,
  parentMessage: null,
  onKeyDownSaveContent: identity,
};


export default MessageItem;

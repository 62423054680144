import React from 'react';
import PropTypes from 'prop-types';

import { FormsCollection, ButtonsCollection } from '../../../../../../components';

const FieldItem = ({
  item, fieldId, handleChange, setFieldId, onDeleteCustomField,
  handleSubmit, values, errors, touched, t,
}) => (
  <FormsCollection.FormWrapper handleSubmit={handleSubmit} className="custom-fields__row custom-fields__row--form-add status-item">
    <div
      key={`field-${item.id}`}
      className={`custom-fields__row field-item
                                ${fieldId === item.id ? 'field-item--editing' : ''}`}
    >
      <div className="custom-fields__col">
        <div className="field-item burger-dnd">
          <span />
          <span />
          <span />
        </div>
        {item.id === fieldId ? (
          <FormsCollection.Input
            className="custom-fields__input-add"
            name="title"
            id="title"
            value={values.title}
            error={errors.title}
            touched={touched.title}
            onChange={handleChange}
          />
        ) : (
          <h4 className="field-item__title">{item.title}</h4>
        )}
      </div>
      <div className="custom-fields__col">
        {
          fieldId === item.id ? (<>
            <ButtonsCollection.ButtonBrill
              className="button-brill--fill custom-fields__add-button"
              type="submit"
            >

              {t('Save')}
            </ButtonsCollection.ButtonBrill>
            <button
              type="button"
              className="button--cancel custom-fields__add-button-cancel"
              onClick={() => setFieldId(null)}
            >

              {t('Cancel')}
            </button>
          </>) : (
            <>
              <ButtonsCollection.ButtonIcons
                onClick={() => setFieldId(item.id)}
                title={t('Edit')}
              >
                <span className="icon-edit-icon" />
              </ButtonsCollection.ButtonIcons>
              <ButtonsCollection.ButtonIcons
                onClick={() => onDeleteCustomField(item.id)}
                title={t('Delete')}
              >
                <span className="icon-trash" />
              </ButtonsCollection.ButtonIcons>
            </>
          )
          }
      </div>
    </div>
  </FormsCollection.FormWrapper>
);

FieldItem.propTypes = {
  setFieldId: PropTypes.func.isRequired,
  fieldId: PropTypes.number,
  onDeleteCustomField: PropTypes.func.isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  touched: PropTypes.instanceOf(Object).isRequired,
  values: PropTypes.oneOfType([PropTypes.shape({
    title: PropTypes.string,
  }), PropTypes.func]),
  errors: PropTypes.shape({
    title: PropTypes.string,
  }),
  t: PropTypes.func.isRequired,
};

FieldItem.defaultProps = {
  values: PropTypes.shape({
    title: '',
  }),
  errors: PropTypes.shape({
    title: '',
  }),
  fieldId: null,
};

export default FieldItem;

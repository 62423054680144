import {
  compose, withProps,
} from 'recompose';

import ServiceIcon from './serviceIcon';
import { MESSENGER_DETAIL } from '../../constants/bots';

const enhance = compose(
  withProps(({ serviceId, className }) => ({
    serviceTitle: MESSENGER_DETAIL[serviceId] ? MESSENGER_DETAIL[serviceId].nameSocial : '',
    className,
    imagePath: MESSENGER_DETAIL[serviceId] ? MESSENGER_DETAIL[serviceId].pathImage : '',
  })),
);

export default enhance(ServiceIcon);

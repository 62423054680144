import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Pagination,
} from '../../../../components';
import ClientsItem from '../clientsItem';
import { DeleteClientsModal, EditClientsModal, CustomFieldsModal } from '../modals';

import './style.sass';

const ClientsList = ({
  clientsEntities, clientsList, clientsCount, gridName, pagination, order, sort, onSortBy,
  isLoading = true, deleteModalHandler, editModalHandler, botsEntities, managersEntities, t,
}) => (
  <Grid.Wrapper
    isLoading={!isLoading}
    isKeepOptions
    name={gridName}
    className="table-clients"
    pagination={(
      <Pagination
        count={clientsCount}
        component={gridName}
        pagination={pagination}
        className="clients-list__pagination"
      />
      )}
  >
    <Grid.Row className="table-clients__row table-clients__row-controls">
      <Grid.Col
        sort={sort}
        order={order}
        filter="id"
        onClick={() => onSortBy({ sort: 'id' })}
        name="#"
        className="table-clients__col"
      />
      <div className="table-clients__col--avatar" />
      <Grid.Col
        sort={sort}
        order={order}
        filter="first_name"
        onClick={() => onSortBy({ sort: 'first_name' })}
        name={t('Name')}
        className="table-clients__col"
      />
      <Grid.Col
        order={order}
        sort={sort}
        onClick={() => onSortBy({ sort: 'phone' })}
        filter="phone"
        name={t('Phone')}
        className="table-clients__col"
      />
      <Grid.Col
        order={order}
        sort={sort}
        onClick={() => onSortBy({ sort: 'service_type' })}
        filter="service_type"
        name={t('Messenger')}
        className="table-clients__col"
      />
      <Grid.Col
        order={order}
        sort={sort}
        onClick={() => onSortBy({ sort: 'bot_id' })}
        filter="bot_id"
        name={t('Bot')}
        className="table-clients__col"
      />
      <Grid.Col
        order={order}
        sort={sort}
        onClick={() => onSortBy({ sort: 'manager_id' })}
        filter="manager_id"
        name={t('Manager')}
        className="table-clients__col"
      />
    </Grid.Row>
    {
      clientsList.map(id => (
        <ClientsItem
          key={id}
          id={id}
          clientsEntities={clientsEntities}
          managersEntities={managersEntities}
          botsEntities={botsEntities}
          deleteModalHandler={() => deleteModalHandler(id)}
          editModalHandler={() => editModalHandler(id)}
        />
      ))}
    <DeleteClientsModal />
    <EditClientsModal />
    <CustomFieldsModal />
  </Grid.Wrapper>
);

ClientsList.propTypes = {
  clientsEntities: PropTypes.instanceOf(Object).isRequired,
  managersEntities: PropTypes.instanceOf(Object).isRequired,
  botsEntities: PropTypes.instanceOf(Object).isRequired,
  clientsList: PropTypes.instanceOf(Array).isRequired,
  clientsCount: PropTypes.number.isRequired,
  gridName: PropTypes.string.isRequired,
  pagination: PropTypes.shape({
    limit: PropTypes.number,
    offset: PropTypes.number,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  sort: PropTypes.string,
  order: PropTypes.string,
  onSortBy: PropTypes.func.isRequired,
  deleteModalHandler: PropTypes.func.isRequired,
  editModalHandler: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

ClientsList.defaultProps = {
  sort: '',
  order: '',
};


export default ClientsList;

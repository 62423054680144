import { memo } from 'react';
import {
  compose, withProps, withHandlers, withContext, lifecycle, withState,
} from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  pathOr, prop, equals,
} from 'ramda';
import { withFormik } from 'formik';
import { withNamespaces } from 'react-i18next';

import Directs from './directs';
import { translateHelpers } from '../../utils/helpers';
import { messengerSelectors, messengerActions } from '../../state/messenger';
import { uiActions } from '../../state/ui';
import rules from './rules';
import { CHANNELS_YOU_BELONG_TO } from '../channels/constants';

const {
  getDirectChannelsRequest,
} = messengerActions;
const { resetPagination } = uiActions;

const getObjectForSend = ({
  search, limit = 10, offset = 0, orderByTitle, isNotMember,
}) => ({
  sortBy: 'name',
  order: prop('value', orderByTitle),
  limit,
  offset,
  search,
  notMember: isNotMember,
});

const setFieldValueWithSubmit = ({
  setFieldValue, values, getChannelsRequest, resetPagination: resetPages,
  setPagination, pagination,
}) => (name, value) => {
  setFieldValue(name, value);
  const newValues = {
    ...values,
    [name]: value,
  };
  setPagination({ ...pagination, offset: 0 });
  getChannelsRequest(null, getObjectForSend({
    search: newValues.search,
    orderByTitle: newValues.orderByTitle,
    ...pagination,
  }));
  resetPages();
};

const mapStateToProps = state => ({
  directChannelsList: messengerSelectors.getMatchedDirectChannelsList(state),
  // todo: matched must has name BelongTo
  directChannelsCount: messengerSelectors.getMatchedDirectChannelsCount(state),
  // channelsYouCanJoinList: messengerSelectors.getYouCanJoinDirectChannelsList(state),
  countChannels: messengerSelectors.getDirectChannelsCount(state),
  directList: messengerSelectors.getDirectChannelsList(state),
  entities: messengerSelectors.getDirectChannels(state),
});

const mapDispatchToProps = ({
  getChannelsRequest: getDirectChannelsRequest,
  resetPagination,
});

const onPageChangeHandler = ({
  setPagination,
  pagination, getChannelsRequest, paginationRequestParams, values,
}) => (meta) => {
  const nextPagination = { ...pagination, ...meta };
  setPagination(nextPagination);
  const isNotMember = equals(pathOr(0, ['type', 'value'], values),
    CHANNELS_YOU_BELONG_TO);
  getChannelsRequest(null, getObjectForSend({
    ...paginationRequestParams,
    ...nextPagination,
    isNotMember,
  }));
};

const enhance = compose(
  memo,
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common', 'chat']),
  withState('pagination', 'setPagination', { limit: 10, offset: 0 }),
  withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: () => ({
      search: '',
      type: {
        value: 1,
      },
      sortBy: 0,
    }),
    validationSchema: rules,
    handleSubmit: (
      { search, orderByTitle },
      { props: { getChannelsRequest, setChannels, pagination } },
    ) => {
      getChannelsRequest(null, getObjectForSend({
        search, orderByTitle, setChannels, ...pagination,
      }));
    },
  }),
  withProps(({
    directList,
    countChannels,
    values: { search, orderByTitle },
  }) => ({
    result: directList,
    countEntities: countChannels,
    paginationRequestParams: getObjectForSend({ name: search, orderByTitle }),
    title: translateHelpers.t('chat', 'My clients'),
  })),
  withHandlers({
    setFieldValueWithSubmit,
    onPageChange: onPageChangeHandler,
  }),
  withContext({
    values: PropTypes.instanceOf(Object),
    setFieldValue: PropTypes.func,
  },
  ({ values, setFieldValueWithSubmit: setFieldValue }) => ({
    values,
    setFieldValue,
  })),
  lifecycle({
    componentDidMount() {
      const { getChannelsRequest, values: { search, orderByTitle }, pagination } = this.props;
      getChannelsRequest(null, getObjectForSend({
        search, orderByTitle, isNotMember: false, ...pagination,
      }));
    },
  }),
);

export default enhance(Directs);

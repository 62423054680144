import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';
import { SummaryCard } from '..';
import './style.sass';

const SummaryCards = ({ t, summary }) => (
  <div className="summary-card__cards">
    <SummaryCard
      className="summary-card__item--all"
      colorBorder="#fcccc4"
      count={propOr(0, 'allCount', summary)}
      title={t('All')}
    />
    <SummaryCard
      className="summary-card__item--progress"
      colorBorder="#f66f5b"
      count={propOr(0, 'inProgressCount', summary)}
      title={t('In progress')}
    />
    <SummaryCard
      className="summary-card__item--done"
      colorBorder="#e6e8eb"
      count={propOr(0, 'doneCount', summary)}
      title={t('Done')}
    />
  </div>
);

SummaryCards.propTypes = {
  t: PropTypes.func.isRequired,
  summary: PropTypes.instanceOf(Object).isRequired,
};

export default SummaryCards;

import { handleActions } from 'redux-actions';
import {
  last, propOr, uniq, filter, pathOr, map, keys,
} from 'ramda';
import { notEqual } from 'ramda-extension';

import types, {
  GROUP_CHANNELS, DIRECT_CHANNELS, YOU_BELONG_TO_CHANNELS, YOU_CAN_JOIN_CHANNELS, MANAGERS_CHANNELS,
} from './types';
import {
  mergeInState, mergeIn, mergeDeepInState,
} from '../../utils/helpers/stateHelpers';

import {
  mergeByPath,
  mergeDeep,
  mergeDeepByPath,
  mergeDeepByPathWithConcatReverse, mergeDeepByProp, mergeByProp,
} from '../../utils/helpers/ramdaStateHelpers';
import { camelCase } from '../../utils/helpers/commonHelpers';
import { CHANNEL_TYPES, GROUP_CHANNEL } from '../../constants/messenger';

const initialState = ({
  channels: null,
  messages: {},
  loadedChannels: {},
  totalUnreadCount: 0,
  generalUnreadCount: 0,
  muteChannels: [],
  allRenderedMessages: [],
});

const reducer = handleActions({
  [types.SET_MEMBER_TO_CHANNEL]: mergeDeepByPath(['channels', 'groupChannels'],
    ({ payload }) => ({
      entities: {
        ...{ [payload.data.result.channel.id]: payload.data.result.channel },
      },
    })),
  [types.DELETE_MEMBER_FROM_CHANNEL]: mergeDeepByPath(['channels', 'groupChannels'],
    ({ payload }) => ({
      entities: {
        ...{ [payload.data.result.channel.id]: payload.data.result.channel },
      },
    })),
  [types.UPDATE__CHANNELS]: mergeDeepByProp('channels',
    ({ payload }, state) => ({
      [camelCase(GROUP_CHANNELS)]: {
        result: [
          ...state.channels.groupChannels.result.filter(id => id
            === payload.data.result.channel.id),
        ],
        entities: {
          ...{ [payload.data.result.channel.id]: payload.data.result.channel },
        },
      },
      [camelCase(DIRECT_CHANNELS)]: {
        result: [...state.channels.directChannels.result, payload.data.result.channel.id],
        entities: {
          ...{ [payload.data.result.channel.id]: payload.data.result.channel },
        },
      },
      // [camelCase(MANAGERS_CHANNELS)]: {
      //   result: [...state.channels.managersChannels.result, payload.data.result.channel.id],
      //   entities: {
      //     ...{ [payload.data.result.channel.id]: payload.data.result.channel },
      //   },
      // },
    })),
  [types.SET_GROUP_CHANNELS]: mergeDeepByProp('channels', ({ payload: { data, count } }) => ({
    [camelCase(GROUP_CHANNELS)]: {
      entities: data.entities[GROUP_CHANNELS],
      result: data.result,
      count,
    },
  })),
  [types.UPDATE_GROUP_CHANNELS]: mergeDeepInState('channels', ({ payload: { data, count } }) => ({
    [camelCase(GROUP_CHANNELS)]: {
      entities: data.entities[GROUP_CHANNELS],
      result: data.result,
      count,
    },
  })),
  [types.SET_DIRECT_CHANNELS]: mergeByProp('channels', ({ payload: { data, count } }) => ({
    [camelCase(DIRECT_CHANNELS)]: {
      entities: data.entities[DIRECT_CHANNELS],
      result: data.result,
      count,
    },
  })),
  [types.SET_MANAGERS_CHANNELS]: mergeByProp('channels', ({ payload: { data, count } }) => ({
    [camelCase(MANAGERS_CHANNELS)]: {
      entities: data.entities[MANAGERS_CHANNELS],
      result: data.result,
      count,
    },
  })),
  [types.SET_MATCHED_DIRECT_CHANNELS]: mergeDeepByPath(['channels', camelCase(DIRECT_CHANNELS)],
    ({ payload: { data, count } }, { entities }) => ({
      entities: { ...data.entities[DIRECT_CHANNELS], ...propOr({}, DIRECT_CHANNELS, entities) },
      matched: data.result,
      matchedCount: count,
    })),
  [types.SET_YOU_BELONG_TO_CHANNELS]: mergeDeepByPath(['channels', 'groupChannels'],
    ({ payload: { data, count } }, { channels }) => ({
      entities: { ...channels.groupChannels.entities, ...data.entities[GROUP_CHANNELS] },
      [camelCase(YOU_BELONG_TO_CHANNELS)]: data.result,
      [`${camelCase(YOU_BELONG_TO_CHANNELS)}Count`]: count,
    })),
  [types.SET_YOU_CAN_JOIN_CHANNELS]: mergeDeepByPath(['channels', 'groupChannels'],
    ({ payload: { data, count } }, { channels }) => ({
      entities: { ...channels.groupChannels.entities, ...data.entities[GROUP_CHANNELS] },
      [camelCase(YOU_CAN_JOIN_CHANNELS)]: data.result,
      [`${camelCase(YOU_CAN_JOIN_CHANNELS)}Count`]: count,
    })),
  [types.SET_YOU_CAN_JOIN_DIRECT_CHANNELS]: mergeDeepByPath(['channels', 'directChannels'],
    ({ payload: { data, count } }, { channels }) => ({
      entities: { ...channels.groupChannels.entities, ...data.entities[DIRECT_CHANNELS] },
      youCanJoinDirectChannelsList: data.result,
      youCanJoinDirectChannelsCount: count,
    })),
  [types.SET_LOADED_CHANNEL]: mergeDeepInState('loadedChannels', action => ({
    [action.payload.channelId]: { ...action.payload },
  })),
  [types.CHANGE_CHANNEL]: mergeIn(action => action.payload),
  [types.SET_MORE_MESSAGES]: mergeDeepByPathWithConcatReverse(['messages'], action => ({
    [action.payload.channelId]: ({
      ...action.payload,
      allRenderedMessages: action.payload.result,
    }),
  })),
  [types.SET_MESSAGE]: mergeDeepInState('messages', action => ({
    [action.payload.channel]: ({
      ...action.payload,
      allRenderedMessages: action.payload.result,
    }),
  })),
  [types.SET_LATEST_MESSAGES]: mergeDeepByPath(['messages'], action => ({
    [action.payload.channelId]: ({
      ...action.payload,
      totalCount: action.payload.totalCount,
      hasMore: action.payload.hasMore,
      lastMessageId: last(action.payload.result),
      allRenderedMessages: action.payload.result,
    }),
  })),
  [types.SET_MESSAGES_PUCK]: mergeByPath(['messages'], action => ({
    [action.payload.channelId]: ({
      ...action.payload,
      totalCount: action.payload.totalCount,
      hasMore: action.payload.hasMore,
      lastMessageId: last(action.payload.result),
      allRenderedMessages: action.payload.result,
    }),
  })),
  [types.SET_LAST_MESSAGE_ID]: mergeDeepInState('messages', action => ({
    [action.payload.channelId]: ({
      ...action.payload,
    }),
  })),
  [types.SET_COUNT_LOADED_MESSAGE]: mergeDeepInState('messages', action => ({
    [action.payload.channelId]: ({
      ...action.payload,
    }),
  })),
  [types.SET_TIMESTAMPS]: (state, action) => ({
    ...state,
    messages: ({
      ...state.messages,
      [action.payload.channelId]: ({
        ...state.messages[action.payload.channelId],
        timestamps: { ...action.payload.timestamps },
      }),
    }),
  }),
  [types.SET_TOTAL_UNREAD_COUNT]: mergeDeep(({ payload }) => (
    {
      totalUnreadCount: propOr(0, 'count', payload),
    }
  )),
  [types.SET_GENERAL_UNREAD_COUNT]: mergeDeep(({ payload }) => ({
    generalUnreadCount: propOr(0, 'count', payload),
  })),
  [types.SET_UNREAD_COUNT_CHANNEL]: mergeDeep(({ payload }, state) => {
    if (!payload.channel.type /* && payload.channel.type === GENERAL_CHANNEL) */) return {};
    let prevResult;
    const managers = state.channels.managersChannels.result;

    if (payload.channel.type === GROUP_CHANNEL) {
      prevResult = state.channels.groupChannels.result;
    } else {
      prevResult = state.channels.directChannels.result;
    }

    const { id } = payload.channel;
    const result = [...prevResult];

    if (!result.includes(id)) result.push(id);
    // if (payload.channel.type === 3) {
    //   return {
    //     channels: {
    //       managersChannels: {
    //         entities: {
    //           [payload.channel.id]: { unread_count: payload.unread_count },
    //         },
    //         result: [...managers],
    //       },
    //     },
    //   };
    // }
    return {
      channels: {
        [camelCase(CHANNEL_TYPES[payload.channel.type])]: {
          entities: {
            [payload.channel.id]: { unread_count: payload.unread_count, ...payload.channel },
          },
          result,
        },
      },
    };
  }),
  [types.SET_LAST_MESSAGE_TIME]: mergeInState('messages', (action, state) => ({
    [action.payload.channelId]: ({
      ...state.messages[action.payload.channelId],
      ...action.payload,
      lastMessageTime: action.payload.lastMessageTime,
    }),
  })),
  [types.SET_ACTIVE_CHANNEL]: mergeIn(action => ({
    activeChannel: { ...action.payload },
  })),
  [types.SET_CHANNEL]: mergeDeepByPath(['channels'], ({ payload }) => ({
    [camelCase(CHANNEL_TYPES[payload.channel.type])]: {
      entities: {
        ...payload.channels,
        ...{ [payload.data.result.channel.id]: payload.data.result.channel },
      },
    },
  })),
  [types.SET_PIN_MESSAGE]: mergeDeepInState('pinnedMessages', ({ payload }) => ({
    [payload.channelId]: payload.message,
  })),
  [types.SET_PINNED_MESSAGES]: mergeInState('pinnedMessages', (action) => {
    const { meta, ...payload } = action.payload;
    return {
      [meta.channelId]: {
        ...payload.data,
      },
    };
  }),
  [types.DELETE_PIN_MESSAGE]: mergeInState('pinnedMessages', (action) => {
    const { channelId, ...payload } = action.payload;
    return {
      [channelId]: {
        ...payload.data,
      },
    };
  }),
  [types.RESET_PIN_MESSAGE]: mergeDeep(({ payload: { channelId, id } }, { pinnedMessages }) => ({
    pinnedMessages: {
      [channelId]: {
        result: filter(notEqual(id), pathOr([], [channelId, 'result'], pinnedMessages)),
      },
    },
  })),
  [types.UPDATE_MESSAGE]: mergeDeepByPath(['messages'], ({ payload }) => {
    const { channel_id, ts, ...props } = payload;
    return {
      [channel_id]: {
        entities: {
          messages: {
            [ts]: { ...props },
          },
        },
      },
    };
  }),
  [types.SET_SCROLL_TO_MESSAGE]: mergeIn(({ payload }) => ({
    scrollToMessage: payload,
  })),
  [types.RESET_MESSAGE]: mergeIn(({
    payload: {
      updatedList, updatedEntities, channel_id, isNotDissCount,
    },
  }, state) => ({
    ...state,
    messages: {
      [channel_id]: ({
        ...state.messages[channel_id],
        entities: { messages: updatedEntities },
        result: updatedList,
        count: state.messages[channel_id].count - (isNotDissCount ? 0 : 1),
      }),
    },
  })),
  [types.SET_MUTE_CHANNEL]: mergeDeep(({ payload: { channelId } }, { muteChannels }) => ({
    muteChannels: uniq([...muteChannels, channelId]),
  })),
  [types.DELETE_MUTE_CHANNEL]: mergeDeep(({ payload: { channelId } }, { muteChannels }) => ({
    muteChannels: filter(notEqual(channelId), muteChannels),
  })),
  [types.DELETE_GROUP_CHANNEL]: mergeDeepByProp('channels', ({ payload: { entities, result, count } }) => ({
    [camelCase(GROUP_CHANNELS)]: {
      entities,
      result,
      count,
    },
  })),
  [types.UPDATE_LAST_USER_OWNER_MESSAGE]: mergeDeep(({ payload: { ts, channelId } }) => ({
    messages: {
      [channelId]: {
        lastUserOwnerMessage: ts.toString(),
      },
    },
  })),
  [types.SET_SEARCH_MESSAGES]: mergeIn(({ payload }) => ({
    searchMessages: {
      entities: payload.data.entities.searchMessages,
      result: payload.data.result,
      hasMore: payload.hasMore,
      count: payload.count,
    },
  })),
  [types.SET_SEARCH_MESSAGES__MORE]: mergeDeep(({ payload }, { searchMessages }) => ({
    searchMessages: {
      entities: { ...propOr({}, 'entities', searchMessages), ...payload.data.entities.searchMessages },
      result: [...propOr([], 'result', searchMessages), ...payload.data.result],
      hasMore: payload.hasMore,
      count: payload.count,
    },
  })),
  [types.RESET_SEARCH_MESSAGES]: mergeIn(() => ({
    searchMessages: null,
  })),
  [types.UPDATE_CHANNEL_BY_SOCKET]: mergeDeep(({ payload }) => ({
    channels: {
      [camelCase(CHANNEL_TYPES[payload.channel.type])]: {
        entities: {
          ...{
            [payload.channel.id]: {
              ...payload.channel,
              members: map(index => pathOr([], ['channel', 'members', index, 'id'], payload), keys(pathOr({}, ['channel', 'members'], payload))),
            },
          },
        },
      },
    },
  })),
},
initialState);

export default reducer;

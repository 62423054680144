import {
  compose, lifecycle, pure, withHandlers, withState,
} from 'recompose';

import {
  always,
  and,
  cond,
  equals,
  identity,
  ifElse,
  isEmpty,
  not,
  or, replace,
  T, when,
} from 'ramda';
import { Maybe } from 'ramda-fantasy';

import { withNamespaces } from 'react-i18next';
import TypeMessage from './textAreaDefault';

import withMessageContent from '../../utils/enchancers/withMessageContent';
import withEmoji from '../../utils/enchancers/withEmoji';
import withRefs from '../../utils/enchancers/withRefs';
import { setCaretToTheEndPosition } from '../../utils/helpers/uiHelpers';
import { isNotNil } from '../../utils/helpers/commonHelpers';
import { callWithPreventEvent } from '../../utils/helpers/uiComponentHelpers/DOMhelpers';

import { KEYS } from '../../constants/ui';
import { setBreakToField } from '../../utils/helpers/uiComponentHelpers/caretHelpers';


const onTextareaKeyDownHandler = ({
  onKeyDownSaveContent,
  onMentionKeyDown,
  isDropdownMentionsOpen,
  isEmojiOpen,
  onKeyDown,
  emojisAutocomplete,
}) => (event) => {
  onMentionKeyDown(event);

  const isEmojiAutoComplete = compose(not, isEmpty)(emojisAutocomplete);
  const isPreventSpecialEnter = or(isEmojiOpen, isEmojiAutoComplete);
  if (!isDropdownMentionsOpen && !isPreventSpecialEnter) {
    onKeyDownSaveContent(event);
  }
  if (onKeyDown) {
    onKeyDown(event);
  }
};

const onSpecialKeysHandler = ({
  lastPressedKey,
  setLastPressedKey,
  emojisAutocomplete,
  onSubmit,
  isEmojiOpen,
}) => (e) => {
  const { keyCode, type } = e;
  const isEmojiAutoComplete = compose(not, isEmpty)(emojisAutocomplete);
  const isPreventSpecialEnter = or(isEmojiOpen, isEmojiAutoComplete);
  const isNotShiftLastPressingKey = compose(not, equals(KEYS.SHIFT))(lastPressedKey);
  const isEnterForSubmitForm = () => and(isNotShiftLastPressingKey, not(isPreventSpecialEnter));
  const onPreventByEmojis = () => when(
    equals(true),
    () => callWithPreventEvent(identity, e),
  )(isPreventSpecialEnter);
  const listenerKeyDown = code => cond([
    [equals(KEYS.SHIFT), setLastPressedKey],
    [equals(KEYS.LEFT_ARROW), onPreventByEmojis],
    [equals(KEYS.RIGHT_ARROW), onPreventByEmojis],
    [equals(KEYS.UP_ARROW), onPreventByEmojis],
    [equals(KEYS.DOWN_ARROW), onPreventByEmojis],
    [equals(KEYS.ENTER),
      cond([
        [isEnterForSubmitForm, () => callWithPreventEvent(onSubmit, e)],
        [T, cond([
          [() => equals(true, isEmojiAutoComplete), () => callWithPreventEvent(identity, e)],
          [T, () => callWithPreventEvent(setBreakToField, e)],
        ])],
      ])],
  ])(code);

  const listenerKeyUp = code => cond([
    [equals(KEYS.SHIFT), () => setLastPressedKey(null)],
    [equals(KEYS.ENTER),
      ifElse(
        isEnterForSubmitForm,
        () => callWithPreventEvent(identity, e),
        ifElse(
          () => equals(true, isEmojiAutoComplete),
          () => callWithPreventEvent(identity, e),
          T,
        ),
      ),
    ],
  ])(code);

  const listenerShortKeys = cond([
    [equals('keydown'), () => listenerKeyDown(keyCode)],
    [equals('keyup'), () => listenerKeyUp(keyCode)],
  ]);

  return listenerShortKeys(type);
};

const onResetAllHtmlHandler = ({
  getField, onPasteTextCheckEmoji,
}) => (e) => {
  const field = getField();
  const selection = window.getSelection();
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = clipboardData.getData('Text');
  e.preventDefault();
  if (selection.focusNode) {
    requestAnimationFrame(() => {
      onPasteTextCheckEmoji(field, pastedData);
    });
  }
};


const onChangeContentHandler = ({
  onChange,
  getRef,
}) => () => {
  const typeMessage = getRef('typeMessage');
  const { innerHTML } = typeMessage;
  onChange(innerHTML);
};

const onSetFocusFieldHandler = ({ getRef }) => () => {
  setCaretToTheEndPosition(getRef('typeMessage'));
};

const enhance = compose(
  withRefs(),
  pure,
  withNamespaces('common'),
  withMessageContent({
    value: ({ value }) => value,
    onSaveMessageContent: ({
      onSubmit, messageContent, onClearMessageContent,
    }) => {
      onClearMessageContent();
      onSubmit(replace(/&gt;/g, '>', messageContent));
    },
  }),
  withEmoji({
    parentElement: ({ getRef }) => getRef('typeMessage'),
    onUpdateContent: ({ onChange }) => onChange,
  }),
  withState('lastPressedKey', 'setLastPressedKey', null),
  withHandlers({
    onTextareaKeyDown: onTextareaKeyDownHandler,
    onChangeContent: onChangeContentHandler,
  }),
  withHandlers({
    onSpecialKeys: onSpecialKeysHandler,
    onResetAllHtml: onResetAllHtmlHandler,
    onSetFocusField: onSetFocusFieldHandler,
  }),
  lifecycle({
    componentDidMount() {
      const { getRef, onSpecialKeys, onResetAllHtml } = this.props;
      const isTextArea = cond([
        [compose(isNotNil), Maybe.Just],
        [T, Maybe.Nothing],
      ]);

      requestAnimationFrame(() => {
        Maybe.maybe(always('Empty'), compose(
          setCaretToTheEndPosition,
          identity,
        ))(isTextArea(getRef('typeMessage')));
      });
      setTimeout(() => {
        if (getRef('typeMessage')) {
          getRef('typeMessage').addEventListener('keydown', onSpecialKeys);
          getRef('typeMessage').addEventListener('keyup', onSpecialKeys);
          getRef('typeMessage').addEventListener('paste', onResetAllHtml);
        }
      });
    },
    componentWillUnmount() {
      const { getRef, onSpecialKeys, onResetAllHtml } = this.props;

      getRef('typeMessage').removeEventListener('keyup', onSpecialKeys);
      getRef('typeMessage').removeEventListener('keydown', onSpecialKeys);
      getRef('typeMessage').addEventListener('paste', onResetAllHtml);
    },
  }),
);

export default enhance(TypeMessage);

import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const Banner = ({
  isVisible, onButtonClick, title, actionTitle,
}) => (
  <>
    { isVisible && (
    <div className="banner-request-notification">
      <span className="banner-request-notification__wrapper">
        <span className="banner-request-notification__label">
          {title}
        </span>
        <button
          onClick={onButtonClick}
          type="button"
          className="banner-request-notification__button"
        >
          {actionTitle}
        </button>
      </span>
    </div>
    )}
  </>
);

Banner.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  actionTitle: PropTypes.string.isRequired,
};

export default Banner;

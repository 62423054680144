import React from 'react';
import PropTypes from 'prop-types';
import { pathOr, path } from 'ramda';
import './style.sass';
import { ButtonBrill } from '../../../../components/buttonsCollection';

const InfoAboutClient = ({
  channel: {
    id,
  },
  clientsEntities,
  channelsEntities,
  botsEntities,
  managersEntities,
  nameSocial,
  customFields,
  customFieldsEntities,
  editModalHandler,
  t,
}) => (
  <div className="info-about-client__wrapper">
    <ul className="info-about-client__list">
      <li className="info-about-client__item">
        {pathOr(t('N/A'), [id, 'name'], channelsEntities)}
      </li>
      <li className="info-about-client__item">
        {t('Messenger')}: {nameSocial}
      </li>
      <li className="info-about-client__item">
        {t('Manager')}: {pathOr(t('N/A'), [pathOr('N/A', [id, 'manager_id'], channelsEntities), 'first_name'], managersEntities)}{' '}{pathOr('', [pathOr(t('N/A'), [id, 'manager_id'], channelsEntities), 'last_name'], managersEntities)}
      </li>
      <li className="info-about-client__item">
        {t('Bot')}: {pathOr(t('N/A'), [pathOr('N/A', [id, 'bot_id'], channelsEntities), 'title'], botsEntities)}
      </li>
      <li className="info-about-client__item">
        {t('Phone')}: {pathOr(t('N/A'), [pathOr('N/A', [id, 'client_id'], channelsEntities), 'phone'], clientsEntities)}
      </li>
      <li className="info-about-client__item">
        Telegram: {pathOr(t('N/A'), [pathOr('N/A', [id, 'client_id'], channelsEntities), 'username'], clientsEntities)}
      </li>
      <li className="info-about-client__item">
        Viber: {pathOr(t('N/A'), [pathOr('N/A', [id, 'client_id'], channelsEntities), 'viber_phone'], clientsEntities)}
      </li>
      <li className="info-about-client__item">
        Facebook: {pathOr(t('N/A'), [pathOr('N/A', [id, 'client_id'], channelsEntities), 'facebook_link'], clientsEntities)}
      </li>
      {
        customFields.map(obj => (
          <li
            className="info-about-client__item"
            key={obj.id}
          >
            {path([obj.field_id, 'title'], customFieldsEntities)}: {obj.value || t('N/A')}
          </li>
        ))
      }
    </ul>
    <ButtonBrill className="bots-item__btn bots-item__btn--edit button--sm" onClick={() => editModalHandler(pathOr(t('N/A'), [id, 'client_id'], channelsEntities))} title={t('Edit').toUpperCase()}>{t('Edit').toUpperCase()}</ButtonBrill>
  </div>
);

InfoAboutClient.propTypes = {
  channel: PropTypes.instanceOf(Object).isRequired,
  clientsEntities: PropTypes.instanceOf(Object).isRequired,
  channelsEntities: PropTypes.instanceOf(Object).isRequired,
  managersEntities: PropTypes.instanceOf(Object).isRequired,
  botsEntities: PropTypes.instanceOf(Object).isRequired,
  customFieldsEntities: PropTypes.instanceOf(Object).isRequired,
  nameSocial: PropTypes.string.isRequired,
  customFields: PropTypes.instanceOf(Array),
  editModalHandler: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

InfoAboutClient.defaultProps = {
  customFields: [],
};

export default InfoAboutClient;

import React from 'react';
import PropTypes from 'prop-types';
import { ButtonFilter } from '../..';

const Col = ({
  onClick, name, sort, filter, className, order,
}) => (
  <>
    <div className={className}>
      <ButtonFilter
        value={filter}
        order={order}
        sort={sort}
        onClick={onClick}
      >
        {name}
      </ButtonFilter>
    </div>
  </>
);

Col.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  filter: PropTypes.string,
  order: PropTypes.string,
  sort: PropTypes.string,
  name: PropTypes.string,
};

Col.defaultProps = {
  name: '',
  onClick: () => {},
  filter: '',
  className: '',
  sort: '',
  order: '',
};

export default Col;

import {
  compose, withHandlers, withProps,
} from 'recompose';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { prop, propOr } from 'ramda';

import MemberItem from './memberItem';
import { getFullName } from '../../../../utils/helpers/userHelpers';
import { getImageUrl } from '../../../../utils/helpers/requestHelpers';
import { withUserProfile } from '../../../../utils/enchancers';
import { usersSelectors } from '../../../../state/users';
import { messengerSelectors } from '../../../../state/messenger';
import { userSelectors } from '../../../../state/user';
import { uiActions } from '../../../../state/ui';
import { IMAGES_SIZE } from '../../../../constants/ui';

const onShowUserProfileHandler = ({
  onSetUserProfile,
  user: { id },
}) => () => onSetUserProfile(id);

const mapStateToProps = (state, { memberId, match: { params: { id } } }) => ({
  user: usersSelectors.getUser(state)(memberId),
  userData: userSelectors.getUserData(state),
  channel: messengerSelectors.getChannelById(state)(id),
});

const mapDispatchToProps = {
  setUserProfileId: uiActions.setUserProfileId,
};

const onClickMemberItemHandler = ({
  setUserProfileId, memberId,
}) => () => setUserProfileId(memberId);

const onDeleteHandler = ({ onSetModalDeleteMember, memberId }) => (e) => {
  e.stopPropagation();
  onSetModalDeleteMember(memberId);
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withUserProfile,
  withNamespaces(['common', 'chat']),
  withHandlers({
    onShowUserProfile: onShowUserProfileHandler,
    onDelete: onDeleteHandler,
    onClick: onClickMemberItemHandler,
  }),
  withProps(({ user, channel, userData }) => ({
    userName: getFullName(user),
    userAvatar: getImageUrl(user.avatar, IMAGES_SIZE.xs),
    onlineStatus: propOr(0, 'is_online', user),
    isCanDeleteMember: prop('created_by', channel) === userData.id,
  })),
);

export default enhance(MemberItem);

/* eslint-disable */
import {
  compose, lifecycle, withStateHandlers, hoistStatics, withHandlers, pure, withProps,
} from 'recompose';
import { withNamespaces } from 'react-i18next';
import {
  always,
  ifElse,
  prop,
  length,
  path,
  objOf,
  identity,
  lt,
  when,
  cond,
  curry,
  is,
  T,
  equals,
  replace,
  invoker,
} from 'ramda';
import withRefs from '../../../../utils/enchancers/withRefs';
import TextArea from './textArea';
import { regexRules } from '../../../../utils/helpers/uiComponentHelpers/common';
import { isConditionRight, map } from '../../../../utils/helpers/commonHelpers';
import { notEqual } from 'ramda-extension';
import {
  createTexNode,
  insertNodeInCurrentFocus, setFocusAfterNode
} from '../../../../utils/helpers/uiComponentHelpers/caretHelpers';

//TODO this component must be rewritten. It's full of bad practice and bugs.

const removeBreakers = when(length, replace(regexRules.breakHtmlElement, ''));

const onToggleFillHandler = ({ setFillStatus, isFill }) => compose(
  map(setFillStatus),
  isConditionRight(notEqual(isFill)),
  ifElse(length, always(true), always(false)),
  path(['target', 'innerHTML']),
);

const setScrollHeightStateHandler = (state, { maxHeight }) => compose(
  objOf('scrollHeight'),
  ifElse(lt(maxHeight),
    always(maxHeight),
    identity),
);

const setFocusStatusStateHandler = () => objOf('isFocus');

const setFillStatusStateHandler = () => objOf('isFill');

const onSetRefFieldHandler = ({ childrenRef, setRef }) => element => compose(
  () => { if(childrenRef) {
    childrenRef(element)

    if (element) {
      element.ondrop = (e) => e.preventDefault()
    }
  }  },
  setRef('input'),
)(element);

const onSetRefScrollHandler = ({ setRef }) => setRef('scroll');

const setHeightToElement = curry((element, height) => cond([
  [is(Number), () => element.style.height = `${height}px`],
  [is(String), () => element.style.height = height],
  [T, always('Type of `height` is not correct')],
]));

const onGrowTextAreaHandler = ({ setScrollHeight, getRef }) => compose(
  (element) => {
    requestAnimationFrame(() => {
      compose((height) => {
        setScrollHeight(height);
        setHeightToElement(element, height);
      }, prop('scrollHeight'))(element);
    });
    setHeightToElement(element, 0);
  },
  path(['target']),
);

const onBlurFieldHandler = ({ setFocusStatus }) => compose(
  setFocusStatus,
  ifElse(length,
    always(false),
    always(null)),
  removeBreakers, // todo: maybe we will has trouble with html elements in form without text
  path(['innerHTML']),
);

const updateScrollPosition = (scrollElement, oldScrollTop, oldScrollHeight) => {
  const scrollTop = scrollElement.getScrollTop();
  const scrollTo =  (scrollTop / 100) * ((100 / oldScrollHeight) * oldScrollTop) + 100;
  scrollElement.scrollTop(scrollTo);
};

const onParseDirtyHtmlHandler = ({ onChange, getRef, name }) => (e) => {
  const fieldElement = getRef('input');
  const scrollElement =  getRef('scroll');
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = clipboardData.getData('Text');
  const scrollTop = scrollElement.getScrollTop();
  const scrollHeight = scrollElement.getScrollHeight();
  const newFieldValue = replace(regexRules.regAllHtml, '', pastedData);
  const element = document.createElement('template');
  const emptyElement = createTexNode();

  element.innerHTML = newFieldValue;
  element.content.appendChild(emptyElement);
  element.className = 'js-element-paste-text';
  document.execCommand('insertHtml', null, '');
  insertNodeInCurrentFocus(element.content);
  onChange({ target: { value: fieldElement.innerHTML, name }});
  element.dispatchEvent(new Event('keyup'));
  requestAnimationFrame(() => {
    updateScrollPosition(scrollElement, scrollTop, scrollHeight);
    setFocusAfterNode(fieldElement, emptyElement, true);
  });

  e.preventDefault();
}

const enhance = compose(
  withRefs(),
  withNamespaces(['validation']),
  withStateHandlers(({ value }) => ({
    isFocus: false,
    scrollHeight: 32,
    isFill: ifElse(length, always(true), always(false))(value),
  }), {
    setScrollHeight: setScrollHeightStateHandler,
    setFocusStatus: setFocusStatusStateHandler,
    setFillStatus: setFillStatusStateHandler,
  }),
  withHandlers({
    onToggleFill: onToggleFillHandler,
    onGrowTextArea: onGrowTextAreaHandler,
    onBlurField: onBlurFieldHandler,
    onSetRefField: onSetRefFieldHandler,
    onSetRefScroll: onSetRefScrollHandler,
    onParseDirtyHtml: onParseDirtyHtmlHandler,
  }),
  withProps(({ maxHeight, scrollHeight }) => ({
    stylesScroll: { maxHeight, height: scrollHeight }
  })),
  pure,
  lifecycle({
    componentDidMount() {
      const {
        setFocusStatus, getRef, onToggleFill, isFocus, onBlurField, onGrowTextArea, onParseDirtyHtml,
        isNotValidationPasteText,
      } = this.props;

      const fieldElement = getRef('input');
      fieldElement.addEventListener('blur', onBlurField);
      fieldElement.addEventListener('keyup', onToggleFill, true);
      onGrowTextArea(fieldElement);
      when(equals(true), () => compose(setFocusStatus, T, invoker('focus'))(fieldElement))(isFocus);
      !isNotValidationPasteText && fieldElement.addEventListener('paste', onParseDirtyHtml);
    },
    componentDidUpdate(prevProps) {
      const { value, setFillStatus, onGrowTextArea, getRef } = this.props;
      const { value: prevValue } = prevProps;
      if(value !== prevValue ){
        const fieldElement = getRef('input');
        onGrowTextArea(fieldElement);
        compose(
          setFillStatus,
          ifElse(
            length,
            always(true),
            always(false),
          ),
        )(value);
      }
    },
    componentWillUnmount() {
      const { getRef, onToggleFill, onGrowTextArea,onBlurField, isNotValidationPasteText,
        onParseDirtyHtml } = this.props;
      const fieldElement = getRef('input');
      fieldElement.removeEventListener('keyup', onToggleFill, true);
      fieldElement.removeEventListener('setEmoji', onGrowTextArea);
      fieldElement.removeEventListener('keydown', onGrowTextArea, true);
      fieldElement.removeEventListener('blur', onBlurField);
      !isNotValidationPasteText && fieldElement.removeEventListener('paste', onParseDirtyHtml);
    },
  }),
);
export default hoistStatics(enhance)(TextArea);

import React from 'react';
import { HistoryList } from './components';
import './style.sass';

const HistoryPayment = () => (
  <>
    <HistoryList />
  </>
);

export default HistoryPayment;

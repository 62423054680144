import React from 'react';
import PropTypes from 'prop-types';

import { ToggleButton } from '../../../../components/index';
import './style.sass';

const NotificationPreferences = ({ t, isMute, onToggleMute }) => (
  <div className="notification-preferences">
    <ul className="notification-preferences__list">
      <li className="notification-preferences__item">
        <div className="notification-preferences__label">{t('chat:Mute')}</div>
        <ToggleButton name="mute-channel" onChange={onToggleMute} status={isMute} />
      </li>
    </ul>
  </div>
);

NotificationPreferences.propTypes = {
  t: PropTypes.func.isRequired,
  isMute: PropTypes.bool.isRequired,
  onToggleMute: PropTypes.func.isRequired,
};

export default NotificationPreferences;

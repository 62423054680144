import {
  compose, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { settingBotsSelectors } from '../../../../state/settingBots';
import BotsItem from './botsItem';

const mapStateToProps = (state, { id }) => ({
  botInfo: settingBotsSelectors.getBotById(state)(id),
});

const mapDispatchToProps = ({
});


const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces('bots'),
  withProps(({ botInfo }) => ({
    title: botInfo.title,
    token: botInfo.token,
    pageId: botInfo.page_id,
    pageAccessToken: botInfo.page_access_token,
  })),
);


export default enhance(BotsItem);

import { compose, defaultProps, withHandlers } from 'recompose';
import { withNamespaces } from 'react-i18next';
import { i18n } from '../../utils';

import Copyright from './copyright';

const enhance = compose(
  defaultProps({
    currentYear: new Date().getFullYear().toString(),
  }),
  withNamespaces('common'),
  withHandlers({
    changeLanguage: () => lng => async (e) => {
      e.preventDefault();
      await i18n.changeLanguage(lng);
    },
  }),
);
export default enhance(Copyright);

import React from 'react';
import PropTypes from 'prop-types';

import { ButtonsCollection, ScrollWrapper } from '../../components';
import { GuideInfo } from './components';
import './style.sass';

const GuideTelegramContainer = ({ goBackHandler, t }) => (
  <ScrollWrapper сlassName="guide__scroll-wrapper">
    <div className="guide__header">
      <ButtonsCollection.ButtonIcons
        title={t('Go back')}
        onClick={goBackHandler}
        className="guide__go-back-btn"
      >
        <>
          <span className="icon-expand-arrow-left" />
          <span> {t('Go back')}</span>
        </>
      </ButtonsCollection.ButtonIcons>
      <h3 className="guide__title">Telegram</h3>
    </div>
    <GuideInfo />
  </ScrollWrapper>
);

GuideTelegramContainer.propTypes = {
  goBackHandler: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default GuideTelegramContainer;

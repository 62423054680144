import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';

import {
  ModalWindow, FormsCollection, ButtonsCollection,
} from '../../../../components';
import './style.sass';
import { AsyncAutoComplete } from '../../../../components/autoCompleteField';

const EditChannelModal = ({
  isOpen,
  getUsersAutocomplete,
  handleSubmit,
  handleChange,
  setFieldValue,
  handleBlur,
  touched,
  onClose,
  values,
  errors,
  t,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={onClose}
    modalName="editChannel"
    title="Edit channel"
    className="modal-window__create-chanel"
  >
    <FormsCollection.FormWrapper handleSubmit={handleSubmit} className="create-channel">
      <FormsCollection.Input
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.title}
        error={propOr('', 'message', errors.title)}
        touched={touched.title}
        name="title"
        placeholderJump={t('Channel title')}
        className="create-channel__title-field"
        id="create-channel__title"
      />
      <div className="create-channel__row">
        <FormsCollection.TextArea
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.description}
          error={propOr('', 'message', errors.description)}
          touched={touched.description}
          name="description"
          className="edit-notes__textarea"
          placeholderJump={t('Description')}
          autoHeight
          maxHeight={190}
        />
      </div>
      <div className="create-channel__row create-channel__row--privacy">
        <div className="create-channel__col-label">
          {t('Privacy')}
        </div>
        <div className="create-channel__col-fields">
          <FormsCollection.Radio
            label="Public"
            name="privacy"
            value="public"
            onChange={handleChange}
            onBlur={handleBlur}
            checked={values.privacy === 'public'}
            id="create-channel-modal__privacy--public"
          />
          <FormsCollection.Radio
            label="Private"
            onChange={handleChange}
            onBlur={handleBlur}
            name="privacy"
            value="private"
            checked={values.privacy === 'private'}
            id="create-channel-modal__privacy--private"
          />
        </div>
      </div>
      {
        values.privacy === 'private' && (
          <div className="create-channel__row create-channel__row--members">
            <div className="create-channel__col-label">
              {t('Members')}
            </div>
            <div className="create-channel__col-fields">
              <AsyncAutoComplete
                isMulti
                name="members"
                placeholder={t('Choose members')}
                closeMenuOnSelect={false}
                value={values.members}
                clearable
                autoload={false}
                defaultValue={values.members}
                loadOptions={getUsersAutocomplete}
                onChange={value => setFieldValue('members', value)}
                touched={touched.members}
              />
            </div>
          </div>
        )
      }
      <div className="button-group">
        <ButtonsCollection.ButtonBrill
          className="button--md create-channel__button-save button-brill--fill"
          type="submit"
        >
          {t('Save')}
        </ButtonsCollection.ButtonBrill>
      </div>
    </FormsCollection.FormWrapper>
  </ModalWindow>
);

EditChannelModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  values: PropTypes.shape({
    title: PropTypes.string,
    privacy: PropTypes.string,
    members: PropTypes.instanceOf(Object),
    description: PropTypes.string,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  getUsersAutocomplete: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  touched: PropTypes.instanceOf(Object).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
};

export default EditChannelModal;

import React from 'react';
import { Header, SubscriberContainer } from '../../../../containers';
import './style.sass';
import NavTabs from '../navTabs';

const SubscriberWrapper = ({ t }) => (
  <>
    <Header hideToggleRightSideBar className="user-header">
        <>
          <h1 className="weight--medium title--xl title-page user-nav__title">

            {`${t('Settings')} - ${t('Subscriber')}`}
          </h1>
          <NavTabs />
        </>
    </Header>
    <div className="main-container__content main-container__content--roles">
      <SubscriberContainer />
    </div>
  </>
);

export default SubscriberWrapper;

import React from 'react';
import PropTypes from 'prop-types';

import {
  Avatar, OnlineStatusLabel, BrillMask, ButtonsCollection,
} from '../../../index';

const MemberItem = ({
  user, onDelete, t, userAvatar, userName, isCanDeleteMember, onClick,
}) => (
  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
  <li onClick={onClick} onKeyPress={onClick} className="project-members__item">
    <BrillMask outsideChildren={<OnlineStatusLabel status={user.is_online} />}>
      <Avatar src={userAvatar} alt={userName} />
    </BrillMask>
    <button type="button" className="project-members__link">
      {`${user.first_name} ${user.last_name}`}
    </button>
    {
      isCanDeleteMember && (
        <ButtonsCollection.ButtonIcons
          className="project-members__button-remove"
          onClick={onDelete}
          title={t('Remove member')}
        >
          <span className="icon-close" />
        </ButtonsCollection.ButtonIcons>
      )
    }
  </li>
);

MemberItem.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  user: PropTypes.shape({
    is_online: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
  }).isRequired,
  userName: PropTypes.string.isRequired,
  userAvatar: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isCanDeleteMember: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};


export default MemberItem;

import { createActions } from 'redux-actions';
import { identity } from 'ramda';
import types from './types';


export const {
  getInfoAboutSubscriber,
  setInfoAboutSubscriber,
  updateInfoAboutSubscriberRequest,
  sendPaymentInfoRequest,
  sendPaymentInfoSuccess,
  getStatusSubscriptionRequest,
  getStatusSubscriptionSuccess,
  clearPaymentData,
  deleteAccountRequest,
  cancelSubscriptionRequest,
  cancelSubscriptionSuccess,
  getPaymentHistory,
  setPaymentHistory,
  sendPaymentReceipt,
} = createActions(
  {
    [types.GET_INFO_ABOUT_SUBSCRIBER]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/subscriber',
        selectorName: 'getInfoAboutSubscriber',
        method: 'GET',
        params,
        actions: {
          success: setInfoAboutSubscriber,
        },
        ...meta,
      }),
    ],
    [types.UPDATE_INFO_ABOUT_SUBSCRIBER_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/subscriber',
        selectorName: 'updateInfoAboutSubscriberRequest',
        method: 'PUT',
        params,
        actions: {
          success: setInfoAboutSubscriber,
        },
        ...meta,
      }),
    ],
    [types.SEND_PAYMENT_INFO_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/subscriber/payment',
        selectorName: 'sendPaymentInfoRequest',
        method: 'POST',
        params,
        actions: {
          success: sendPaymentInfoSuccess,
        },
        ...meta,
      }),
    ],
    [types.GET_STATUS_SUBSCRIPTION_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/subscriber/status',
        selectorName: 'getStatusSubscriptionRequest',
        method: 'GET',
        params,
        actions: {
          success: getStatusSubscriptionSuccess,
        },
        ...meta,
      }),
    ],
    [types.DELETE_ACCOUNT_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/subscriber/delete',
        selectorName: 'deleteAccountRequest',
        method: 'POST',
        params,
        ...meta,
      }),
    ],
    [types.CANCEL_SUBSCRIPTION_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/subscriber/unsubscribe',
        selectorName: 'cancelSubscriptionRequest',
        method: 'POST',
        params,
        actions: {
          success: setInfoAboutSubscriber,
        },
        ...meta,
      }),
    ],
    [types.GET_PAYMENT_HISTORY]: [
      identity,
      ({ id, ...params }, meta) => ({
        async: true,
        route: `/subscriber/history/${id}`,
        selectorName: 'getPaymentHistory',
        method: 'GET',
        params,
        actions: {
          success: setPaymentHistory,
        },
        ...meta,
      }),
    ],
    [types.SEND_PAYMENT_RECEIPT]: [
      identity,
      ({ id, ...params }, meta) => ({
        async: true,
        route: `/subscriber/receipt/${id}`,
        selectorName: 'sendPaymentReceipt',
        method: 'GET',
        params,
        ...meta,
      }),
    ],
  },
  types.SET_INFO_ABOUT_SUBSCRIBER,
  types.SEND_PAYMENT_INFO_SUCCESS,
  types.GET_STATUS_SUBSCRIPTION_SUCCESS,
  types.CLEAR_PAYMENT_DATA,
  types.CANCEL_SUBSCRIPTION_SUCCESS,
  types.SET_PAYMENT_HISTORY,
);

import React from 'react';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import { DropDown, FormsCollection } from '../../../../components';
import './style.sass';
import {
  SORT_BY_LETTERS_DOWN,
  SORT_BY_LETTERS_UP,
} from '../../constants';

const FiltersRow = ({
  setFieldValue,
  values,
  t,
}) => (
  <div className="directs-filter">
    <div className="directs-filter__row">
      <FormsCollection.Search
        className="search-field--circle directs-filter__search-field"
        name="search"
        placeholder={t('Search')}
        withoutControl
        value={values.search}
        onChange={e => setFieldValue('search', e.target.value)}
      />
      <DropDown
        label={`${t('Sort')}: `}
        currentItem={path(['orderByTitle', 'id'], values)}
        list={
          [
            {
              id: 1,
              label: t('All'),
              value: null,
            },
            {
              id: 2,
              label: t('A-Z'),
              value: SORT_BY_LETTERS_DOWN,
            },
            {
              id: 3,
              label: t('Z-A'),
              value: SORT_BY_LETTERS_UP,
            },
          ]
        }
        onChange={({ val }) => setFieldValue('orderByTitle', val)}
      />
    </div>
  </div>
);

FiltersRow.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
};

export default FiltersRow;

import React from 'react';
import PropTypes from 'prop-types';

import { FormsCollection, ScrollWrapper } from '../../components';
import { FormProfile, AvatarProfile } from './components';
import './style.sass';
import { getFullName } from '../../utils/helpers/userHelpers';

const Profile = ({
  handleSubmit,
  handleChange,
  values,
  errors,
  setFieldValue,
  userAvatar,
  // eslint-disable-next-line camelcase
  user: { first_name = '', last_name = '' },
  isPending,
  avatarResetHandler,
  avatarUploadHandler,
  errorMessage,
  setErrorMessage,
}) => (
  <ScrollWrapper>
    <div className="profile">
      <div className="profile__container">
        <FormsCollection.FormWrapper
          handleSubmit={handleSubmit}
          className="profile__form"
        >
          <FormProfile
            pending={isPending}
            values={values}
            errors={errors}
            handleChange={handleChange}
          />
          <AvatarProfile
            alt={getFullName({ first_name, last_name })}
            value={values.avatar}
            name="avatar"
            userAvatar={userAvatar}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            setFieldValue={setFieldValue}
            onChange={handleChange}
            onUploadCallback={avatarUploadHandler}
            onResetCallback={avatarResetHandler}
          />
        </FormsCollection.FormWrapper>
      </div>
    </div>
  </ScrollWrapper>
);

Profile.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  userAvatar: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isPending: PropTypes.bool.isRequired,
  avatarResetHandler: PropTypes.func.isRequired,
  avatarUploadHandler: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  errorMessage: PropTypes.string,
  setErrorMessage: PropTypes.func.isRequired,
};

Profile.defaultProps = {
  userAvatar: 0,
  errorMessage: '',
};

export default Profile;

import {
  compose, withHandlers, withProps, withStateHandlers,
} from 'recompose';
import { identity } from 'ramda';
import { connect } from 'react-redux';

import { uploadTempFileRequest } from '../../state/ui/actions';
import { getTempFileUploadPending } from '../../state/ui/selectors';
import { getImageUrl } from '../helpers/requestHelpers';

const onUploadFileHandler = ({
  uploadTempFile, setFileValue, onChange, name, onUploadCallback, onCbErrorFileUploading = identity,
}) => (e) => {
  const previewData = URL.createObjectURL(e.currentTarget.files[0]);
  const formData = new FormData();
  formData.append('file', e.currentTarget.files[0]);
  uploadTempFile(formData, {
    callbacks: {
      error: onCbErrorFileUploading,
      success: ({ model }) => {
        onChange({
          target: {
            name,
            value: model.id,
          },
        });
        if (onUploadCallback) onUploadCallback(model.id);
        setFileValue(previewData);
      },
    },
  });
};


const onDeleteFileHandler = ({ onChange, name }) => () => {
  onChange({
    target: {
      name,
      value: '',
    },
  });
};

const setFileValueStateHandler = () => value => ({ fileUrl: value });

const mapStateToProps = state => ({
  isPending: getTempFileUploadPending(state),
});
const mapDispatchToProps = {
  uploadTempFile: uploadTempFileRequest,
};

const withUploadFile = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStateHandlers(() => ({ fileUrl: '' }), {
    setFileValue: setFileValueStateHandler,
  }),
  withHandlers({
    onDeleteFile: onDeleteFileHandler,
    onUploadFile: onUploadFileHandler,
  }),
  withProps(({ value }) => ({ value: getImageUrl(value) })),
);

export default withUploadFile;

import React from 'react';
import PropTypes from 'prop-types';

import {
  eighthImage,
  fifthImage,
  firstImage,
  fourthImage,
  ninthImage,
  sixthImage,
  secondImage,
  tenthImage,
  thirdImage,
} from '../../../../constants/viberImages';

import './style.sass';

const GuideInfo = ({ openModalHandler, t }) => (
  <div className="guide__wrapper">
    <div className="guide__content">
      <span className="guide__text">{t('viber-sentence-first')}</span>
      <button onClick={() => openModalHandler(firstImage)} type="button" className="guide__container-btn">
        <img className="guide__image guide__image--top" src={firstImage} alt="firstImage" />
      </button>
      <span className="guide__text">{t('viber-sentence-second')} <a href="https://partners.viber.com">partners.viber.com</a></span>
      <button onClick={() => openModalHandler(secondImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={secondImage} alt="secondImage" />
      </button>
      <span className="guide__text">{t('viber-sentence-third')}</span>
      <button onClick={() => openModalHandler(thirdImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={thirdImage} alt="thirdImage" />
      </button>
      <span className="guide__text">{t('viber-sentence-fourth')}</span>
      <button onClick={() => openModalHandler(fourthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={fourthImage} alt="fourthImage" />
      </button>
      <span className="guide__text">{t('viber-sentence-fifth')}</span>
      <ul className="guide__list">
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <li className="guide__item">{t('viber-account-name')}</li>
        <li className="guide__item">{t('viber-account-uri')}</li>
        <li className="guide__item">{t('viber-account-category')}</li>
        <li className="guide__item">{t('viber-account-subcategory')}</li>
        <li className="guide__item">{t('viber-account-language')}</li>
        <li className="guide__item">{t('viber-account-description')}</li>
        <li className="guide__item">{t('viber-account-website')}</li>
        <li className="guide__item">{t('viber-account-email')}</li>
        <li className="guide__item">{t('viber-account-location')}</li>
      </ul>
      <span className="guide__text">{t('viber-sentence-sixth')}</span>
      <button onClick={() => openModalHandler(fifthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={fifthImage} alt="fifthImage" />
      </button>
      <span className="guide__text">{t('viber-sentence-seventh')}</span>
      <button onClick={() => openModalHandler(sixthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={sixthImage} alt="sixthImage" />
      </button>
      <span className="guide__text">{t('viber-sentence-eighth')}</span>
      <button onClick={() => openModalHandler(eighthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={eighthImage} alt="eighthImage" />
      </button>
      <span className="guide__text">{t('viber-sentence-ninth')}</span>
      <button onClick={() => openModalHandler(ninthImage)} type="button" className="guide__container-btn">
        <img className="guide__image guide__image--top" src={ninthImage} alt="ninthImage" />
      </button>
      <span className="guide__text">{t('viber-sentence-tenth')}</span>
      <button onClick={() => openModalHandler(tenthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={tenthImage} alt="tenthImage" />
      </button>
      <span className="guide__text">{t('viber-sentence-eleventh')}</span>
    </div>
  </div>
);

GuideInfo.propTypes = {
  openModalHandler: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default GuideInfo;

import React from 'react';
import PropTypes from 'prop-types';
import { includes } from 'ramda';

import {
  Header, Chat,
} from '../../../../containers';
import './style.sass';
import { BreadCrumbs, InformationRightSideBar } from '..';
import HeaderDropDown from './components';
import { GROUP_CHANNELS } from '../../../../constants/messenger';

const MessengerContainer = ({
  channelId, activeChannel, isUserOwnsChannel, botName, t,
}) => (
  <div className="main-container">
    <Header className="header--messenger">
      <BreadCrumbs
        title={activeChannel.name}
        botName={t(`chat:${botName}`)}
        channelType={activeChannel.type}
      />
      <>
        <HeaderDropDown
          isRenderChannelControl={includes(activeChannel.type, GROUP_CHANNELS)}
          isUserOwnsChannel={isUserOwnsChannel}
        />
      </>
    </Header>
    <div className="main-container__content">
      <div className="messenger">
        <Chat
          channelId={Number(channelId)}
        />
      </div>
      <InformationRightSideBar channelId={channelId} />
    </div>
  </div>
);

MessengerContainer.propTypes = {
  channelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isUserOwnsChannel: PropTypes.bool.isRequired,
  activeChannel: PropTypes.instanceOf(Object).isRequired,
  botName: PropTypes.string,
  t: PropTypes.func.isRequired,
};

MessengerContainer.defaultProps = {
  channelId: 0,
  botName: '',
};

export default MessengerContainer;

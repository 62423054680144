import { createSelector } from 'reselect';
import { pathOr, identity } from 'ramda';

const getSettingUsersEntities = createSelector(pathOr({}, ['settingUsers', 'entities']), identity);
const getSettingUsersList = createSelector(pathOr([], ['settingUsers', 'result']), identity);
const getSettingUsersCount = createSelector(pathOr(0, ['settingUsers', 'count']), identity);
const getSettingUsersPending = createSelector(pathOr(false, ['pending', 'getSettingUsersRequest']), identity);
const getSettingUsersHasMore = createSelector(pathOr(false, ['settingUsers', 'hasMore']), identity);
const getDeleteUserPending = createSelector(pathOr(false, ['pending', 'deletingSettingUserRequest']), identity);
const getCreateUserPending = createSelector(pathOr(false, ['pending', 'createUserRequest']), identity);

const getUpdateUserPending = createSelector(pathOr(false, ['pending', 'updateSettingUserRequest']), identity);
const getResendInvitePending = createSelector(pathOr(false, ['pending', 'resendInviteRequest']), identity);

export {
  getSettingUsersEntities,
  getSettingUsersList,
  getSettingUsersCount,
  getSettingUsersPending,
  getSettingUsersHasMore,
  getDeleteUserPending,
  getCreateUserPending,
  getUpdateUserPending,
  getResendInvitePending,
};

import React from 'react';
import PropTypes from 'prop-types';
import { identity } from 'ramda';
import { isNotEmpty } from 'ramda-extension';

import { MessageHistory, SendMessage } from './components';
import './style.sass';
import JoinToChannel from './components/joinToChannel';
import { translateHelpers } from '../../utils/helpers';

const Chat = ({
  isMember, onSubmitMessage, setRef, isChannelLoaded,
  activeChannel, isScrollToBottom, setIsScrollToBottom, members, containerSize,
  containerWidth, unreadIndex, setScrollToIndex,
  onTextAreaKeyDown, editableMessage, setEditableMessage, scrollToIndex, membersForMention,
  onKeyDownPureTextArea, onTextAreaFocus, replyMessage, setReplyMessage, channel,
  onRenderContent, isReplyEdited, setIsPuck, isPuck, updateMessageContainerHeight, onSetInputRef,
  readMessagesWhenMountMessageHistory,
}) => (
  <div ref={e => setRef('container', e)} className="messenger">
    <MessageHistory
      activeChannel={activeChannel}
      isChannelLoaded={isChannelLoaded}
      unreadIndex={unreadIndex}
      isScrollToBottom={isScrollToBottom}
      setIsScrollToBottom={setIsScrollToBottom}
      height={containerSize}
      setScrollToIndex={setScrollToIndex}
      scrollToIndex={scrollToIndex}
      containerWidth={containerWidth}
      members={members}
      editableMessage={editableMessage}
      setEditableMessage={setEditableMessage}
      replyMessage={replyMessage}
      isReplyEdited={isReplyEdited}
      setReplyMessage={setReplyMessage}
      setIsPuck={setIsPuck}
      isPuck={isPuck}
      onDidMount={readMessagesWhenMountMessageHistory}
    />
    <div
      className={`message-textArea
     ${isNotEmpty(replyMessage) ? 'message-textArea--replied' : ''}
     ${!isMember ? 'message-textArea--join-to-channel' : ''}`}
      ref={e => setRef('fieldContainer', e)}
    >
      {
           channel.type === 3 || isMember ? (
             <SendMessage
               sendLabel={translateHelpers.t('chat', 'Send')}
               className="message-textArea__textArea"
               onSubmit={onSubmitMessage}
               keyField={`messenger-${channel.id}`}
               members={membersForMention}
               onKeyDown={onTextAreaKeyDown}
               onKeyDownPureTextArea={onKeyDownPureTextArea}
               onTextAreaFocus={onTextAreaFocus}
               replyMessage={replyMessage}
               setReplyMessage={setReplyMessage}
               onRenderContent={onRenderContent}
               onSetInputRef={onSetInputRef}
             />
           ) : (
             <JoinToChannel updateMessageContainerHeight={updateMessageContainerHeight} />
           )
      }
    </div>
  </div>
);

Chat.propTypes = {
  activeChannel: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.number,
  }).isRequired,
  isChannelLoaded: PropTypes.shape({
    id: PropTypes.number,
    loading: PropTypes.bool,
  }),
  containerSize: PropTypes.number.isRequired,
  unreadIndex: PropTypes.number,
  containerWidth: PropTypes.number,
  members: PropTypes.oneOfType([PropTypes.instanceOf(Object).isRequired,
    PropTypes.oneOf([null])]),
  membersForMention: PropTypes.instanceOf(Object).isRequired,
  isScrollToBottom: PropTypes.bool.isRequired,
  setIsScrollToBottom: PropTypes.func.isRequired,
  onSubmitMessage: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired,
  onTextAreaKeyDown: PropTypes.func.isRequired,
  onTextAreaFocus: PropTypes.func.isRequired,
  onKeyDownPureTextArea: PropTypes.func.isRequired,
  editableMessage: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.number,
  ]),
  scrollToIndex: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.number,
  ]),
  setScrollToIndex: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
  ]),
  setEditableMessage: PropTypes.func.isRequired,
  setReplyMessage: PropTypes.func.isRequired,
  onRenderContent: PropTypes.func.isRequired,
  isMember: PropTypes.bool.isRequired,
  isReplyEdited: PropTypes.bool.isRequired,
  replyMessage: PropTypes.oneOfType([PropTypes.instanceOf(Object), () => null]),
  setIsPuck: PropTypes.func.isRequired,
  isPuck: PropTypes.bool.isRequired,
  channel: PropTypes.instanceOf(Object).isRequired,
  updateMessageContainerHeight: PropTypes.func.isRequired,
  onSetInputRef: PropTypes.func.isRequired,
  readMessagesWhenMountMessageHistory: PropTypes.func.isRequired,
};

Chat.defaultProps = {
  unreadIndex: 0,
  setScrollToIndex: identity,
  editableMessage: null,
  replyMessage: {},
  scrollToIndex: null,
  containerWidth: 0,
  members: null,
  isChannelLoaded: null,
};

export default Chat;

import React from 'react';
import PropTypes from 'prop-types';
import TariffsItem from '../tariffsItem';
import { TARIFFS_NAMES, PAYMENTS_STATUSES } from '../../../../constants/subscription';
import './style.sass';

const TariffsContainer = ({
  t, onChangeTariff, onCancelSubscription, onGoBack, tariff, paymentStatus,
}) => (
  <div className="tariffs">
    <button type="button" onClick={onGoBack} className="tariffs__btn-go-back">
      <span className="icon-expand-arrow-left tariffs__arrow-left" />
      {t('Go back')}
    </button>
    <h2 className="tariffs__header">{t('Upgrade your monthly subscription')}</h2>
    <div className="tariffs__list">
      <TariffsItem
        title="Entrepreneur"
        isFree
        free="Free"
        onChangeTariff={() => onChangeTariff(TARIFFS_NAMES.ENTREPRENEUR)}
        btnText={tariff === TARIFFS_NAMES.ENTREPRENEUR ? 'Current plan' : 'Order'}
        isCover={tariff >= TARIFFS_NAMES.ENTREPRENEUR}
      >
        <ul className="tariff__benefits-list">
          <li className="tariff__benefits-item"><span className="icon-check tariff__benefits-icon" />{t('entrepreneur-item-1')}</li>
          <li className="tariff__benefits-item"><span className="icon-check tariff__benefits-icon" />{t('entrepreneur-item-2')}</li>
          <li className="tariff__benefits-item"><span className="icon-check tariff__benefits-icon" />{t('entrepreneur-item-3')}</li>
          <li className="tariff__benefits-item"><span className="icon-check tariff__benefits-icon" />{t('entrepreneur-item-4')}</li>
          <li className="tariff__benefits-item"><span className="icon-check tariff__benefits-icon" />{t('entrepreneur-item-5')}</li>
        </ul>
      </TariffsItem>
      <TariffsItem
        title="Business"
        isFree={false}
        amount={9}
        onChangeTariff={() => onChangeTariff(TARIFFS_NAMES.BUSINESS)}
        btnText={(paymentStatus === PAYMENTS_STATUSES.PAYMENT_STATUS_UNSUBSCRIBED
        && tariff >= TARIFFS_NAMES.BUSINESS) || tariff === TARIFFS_NAMES.ENTREPRENEUR
          ? 'Upgrade' : tariff === TARIFFS_NAMES.BUSINESS
            ? 'Current plan' : 'Order'}
        isCover={!(PAYMENTS_STATUSES.PAYMENT_STATUS_UNSUBSCRIBED === paymentStatus
          && tariff === TARIFFS_NAMES.BUSINESS) && tariff >= TARIFFS_NAMES.BUSINESS}
      >
        <ul className="tariff__benefits-list">
          <li className="tariff__benefits-item"><span className="icon-check tariff__benefits-icon" />{t('business-item-1')}</li>
          <li className="tariff__benefits-item"><span className="icon-check tariff__benefits-icon" />{t('business-item-2')}</li>
          <li className="tariff__benefits-item"><span className="icon-check tariff__benefits-icon" />{t('business-item-3')}</li>
          <li className="tariff__benefits-item"><span className="icon-check tariff__benefits-icon" />{t('business-item-4')}</li>
          <li className="tariff__benefits-item"><span className="icon-check tariff__benefits-icon" />{t('business-item-5')}</li>
        </ul>
      </TariffsItem>
      <TariffsItem
        title="Virtuoso"
        isFree={false}
        amount={49}
        onChangeTariff={() => onChangeTariff(TARIFFS_NAMES.VIRTUOSO)}
        btnText={tariff === TARIFFS_NAMES.VIRTUOSO && paymentStatus !== PAYMENTS_STATUSES.PAYMENT_STATUS_UNSUBSCRIBED ? 'Current plan' : 'Upgrade'}
        isCover={paymentStatus !== PAYMENTS_STATUSES.PAYMENT_STATUS_UNSUBSCRIBED
        && tariff === TARIFFS_NAMES.VIRTUOSO}
      >
        <ul className="tariff__benefits-list">
          <li className="tariff__benefits-item"><span className="icon-check tariff__benefits-icon" />{t('virtuoso-item-1')}</li>
          <li className="tariff__benefits-item"><span className="icon-check tariff__benefits-icon" />{t('virtuoso-item-2')}</li>
          <li className="tariff__benefits-item"><span className="icon-check tariff__benefits-icon" />{t('virtuoso-item-3')}</li>
          <li className="tariff__benefits-item"><span className="icon-check tariff__benefits-icon" />{t('virtuoso-item-4')}</li>
          <li className="tariff__benefits-item"><span className="icon-check tariff__benefits-icon" />{t('virtuoso-item-5')}</li>
          <li className="tariff__benefits-item"><span className="icon-check tariff__benefits-icon" />{t('virtuoso-item-6')}</li>
        </ul>
      </TariffsItem>
    </div>
    { tariff !== TARIFFS_NAMES.ENTREPRENEUR
    && paymentStatus !== PAYMENTS_STATUSES.PAYMENT_STATUS_UNSUBSCRIBED
    && paymentStatus !== PAYMENTS_STATUSES.PAYMENT_STATUS_FREE
    && paymentStatus !== PAYMENTS_STATUSES.PAYMENT_STATUS_NOT_PAID ? (
      <button type="button" onClick={onCancelSubscription} className="tariffs__btn-cancel">
        {t('Cancel subscription')}
      </button>
      ) : ('')}
  </div>
);

TariffsContainer.propTypes = {
  t: PropTypes.func.isRequired,
  onChangeTariff: PropTypes.func.isRequired,
  onCancelSubscription: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  tariff: PropTypes.number.isRequired,
  paymentStatus: PropTypes.number.isRequired,
};

export default TariffsContainer;

import {
  compose, lifecycle, withHandlers, withProps, withStateHandlers,
} from 'recompose';
import { concat } from 'ramda';
import { connect } from 'react-redux';

import { withRouter } from 'react-router';
import { withNamespaces } from 'react-i18next';
import MainNav from './mainNav';
import { openModal } from '../../../../state/ui/actions';
import { LAST_CHANNEL_MESSENGER_STORAGE } from '../../../../constants/messenger';
import * as messengerSelectors from '../../../../state/messenger/selectors';
import { userSelectors } from '../../../../state/user';

const mapDispatchToProps = ({
  setOpenModal: openModal,
});

const channelIdFromStorage = () => window.localStorage.getItem(LAST_CHANNEL_MESSENGER_STORAGE) || 1;

const onChangeStorageHandler = ({ setLastChannelId }) => () => {
  const channelId = channelIdFromStorage();
  setLastChannelId(channelId);
};

const setLastChannelIdStateHandler = () => channelId => ({
  lastChannelId: channelId || '',
});

const onCreateProjectModalHandler = ({ setOpenModal }) => (e) => {
  e.preventDefault();
  setOpenModal('createProjectModal');
};

const mapStateToProps = state => ({
  totalUnreadCount: messengerSelectors.getTotalUnreadCount(state),
  generalUnreadCount: messengerSelectors.getGeneralUnreadCount(state),
  ownChannelId: userSelectors.getOwnChannelId(state) || '',
  directChannels: messengerSelectors.getDirectChannelsList(state),
  groupChannels: messengerSelectors.getGroupChannelsList(state),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withNamespaces('common'),
  withStateHandlers(() => ({ lastChannelId: channelIdFromStorage() }), {
    setLastChannelId: setLastChannelIdStateHandler,
  }),
  withHandlers({
    onCreateProjectModal: onCreateProjectModalHandler,
    onChangeStorage: onChangeStorageHandler,
  }),
  withProps(({ groupChannels, directChannels }) => ({
    allChannels: concat(groupChannels.filter(id => id !== 1),
      directChannels.filter(id => id !== 1 && id !== 2)),
  })),
  lifecycle({
    componentDidMount() {
      window.addEventListener('storageChange', this.props.onChangeStorage, false);
      window.addEventListener('storage', this.props.onChangeStorage, false);
    },
    componentDidUpdate({ lastChannelId }) {
      const storageChannelId = channelIdFromStorage();
      if (storageChannelId && lastChannelId !== storageChannelId) {
        this.props.onChangeStorage();
      }
    },
    componentWillUnmount() {
      window.removeEventListener('storageChange', this.props.onChangeStorage, false);
      window.removeEventListener('storage', this.props.onChangeStorage, false);
    },
  }),
);
export default enhance(MainNav);

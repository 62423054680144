import { createActions } from 'redux-actions';
import {
  identity, pathOr, compose, ap, of, propOr,
} from 'ramda';

import types from './types';
import { getError } from '../../utils/helpers/requestHelpers';
import { callNotification } from '../../utils/helpers/notifies';
import { LARGE_FILE_ERROR } from '../../constants/errors';

export const {
  uploadTempFileRequest,
  displayNotification,
  displayErrorPage,
  goBackFromErrorPage,
  setPreloader,
  changeLeftSidebarStatus,
  changeRightSidebarStatus,
  changeSwipeSidebarsStatus,
  setPagination,
  resetPagination,
  openModal,
  closeModal,
  setSort,
  setOrder,
  setGridOptions,
  setRoutesType,
  resetUi,
  onDragEnd,
  getUserProfileRequest,
  showNotification,
  clearGridOptions,
  setFilter,
  setErrorPage,
  changeProjectsTabStatus,
  changeCoWorkersTabStatus,
  changeModulesTabStatus,
  changeImportantTasksTabStatus,
  changeProjectMembersTabStatus,
  changeMessengerDetailsTabStatus,
  changeMessengerMediaTabStatus,
  changeMessengerPinnedItemsTabStatus,
  changeMessengerNotificationTabStatus,
  changeMessengerMembersTabStatus,
  resetModals,
  setUserProfileId,
  setUserProfile,
  setIsFocus,
  setTextareaMessengerWasChanged,
  setUrlBeforeChannels,
  setWsError,
  saveFieldValue,
  resetFieldValue,
  saveUrlFilter,
  resetUrlFilter,
} = createActions(
  {
    [types.UPLOAD_TEMP_FILE_REQUEST]: [
      identity,
      (params, { callbacks, meta }) => ({
        async: true,
        route: '/temp-files',
        selectorName: 'uploadTempFilesRequest',
        method: 'POST',
        callbacks: {
          success: pathOr(identity, ['success'], callbacks),
          error: compose(ap([compose(callNotification.error, propOr(LARGE_FILE_ERROR, 'message')),
            propOr(identity, 'error', callbacks)]), of, getError),
        },
        params,
        ...meta,
      }),
    ],
    [types.ON_DRAG_END]: [
      identity,
      (params, meta) => meta,
    ],
    [types.GET_USER_PROFILE_REQUEST]: [
      identity,
      ({ userId, ...params }, meta) => ({
        async: true,
        params,
        route: `/users/${userId}`,
        selectorName: 'getUserProfileRequest',
        method: 'GET',
        actions: {
          success: setUserProfile,
        },
        ...meta,
      }),
    ],
    [types.SHOW_NOTIFICATION]: [
      identity,
      (payload, meta) => ({ ...meta }),
    ],
  },
  types.DISPLAY_NOTIFICATION,
  types.DISPLAY_ERROR_PAGE,
  types.GO_BACK_FROM_ERROR_PAGE,
  types.SET_PRELOADER,
  types.CHANGE_LEFT_SIDEBAR_STATUS,
  types.CHANGE_RIGHT_SIDEBAR_STATUS,
  types.CHANGE_SWIPE_SIDEBARS_STATUS,
  types.SET_PAGINATION,
  types.RESET_PAGINATION,
  types.OPEN_MODAL,
  types.CLOSE_MODAL,
  types.SET_SORT,
  types.SET_ORDER,
  types.SET_GRID_OPTIONS,
  types.SET_ROUTES_TYPE,
  types.RESET_UI,
  types.CLEAR_GRID_OPTIONS,
  types.SET_FILTER,
  types.SET_ERROR_PAGE,
  types.CHANGE_PROJECTS_TAB_STATUS,
  types.CHANGE_CO_WORKERS_TAB_STATUS,
  types.CHANGE_MODULES_TAB_STATUS,
  types.CHANGE_IMPORTANT_TASKS_TAB_STATUS,
  types.CHANGE_PROJECT_MEMBERS_TAB_STATUS,
  types.CHANGE_MESSENGER_DETAILS_TAB_STATUS,
  types.CHANGE_MESSENGER_MEDIA_TAB_STATUS,
  types.CHANGE_MESSENGER_PINNED_ITEMS_TAB_STATUS,
  types.CHANGE_MESSENGER_NOTIFICATION_TAB_STATUS,
  types.CHANGE_MESSENGER_MEMBERS_TAB_STATUS,
  types.RESET_MODALS,
  types.SET_USER_PROFILE_ID,
  types.SET_USER_PROFILE,
  types.SET_IS_FOCUS,
  types.SET_TEXTAREA_MESSENGER_WAS_CHANGED,
  types.SET_URL_BEFORE_CHANNELS,
  types.SET_WS_ERROR,
  types.SAVE_FIELD_VALUE,
  types.RESET_FIELD_VALUE,
  types.SAVE_URL_FILTER,
  types.RESET_URL_FILTER,
);

import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { pathOr, tap } from 'ramda';
import { withNamespaces } from 'react-i18next';
import rules from './rules';
import CustomFieldsModal from './customFieldsModal';
import { getModal } from '../../../../../state/ui/selectors';
import { uiActions } from '../../../../../state/ui';
import { customFieldsActions, customFieldsSelectors } from '../../../../../state/customFields';
import { callNotification } from '../../../../../utils/helpers/notifies';
import { customFields } from '../../../../../constants';


const onCloseModalHandler = ({ onCloseModal }) => () => onCloseModal('customFieldsModal');

const submitForm = (formValues, {
  props: {
    setIsUiAddMoreFields, addCustomField, setErrorFromBack,
  }, resetForm,
}) => {
  const { title } = formValues;
  const { CLIENTS } = customFields.CUSTOM_FIELDS_MODELS;
  if (title.trim()) {
    addCustomField({
      title, model_name: CLIENTS,
    }, {
      callbacks: {
        success: () => {
          resetForm({});
          tap(setIsUiAddMoreFields, false);
        },
        error:
          compose(
            (e) => {
              callNotification.error(e);
              setErrorFromBack(e);
            },
            pathOr({}, ['response', 'data', 'result', 'error']),
          ),
      },
    });
  } else {
    callNotification.error('Title is required');
  }
};


const onDeleteCustomFieldHandler = ({ setOpenModal, setFieldId }) => (id) => {
  setFieldId(id);
  setOpenModal('deleteCustomField');
};

const mapStateToProps = state => ({
  isOpen: getModal(state)('customFieldsModal'),
  customFieldsList: customFieldsSelectors.getCustomFieldsList(state),
});

const mapDispatchToProps = ({
  onCloseModal: uiActions.closeModal,
  setOpenModal: uiActions.openModal,
  addCustomField: customFieldsActions.addCustomFieldRequest,
  getCustomFields: customFieldsActions.getCustomFieldsRequest,
});

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common', 'customFields']),
  withState('isUiAddMoreFields', 'setIsUiAddMoreFields', null),
  withState('fieldId', 'setFieldId', null),
  withState('errorFromBack', 'setErrorFromBack', {}),
  withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: rules,
    handleSubmit: submitForm,
    mapPropsToValues: () => ({
      title: '',
    }),
    enableReinitialize: true,
  }),
  withHandlers({
    onCloseModalHandler,
    onDeleteCustomField: onDeleteCustomFieldHandler,
  }),
  lifecycle({
    componentDidMount() {
      const { getCustomFields } = this.props;
      getCustomFields();
    },
  }),
);

export default enhancer(CustomFieldsModal);

import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const FormWrapper = ({
  children, handleSubmit, name, className, customRef, classNameForm,
}) => (
  <div className={`form-group ${classNameForm}`}>
    <form action="" ref={e => customRef(e)} onSubmit={handleSubmit} name={name} className={`form-group__form ${className}`}>
      { children }
    </form>
  </div>
);

FormWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.object,
    PropTypes.any,
  ]).isRequired,
  customRef: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  className: PropTypes.string,
  name: PropTypes.string,
  classNameForm: PropTypes.string,
};

FormWrapper.defaultProps = {
  name: '',
  className: '',
  classNameForm: '',
  customRef: () => {},
};

export default FormWrapper;

import {
  compose, getContext, withProps,
} from 'recompose';
import PropTypes from 'prop-types';
import { always, ifElse, prop } from 'ramda';
import { connect } from 'react-redux';

import { withNamespaces } from 'react-i18next';
import DirectsItem from './directsItem';
import { uiSelectors } from '../../../../state/ui';
import { usersActions, usersSelectors } from '../../../../state/users';
import { messengerActions } from '../../../../state/messenger';
import { getDirectChannelId } from '../../../../state/messenger/selectors';
import { clientsSelectors } from '../../../../state/clients';
import { settingBotsSelectors } from '../../../../state/settingBots';
import { settingUsersSelectors } from '../../../../state/settingUsers';


const mapStateToProps = (state, { id }) => ({
  userId: uiSelectors.getUserProfileId(state),
  users: usersSelectors.getUsersEntities(state),
  channelId: getDirectChannelId(state)(id),
  isPending: uiSelectors.getUserProfileRequestPending(state),
  clientsEntities: clientsSelectors.getClientsEntities(state),
  botsEntities: settingBotsSelectors.getBotsEntities(state),
  managersEntities: settingUsersSelectors.getSettingUsersEntities(state),
});

const mapDispatchToProps = {
  getUserProfile: usersActions.getUserProfileRequest,
  openChannelByUserId: messengerActions.openDirectChannelByUserId,
};


const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces('common'),
  getContext({
    values: PropTypes.shape({}),
  }),
  withProps(({ values: { type } }) => ({
    isChannelICanJoin: ifElse(prop('value'), always(true), always(false))(type),
  })),
);

export default enhance(DirectsItem);

import React from 'react';
import PropTypes from 'prop-types';
import { path } from 'ramda';

import {
  ButtonsCollection,
  FormsCollection, Layouts,
} from '../../../components';
import NotificationsForAuthForm from '../components/errorNotification';
import './style.sass';

const ChangePassword = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,
  authNotification,
  t,
  pending,
}) => (
  <Layouts.Auth>
    <NotificationsForAuthForm />
    <div className="auth-wrapper__wrap">
      <FormsCollection.FormWrapper handleSubmit={handleSubmit}>
        <div className="auth-wrapper__form-contain">
          <h2 className="title weight--bold title--md text-align--center">{t('Reset password')}</h2>
          <FormsCollection.Input
            type="text"
            hiddenEye
            placeholderJump={t('New password')}
            required
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!path(['password'], authNotification)}
            name="password"
            id="reset-password__password"
            touched={touched.password}
            value={values.password}
          />
          <FormsCollection.Input
            type="text"
            hiddenEye
            placeholderJump={t('Confirm new password')}
            required
            onChange={handleChange}
            error={!!path(['passwordConfirm'], authNotification)}
            onBlur={handleBlur}
            name="passwordConfirm"
            id="reset-password__confirm-password"
            touched={touched.passwordConfirm}
            value={values.passwordConfirm}
          />
          <div className="button-group">
            <ButtonsCollection.ButtonBrill
              type="submit"
              pending={pending}
              className="auth-wrapper__button--with-border button--fill"
              borderColor="#26b5ed"
              borderWidth="2"
            >
              {t('Confirm')}
            </ButtonsCollection.ButtonBrill>
          </div>
        </div>
      </FormsCollection.FormWrapper>
    </div>
  </Layouts.Auth>
);

ChangePassword.propTypes = {
  values: PropTypes.shape({
    password: PropTypes.string,
    passwordConfirm: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  touched: PropTypes.shape({
    passwordConfirm: PropTypes.bool,
    password: PropTypes.bool,
  }).isRequired,
  authNotification: PropTypes.shape({
    password: PropTypes.object,
    passwordConfirm: PropTypes.object,
  }),
  pending: PropTypes.bool,
};

ChangePassword.defaultProps = {
  pending: false,
  authNotification: {},
};

export default ChangePassword;

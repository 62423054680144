import React from 'react';
import PropTypes from 'prop-types';

import { Header } from '../../containers';
import { Copyright, ButtonsCollection } from '../../components';
import './style.sass';

const PageError = ({
  deletePage404, label, code, title, subTitle, t,
}) => (
  <div className="page-wrapper page-error">
    <Header empty />
    <div className="page-error__wrapper">
      <div className="page-error__row">
        <h1 className="page-error__code">{code}</h1>
        <h2 className="page-error__title">{t(title)}</h2>
        <b className="page-error__help-text">{t(subTitle)}</b>
        <div className="button-group">
          <ButtonsCollection.ButtonBrill
            className="button--md page-error__back-button"
            onClick={deletePage404}
          >
            {t(label)}
          </ButtonsCollection.ButtonBrill>
        </div>
      </div>
    </div>
    <Copyright />
  </div>
);

PageError.propTypes = {
  deletePage404: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  code: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  t: PropTypes.func.isRequired,
};

PageError.defaultProps = {
  subTitle: '',
};

export default PageError;

import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const ButtonClose = ({
  title, onClick, className, t,
}) => (
  <button
    className={`close-button ${className}`}
    aria-label={`Close ${title}`}
    title={`${t('close')}${' '}${title}`}
    type="button"
    onClick={() => onClick()}
  >
    <span className="icon-close" />
  </button>
);
ButtonClose.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
};
ButtonClose.defaultProps = {
  title: '',
  onClick: () => {},
  className: '',
};
export default ButtonClose;

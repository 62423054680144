import React from 'react';

import PropTypes from 'prop-types';

import {
  eighteenthImage,
  eighthImage,
  eleventhImage,
  fifteenthImage,
  fifthImage,
  firstImage,
  fourteenthImage,
  fourthImage,
  nineteenthImage,
  ninthImage,
  secondimage,
  seventeenthImage,
  seventhImage,
  sixteenthImage,
  sixthImage,
  tenthImage,
  thirdImage,
  thirteenthImage,
  thirtiethImage,
  twelfthImage,
  twentiethImage, twentyFifthImage,
  twentyFirstImage, twentyFourthImage,
  twentySecondImage, twentySeventhImage, twentySixthImage,
  twentyThirdImage, twentyNinthImage,
} from '../../../../constants/facebookImages';

import './style.sass';

const GuideInfo = ({ openModalHandler, onCopyLinkClick, t }) => (
  <div className="guide__wrapper">
    <div className="guide__content">
      <span className="guide__text">{t('fb-sentence-first')}</span>
      <span className="guide__text">{t('fb-sentence-second-part-one')} <a href="https://developers.facebook.com/">https://developers.facebook.com/</a> {t('fb-sentence-second-part-two')}
      </span>
      <div className="guide__container-image">
        <button onClick={() => openModalHandler(firstImage)} type="button" className="guide__container-btn">
          <img className="guide__image guide__image--top" src={firstImage} alt="firstImage" />
        </button>
        <button onClick={() => openModalHandler(secondimage)} type="button" className="guide__container-btn">
          <img className="guide__image" src={secondimage} alt="secondImage" />
        </button>
      </div>
      <span className="guide__text">{t('fb-sentence-third')}</span>
      <button onClick={() => openModalHandler(thirdImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={thirdImage} alt="thirdImage" />
      </button>
      <span className="guide__text">{t('fb-sentence-fourth')}</span>
      <button onClick={() => openModalHandler(fourthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={fourthImage} alt="fourthImage" />
      </button>
      <span className="guide__text">{t('fb-sentence-fifth')}</span>
      <button onClick={() => openModalHandler(fifthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={fifthImage} alt="fifthImage" />
      </button>
      <div className="guide__container">
        <ul className="guide__list">
          {t('fb-sentence-sixth')}
          <li className="guide__item">{t('fb-sentence-sixth-item-one')}</li>
          <li className="guide__item">{t('fb-sentence-sixth-item-two')}</li>
          <li className="guide__item">{t('fb-sentence-sixth-item-three')}</li>
        </ul>
      </div>
      <button onClick={() => openModalHandler(sixthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={sixthImage} alt="sixthImage" />
      </button>
      <span className="guide__text">{t('fb-sentence-seventh')}</span>
      <button onClick={() => openModalHandler(seventhImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={seventhImage} alt="seventhImage" />
      </button>
      <span className="guide__text">{t('fb-sentence-eighth-part-one')} <span title="Натисніть щоб скопіювати" onClick={onCopyLinkClick('https://api.smartex.firs.org.ua/')} className="copiable-link">{t('fb-sentence-eighth-part-two')}</span> {t('fb-sentence-eighth-part-three')}</span>
      <button onClick={() => openModalHandler(eighthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={eighthImage} alt="eighthImage" />
      </button>
      <span className="guide__text">{t('fb-sentence-ninth')}</span>
      <div className="guide__container-image">
        <button onClick={() => openModalHandler(ninthImage)} type="button" className="guide__container-btn">
          <img className="guide__image guide__image--top" src={ninthImage} alt="ninthImage" />
        </button>
        <button onClick={() => openModalHandler(tenthImage)} type="button" className="guide__container-btn">
          <img className="guide__image" src={tenthImage} alt="tenthImage" />
        </button>
      </div>
      <span className="guide__text">{t('fb-sentence-tenth')}</span>
      <button onClick={() => openModalHandler(eleventhImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={eleventhImage} alt="eleventhImage" />
      </button>
      <span className="guide__text">{t('fb-sentence-eleventh')}</span>
      <button onClick={() => openModalHandler(twelfthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={twelfthImage} alt="twelfthImage" />
      </button>
      <span className="guide__text">{t('fb-sentence-twelfth')}</span>
      <span className="guide__text">{t('fb-sentence-thirteenth')}</span>
      <button onClick={() => openModalHandler(thirteenthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={thirteenthImage} alt="thirteenthImage" />
      </button>
      <span className="guide__text">{t('fb-sentence-fourteenth')}</span>
      <button onClick={() => openModalHandler(fourteenthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={fourteenthImage} alt="fourteenthImage" />
      </button>
      <span className="guide__text">{t('fb-sentence-fifteenth')}</span>
      <button onClick={() => openModalHandler(fifteenthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={fifteenthImage} alt="fifteenthImage" />
      </button>
      <span className="guide__text">{t('fb-sentence-sixteenth')}</span>
      <button onClick={() => openModalHandler(sixteenthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={sixteenthImage} alt="sixteenthImage" />
      </button>
      <span className="guide__text">{t('fb-sentence-seventeenth')}</span>
      <button onClick={() => openModalHandler(seventeenthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={seventeenthImage} alt="seventeenthImage" />
      </button>
      <div className="guide__container">
        <ul className="guide__list">{t('fb-sentence-eighteenth')}
          <li className="guide__item">{t('fb-sentence-eighteenth-item-one')}</li>
          <li className="guide__item">{t('fb-sentence-eighteenth-item-two')}</li>
          <li className="guide__item">
            <span className="guide__item--text">{t('fb-sentence-eighteenth-item-three')}</span>
            <button onClick={() => openModalHandler(eighteenthImage)} type="button" className="guide__container-btn">
              <img className="guide__image" src={eighteenthImage} alt="eighteenthImage" />
            </button>
          </li>
          <li>{t('fb-sentence-eighteenth-item-four')}</li>
        </ul>
      </div>
      <span className="guide__text">{t('fb-sentence-nineteenth')}</span>
      <button onClick={() => openModalHandler(nineteenthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={nineteenthImage} alt="nineteenthImage" />
      </button>
      <span className="guide__text">{t('fb-sentence-twentieth')}</span>
      <button onClick={() => openModalHandler(twentiethImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={twentiethImage} alt="twentiethImage" />
      </button>
      <span className="guide__text">{t('fb-sentence-twenty-first')}</span>
      <button onClick={() => openModalHandler(twentyFirstImage)} type="button" className="guide__container-btn">
        <img className="guide__image guide__image--top" src={twentyFirstImage} alt="twentyFirstImage" />
      </button>
      <div className="guide-__container">
        <ul className="guide__list">
          {t('fb-sentence-twenty-second')}
          <li className="guide__item" id="callback-url"><span title="Натисніть щоб скопіювати" onClick={onCopyLinkClick('https://api.smartex.firs.org.ua/webhook/facebook/')} className="copiable-link">Callback URL</span>{t('fb-sentence-twenty-second-item-one')}</li>
          <li className="guide__item">{t('fb-sentence-twenty-second-item-two')}</li>
        </ul>
      </div>
      <button onClick={() => openModalHandler(twentySecondImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={twentySecondImage} alt="twentySecondImage" />
      </button>
      <span className="guide__text">{t('fb-sentence-twenty-third')}</span>
      <span className="guide__text">{t('fb-sentence-twenty-fourth')}</span>
      <button onClick={() => openModalHandler(twentyThirdImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={twentyThirdImage} alt="twentyThirdImage" />
      </button>
      <span className="guide__text">{t('fb-sentence-twenty-fifth')}</span>
      <button onClick={() => openModalHandler(twentyFourthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={twentyFourthImage} alt="twentyFourthImage" />
      </button>
      <span className="guide__text">{t('fb-sentence-twenty-sixth')}</span>
      <button onClick={() => openModalHandler(twentyFifthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={twentyFifthImage} alt="twentyFifthImage" />
      </button>
      <span className="guide__text">{t('fb-sentence-twenty-seventh')}</span>
      <button onClick={() => openModalHandler(twentySixthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={twentySixthImage} alt="twentySixthImage" />
      </button>
      <span className="guide__text">{t('fb-sentence-twenty-eighth')}</span>
      <button onClick={() => openModalHandler(twentySeventhImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={twentySeventhImage} alt="twentySeventhImage" />
      </button>
      <span className="guide__text">{t('fb-sentence-twenty-ninth')}</span>
      <span className="guide__text">{t('fb-sentence-thirtieth')}</span>
      <div className="guide__container-image">
        <button onClick={() => openModalHandler(twentyNinthImage)} type="button" className="guide__container-btn">
          <img className="guide__image guide__image--top" src={twentyNinthImage} alt="twentyNinthImage" />
        </button>
        <button onClick={() => openModalHandler(thirtiethImage)} type="button" className="guide__container-btn">
          <img className="guide__image" src={thirtiethImage} alt="thirtiethImage" />
        </button>
      </div>
      <span className="guide__text">{t('fb-sentence-thirty-first')}</span>
    </div>
  </div>
);

GuideInfo.propTypes = {
  openModalHandler: PropTypes.func.isRequired,
  onCopyLinkClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


export default GuideInfo;

import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmModal } from '../../../../components';
import './style.sass';


const DeleteAccountModal = ({
  isOpen, t, onDeleteAccount, onClose, isPending,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    actionTitle={t('Delete')}
    onAction={onDeleteAccount}
    onClose={onClose}
    isPending={isPending}
    title={t('Delete account')}
  >

    {t('Are you sure, you want to delete your account?')}
  </ConfirmModal>
);

DeleteAccountModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  isPending: PropTypes.bool.isRequired,
  onDeleteAccount: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DeleteAccountModal;

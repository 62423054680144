import { compose, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { pathOr } from 'ramda';
import { withNamespaces } from 'react-i18next';
import { getModal } from '../../../../../../state/ui/selectors';
import { closeModal } from '../../../../../../state/ui/actions';
import { customFieldsActions } from '../../../../../../state/customFields';
import DeleteCustomFieldModal from './deleteCustomFieldModal';
import { callNotification } from '../../../../../../utils/helpers/notifies';

const mapStateToProps = state => ({
  isOpen: getModal(state)('deleteCustomField'),
});

const mapDispatchToProps = ({
  onClose: () => closeModal('deleteCustomField'),
  deleteCustomField: customFieldsActions.deleteCustomFieldRequest,
});

const onDeleteHandler = ({
  fieldId, onClose, deleteCustomField, setErrorFromBack,
}) => () => {
  deleteCustomField({ id: fieldId }, {
    callbacks: {
      success: onClose,
      error: compose(
        (e) => {
          callNotification.error(e);
          setErrorFromBack(e);
        },
        pathOr({}, ['response', 'data', 'result', 'error']),
      ),
    },
  });
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces('customFields'),
  withState('errorFromBack', 'setErrorFromBack', {}),
  withHandlers({
    onDelete: onDeleteHandler,
  }),
);
export default enhance(DeleteCustomFieldModal);

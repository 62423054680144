import React from 'react';
import PropTypes from 'prop-types';
import { Header, SubscriptionContainer } from '../../../../containers';
import NavTabs from '../navTabs';
import { ScrollWrapper } from '../../../../components';

const SubscriptionWrapper = ({ t }) => (
  <>
    <Header hideToggleRightSideBar className="user-header">
        <>
          <h1 className="weight--medium title--xl title-page user-nav__title">

            {`${t('Settings')} - ${t('Subscription')}`}
          </h1>
          <NavTabs />
        </>
    </Header>
    <div className="main-container__content main-container__content--roles">
      <ScrollWrapper>
        <SubscriptionContainer />
      </ScrollWrapper>
    </div>
  </>

);

SubscriptionWrapper.propTypes = {
  t: PropTypes.func.isRequired,
};

export default SubscriptionWrapper;

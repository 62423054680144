import React from 'react';
import PropTypes from 'prop-types';

import {
  Layouts,
} from '../../components';
import { Header, LeftSideBar, ClientsContainer } from '../../containers';
import { ButtonBrill } from '../../components/buttonsCollection';
import { ROLE_IDS } from '../../constants/pemissions';
import './style.sass';

const Clients = ({ customFieldsModal, currentUser, t }) => (
  <Layouts.Main>
    <LeftSideBar />
    <div className="main-container main-container--settings">
      <Header hideToggleRightSideBar className="clients-header">
        <>
          <h1 className="weight--medium title--xl title-page clients-nav__title">
            {t('Clients')}
          </h1>
        </>
        { currentUser.role_id === ROLE_IDS.ADMIN
        && (
        <ButtonBrill
          className="clients__btn-custom-fields button--sm"
          onClick={() => customFieldsModal()}
          title={t('customFields:Custom fields').toUpperCase()}
        >
          {t('customFields:Custom fields').toUpperCase()}
        </ButtonBrill>
        )
        }
      </Header>
      <ClientsContainer />
    </div>
  </Layouts.Main>
);

Clients.propTypes = {
  customFieldsModal: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
};


export default Clients;

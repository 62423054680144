import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ChannelItem } from './components';
import './style.sass';

const ManagersChannels = ({
  managersChannelsList, managersChannelsEntities,
  t,
}) => (
  <div className="chanel-nav-group">
    <div className="chanel-nav-group__header">
      <NavLink
        to="/messenger/channels/"
        className="chanel-nav-group__title"
      >
        {t('Managers')}
      </NavLink>
    </div>
    <nav className="chanel-nav-group__nav">
      { managersChannelsList.map(channel => (
        <ChannelItem
          key={channel}
          channelId={channel}
          channel={managersChannelsEntities[channel]}
        />
      ))}
    </nav>
  </div>
);

ManagersChannels.propTypes = {
  managersChannelsList: PropTypes.arrayOf(PropTypes.number),
  managersChannelsEntities: PropTypes.instanceOf(Object),
  t: PropTypes.func.isRequired,
};

ManagersChannels.defaultProps = {
  managersChannelsList: null,
  managersChannelsEntities: null,
};

export default ManagersChannels;

import {
  branch,
  compose, lifecycle, pure, renderNothing, withHandlers,
} from 'recompose';
import { withNamespaces } from 'react-i18next';
import { withRefs } from '../../utils/enchancers';

import ModalWindow from './modalWindow';

const clickOutside = ({ getRef, onCloseModal }) => e => (!getRef('bodyModal').contains(e.target) ? onCloseModal() : null);

const onCloseModalHandler = ({ onClose }) => () => {
  const isConfirm = window.confirm('You are sure want close this modal window ?');
  if (isConfirm) onClose();
};

const enhance = compose(
  pure,
  withNamespaces(['common']),
  branch(({ isOpen }) => !isOpen, renderNothing),
  withRefs(),
  withHandlers({
    onCloseModal: onCloseModalHandler,
  }),
  withHandlers({
    clickOutsideHandler: clickOutside,
  }),
  lifecycle({
    componentDidUpdate() {
      const { getRef, clickOutsideHandler } = this.props;
      const container = getRef('modalContainer');
      if (container) {
        getRef('modalContainer').addEventListener('click', clickOutsideHandler, true);
      }
    },
    componentWillUnmount() {
      const { getRef, clickOutsideHandler } = this.props;
      const container = getRef('modalContainer');
      if (container) {
        container.removeEventListener('click', clickOutsideHandler, true);
      }
    },
  }),
);

export default enhance(ModalWindow);

import {
  compose, lifecycle, withHandlers,
} from 'recompose';
import { path } from 'ramda';
import { withNamespaces } from 'react-i18next';
import AvatarProfile from './avatarProfile';
import { withUploadFile } from '../../../../utils/enchancers';

const onResetAvatarHandler = ({
  setFileValue, setFieldValue, name, onResetCallback = () => {},
}) => () => {
  setFileValue(null);
  setFieldValue(name, null);
  onResetCallback();
};

const onCbErrorFileUploadingHandler = ({ setErrorMessage }) => (e) => {
  const errorMessage = path(['response', 'data', 'result', 'error', 'message'], e);
  setErrorMessage(errorMessage || 'Error when uploading file');
};

const onChangeHandler = ({ onUploadFile, setErrorMessage }) => (e) => {
  setErrorMessage('');
  onUploadFile(e);
};

const enhance = compose(
  withNamespaces('common'),
  withHandlers({
    onCbErrorFileUploading: onCbErrorFileUploadingHandler,
  }),
  withUploadFile,
  withHandlers({
    onResetAvatar: onResetAvatarHandler,
    onChange: onChangeHandler,
  }),
  lifecycle({
    componentDidMount() {
      const { setFieldValue, userAvatar, setErrorMessage } = this.props;
      setFieldValue('avatar', userAvatar);
      setErrorMessage('');
    },
  }),
);

export default enhance(AvatarProfile);

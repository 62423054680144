import { createSelector } from 'reselect';
import {
  pathOr, identity, prop, groupBy,
} from 'ramda';
import { memoizeWithIdentity } from 'ramda-extension';

export const getBotsList = createSelector(pathOr([], ['settingBots', 'result']), identity);
export const getBotsEntities = createSelector(pathOr({}, ['settingBots', 'entities']), identity);

export const getGroupedBotsList = createSelector(
  getBotsList,
  getBotsEntities,
  (list, entities) => groupBy((id) => {
    const bot = prop(id, entities);
    return bot.type;
  }, list),
);


export const getBotById = createSelector(
  getBotsEntities,
  bots => memoizeWithIdentity(botId => prop(botId, bots)),
);

export const getBotByServiceId = createSelector(
  getBotsEntities,
  bots => serviceId => Object.values(bots)
    .filter(bot => bot.service_bot_id === serviceId)[0] || null,
);

export const getEditRequestError = createSelector(pathOr(null, ['errors', 'editBotRequest']), identity);
export const getPendingSettingsBots = createSelector(pathOr(true, ['pending', 'getSettingBotsRequest']), identity);

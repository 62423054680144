import React from 'react';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import { Grid } from '../../../../../../components';
import { TYPE_PAYMENT_LOGS, TARIFFS } from '../../../../../../constants/subscription';

const HistoryItem = ({
  log, t, transactionDate, onSendReceipt,
}) => (
  <Grid.Row
    className="history-payment__row"
  >
    <Grid.ColData className="history-payment__col">
      <div className="text--cut p-xs text--breaker">
        {transactionDate}
      </div>
    </Grid.ColData>
    <Grid.ColData className="history-payment__col">
      <div className="text--cut p-xs text--breaker">
        {t(TYPE_PAYMENT_LOGS[pathOr('N/A', ['type'], log)])}
      </div>
    </Grid.ColData>
    <Grid.ColData className="history-payment__col">
      <div className="text--cut p-xs text--breaker">
        {pathOr('N/A', ['card_number'], log)}
      </div>
    </Grid.ColData>
    <Grid.ColData className="history-payment__col history-payment__col--amount">
      <div className="text--cut p-xs text--breaker">
        {pathOr('N/A', ['amount'], log)}
      </div>
    </Grid.ColData>
    <Grid.ColData className="history-payment__col history-payment__col--currency">
      <div className="text--cut p-xs text--breaker">
        {pathOr('N/A', ['currency'], log)}
      </div>
    </Grid.ColData>
    <Grid.ColData className="history-payment__col history-payment__col--tariff">
      <div className="text--cut p-xs text--breaker">
        {t(TARIFFS[pathOr('N/A', ['tariff'], log)].title)}
      </div>
    </Grid.ColData>
    <Grid.ColData className="history-payment__col history-payment__col--receipt">
      <div className="text--cut p-xs text--breaker">
        <button
          className="history-payment__btn-send"
          type="button"
          onClick={() => onSendReceipt(pathOr('N/A', ['id'], log))}
        >
          {t('Send')}
        </button>
      </div>
    </Grid.ColData>
  </Grid.Row>
);

HistoryItem.propTypes = {
  log: PropTypes.instanceOf(Object).isRequired,
  transactionDate: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  onSendReceipt: PropTypes.func.isRequired,
};

export default HistoryItem;

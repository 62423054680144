import { createActions } from 'redux-actions';
import { identity } from 'ramda';
import types from './types';
import { uiActions } from '../ui';

export const {
  loginFetch,
  forgotPasswordFetch,
  resetPasswordFetch,
  checkCodeFetch,
  checkResetPasswordToken,
  signUpFetch,
  continueSignUpFetch,
  checkCurrentUserRequest,
  updateUserProfileRequest,
  setUserIsValid,
  loginRequestSuccess,
  forgotPasswordSuccess,
  resetPasswordSuccess,
  signUpRequestSuccess,
  setAuthenticated,
  continueSignUpRequestSuccess,
  checkCodeSuccess,
  setJwt,
  checkResetPasswordTokenSuccess,
  logout,
  setUserIsInvalid,
  setUser,
  setUserIsValidFlag,
} = createActions(
  {
    [types.LOGIN_FETCH]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/auth/login',
        selectorName: 'loginRequest',
        method: 'POST',
        params,
        actions: {
          success: loginRequestSuccess,
          error: uiActions.displayNotification,
        },
        ...meta,
      }),
    ],
    [types.FORGOT_PASSWORD_FETCH]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/forgot-password',
        selectorName: 'forgotPasswordRequest',
        method: 'POST',
        params,
        actions: {
          success: forgotPasswordSuccess,
          error: uiActions.displayNotification,
        },
        ...meta,
      }),
    ],
    [types.RESET_PASSWORD_FETCH]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/forgot-password/change-password',
        selectorName: 'resetPasswordRequest',
        method: 'POST',
        params,
        actions: {
          success: resetPasswordSuccess,
          error: uiActions.displayNotification,
        },
        ...meta,
      }),
    ],
    [types.CHECK_CODE_FETCH]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/auth/check-activation-code',
        selectorName: 'checkCode',
        method: 'POST',
        params,
        actions: {
          success: checkCodeSuccess,
        },
        ...meta,
      }),
    ],
    [types.CHECK_RESET_PASSWORD_TOKEN]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/forgot-password/check-code',
        selectorName: 'checkResetPasswordToken',
        method: 'POST',
        params,
        actions: {
          success: checkResetPasswordTokenSuccess,
        },
        ...meta,
      }),
    ],
    [types.SIGN_UP_FETCH]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/auth/sign-up',
        selectorName: 'signUpRequest',
        method: 'POST',
        params,
        actions: {
          success: signUpRequestSuccess,
          error: uiActions.displayNotification,
        },
        ...meta,
      }),
    ],
    [types.CONTINUE_SIGN_UP_FETCH]: [
      identity,
      (params, meta) => ({
        async: true,
        route: '/auth/continue-sign-up',
        selectorName: 'continueSignUpRequest',
        method: 'POST',
        params,
        actions: {
          success: continueSignUpRequestSuccess,
          error: uiActions.displayNotification,
        },
        ...meta,
      }),
    ],
    [types.CHECK_CURRENT_USER_REQUEST]: [
      identity,
      params => ({
        async: true,
        params,
        route: '/auth/current-user',
        selectorName: 'checkCurrentUser',
        method: 'GET',
        actions: {
          success: setUserIsValid,
          error: setUserIsInvalid,
        },
      }),
    ],
    [types.UPDATE_USER_PROFILE_REQUEST]: [
      identity,
      params => ({
        async: true,
        params,
        route: '/users/profile',
        selectorName: 'updateUserProfileRequest',
        method: 'PUT',
        actions: {
          success: setUser,
        },
      }),
    ],
  },
  types.SET_USER_IS_VALID,
  types.LOGIN_REQUEST_SUCCESS,
  types.FORGOT_PASSWORD_SUCCESS,
  types.RESET_PASSWORD_SUCCESS,
  types.SIGN_UP_REQUEST_SUCCESS,
  types.SET_AUTHENTICATED,
  types.CONTINUE_SIGN_UP_REQUEST_SUCCESS,
  types.CHECK_CODE_SUCCESS,
  types.SET_JWT,
  types.CHECK_RESET_PASSWORD_TOKEN_SUCCESS,
  types.LOGOUT,
  types.SET_USER_IS_INVALID,
  types.SET_USER,
  types.SET_USER_IS_VALID_FLAG,
);

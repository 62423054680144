import { compose, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import SubscriberContainer from './subscriberContainer';
import { subscriptionActions } from '../../state/subscription';
import { withSuperAdmin } from '../../utils/enchancers';

const mapDispatchToProps = {
  getSubscriberData: subscriptionActions.getInfoAboutSubscriber,
};

const enhance = compose(
  withSuperAdmin(),
  connect(null, mapDispatchToProps),
  withNamespaces(['common']),
  lifecycle({
    componentDidMount() {
      const { getSubscriberData } = this.props;
      getSubscriberData();
    },
  }),
);

export default enhance(SubscriberContainer);

const ERRORS_API = {
  FORBIDDEN: 'Forbidden',
  NOT_FOUND: 'Not found',
  PAYLOAD_TOO_LARGE: 'Payload Too Large',
};

const ERRORS_CODE = {
  404: ERRORS_API.NOT_FOUND,
  400: ERRORS_API.FORBIDDEN,
  413: ERRORS_API.PAYLOAD_TOO_LARGE,
};

export {
  ERRORS_API,
  ERRORS_CODE,
};

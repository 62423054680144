const PERMISSIONS = {
  USER: 1,
  MEMBER: 2,
  MANAGER: 3,
};

const ROLE_IDS = {
  MANAGER: 1,
  ADMIN: 2,
};

const MODULES = {
  USERS: 'users',
  ROLES: 'roles',
  PROJECTS: 'projects',
  PROJECTS_DOCUMENTS: 'project_documents',
  TASKS: 'tasks',
  CHANNELS: 'channels',
  LEADS: 'leads',
  CONTACTS: 'contacts',
  SETTINGS: 'settings',
  PROFILE: 'profile',
  GUESTS: 'guests',
  EVENTS: 'events',
  STATS: 'stats',
  MATERIALS: 'materials',
  CUSTOMERS: 'customers',
};

const ACTIONS = {
  READ: 'read',
  DELETE: 'delete',
  UPDATE: 'update',
  CREATE: 'create',

};

export default PERMISSIONS;

export {
  ACTIONS,
  MODULES,
  ROLE_IDS,
};

import React from 'react';

import { ScrollWrapper } from '../../components';
import './style.sass';
import { UserEditForm } from './components';

const SettingUserContainer = () => (
  <ScrollWrapper className="setting-user__scroll-wrapper">
    <div className="userEdit">
      <UserEditForm />
    </div>
  </ScrollWrapper>
);

export default SettingUserContainer;

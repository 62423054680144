/* eslint-disable no-param-reassign */
import {
  compose, lifecycle, withStateHandlers, hoistStatics, defaultProps,
} from 'recompose';
import withRefs from '../../../../utils/enchancers/withRefs';
import TextArea from './textArea';

const autoHeightTextArea = (textArea, handlerScrollHeight, onUpdateRows, scrollWrapper) => {
  textArea.style.height = '5px';
  textArea.style.height = `${textArea.scrollHeight}px`;
  handlerScrollHeight(textArea.scrollHeight + 3);
  textArea.dispatchEvent(new CustomEvent('customResize'));
  setTimeout(() => {
    // onUpdateRows();
  });
  let oldHeight = textArea.scrollHeight;
  const textAreaAutoGrow = () => {
    textArea.style.height = '5px';
    textArea.style.height = `${textArea.scrollHeight}px`;
    if (oldHeight !== textArea.scrollHeight) {
      handlerScrollHeight(textArea.scrollHeight + 3);
      oldHeight = textArea.scrollHeight;
      textArea.dispatchEvent(new CustomEvent('customResize'));
      onUpdateRows();
    }
    if (scrollWrapper) {
      scrollWrapper.scrollToBottom();
    }
  };
  textArea.addEventListener('keyup', textAreaAutoGrow);
  textArea.addEventListener('keydown', textAreaAutoGrow);
};

const enhance = compose(
  withRefs(),
  defaultProps({
    onUpdateRows: () => {},
  }),
  withStateHandlers(() => ({ inFocus: false, scrollHeight: 0 }), {
    handlerScrollHeight: (state, { maxHeight }) => (val) => {
      if (val > maxHeight) {
        return {
          scrollHeight: maxHeight,
        };
      }
      return {
        scrollHeight: val,
      };
    },
    handlerInFocus: () => val => ({
      inFocus: val,
    }),
  }),
  lifecycle({
    componentDidMount() {
      const {
        getRef, handlerInFocus, handlerScrollHeight, onUpdateRows,
      } = this.props;
      const el = getRef('input');
      autoHeightTextArea(el, handlerScrollHeight, onUpdateRows, getRef('scrollWrapper'));
      el.addEventListener('focus', handlerInFocus(true));
      el.addEventListener('blur', () => (!el.value.length ? handlerInFocus(false) : null));
    },
  }),
);
export default hoistStatics(enhance)(TextArea);

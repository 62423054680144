/* eslint-disable import/prefer-default-export */
const PRIORITY = {
  LOW: {
    color: '#2196f3',
    value: 0,
    title: 'Low',
  },
  MEDIUM: {
    color: '#4caf50',
    value: 1,
    title: 'Medium',
  },
  HIGH: {
    color: '#ffc107',
    value: 2,
    title: 'High',
  },
  VERY_HIGH: {
    color: '#f44336',
    value: 3,
    title: 'Urgent',
  },
};

const STATUS = {
  TO_DO: {
    id: 1,
    value: 0,
    title: 'To Do',
  },
  IN_PROGRESS: {
    id: 2,
    value: 1,
    title: 'In Progress',
  },
  DONE: {
    id: 3,
    value: 2,
    title: 'Done',
  },
};

const STATUSES_BY_ID = {
  [STATUS.TO_DO.id]: 'TO_DO',
  [STATUS.IN_PROGRESS.id]: 'IN_PROGRESS',
  [STATUS.DONE.id]: 'DONE',
};

const MY_TASKS_FILTER = {
  ASSIGNED_TO_ME: 'assignedToMe',
  CREATED_BY_ME: 'createdByMe',
};

const MY_TASKS_GROUP_BY = {
  TASKS: 'tasks',
  PROJECTS: 'projects',
};

const TASKS_WITHOUT_PROJECT = 'without-project';

export {
  PRIORITY,
  STATUS,
  MY_TASKS_FILTER,
  MY_TASKS_GROUP_BY,
  TASKS_WITHOUT_PROJECT,
  STATUSES_BY_ID,
};

import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const Input = ({
  name,
  t,
  onChange,
  error,
  touched,
  value,
  onBlur,
  placeholder,
  placeholderJump,
  setRef,
  inFocus,
  className,
  hiddenEye,
  statusVisible,
  onFocus,
  getType,
  handlerVisibleStatus,
  onKeyUp,
  onKeyDown,
  id,
  children,
  disabled,
}) => (
  <div className={`field-group
  ${className} ${error && touched ? 'field-group--has-error' : ''}
   ${inFocus ? 'field-group--pseudo-focus' : ''}
    ${disabled ? 'field-group--disabled' : ''}
   `}
  >
    <div className="field-group__wrap">
      <input
        name={name}
        type={getType()}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        autoComplete="off"
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        className="field-group__place-write"
        value={value}
        id={id}
        placeholder={placeholder}
        ref={e => setRef('input', e)}
        disabled={disabled}
      />
      {hiddenEye && (
        <button
          tabIndex="-1"
          aria-label={!statusVisible ? 'Show password' : 'Hide password'}
          title={!statusVisible ? 'Show password' : 'Hide password'}
          type="button"
          onClick={() => handlerVisibleStatus()}
          className="field-group__button-hidden"
        >
          {
            !statusVisible ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                strokeLinecap="round"
                strokeLinejoin="round"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <g fill="none" fillRule="evenodd" transform="translate(2 6)">
                  <path d="M20 5.862s-4.483 5.172-10 5.172S0 5.862 0 5.862 4.483 0 10 0s10 5.862 10 5.862z" />
                  <circle cx="10" cy="5.517" r="3.448" />
                </g>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                strokeLinecap="round"
                strokeLinejoin="round"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <g fill="none" fillRule="evenodd">
                  <path d="M18.552 8.517C20.69 10.034 22 11.621 22 11.621s-4.483 5.517-10 5.517c-.897 0-1.931-.069-2.759-.345M5.448 14.69C3.31 13.138 3.345 13.31 2 11.62c0 0 4.483-5.861 10-5.861.759 0 1.38.172 2.069.344M8.552 11.62A3.415 3.415 0 0 1 12 8.173M15.448 11.276A3.415 3.415 0 0 1 12 14.724M20.276 3L3.724 19.897" />
                </g>
              </svg>
            )
          }
        </button>
      )}
      {placeholderJump && (
        <span
          className="field-group__placeholder-jump"
        >
          {placeholderJump}
        </span>
      )}
    </div>
    {typeof error === 'string' && error.length !== 0 && touched && (
    <span className="field-group__error field__error-text">
      {t(error, { key: placeholderJump })}
    </span>
    )}
    {
      children
    }
  </div>
  // TODO Change error handling
);

Input.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  value: PropTypes.string,
  id: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  getType: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  placeholderJump: PropTypes.string,
  setRef: PropTypes.func,
  inFocus: PropTypes.bool,
  onFocus: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyDown: PropTypes.func,
  touched: PropTypes.bool,
  className: PropTypes.string,
  hiddenEye: PropTypes.bool,
  statusVisible: PropTypes.bool,
  handlerVisibleStatus: PropTypes.func,
  t: PropTypes.func.isRequired,
  children: PropTypes.oneOfType(
    [PropTypes.element,
      PropTypes.string,
      PropTypes.array],
  ),
  disabled: PropTypes.bool,
};

Input.defaultProps = {
  onChange: () => {},
  error: '',
  value: '',
  onBlur: () => {},
  onFocus: () => {},
  onKeyUp: () => {},
  onKeyDown: () => {},
  placeholder: ' ',
  placeholderJump: '',
  setRef: () => {},
  inFocus: false,
  touched: false,
  className: '',
  hiddenEye: false,
  statusVisible: false,
  handlerVisibleStatus: () => {},
  children: '',
  disabled: false,
};
// TODO: Add icons eyes to Icomoon
export default Input;

import React from 'react';
import PropTypes from 'prop-types';
import { pathOr, path } from 'ramda';
import {
  Avatar, BrillMask, ButtonsCollection, DropDown, Grid,
} from '../../../../components';
import { MESSENGER_DETAIL } from '../../../../constants/bots';
import { getFullName } from '../../../../utils/helpers/userHelpers';

import './style.sass';

const ClientsItem = ({
  id,
  nameSocial,
  displayDetailInfo,
  displayDetailInfoStateHandler,
  clientsEntities,
  deleteModalHandler,
  editModalHandler,
  botsEntities,
  managersEntities,
  customField,
  customFieldEntities,
  t,
}) => (
  <Grid.Row
    className="table-clients__item project-row"
    onClick={displayDetailInfoStateHandler}
  >
    <Grid.Row className="table-clients__head-row project-row">
      <Grid.ColData className="table-clients__col">
        {id}
      </Grid.ColData>
      <Grid.ColData className="table-clients__col--avatar">
        <BrillMask>
          <Avatar
            src={pathOr('', [id, 'avatar'], clientsEntities)}
            alt={getFullName(clientsEntities[id])}
          />
        </BrillMask>
      </Grid.ColData>
      <Grid.ColData className="table-clients__col table-clients__col--name">
        <div className="text--cut">
          {getFullName(clientsEntities[id])}
        </div>
      </Grid.ColData>
      <Grid.ColData className="table-clients__col table-clients__col--phone">
        <div className="text--cut p-xs text--breaker">
          {pathOr(t('N/A'), [id, 'phone'], clientsEntities)}
        </div>
      </Grid.ColData>
      <Grid.ColData className="table-clients__col table-clients__col--messenger">
        <div className="text--cut">
          {nameSocial}
        </div>
      </Grid.ColData>
      <Grid.ColData className="table-clients__col table-clients__col--bot">
        <div className="text--cut p-xs text--breaker">
          {pathOr(t('N/A'), [`${pathOr('N/A', [id, 'bot_id'], clientsEntities)}`, 'title'], botsEntities)}
        </div>
      </Grid.ColData>
      <Grid.ColData className="table-clients__col table-clients__col--manager">
        <div className="text--cut">
          {pathOr(t('N/A'), [pathOr('N/A', [id, 'manager_id'], clientsEntities), 'first_name'], managersEntities)}
          {' '}
          {pathOr('', [pathOr('N/A', [id, 'manager_id'], clientsEntities), 'last_name'], managersEntities)}
        </div>
      </Grid.ColData>
      <Grid.ColData className="table-clients__col table-clients__col--controls">
        <DropDown
          name="client-control"
          className="clients-control__drop-down"
          label={(
            <ButtonsCollection.ButtonIcons
              title={t('clients:Controls for clients')}
              className="clients__burger"
            >
              <span className="icon-settings-vertical">
                <span
                  className="path1"
                />
                <span className="path2" />
                <span
                  className="path3"
                />
              </span>
            </ButtonsCollection.ButtonIcons>
            )}
          list={[
            {
              id: 0,
              label: (
                <>
                  <span className="clients__control-icon icon-edit-icon" />
                  <span className="clients__control__text"> {t('Edit')}</span>
                </>
              ),
              onClick: editModalHandler,
            },
            {
              id: 1,
              label: (
                <>
                  <span className="clients__control-icon icon-delete-icon" />
                  <span className="clients__control__text"> {t('Delete')}</span>
                </>
              ),
              onClick: deleteModalHandler,
            },
          ]}
        />
      </Grid.ColData>
    </Grid.Row>
    <Grid.Row className={`table-clients__info-row project-row table-clients__${displayDetailInfo ? '' : 'hide'}-info`}>
      <Grid.ColData className="table-clients__col table-clients__col--telegram">
        <div className="text--cut text--breaker">
          Telegram: {pathOr(t('N/A'), [id, 'username'], clientsEntities)}
        </div>
      </Grid.ColData>
      <Grid.ColData className="table-clients__col table-clients__col--viber">
        <div className="text--cut">
          Viber: {pathOr(t('N/A'), [id, 'viber_phone'], clientsEntities)}
        </div>
      </Grid.ColData>
    </Grid.Row>
    <Grid.Row className={`table-clients__info-row project-row table-clients__${displayDetailInfo ? '' : 'hide'}-info`}>
      <Grid.ColData className="table-clients__col table-clients__col--facebook">
        <div className="text--cut text--breaker">
          Facebook: {pathOr(t('N/A'), [id, 'facebook_link'], clientsEntities)}
        </div>
      </Grid.ColData>
      <Grid.ColData className="table-clients__col table-clients__col--manager">
        <div className="text--cut">
          {t('Manager')}: {pathOr(t('N/A'), [pathOr('N/A', [id, 'manager_id'], clientsEntities), 'first_name'], managersEntities)}
          {' '}
          {pathOr('', [pathOr(t('N/A'), [id, 'manager_id'], clientsEntities), 'last_name'], managersEntities)}
        </div>
      </Grid.ColData>
    </Grid.Row>
    <Grid.Row className={`table-clients__info-row project-row table-clients__${displayDetailInfo ? '' : 'hide'}-info`}>
      <Grid.ColData className="table-clients__col table-clients__col--messenger">
        <div className="text--cut">
          {t('Messenger')}: {MESSENGER_DETAIL[path([id, 'service_type'], clientsEntities)].nameSocial}
        </div>
      </Grid.ColData>
      <Grid.ColData className="table-clients__col table-clients__col--bot">
        <div className="text--cut text--breaker">
          {t('Bot')}: {pathOr(t('N/A'), [`${pathOr('N/A', [id, 'bot_id'], clientsEntities)}`, 'title'], botsEntities)}
        </div>
      </Grid.ColData>
    </Grid.Row>
    {
      customField.map((array, index) => (
        <Grid.Row
          className={`table-clients__info-row project-row table-clients__${displayDetailInfo ? '' : 'hide'}-info`}
          key={`custom-fields-${array[index] ? array[index].id : id}`}
        >
          {array.map(({ id: fieldValueId, field_id: fieldId, value }) => (
            <Grid.ColData
              className="table-clients__col table-clients__col--custom-field"
              key={`custom-field-${fieldValueId}`}
            >
              <div className="text--cut">
                {pathOr(t('N/A'), [fieldId, 'title'], customFieldEntities)}:{value ? ` ${value}` : ` ${t('N/A')}`}
              </div>
            </Grid.ColData>
          ))
            }
        </Grid.Row>
      ))
    }
  </Grid.Row>
);

ClientsItem.propTypes = {
  id: PropTypes.number.isRequired,
  clientsEntities: PropTypes.instanceOf(Object).isRequired,
  botsEntities: PropTypes.instanceOf(Object).isRequired,
  customFieldEntities: PropTypes.instanceOf(Object).isRequired,
  managersEntities: PropTypes.instanceOf(Object).isRequired,
  displayDetailInfo: PropTypes.bool.isRequired,
  displayDetailInfoStateHandler: PropTypes.func.isRequired,
  deleteModalHandler: PropTypes.func.isRequired,
  editModalHandler: PropTypes.func.isRequired,
  nameSocial: PropTypes.string.isRequired,
  customField: PropTypes.instanceOf(Array),
  t: PropTypes.func.isRequired,
};

ClientsItem.defaultProps = {
  customField: [],
};

export default ClientsItem;

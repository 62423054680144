import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Avatar, BrillMask, ButtonsCollection, DropDown, OnlineStatusLabel,
} from '../../../../components';
import { WINDOW_WIDTH } from '../../../../constants/ui';

// @todo: Prop asLink in brill mask does not work

const UserControls = ({
  logout, userName, userAvatar, onlineStatus, push, windowWidth, t,
}) => (
  <div className="header__user-controls user-controls">
    <BrillMask
      asLink
      to="/profile/"
      onClick={() => push('/profile/')}
      outsideChildren={<OnlineStatusLabel status={onlineStatus} />
      }
    >
      <Avatar src={userAvatar} alt={userName} />
    </BrillMask>
    <DropDown
      name="header-out"
      label={(
        <ButtonsCollection.ButtonIcons
          title={t('Toggle show profile group')}
          className="user-controls__burger"
        >
          <span className="icon-settings-vertical">
            <span
              className="path1"
            />
            <span className="path2" />
            <span
              className="path3"
            />
          </span>
        </ButtonsCollection.ButtonIcons>
      )}
      list={windowWidth < WINDOW_WIDTH.X_SMALL ? (
        [
          {
            id: 1,
            label: (
              <Link to="/profile/" className="user-controls__link">
                <span className="icon-edit-icon" />

                {t('My profile')}
              </Link>
            ),
          },
          {
            id: 0,
            label: (
              <>
                <span className="button-logout icon icon-reply" />
                <span className="button-logout btn">

                  {t('Log Out')}
                </span>
              </>
            ),
            onClick: logout,
          },
        ]
      ) : (
        [
          {
            id: 0,
            label: (
              <>
                <span className="button-logout icon icon-reply" />
                <span className="button-logout btn">

                  {t('Log Out')}
                </span>
              </>
            ),
            onClick: logout,
          },
        ]
      )
      }
    />
  </div>
);

UserControls.propTypes = {
  push: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
  userAvatar: PropTypes.string.isRequired,
  windowWidth: PropTypes.number.isRequired,
  onlineStatus: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

UserControls.defaultProps = {
  onlineStatus: 0,
};

export default UserControls;

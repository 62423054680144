import {
  compose, lifecycle, withHandlers, withState, renderNothing, branch,
} from 'recompose';
import {
  map, propOr, values, pathOr, path,
} from 'ramda';
import { connect } from 'react-redux';
import { push as routePush } from 'connected-react-router';

import { withRouter } from 'react-router';
import { withFormik } from 'formik';
import { isNotEmpty, notEqual } from 'ramda-extension';
import { withNamespaces } from 'react-i18next';
import UserEditForm from './userEditForm';
import { settingUsersActions, settingUsersSelectors } from '../../../../state/settingUsers';
import { permissionsActions, permissionsSelectors } from '../../../../state/permissions';
import { renameKeys } from '../../../../utils/helpers/commonHelpers';
import { getErrors } from '../../../../utils/helpers/requestHelpers';
import { getErrorsForField } from '../../../../utils/helpers/formHelpers';
import rules from './rules';
import { withAutocomplete } from '../../../../utils/enchancers';
import { callNotification } from '../../../../utils/helpers/notifies';

const mapStateToProps = (state, { match: { params: { userId } } }) => ({
  user: propOr({}, userId, settingUsersSelectors.getSettingUsersEntities(state)),
  roles: permissionsSelectors.getRolesEntities(state),
  isUpdatePending: settingUsersSelectors.getUpdateUserPending(state),
  isResendInvitePending: settingUsersSelectors.getResendInvitePending(state),
});

const mapDispatchToProps = {
  getUsersRequest: settingUsersActions.getSettingUserRequest,
  getRolesRequest: permissionsActions.getRolesRequest,
  updateUserRequest: settingUsersActions.updateSettingUserRequest,
  resendInvite: settingUsersActions.resendUserInviteRequest,
  push: routePush,
};

const getInitialValues = ({
  roles,
  user: {
    first_name, last_name, username, role_id, id, email, status,
  },
}) => {
  const role = propOr({}, role_id, roles);
  return {
    first_name,
    last_name,
    userId: id,
    username,
    status,
    email,
    role_id: { ...role, value: role_id, label: role.name },
  };
};

const onSend = ({ role_id, ...formValues }, { props }) => {
  props.updateUserRequest({ ...formValues, role_id: role_id.value }, {
    callbacks: {
      success: () => props.push('/preferences/users/'),
      error: compose(
        callNotification.error,
        props.setErrorFromBack, pathOr({}, ['response', 'data', 'result', 'errors']),
      ),
    },
  });
};

const withUsers = compose(
  withRouter,
  connect(null, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      this.props.getUsersRequest({ userId: this.props.match.params.userId },
        { isRedirectTo404ByError: true });
    },
  }),
);

const onResendInviteHandler = ({
  resendInvite,
  match: { params: { userId } },
}) => () => resendInvite({ userId });

const enhance = compose(
  withUsers,
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces('common'),
  branch(({ user }) => !user.id, renderNothing),
  withState('errorFromBack', 'setErrorFromBack', {}),
  withFormik({
    mapPropsToValues: getInitialValues,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: rules,
    handleSubmit: onSend,
  }),
  withAutocomplete({
    name: 'getRolesAutocomplete',
    action: (params, meta) => permissionsActions.getRolesRequest(params,
      { ...meta, isAutocomplete: true }),
    dataPath: compose(
      map(renameKeys({ name: 'label', id: 'value' })),
      values,
      path(['data', 'entities', 'roles']),
    ),
    searchField: 'name',
  }),
  withHandlers({
    getErrorField: ({
      errorFromBack,
      errors,
    }) => getErrorsForField({ ...errorFromBack, ...errors }),
    onResendInvite: onResendInviteHandler,
  }),
  lifecycle({
    componentDidMount() {
      this.props.getRolesRequest();
    },
    componentDidUpdate(prevProps) {
      const { errorFromBack } = this.props;
      if (notEqual(errorFromBack, prevProps.errorFromBack) && isNotEmpty(errorFromBack)) {
        getErrors(errorFromBack);
        this.props.setErrors(getErrors(errorFromBack));
      }
    },
  }),
);

export default enhance(UserEditForm);

import {
  curry, compose, identity, equals, curryN, pathOr,
} from 'ramda';
import { Future } from 'ramda-fantasy';

import { notifySound } from '../../../constants/assets';

const notificationSound = new Audio(notifySound);

const regexElements = {
  regOneSpace: '\\s',
  regAt: '[@]',
  regBreak: '<br>',
  regReply: '(^|\\n)>(?:.*)',
  regBold: '(?:\\*)(?:.*?)\\*',
  regItalic: '(\\s|^)(?:_)(?:.*?)_(\\s|$)',
  regStrike: '(?:~)(?:.*?)~',
  regCode: '(?:(\\s|^)`)(?:.*?)`(?:\\s|$)',
  regCodeDelete: '((\\s|^)(`))|((`)(\\s|$))',
  regPer: '(?:```)(?:.*?)```',
  regEmoji: '(?:(?!::)(?::\\w*(?:(?:_|(?:\\w\\-))\\w*)*:))',
  regMention: '\\[(@[\\w.]*?\\s*?\\w*\\|@\\w.+?)]',
  regMentionForReplace: '\\[(@[\\w]*?\\s*?\\w*\\|@\\w.+?)]',
  regLink: '((?:(?:^|(?!:\\s|\\n|\\r|\\)|\\(|\'|"|`))(?:https?:\\/\\/)?(?:[\\da-z\\.-]+)\\.(?:[a-z]{1,6})(?:[a-z\\?\\=0-9A-Z\\&\\;\\-\\_\\.]))(?:\\/[\\w\\d\\S]*)?)',
  regLink2: '((?:(?:^|\\s|\\n|\\r|\\)|\\(|\'|"|`])(?:https?:\\/\\/)?(?:[\\da-z\\.-]+)\\.(?:[a-z]{1,6})(?:[a-z\\?\\=0-9A-Z\\&\\;\\-\\_\\.]))(?:\\/[\\w\\d\\S]*)?)',
};

const regexRules = {
  regOneSpace: new RegExp(regexElements.regOneSpace),
  regAt: new RegExp(regexElements.regAt),
  regBold: new RegExp(regexElements.regBold, 'gi'),
  regItalic: new RegExp(regexElements.regItalic, 'gi'),
  regStrike: new RegExp(regexElements.regStrike, 'gi'),
  regCode: new RegExp(regexElements.regCode, 'gi'),
  regPer: new RegExp(regexElements.regPer, 'gim'),
  regReply: new RegExp(regexElements.regReply, 'gim'),
  regCodeDelete: new RegExp(regexElements.regCodeDelete, 'g'),
  regLink: new RegExp(regexElements.regLink, 'g'),
  regLink2: new RegExp(regexElements.regLink2, 'g'),
  divHtmlElement: /<(?:\/)?div>/gi,
  mentionHtmlElement: /<\/?mention(?:\s[A-z0-9-]*="[A-z0-9-]*")*>/gi,
  preWrapper: /```/gi,
  breakHtmlElement: /<br>/gi,
  regCharacterAfterSpace: /\s[\w*]/,
  regStartMention: /(^|\s)@/,
  regStartWord: /(^|\s)/,
  regFullEmojiString: new RegExp(regexElements.regEmoji, 'gim'),
  regAllHtml: /<\/?[^>]*>/gim,
  regMention: /(^|\s)@([\w.]*)\s?([\w.]*)(\s\w*)?$/,
  regMentionTest: /((^|\s)@(?:[\w.]*)\s?(?:[\w.]*)(?:\w*)?\s$)/,
  regMentionForConvert: /^@([\w.]*)\s?([\w.]*)(\s\w*)?(\|@\w.+)?$/,
  regEmojiForConvert: /(?:(?!::)(?::\w*(?:(?:_|(?:\w))\w*)*:))/,
  reqEmoji: /(?:(?!::)(?::\w*(?:(?:_|(?:\w))\w*)*:))/,
  regMentionElement: /[(@[\w.]*?\s*?\w*\|@(\w|\.)+?]/gi,
  reqEmojiCode: /(?:(?!::)(?::\w*(?:(?:_|(?:\w))\w*)*:))/gim,
  regIsMentionCompleted: /(^|\s)@([\w.]+)\s([\w.]*)(\s\w*)?\s$/,
  regMentionToTag: /\[(@[\w.]*?\s*?\w*)]/,
  // eslint-disable-next-line no-useless-escape
  regMessageContent: /\[(@[\w.]*?\s*?\w*\|@(?:\w|\.|\s)+?)]|(:\w*((_|(?:\w\-))\w*)*:)|(<br>)|((^|\s)(?:\*)(?:.*?)\*(?:$|\s))|((^|\s)(?:_)(?:.*?)_(?:$|\s))|((^|\s)(?:~)(?:.*?)~(?:$|\s))|((^|\s)(?:(\s|^)`)(?:.*?)`(?:\s|$)(?:$|\s))|((^|\s)(?:```)(?:.*?)```(?:$|\s))|(^>(?:.*))|(^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-\?]*)*\/?$)/gi,
  regSpace: /&nbsp;/,
  regMentionToSelector: /<mention data-id="([\w.]+)" .*?data-type="mention".*?>(.+?)<\/mention>/g,
  regLocalMentionToSelector: /<mention data-id="([\w.]+)" .*?data-type="mention".*?>(.+?)<\/mention>/,
  regDirtyCharacters: /(<!-- -->|&nbsp;)/g,
  regIdAndUsername: /(\w*)\|(@\w.+)/,
  regTextElements: new RegExp(`(?:${regexElements.regMentionForReplace})|(${regexElements.regEmoji})|(${regexElements.regBreak})|(${regexElements.regLink})|(\n)`, 'g'),
  regTextStyles: new RegExp(`(${regexElements.regBold})|(${regexElements.regItalic})|(${regexElements.regStrike})|(${regexElements.regCode})`, 'g'),
  regTextWrappers: new RegExp(`(${regexElements.regReply})|(${regexElements.regPer})`, 'gi'),
  regEmojiText: /(^|\s*):\w*/gim,
};

const emojiEvent = new Event('setEmoji');

const curryRegexTest = curry((rules, data) => rules.test(data));
const curryRegexMatch = curryN(2, (rules, string, fallbackRules) => {
  const res = rules.exec(string);
  if (!res && fallbackRules) return fallbackRules.exec(string);
  return res;
});
const currySplit = curry((rules, string) => string.split(rules));
const curryRegexReplace = curry((rules, callback, string) => string.replace(rules, callback));

const regexWithParams = curry((rule, params) => new RegExp(rule, params));

const replaceEachNode = curryN(3, (transform, valuePath, rules, fallbackRules) => compose(
  transform,
  valuePath,
  node => curryRegexMatch(rules, node, fallbackRules),
));

const requestPermission = (success, cb) => Future(async (reject, resolve) => {
  if (window.Notification) {
    const permission = await window.Notification.requestPermission();
    await cb();
    resolve(permission);
  } else {
    resolve('denied');
  }
})
  .fork(identity, success);

const getNotificationPermission = () => pathOr('denied', ['permission'], window.Notification);

const isNotificationPermission = type => equals(type, getNotificationPermission());

export {
  replaceEachNode,
  isNotificationPermission,
  getNotificationPermission,
  curryRegexReplace,
  emojiEvent,
  requestPermission,
  notificationSound,
  currySplit,
  curryRegexTest,
  regexWithParams,
  curryRegexMatch,
  regexRules,
};

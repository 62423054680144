import { createSelector } from 'reselect';
import { pathOr, identity } from 'ramda';

const getSubscriberData = createSelector(pathOr(null, ['subscription', 'subscriber']), identity);
const getSubscriptionStatus = createSelector(pathOr(null, ['subscription', 'status']), identity);
const getSubscriberPayment = createSelector(pathOr({}, ['subscription', 'payment']), identity);
const updateSubscriberPending = createSelector(pathOr(false, ['pending', 'updateInfoAboutSubscriberRequest']), identity);
const getSubscriberPending = createSelector(pathOr(false, ['pending', 'getInfoAboutSubscriber']), identity);
const cancelSubscriptionPending = createSelector(pathOr(false, ['pending', 'cancelSubscriptionRequest']), identity);
const deleteAccountPending = createSelector(pathOr(false, ['pending', 'deleteAccountRequest']), identity);
const getPaymentLogs = createSelector(pathOr([], ['subscription', 'paymentLogs']), identity);
export {
  getSubscriberData,
  getSubscriberPending,
  updateSubscriberPending,
  getSubscriberPayment,
  getSubscriptionStatus,
  cancelSubscriptionPending,
  deleteAccountPending,
  getPaymentLogs,
};

import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose';
import { identity } from 'ramda';

import { subscribeOnEvent, unSubscribeFromEvent } from '../helpers/DOMHelper/listeners';


const withOnline = (onOnlineCb = identity,
  onOfflineCb = identity) => compose(
  withState('isOnline', 'setIsOnline', true),
  withHandlers(props => ({
    onOnline: ({ setIsOnline }) => () => { onOnlineCb(props); setIsOnline(true); },
    onOffline: ({ setIsOnline }) => () => { onOfflineCb(props); setIsOnline(false); },
  })),
  lifecycle(
    {
      componentDidMount() {
        subscribeOnEvent(window, 'online', this.props.onOnline);
        subscribeOnEvent(window, 'offline', this.props.onOffline);
      },
      componentWillUnmount() {
        unSubscribeFromEvent(window, 'online', this.props.onOnline);
        unSubscribeFromEvent(window, 'offline', this.props.onOffline);
      },
    },
  ),
);

export default withOnline;

import React from 'react';
import PropTypes from 'prop-types';
import { ButtonsCollection } from '../../..';

import './style.sass';

const AttachImage = ({
  isPending,
  onBlur,
  name, labelText, id, className, value, error, touched, onDeleteFile, onUploadFile, fileUrl,
}) => (
  <div className={`attach-image-field ${className}`}>
    <input
      type="file"
      onChange={onUploadFile}
      name={name}
      onBlur={onBlur}
      className="attach-image-field__field"
      id={id}
    />
    {
        value ? (
          <div className="attach-image-field__preview-row">
            <div className="attach-image-field__preview-card">
              <ButtonsCollection.ButtonIcons
                title="Delete image"
                onClick={onDeleteFile}
                className="attach-image-field__preview-delete-button"
              >
                <span className="icon-cross" />
              </ButtonsCollection.ButtonIcons>
              <img alt="Logo for project" className="attach-image-field__preview-image" src={fileUrl || value} />
            </div>
          </div>
        ) : (
          <ButtonsCollection.ButtonBrill
            pending={isPending}
            type="button"
            className="button--sm attach-image-field__button"
          >
            <label className="attach-image-field__button-text" htmlFor={id}>
              {labelText}
            </label>
          </ButtonsCollection.ButtonBrill>
        )
      }
    {typeof error === 'string' && error.length !== 0 && touched && (
      <span className="attach-image-field__error field__error-text">
        { error}
      </span>
    )}
  </div>
);

AttachImage.propTypes = {
  isPending: PropTypes.bool.isRequired,
  fileUrl: PropTypes.string.isRequired,
  onUploadFile: PropTypes.func.isRequired,
  labelText: PropTypes.string,
  id: PropTypes.string.isRequired,
  touched: PropTypes.bool,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  onBlur: PropTypes.func,
  onDeleteFile: PropTypes.func.isRequired,
  error: PropTypes.string,
};

AttachImage.defaultProps = {
  value: '',
  labelText: 'Add Image',
  onBlur: () => {},
  touched: false,
  className: '',
  error: '',
};

export default AttachImage;

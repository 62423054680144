import {
  compose, lifecycle, pure, withHandlers, withState, defaultProps, withProps,
} from 'recompose';
import ButtonBrill from './buttonBrill';
import { withWindowWidth } from '../../../utils/enchancers';

const onSetDelayHandler = ({ setIsDelay, delay }) => () => {
  setIsDelay(true);
  setTimeout(() => setIsDelay(false), delay);
};

const enhance = compose(
  pure,
  defaultProps({
    delay: 0,
    onEndDelay: () => {},
  }),
  withWindowWidth(),
  withState('isDelay', 'setIsDelay', false),
  withHandlers({
    onSetDelay: onSetDelayHandler,
  }),
  withProps(({
    renderMobileButton,
    windowWidth,
  }) => ({ isMobileButton: renderMobileButton && windowWidth < 768 })),
  lifecycle({
    componentDidUpdate(prevProps) {
      const {
        pending, onSetDelay, isDelay, onEndDelay,
      } = this.props;
      if (pending !== prevProps.pending) {
        onSetDelay(false);
      }
      if (!isDelay && isDelay !== prevProps.isDelay) {
        onEndDelay(this.props);
      }
    },
  }),
);
export default enhance(ButtonBrill);

import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';

import { pathOr } from 'ramda';
import { Label } from '../../../../components';
import { isChannelPrivate } from '../../../../utils/helpers/messengerHelpers/channelHelpers';

const ChannelsItem = ({
  channel, channel: { bot_id: botId, type }, botsEntities, t,
}) => (
  type !== 0 ? (
    <li key={channel.id} className="channels-list__item">
      <Link to={`/messenger/${channel.id}`} className="channels-list__link">
        <div className="channels-list__title-group">
          {
          isChannelPrivate(channel.type) ? (
            <span className="channels-list__title-group-icon icon-chanel-lock-icon" />
          ) : (
            <span className="channels-list__title-group-icon icon-chanel-icon" />
          )
        }
          <h3 className="channels-list__title-group-name">{channel.name}</h3>
          <h3 className="channels-list__title-group-bot">{pathOr(t('N/A'), [botId, 'title'], botsEntities)}</h3>
        </div>
        <div className="channels-list__created-by">

        Created
          {` at ${moment(channel.created_at).format('DD MMM YYYY')}`}
        </div>
        <Label className="channels-list__link-to  label--xs">
          <span
            className="channels-list__link-to-icon icon-argon-arrow"
          />
        </Label>
      </Link>
    </li>
  ) : ('')
);

ChannelsItem.propTypes = {
  channel: PropTypes.instanceOf(Object).isRequired,
  botsEntities: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
};

export default ChannelsItem;

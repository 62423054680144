import React from 'react';
import PropTypes from 'prop-types';
import './style.sass';

const SubscriptionCard = ({ title, children }) => (
  <div className="subscription-card">
    <div className="subscription-card__content">
      <h2 className="subscription-card__title">{title}</h2>
      <div className="subscription-card__content">
        <>
          {children}
        </>
      </div>
    </div>
  </div>
);

SubscriptionCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

SubscriptionCard.defaultProps = {
  children: '',
};

export default SubscriptionCard;

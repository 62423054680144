import React from 'react';
import PropTypes from 'prop-types';
import { path } from 'ramda';

import {
  ButtonsCollection,
  FormsCollection, Layouts,
} from '../../../components';
import NotificationsForAuthForm from '../components/errorNotification';

import './style.sass';

const JoinYourTeam = ({
  handleSubmit,
  handleChange,
  handleBlur,
  touched,
  authNotification,
  values,
  t,
  pending,
}) => (
  <Layouts.Auth>
    <NotificationsForAuthForm />
    <div className="auth-wrapper__wrap">
      <FormsCollection.FormWrapper handleSubmit={handleSubmit}>
        <div className="auth-wrapper__form-contain">
          <h2 className="title weight--bold title--md text-align--center">{t('Join your team!')}</h2>
          <FormsCollection.Input
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstName}
            error={!!path(['firstName'], authNotification)}
            touched={touched.firstName}
            name="firstName"
            id="join-your-team__first-name"
            placeholderJump={t('First name')}
          />
          <FormsCollection.Input
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastName}
            error={!!path(['lastName'], authNotification)}
            touched={touched.lastName}
            name="lastName"
            id="join-your-team__last-name"
            placeholderJump={t('Last name')}
          />
          <FormsCollection.Input
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.username}
            error={!!path(['username'], authNotification)}
            touched={touched.username}
            name="username"
            id="join-your-team__username"
            placeholderJump={t('Username')}
          />
          <FormsCollection.Input
            type="password"
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!path(['password'], authNotification)}
            value={values.password}
            touched={touched.password}
            hiddenEye
            id="join-your-team__password"
            placeholderJump={t('Password')}
          />
          <FormsCollection.Input
            type="password"
            hiddenEye
            placeholderJump={t('Confirm new password')}
            required
            onChange={handleChange}
            error={!!path(['passwordConfirm'], authNotification)}
            onBlur={handleBlur}
            name="passwordConfirm"
            id="join-your-team__confirm-password"
            touched={touched.passwordConfirm}
            value={values.passwordConfirm}
          />
          <div className="button-group">
            <ButtonsCollection.ButtonBrill
              type="submit"
              pending={pending}
              className="auth-wrapper__button--with-border button--fill"
              borderColor="#26b5ed"
              borderWidth="2"
            >
              {t('Join')}
            </ButtonsCollection.ButtonBrill>
          </div>
        </div>
      </FormsCollection.FormWrapper>
    </div>
  </Layouts.Auth>
);

JoinYourTeam.propTypes = {
  pending: PropTypes.bool,
  values: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    username: PropTypes.string,
    password: PropTypes.string,
    passwordConfirm: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  touched: PropTypes.shape({
    firstName: PropTypes.bool,
    lastName: PropTypes.bool,
    username: PropTypes.bool,
    password: PropTypes.bool,
    passwordConfirm: PropTypes.bool,
  }).isRequired,
  authNotification: PropTypes.shape({
    firstName: PropTypes.object,
    lastName: PropTypes.object,
    username: PropTypes.object,
    password: PropTypes.object,
  }),
  t: PropTypes.func,
};

JoinYourTeam.defaultProps = {
  pending: false,
  authNotification: {},
  t: () => {},
};

export default JoinYourTeam;

import React from 'react';
import PropTypes from 'prop-types';
import ModulesItem from './components';
import { TabWrapper } from '../../../../components';
import './style.sass';

const ModulesList = ({
  data, isModulesTabOpen, changeModulesTabStatus, title,
}) => (
  <div className="modules-list">
    <div className="modules-list__container">
      <TabWrapper
        onChangeStatusTab={changeModulesTabStatus}
        defaultStatusTab={isModulesTabOpen}
        title={title}
      >
        <ul className="modules-list__list">
          {
            data.map(({
              title: titleItem, asLink, href, onClick,
            }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <ModulesItem key={`module-${index}`} title={titleItem} asLink={asLink} href={href} onClick={onClick} />
            ))
          }
        </ul>
      </TabWrapper>
    </div>
  </div>
);
ModulesList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  isModulesTabOpen: PropTypes.bool.isRequired,
  changeModulesTabStatus: PropTypes.func.isRequired,
  title: PropTypes.string,
};

ModulesList.defaultProps = {
  title: 'Modules',
};

export default ModulesList;

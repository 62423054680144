import React from 'react';
import PropTypes from 'prop-types';
import ReactCustomScrollBar from 'react-custom-scrollbars';

import './style.sass';
import { omit } from 'ramda';

// Todo: Find solution for Updating isVisible

const renderViewScrollCustom = e => <div {...e} className="scroll-wrapper__view" />;
const renderThumbScrollCustom = e => <div {...(e)} className="scroll-wrapper__thumb-vertical" />;

const renderTrackScrollCustom = autoHideHover => (style, ...e) => (
  <div {...(e)} style={{ ...style }} className={`scroll-wrapper__track-vertical ${autoHideHover ? 'scroll-wrapper__track-vertical--auto-hide-hover' : ''}`} />
);


const ScrollWrapper = ({
  children,
  refCustom,
  onCustomUpdate,
  isEasyScroll,
  autoHideHover,
  isVisible,
  heightIs,
  className,
  ...props
}) => {
  const customClassName = `scroll-wrapper ${isVisible ? '' : 'scroll-wrapper--hidden'} ${className}`;
  const stylesCustom = { height: heightIs || '100%', width: '100%' };
  return (
    <ReactCustomScrollBar
      className={customClassName}
      ref={refCustom}
      renderView={renderViewScrollCustom}
      onUpdate={onCustomUpdate}
      renderTrackVertical={renderTrackScrollCustom(autoHideHover)}
      renderThumbVertical={renderThumbScrollCustom}
      style={stylesCustom}
      {...omit(['onVisibleStatus', 'setVisibleStatus'], props)}
    >
      {
         children
       }
    </ReactCustomScrollBar>
  );
};
ScrollWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
    PropTypes.string,
  ]).isRequired,
  heightIs: PropTypes.oneOfType(
    [PropTypes.string,
      PropTypes.number],
  ),
  onUpdate: PropTypes.func,
  onVisibleStatus: PropTypes.func.isRequired,
  className: PropTypes.string,
  setVisibleStatus: PropTypes.func.isRequired,
  refCustom: PropTypes.func,
  isVisible: PropTypes.bool.isRequired,
  autoHideHover: PropTypes.bool,
  onCustomUpdate: PropTypes.func.isRequired,
  isEasyScroll: PropTypes.bool,
};

ScrollWrapper.defaultProps = {
  heightIs: '',
  className: '',
  autoHideHover: false,
  isEasyScroll: false,
  onUpdate: () => {},
  refCustom: () => {},
};
export default ScrollWrapper;

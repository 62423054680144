/* eslint-disable max-len */
import {
  compose, lifecycle, withHandlers, withState, withStateHandlers,
} from 'recompose';
import { inc, remove } from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';


import { closeModal, uploadTempFileRequest } from '../../state/ui/actions';
import { getModal, getTempFileUploadPending } from '../../state/ui/selectors';
import AttachFilesModal from './attachFilesModal';
import { MODALS_UPLOADING_FILES } from '../../utils/enchancers/withFilesUploading';
import { withRefs } from '../../utils/enchancers';

const FILES_FIELD = 'FILES_FIELD';

const mapStateToProps = state => ({
  isOpen: getModal(state)(MODALS_UPLOADING_FILES.FILES_UPLOADING_MODAL),
  isPending: getTempFileUploadPending(state),
});

const mapDispatchToProps = ({
  onCloseModal: () => closeModal('attachFilesTaskModal'),
  uploadTempFile: uploadTempFileRequest,
});

const onChangeHandler = ({ setFilesList }) => (e) => {
  const filesList = Array.from(e.currentTarget.files);
  setFilesList(filesList);
};

const onUploadFilesHandler = ({
  filesList, uploadTempFile, setFilesIdList, loadingFiles, getRef,
}) => () => {
  filesList.map((file, index) => {
    const formData = new FormData();
    const fieldElement = getRef(FILES_FIELD);

    formData.append('file', file);

    return uploadTempFile(formData, {
      callbacks: {
        success: ({ model }) => {
          setFilesIdList(loadingFiles.concat(model));
        },
        error: () => {
          const dataTransfer = new DataTransfer();

          formData.delete('file');
          Array.from(fieldElement.files)
            .splice(inc(index), 1)
            .map(fileItem => dataTransfer
              .items
              .add(fileItem));
          fieldElement.files = dataTransfer.files;
        },
      },
    });
  });
};

const setLoadingFilesStateHandler = ({ loadingFiles }) => item => ({ loadingFiles: loadingFiles.concat(item) });

const onDeleteFileStateHandler = ({ loadingFiles }) => index => ({ loadingFiles: remove(index, 1, loadingFiles) });

const setRefFilesFiledHandler = ({ setRef }) => e => setRef(FILES_FIELD, e);

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withRefs(),
  withState('filesList', 'setFilesList', []),
  withStateHandlers(() => ({ loadingFiles: [] }), {
    onResetLoadingFiles: () => () => ({ loadingFiles: [] }),
    setLoadingFiles: setLoadingFilesStateHandler,
    onDeleteFile: onDeleteFileStateHandler,
  }),
  withHandlers({
    onChange: onChangeHandler,
    onUploadFiles: onUploadFilesHandler,
    setRefFilesFiled: setRefFilesFiledHandler,
  }),
  lifecycle({
    componentDidUpdate({ filesList }) {
      if (this.props.filesList !== filesList) {
        this.props.onUploadFiles();
      }
    },
  }),
);

export default enhance(AttachFilesModal);

import React from 'react';
import PropTypes from 'prop-types';

const ColData = ({ className, children }) => (
  <div className={className}>
    { children }
  </div>
);

ColData.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string.isRequired,
};

ColData.defaultProps = {
  children: null,
};

export default ColData;

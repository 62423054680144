import { compose } from 'recompose';
import { connect } from 'react-redux';

import { withNamespaces } from 'react-i18next';
import NotificationsForAuthForm from './errorNotification';
import { uiSelectors } from '../../../../state/ui';

const mapStateToProps = state => ({
  notification: uiSelectors.getAuthNotification(state),
});

const enhance = compose(
  connect(mapStateToProps),
  withNamespaces('common'),
);
export default enhance(NotificationsForAuthForm);

import { compose, lifecycle } from 'recompose';
import { memo } from 'react';
import { equals } from 'ramda';

import { withNamespaces } from 'react-i18next';
import MessageInnerContent from './messageInnerContent';

const enhance = compose(
  withNamespaces('common'),
  lifecycle({
    shouldComponentUpdate({
      isShowActions, message, isEditable, isUnread, editableMessage, isPending,
    }) {
      return !equals(message, this.props.message.content)
        || this.props.isShowActions !== isShowActions || isEditable !== this.props.isEditable
        || isUnread !== this.props.isUnread
        || this.props.editableMessage !== editableMessage
        || isPending !== this.props.isPending;
    },
  }),
  memo,
);
export default enhance(MessageInnerContent);

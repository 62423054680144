import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { withFormik } from 'formik/dist/index';
import { withNamespaces } from 'react-i18next';
import { filter, includes } from 'ramda';

import { uiActions } from '../../../../state/ui';
import { getModal } from '../../../../state/ui/selectors';
import { translateHelpers } from '../../../../utils/helpers';
import { usersActions } from '../../../../state/users';
import inviteMembersModal from './inviteMembersModal';

const rules = yup.object().shape({
  members: yup
    .array()
    .required({
      field: 'members',
      message: 'required',
      params: {
        key: translateHelpers.t('common', 'Members'),
      },
    }),
});

const initialValues = { members: [] };

const getAutocompleteHandler = ({
  getUsersAutocompleteAction, membersList,
}) => (fieldValue, callback) => {
  getUsersAutocompleteAction({
    q: fieldValue,
    limit: 10,
  }, {
    callbacks: {
      success: ({ users }) => {
        const usersResult = filter(
          ({ value }) => !includes(value, membersList),
          users,
        );
        callback(usersResult);
      },
    },
  });
};

const mapStateToProps = state => ({
  isOpen: getModal(state)('inviteMembers'),
});

const mapDispatchToProps = ({
  setUsersAutocomplete: usersActions.setUsersListAutocomplete,
  closeModal: uiActions.closeModal,
  getUsersAutocompleteAction: usersActions.getUsersListAutocompleteRequest,
});

const onCloseModalHandler = ({ resetForm, closeModal }) => () => {
  resetForm(initialValues);
  closeModal('inviteMembers');
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common', 'validation', 'chat']),
  withFormik({
    mapPropsToValues: () => initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    validationSchema: rules,
    handleSubmit: (formValues,
      {
        resetForm,
        props: {
          onInvite,
          closeModal,
        },
      }) => {
      const { members } = formValues;
      const memberIds = members.map(member => member.value);
      onInvite(memberIds, {
        success: () => { closeModal('inviteMembers'); resetForm(initialValues); },
      });
    },
  }),
  withHandlers({
    getAutocomplete: getAutocompleteHandler,
    onCloseModal: onCloseModalHandler,
  }),
);
export default enhance(inviteMembersModal);

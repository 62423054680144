import { compose, getContext, withHandlers } from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import LeftSideBar from './leftSideBar';
import { uiSelectors, uiActions } from '../../state/ui';
import { WINDOW_WIDTH } from '../../constants/ui';

const mapStateToProps = state => ({
  isSocketError: uiSelectors.getIsWsError(state),
  isOpenLeftSideBar: uiSelectors.getLeftSidebar(state),
});

const mapDispatchToProps = {
  changeLeftSideBar: uiActions.changeLeftSidebarStatus,
};

const onCloseLeftSideBarAfterClickHandler = ({ changeLeftSideBar }) => () => {
  if (window.innerWidth < WINDOW_WIDTH.MEDIUM) changeLeftSideBar(false);
};


const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  getContext({
    setLeftSideBarRef: PropTypes.func,
  }),
  withHandlers({
    onCloseLeftSideBarAfterClick: onCloseLeftSideBarAfterClickHandler,
  }),
);
export default enhance(LeftSideBar);

import React from 'react';
import PropTypes from 'prop-types';

import { Pagination, ScrollWrapper } from '../../components';
import { FilterRow, DirectsList } from './components';
import './style.sass';

const Directs = ({
  onGoBack,
  countEntities,
  entities,
  title,
  result,
  onPageChange,
  pagination,
  t,
}) => (
  <ScrollWrapper>
    <div className="directs">
      <div className="directs__container">
        <button
          onClick={onGoBack}
          type="button"
          className="directs__button-close"
          title={t('chat:Close page Channels')}
          aria-label={t('chat:Close page Channels')}
        >
          <span className="icon-close" />
        </button>
        <FilterRow />
        <DirectsList
          title={title}
          entities={entities}
          result={result}
        />
        <Pagination
          count={countEntities}
          onPageChange={onPageChange}
          pagination={pagination}
        />
      </div>
    </div>
  </ScrollWrapper>
);

Directs.propTypes = {
  title: PropTypes.string.isRequired,
  entities: PropTypes.instanceOf(Object).isRequired,
  result: PropTypes.arrayOf(PropTypes.number).isRequired,
  onPageChange: PropTypes.func.isRequired,
  countEntities: PropTypes.number.isRequired,
  onGoBack: PropTypes.func.isRequired,
  pagination: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
};
export default Directs;

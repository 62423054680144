import PropTypes from 'prop-types';
import { compose, getContext } from 'recompose';
import { withNamespaces } from 'react-i18next';
import GuideFacebookContainer from './guideFacebookContainer';

const enhance = compose(
  withNamespaces('common'),
  getContext({
    goBackHandler: PropTypes.func.isRequired,
  }),
);

export default enhance(GuideFacebookContainer);

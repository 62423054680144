import Ws from '@adonisjs/websocket-client';

import { getWsApiUrl } from '../requestHelpers';
import { subscribeOnEvent } from '../DOMHelper/listeners';

function Socket() {
  let ws;
  function socketConnection(token, onCloseCb, onOpenCb) {
    ws = Ws(getWsApiUrl(window.location), {
      reconnection: false,
      reconnectionAttempts: 0,
    });
    ws.withJwtToken(token).connect();
    const onOpenCbCustom = () => onOpenCb({ isSubscriptions: ws.subscriptions });
    ws.on('open', onOpenCbCustom);
    ws.on('close', () => {
      ws = null;
      onCloseCb();
    });
    subscribeOnEvent(window, 'offline', () => {
      if (ws) ws.close();
      onCloseCb();
    });
  }

  function subscribe(topic) {
    const getSubscription = ws.getSubscription(topic);
    if (!getSubscription) {
      return ws.subscribe(topic);
    }
    return getSubscription;
  }

  return {
    initSocketConnection({ onCloseCb, onOpenCb }) {
      if (!ws) socketConnection(localStorage.getItem('authToken'), onCloseCb, onOpenCb);
    },
    getSocketConnection() {
      if (!ws) {
        throw new Error("ws isn't init. You must call initSocketConnection");
      }
      return {
        ws,
        subscribe: topic => subscribe(topic),
      };
    },
  };
}

export default Socket();

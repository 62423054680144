export default {
  LOGIN_FETCH: 'LOGIN_FETCH',
  FORGOT_PASSWORD_FETCH: 'FORGOT_PASSWORD_FETCH',
  RESET_PASSWORD_FETCH: 'RESET_PASSWORD_FETCH',
  CHECK_CODE_FETCH: 'CHECK_CODE_FETCH',
  SIGN_UP_FETCH: 'SIGN_UP_FETCH',
  CONTINUE_SIGN_UP_FETCH: 'CONTINUE_SIGN_UP_FETCH',
  CHECK_RESET_PASSWORD_TOKEN: 'CHECK_RESET_PASSWORD_TOKEN',
  LOGIN_REQUEST_SUCCESS: 'LOGIN_REQUEST_SUCCESS',
  CHECK_CODE_SUCCESS: 'CHECK_CODE_SUCCESS',
  SET_AUTHENTICATED: 'SET_AUTHENTICATED',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  SIGN_UP_REQUEST_SUCCESS: 'SIGN_UP_REQUEST_SUCCESS',
  CONTINUE_SIGN_UP_REQUEST_SUCCESS: 'CONTINUE_SIGN_UP_REQUEST_SUCCESS',
  SET_JWT: 'SET_JWT',
  CHECK_RESET_PASSWORD_TOKEN_SUCCESS: 'CHECK_RESET_PASSWORD_TOKEN_SUCCESS',
  CHECK_CURRENT_USER_REQUEST: 'CHECK_CURRENT_USER_REQUEST',
  SET_USER_IS_VALID: 'SET_USER_IS_VALID',
  SET_USER_IS_VALID_FLAG: 'SET_USER_IS_VALID_FLAG',
  SET_USER_IS_INVALID: 'SET_USER_IS_INVALID',
  LOGOUT: 'LOGOUT',

  UPDATE_USER_PROFILE_REQUEST: 'UPDATE_USER_PROFILE_REQUEST',
  SET_USER: 'SET_USER',
};

import { memo } from 'react';
import {
  compose, lifecycle, withStateHandlers,
} from 'recompose';
import { connect } from 'react-redux';

import { withNamespaces } from 'react-i18next';
import ClientsContainer from './clientsContainer';
import withGrid from '../../utils/enchancers/withGrid';
import { clientsActions, clientsSelectors } from '../../state/clients';
import { settingBotsActions, settingBotsSelectors } from '../../state/settingBots';
import { settingUsersActions, settingUsersSelectors } from '../../state/settingUsers';
import { clients } from '../../constants';


const mapStateToProps = state => ({
  clientsList: clientsSelectors.getClientsList(state),
  clientsEntities: clientsSelectors.getClientsEntities(state),
  clientsCount: clientsSelectors.getClientsCount(state),
  clientsHasMore: clientsSelectors.getClientsHasMore(state),
  isPending: clientsSelectors.getClientsPending(state),
  botsEntities: settingBotsSelectors.getBotsEntities(state),
  managersEntities: settingUsersSelectors.getSettingUsersEntities(state),
});

const mapDispatchToProps = ({
  getClientsRequest: clientsActions.getClientsRequest,
  getSettingBotsRequest: settingBotsActions.getSettingBotsRequest,
  getSettingUsersRequest: settingUsersActions.getSettingUsersRequest,
});

const setSearchValueStateHandlers = () => ({ target: { value } }) => ({ searchValue: value });

const onSearchHandler = ({ searchValue }) => () => ({ searchByValue: searchValue });

const enhance = compose(
  memo,
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces('common'),
  withStateHandlers(() => ({ searchValue: '', searchByValue: '' }), {
    setSearchValue: setSearchValueStateHandlers,
    onSearch: onSearchHandler,
  }),
  withGrid({
    limit: clients.LIMIT_OF_CLIENTS_IN_PAGE,
    gridName: 'clientsList',
    hasMore: ({ clientsHasMore }) => clientsHasMore,
    count: ({ clientsCount }) => clientsCount,
    action: ({ getClientsRequest }) => getClientsRequest,
    searchValue: ({ searchByValue }) => searchByValue,
    sortKey: 'sortBy',
    searchByKey: 'name',
  }),
  lifecycle({
    componentDidMount() {
      this.props.getClientsRequest();
      this.props.getSettingBotsRequest();
      this.props.getSettingUsersRequest();
    },
  }),
);

export default enhance(ClientsContainer);

import { compose, withHandlers } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { withNamespaces } from 'react-i18next';
import DeleteUserModal from './deleteUserModal';
import { getModal } from '../../../../state/ui/selectors';
import { uiActions } from '../../../../state/ui';
import { settingUsersActions, settingUsersSelectors } from '../../../../state/settingUsers';

const mapDispatchToProps = {
  onCloseModal: uiActions.closeModal,
  deleteUserRequest: settingUsersActions.deleteSettingUserRequest,
};

const mapStateToProps = state => ({
  isOpen: getModal(state)('deleteUserModal'),
  isPending: settingUsersSelectors.getDeleteUserPending(state),
});


const onDeleteHandler = ({
  onClose, selectedUser, deleteUserRequest, pagination,
}) => () => deleteUserRequest({ userId: selectedUser.id }, {
  callbacks: { success: onClose },
  ...pagination,
});

const onCloseHandler = ({ onCloseModal }) => () => onCloseModal('deleteUserModal');

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces('common'),
  withRouter,
  withHandlers({
    onClose: onCloseHandler,
  }),
  withHandlers({
    onDelete: onDeleteHandler,
  }),
);


export default enhance(DeleteUserModal);

/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import 'emoji-mart/css/emoji-mart.css';
import { FormsCollection, ButtonsCollection } from '..';
import AttachFilesModal from './attachFilesModal';
import './style.sass';
import EmojiPicker from './emojiPicker';
import EmojiAutoComplete from './emojiAutoComplete';

const TextAreaDefault = ({
  isFocus,
  value,
  name,
  onSubmit,
  heightHandler,
  pending,
  onSetEmoji,
  onFieldFocus,
  onFieldBlur,
  onChangeByMouseDown,
  isEmojiOpen,
  onOpenEmoji,
  onCloseEmoji,
  isEmojiAutocompleteOpen,
  onOpenEmojiAutocompleteOpen,
  onOpenEmojiAutocompleteClose,
  emojisAutocomplete,
  onSetRefFormElement,
  onSetFocusField,
  setRef,
  onSetRefMessageFieldWrapper,
  onSetEmojiByAutocomplete,
  onChangeContent,
  emojiSelected,
  setEmojiSelected,
  emojiTyping,
  t,
}) => (
  <div
    className={`message-textArea ${isFocus ? 'message-textArea--is-focus' : null}`}
    ref={onSetRefMessageFieldWrapper}
  >
    <EmojiAutoComplete
      onChange={onChangeContent}
      set="messenger"
      emojisAutocomplete={emojisAutocomplete}
      emojiSelected={emojiSelected}
      onSetEmoji={onSetEmoji}
      emojiTyping={emojiTyping}
      isOpen={isEmojiAutocompleteOpen}
      onSetEmojiByAutocomplete={onSetEmojiByAutocomplete}
      isEmojiPickerOpen={isEmojiOpen}
      onOpen={onOpenEmojiAutocompleteOpen}
      onClose={onOpenEmojiAutocompleteClose}
      setEmojiSelected={setEmojiSelected}
    />
    <FormsCollection.FormWrapper
      customRef={onSetRefFormElement}
      handleSubmit={onSubmit}
      className="message-textArea__row"
    >
      <div className="message-textArea__col">
        <button
          type="button"
          className="message-textArea__control-button"
        >
          {/* <span className="icon-attach-file" /> */}
        </button>
      </div>
      <div className="message-textArea__col message-textArea__col--fill">
        <FormsCollection.TextArea
          autoHeight
          maxHeight={320}
          onUpdateScroll={heightHandler}
          onFocus={onFieldFocus}
          onBlur={onFieldBlur}
          onChange={onChangeContent}
          onKeyUp={onChangeByMouseDown}
          value={value}
          childrenRef={ref => setRef('typeMessage', ref)}
          name={name}
          placeholder={t('chat:Type a message')}
          className="message-textArea__textArea"
          isNotValidationPasteText
        >
        </FormsCollection.TextArea>
      </div>
      <div className="message-textArea__col">
        <EmojiPicker
          data-emoji="data-emoji"
          onSetFocusField={onSetFocusField}
          onOpen={onOpenEmoji}
          onClose={onCloseEmoji}
          isOpen={isEmojiOpen}
          onSetEmoji={onSetEmoji}
        />
        <ButtonsCollection.ButtonBrill
          type="submit"
          pending={pending}
          disabled={!value}
          className="button--xs message-textArea__button-send button-brill--fill"
        >

          {t('chat:Send')}
        </ButtonsCollection.ButtonBrill>
      </div>
    </FormsCollection.FormWrapper>
    <AttachFilesModal />
  </div>
);

TextAreaDefault.propTypes = {
  pending: PropTypes.bool.isRequired,
  isFocus: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  heightHandler: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSetEmoji: PropTypes.func.isRequired,
  onChangeByMouseDown: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default TextAreaDefault;

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { path } from 'ramda';

import { FormsCollection, ButtonsCollection, Layouts } from '../../../components';
import NotificationsForAuthForm from '../components/errorNotification';
import './style.sass';

const SignUp = ({
  handleBlur,
  touched,
  values,
  t,
  prefix,
  prefixForTitle,
  handleSubmit,
  authNotification,
  saveEmailWithDomain,
  pending,
}) => (
  <Layouts.Auth>
    <NotificationsForAuthForm />
    <div className="auth-wrapper__wrap">
      <FormsCollection.FormWrapper handleSubmit={handleSubmit}>
        <div className="auth-wrapper__form-contain">
          <h2 className="title weight--bold title--md text-align--center">
            {t('Sign up to {{domain}}', { domain: prefixForTitle })}
          </h2>
          <div className="auth-wrapper__helper-text--to-field">{t('Enter your email password to get started')}</div>
          <div className="auth-wrapper__field-email--sign-up">
            <FormsCollection.Input
              type="text"
              name="emailWithoutPrefix"
              onChange={e => saveEmailWithDomain(e)}
              onBlur={handleBlur}
              error={!!path(['email'], authNotification)}
              touched={touched.emailWithoutPrefix}
              value={values.emailWithoutPrefix}
              id="sing-up__email-without-prefix"
              className="auth-wrapper__input--bold auth-wrapper__field-email--sign-up"
              required
            />
            <b className="weight--medium">{prefix}</b>
            <input value={values.email} onBlur={handleBlur} name="email" type="hidden" />
          </div>
          <div className="button-group">
            <ButtonsCollection.ButtonBrill
              className="auth-wrapper__button--with-border button--fill"
              borderColor="#26b5ed"
              borderWidth="2"
              pending={pending}
              type="submit"
            >
              {t('Continue')}
            </ButtonsCollection.ButtonBrill>
          </div>
          <div className="auth-wrapper__nav-group">
            <nav className="auth-wrapper__nav">
              <ul className="auth-wrapper__list">
                <li className="auth-wrapper__item">
                  <Link to="/" className="auth-wrapper__link">{t('I have an account')}</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </FormsCollection.FormWrapper>
    </div>
  </Layouts.Auth>
);

SignUp.propTypes = {
  prefixForTitle: PropTypes.string,
  pending: PropTypes.bool,
  t: PropTypes.func.isRequired,
  prefix: PropTypes.string.isRequired,
  saveEmailWithDomain: PropTypes.func.isRequired,
  values: PropTypes.shape({
    email: PropTypes.string,
    emailWithoutPrefix: PropTypes.string,
  }).isRequired,
  authNotification: PropTypes.shape({
    email: PropTypes.array,
  }),
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  touched: PropTypes.shape({
    emailWithoutPrefix: PropTypes.bool,
    email: PropTypes.bool,
  }).isRequired,
};

SignUp.defaultProps = {
  prefixForTitle: '',
  pending: false,
  authNotification: {},
};

export default SignUp;

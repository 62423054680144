import React from 'react';
import PropTypes from 'prop-types';

import { ModalWindow, FormsCollection, ButtonsCollection } from '..';
import { FilesField, FilesItem } from './components/index';
import './style.sass';

const AttachFilesModal = ({
  isOpen, onCloseUploadFilesModal, isPending, onSetFilesContinue, onChange, resetFilesIdList,
  filesListIds, setRefFilesFiled,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={onCloseUploadFilesModal}
    modalName="attachFiles"
    title="Upload file"
    className="modal-window__attach-files"
  >
    <FormsCollection.FormWrapper
      handleSubmit={onSetFilesContinue}
      className="attach-files"
    >
      <FilesField
        pending={isPending}
        setRefFilesFiled={setRefFilesFiled}
        onChange={onChange}
      />
      <div className="attach-files__uploaded-files">
        {
          filesListIds.map(item => (
            <FilesItem item={item} key={`item-file${item.filename}`} onDelete={() => resetFilesIdList([item.id])} />
          ))
        }
      </div>
      <div className="button-group">
        <ButtonsCollection.ButtonBrill
          className="button-brill--fill task-files__add-button  button--sm"
          type="submit"
        >

          Upload
        </ButtonsCollection.ButtonBrill>
      </div>
    </FormsCollection.FormWrapper>
  </ModalWindow>
);
AttachFilesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseUploadFilesModal: PropTypes.func.isRequired,
  isPending: PropTypes.bool.isRequired,
  onSetFilesContinue: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  filesListIds: PropTypes.instanceOf(Array).isRequired,
  resetFilesIdList: PropTypes.func.isRequired,
  setRefFilesFiled: PropTypes.func.isRequired,
};

export default AttachFilesModal;

import { createActions } from 'redux-actions';
import { identity } from 'ramda';
import types from './types';
import { settingRolesSchema } from './schema';

export const {
  getRolesRequest,
  setRoles,
} = createActions(
  {
    [types.GET_ROLES_REQUEST]: [
      identity,
      (params, meta = {}) => ({
        async: true,
        route: '/roles',
        selectorName: 'getRolesRequest',
        method: 'GET',
        params,
        schema: {
          rules: settingRolesSchema,
          pathData: ['data'],
        },
        ...(meta.isAutocomplete ? {} : { actions: { success: setRoles } }),
        ...meta,
      }),
    ],
  },
  types.SET_ROLES,
);

import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmModal } from '../../..';
import './style.sass';

const DeleteMemberModal = ({
  isOpen,
  isPending,
  onCloseModal,
  memberName,
  onDelete,
  t,
  titleItem,
  titleMain,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    isPending={isPending}
    actionTitle="Delete"
    onAction={onDelete}
    onClose={onCloseModal}
    title={`Delete ${titleItem}`}
  >
    <span className="delete-member__title title-md">
      {t('Are you sure you want to delete')}
      <b className="delete-member__name">{memberName}</b>
      {' '}

      from this
      {' '}
      {titleMain.toLowerCase()}

?
    </span>
  </ConfirmModal>
);

DeleteMemberModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  memberName: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  titleMain: PropTypes.string.isRequired,
  titleItem: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default DeleteMemberModal;

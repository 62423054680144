import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import SubscriberWrapper from './subscriberWrapper';
import { subscriptionSelectors } from '../../../../state/subscription';
import { withSubscription } from '../../../../utils/enchancers';

const mapStateToProps = state => ({
  subscriber: subscriptionSelectors.getSubscriberData(state),
});

const enhance = compose(
  withSubscription(),
  connect(mapStateToProps),
  withNamespaces(['common']),
);

export default enhance(SubscriberWrapper);

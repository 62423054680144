import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  DirectChannels,
  GroupChannels,
  ManagersChannels,
  ChannelsContainer,
  MessengerContainer,
  DirectsContainer,
} from './components';
import { Layouts, NoInternetConnection, ButtonsCollection } from '../../components';
import { LeftSideBar } from '../../containers';
import './style.sass';
import {
  InviteMembersModal,
  DeleteChannelModal,
  LeaveChannelModal,
  EditChannelModal,
  EditCurrentClientModal,
} from './modals';
import { CHANNELS_ROUTES } from '../../constants/ui';
import { DOWN_CHANNEL, UPPER_CHANNEL } from './consts';

const Messenger = ({
  onScrollToUnreadChannel, onWatchUnreadChannels,
  shownButtonScrollToChannel, t,
  onCloseLeftSideBarAfterClick,
}) => (
  <Layouts.Main>
    <LeftSideBar onScroll={onWatchUnreadChannels}>
      <GroupChannels onCloseLeftSideBarAfterClick={onCloseLeftSideBarAfterClick} />
      <DirectChannels onCloseLeftSideBarAfterClick={onCloseLeftSideBarAfterClick} />
      {/* <ManagersChannels /> */}
      {
        shownButtonScrollToChannel[UPPER_CHANNEL] && (
          <ButtonsCollection.ButtonBrill
            onClick={() => onScrollToUnreadChannel(UPPER_CHANNEL)}
            className="button-brill--fill button--unread-mentions button--unread-mentions--up"
          >
            <span className="icon-down" />

            {t('Unread messages')}
          </ButtonsCollection.ButtonBrill>
        )
      }
      {
        shownButtonScrollToChannel[DOWN_CHANNEL] && (
          <ButtonsCollection.ButtonBrill
            onClick={() => onScrollToUnreadChannel(DOWN_CHANNEL)}
            className="button-brill--fill button--unread-mentions"
          >
            <span className="icon-down" />

            {t('Unread messages')}
          </ButtonsCollection.ButtonBrill>
        )
      }
    </LeftSideBar>
    <NoInternetConnection>
      <Switch>
        <Route exact path={CHANNELS_ROUTES.CHANNELS} component={ChannelsContainer} />
        <Route exact path={CHANNELS_ROUTES.DIRECTS} component={DirectsContainer} />
        <Route component={MessengerContainer} />
      </Switch>
      <InviteMembersModal />
      <DeleteChannelModal />
      <EditChannelModal />
      <LeaveChannelModal />
      <EditCurrentClientModal />
    </NoInternetConnection>
  </Layouts.Main>
);

Messenger.propTypes = {
  onScrollToUnreadChannel: PropTypes.func.isRequired,
  onWatchUnreadChannels: PropTypes.func.isRequired,
  shownButtonScrollToChannel: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
  onCloseLeftSideBarAfterClick: PropTypes.func.isRequired,
};

export default Messenger;

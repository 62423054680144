export const TARIFFS = {
  1: {
    title: 'entrepreneur',
    cost: '',
  },
  2: {
    title: 'business',
    cost: '$9',
  },
  3: {
    title: 'virtuoso',
    cost: '$49',
  },
};

export const PAYMENTS_STATUSES = {
  PAYMENT_STATUS_NOT_PAID: 0,
  PAYMENT_STATUS_PAID: 1,
  PAYMENT_STATUS_FREE: 2,
  PAYMENT_STATUS_UNSUBSCRIBED: 3,
  PAYMENT_STATUS_BEFORE_DELETING: 4,
};

export const TARIFFS_NAMES = {
  ENTREPRENEUR: 1,
  BUSINESS: 2,
  VIRTUOSO: 3,
};

export const TYPE_PAYMENT = {
  CHANGE: true,
  USUAL: false,
};

export const TYPE_PAYMENT_LOGS = {
  0: 'Wait',
  1: 'Issued',
  2: 'Paid',
  3: 'Canceled',
  4: 'Failure',
};

import React from 'react';
import { SubscriberEditForm } from './components';
import { ScrollWrapper } from '../../components';
import './style.sass';

const SubscriberContainer = () => (
  <ScrollWrapper className="setting-user__scroll-wrapper">
    <div className="subscriber-info">
      <SubscriberEditForm />
    </div>
  </ScrollWrapper>
);

export default SubscriberContainer;

import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import './style.sass';

const ModulesItem = ({
  title, onClick, asLink, href,
}) => (
  <>
    {
      asLink ? (
        <NavLink to={href} activeClassName="modules-item__link--active" className="modules-item__link">{title}</NavLink>
      ) : (
        <button className="modules-item__button" type="button" onClick={onClick}>{title}</button>
      )
    }
  </>
);
ModulesItem.propTypes = {
  title: PropTypes.string.isRequired,
  asLink: PropTypes.bool,
  onClick: PropTypes.func,
  href: PropTypes.string,
};
ModulesItem.defaultProps = {
  asLink: false,
  href: '',
  onClick: () => {},
};
export default ModulesItem;

import {
  compose, withContext, withHandlers, withState,
} from 'recompose';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import ClientsList from './clientsList';
import { uiActions } from '../../../../state/ui';

const mapDispatchToProps = {
  setOpenModal: uiActions.openModal,
};


const deleteModalHandler = ({
  setClientData, setOpenModal,
}) => (id) => {
  setClientData(
    id,
  );
  setOpenModal('deleteClientsModal');
};

const editModalHandler = ({
  setClientData, setOpenModal,
}) => (id) => {
  setClientData(
    id,
  );
  setOpenModal('editClientsModal');
};

const enhance = compose(
  connect(null, mapDispatchToProps),
  withNamespaces('common'),
  withState('clientData', 'setClientData', null),
  withContext({
    clientData: PropTypes.number,
    setClientData: PropTypes.func,
  },
  ({
    setClientData, clientData,
  }) => ({
    setClientData, clientData,
  })),
  withHandlers({
    deleteModalHandler,
    editModalHandler,
  }),
);

export default enhance(ClientsList);

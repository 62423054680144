import React from 'react';
import PropTypes from 'prop-types';

const Option = ({
  innerText, value, isActive, onSelectOption,
}) => (
  <button
    type="button"
    className={`select-field__option
    ${isActive ? 'select-field__option--active' : ''}
  `}
    onClick={() => onSelectOption(value)}
  >
    <div className="select-field__option-inner-text text--cut">
      {innerText}
    </div>
  </button>
);

Option.propTypes = {
  isActive: PropTypes.bool,
  innerText: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onSelectOption: PropTypes.func.isRequired,
};

Option.defaultProps = {
  isActive: false,
};

export default Option;

import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Label, ButtonsCollection } from '../../..';
import Number from './components';
import './style.sass';

const arraySeconds = [...Array(60).keys()];
const arrayHours = [...Array(24).keys()];
// TODO: refactor For VP
// props --  onChange as callBack with value

const TimePickerComponent = ({
  convertTimeToString,
  currentHour,
  currentMinute,
  slidersConfig,
  getRef,
  className,
  name,
  setRef,
  changeHourHandler,
  changeMinuteHandler,
  changeMinuteSliderHandler,
  changeHourSliderHandler,
  setFocusStateHandler,
  togglePicker,
  inFocus,
  error,
  touched,
  onBlur,
  isPicker,
  disabled,
}) => (
  <div
    className={`time-picker
    ${currentHour || currentMinute ? 'time-picker--is-fill' : ''}
    ${(error && touched) ? 'time-picker--has-error' : ''}
    ${className} ${inFocus ? 'time-picker--focus' : ''}`}
    ref={e => setRef('inputFieldGroup', e)}
  >
    <div className="time-picker__input-container">
      <div className="time-picker__place-write">
        <Number
          min="0"
          max="23"
          charMask="H"
          placeholder="HH"
          disabled={disabled}
          convertTimeToString={convertTimeToString}
          onFocus={() => setFocusStateHandler(true)}
          onBlur={() => { onBlur({ target: { name } }); setFocusStateHandler(false); }}
          onChange={e => changeHourHandler(e, false)}
          customRef={e => setRef('fieldHour', e)}
        />
        <Number
          min="0"
          max="59"
          charMask="M"
          placeholder="MM"
          disabled={disabled}
          convertTimeToString={convertTimeToString}
          onFocus={() => setFocusStateHandler(true)}
          onBlur={() => { onBlur({ target: { name } }); setFocusStateHandler(false); }}
          onChange={e => changeMinuteHandler(e, false)}
          customRef={e => setRef('fieldMinute', e)}
        />
        <ButtonsCollection.ButtonIcons
          title="Show time slider"
          disabled={disabled}
          className="time-picker__toggle-button"
          onClick={() => togglePicker(!inFocus, true, getRef)}
        >
          <span className="icon-timer-2-icon" />
        </ButtonsCollection.ButtonIcons>
      </div>
      <div className={`time-picker__modal ${isPicker ? 'time-picker__modal--focus' : ''}`}>
        <div className="time-picker__row">
          <Slider
            {...slidersConfig}
            beforeChange={changeHourSliderHandler}
            ref={e => setRef('hoursSlider', e)}
          >
            {
              arrayHours.map((el, index) => (
                <div
                  className="time-picker__item"
                  key={`timePickerHour${name}${new Date().getTime() * Math.random()}`}
                >
                  {currentHour === index ? (
                    <Label className="label--fill time-picker__label-current label--xs">
                      {convertTimeToString(index)}
                    </Label>
                  ) : (
                    convertTimeToString(index)
                  )
                  }
                </div>
              ))
            }
          </Slider>
        </div>
        <div className="time-picker__row">
          <Slider
            {...slidersConfig}
            beforeChange={changeMinuteSliderHandler}
            ref={e => setRef('minuteSlider', e)}
          >
            {
                arraySeconds.map((el, index) => (
                  <div
                    className="time-picker__item"
                    key={`timePickerMinute${name}${new Date().getTime() * Math.random()}`}
                  >
                    {currentMinute === index ? (
                      <Label className="label--fill time-picker__label-current label--xs">
                        {convertTimeToString(index)}
                      </Label>
                    ) : (
                      convertTimeToString(index)
                    )
                    }
                  </div>
                ))
              }
          </Slider>
        </div>
      </div>
    </div>
    {typeof error === 'string' && error.length !== 0 && touched && (
      <span className="field-group__error field__error-text">
        {error}
      </span>
    )}
  </div>
);
TimePickerComponent.propTypes = {
  currentHour: PropTypes.oneOfType(
    [
      PropTypes.number,
      PropTypes.string,
    ],
  ),
  currentMinute: PropTypes.oneOfType(
    [
      PropTypes.number,
      PropTypes.string,
    ],
  ),
  slidersConfig: PropTypes.shape(),
  getRef: PropTypes.func,
  setRef: PropTypes.func,
  changeHourHandler: PropTypes.func.isRequired,
  changeMinuteHandler: PropTypes.func.isRequired,
  changeMinuteSliderHandler: PropTypes.func.isRequired,
  changeHourSliderHandler: PropTypes.func.isRequired,
  setFocusStateHandler: PropTypes.func.isRequired,
  togglePicker: PropTypes.func.isRequired,
  isPicker: PropTypes.bool.isRequired,
  inFocus: PropTypes.bool,
  convertTimeToString: PropTypes.func.isRequired,
  className: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape(),
  ]),
  onBlur: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
};
TimePickerComponent.defaultProps = {
  currentHour: 0,
  currentMinute: 0,
  slidersConfig: {},
  getRef: () => {},
  setRef: () => {},
  className: '',
  name: '',
  error: '',
  touched: false,
  onBlur: () => {},
  inFocus: false,
  disabled: false,
};
export default TimePickerComponent;

import React from 'react';
import PropTypes from 'prop-types';
import { ButtonsCollection, FormsCollection, ModalWindow } from '../../../../../components';

import './style.sass';
import FieldItem from './fieldItem';
import DeleteCustomFieldModal from './deleteCustomFieldModal';

const CustomFieldsModal = ({
  isOpen, onCloseModalHandler, handleSubmit, values,
  touched,
  errors,
  handleChange,
  isUiAddMoreFields,
  setIsUiAddMoreFields,
  customFieldsList,
  setFieldId,
  onDeleteCustomField,
  fieldId,
  t,
}) => (
  <>
    <ModalWindow
      isOpen={isOpen}
      onClose={onCloseModalHandler}
      modalName="customFieldsModal"
      title={t('customFields:Custom fields')}
      className="modal-window__custom-fields"
    >
      <>
        <div>
          {
        customFieldsList.map(id => (
          <FieldItem
            id={id}
            key={`status-${id}`}
            fieldId={fieldId}
            onDeleteCustomField={onDeleteCustomField}
            setFieldId={setFieldId}
          />
        ))
      }
        </div>
        {
      isUiAddMoreFields && (
      <FormsCollection.FormWrapper handleSubmit={handleSubmit} className="custom-fields__row custom-fields__row--form-add status-item">
        <div className="custom-fields__col">
          <FormsCollection.Input
            name="title"
            id="title"
            value={values.title}
            touched={touched.title}
            error={errors.title}
            className="custom-fields__input-add"
            onChange={handleChange}
          />
        </div>
        <div className="custom-fields__col">
          <ButtonsCollection.ButtonBrill
            className="button-brill--fill custom-fields__add-button"
            type="submit"
          >
            {t('Save')}
          </ButtonsCollection.ButtonBrill>
          <button
            type="button"
            className="button--cancel custom-fields__add-button-cancel"
            onClick={() => setIsUiAddMoreFields(false)}
          >
            {t('Cancel')}
          </button>
        </div>
      </FormsCollection.FormWrapper>
      )}
        {
      !isUiAddMoreFields && (
        <div className="custom-fields__row custom">
          <button
            type="button"
            className="field-item__add-more"
            onClick={() => setIsUiAddMoreFields(true)}
          >
            <>
              <span className="field-item__add-more__icon icon-plus" />
              <span className="field-item_add-more__title">{t('customFields:Add field')}</span>
            </>
          </button>
        </div>
      )
    }
      </>
    </ModalWindow>
    <DeleteCustomFieldModal fieldId={fieldId} />
  </>
);

CustomFieldsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModalHandler: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  touched: PropTypes.instanceOf(Object).isRequired,
  values: PropTypes.oneOfType([PropTypes.shape({
    title: PropTypes.string.isRequired,
  }), PropTypes.func]),
  errors: PropTypes.shape({
    title: PropTypes.string,
  }),
  setIsUiAddMoreFields: PropTypes.func.isRequired,
  isUiAddMoreFields: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
  customFieldsList: PropTypes.instanceOf(Array),
  fieldId: PropTypes.number,
  setFieldId: PropTypes.func.isRequired,
  onDeleteCustomField: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

CustomFieldsModal.defaultProps = {
  values: PropTypes.shape({
    title: '',
  }),
  errors: PropTypes.shape({
    title: '',
  }),
  isUiAddMoreFields: null,
  customFieldsList: [],
  fieldId: null,
};

export default CustomFieldsModal;

/* eslint-disable  */
import * as R from 'ramda';
import { isNil, ifElse, type } from 'ramda';
import { normalize } from 'normalizr';
import moment from 'moment';
import { Either, Maybe, Tuple } from 'ramda-fantasy';
import { push } from 'connected-react-router';

import {
  messengerTypes, messengerSelectors, messengerActions, messengerSchemas,
} from '../../../state/messenger';
import { MESSAGES } from '../../../state/messenger/types';
import { getActiveChannel } from '../../../state/messenger/selectors';
import { userSelectors } from '../../../state/user';
import { uiActions, uiSelectors, uiTypes } from '../../../state/ui';

import {
  // eslint-disable-next-line no-unused-vars
  // checkIfDayIsSame,
  getOldestMessageTimestamps,
  getUniqueOldestTimestamp,
} from '../../helpers/messengerHelpers';
import { setUsersUpdateMiddleware } from '../../helpers/stateHelpers';
import { channelHelpers } from '../../helpers/messengerHelpers/';
import {
  convertUtcTimeToStartDay,
  curriedNext,
  isConditionRight,
  isNotNil,
  maybeValueNotNil,
  debounce,
  map,
  functorGetOrElse, safeNotNil,
} from '../../helpers/commonHelpers';
import { decryptString } from '../../helpers/stringHelpers/common';
import {
  getChannelId,
  getIsShowUnreadMessage,
  isUserFocusOnChat,
  isShowUnreadMessageLens,
  modifyChannel,
  senderUserEqual,
  unreadCountLens,
  objWithChannelIdAndUnread,
  mapSafeChannel,
} from '../../helpers/messengerHelpers/channelHelpers';
import {
  getFirstMessageByCondition, pathMessageEntities
} from '../../helpers/messengerHelpers/messages';
import { helpers } from '../..';
import { LAST_CHANNEL_MESSENGER_STORAGE } from '../../../constants/messenger';
import { propOr, pathOr, path } from 'ramda';
import { prop } from 'ramda';
import { user } from '../../i18n/translations/en';

const { webSocketHelpers } = helpers;
const { deleteChannel } = channelHelpers;

export const getMessagesMiddleware = ({ getState }) => next => (action) => {
  if (action.type === messengerTypes.GET_LATEST_MESSAGES_REQUEST) {
    const state = getState();
    const { payload: { channelId }, meta } = action;
    const showMore = R.prop('showMore', meta);
    const loadedChannels = messengerSelectors.getLoadedChannels(state)(channelId);
    if (!showMore && loadedChannels) {
      next(messengerActions.cancelMessageRequest({
        message: 'Message already loaded',
      }));
    } else {
      next(action);
    }
  } else {
    next(action);
  }
};


export const updateMessagesMiddleware = () => next => (action) => {
  if (action.type === messengerTypes.UPDATE_MESSAGE) {
    const {
      payload: {
        content, ts, channel_id, id, isPending, updated_at, ...data
      },
    } = action;
    if (isPending) {
      next(messengerActions.updateMessageRequest({
        content, updated_at, channelId: channel_id, message_id: id,
      }));
    }
    action.payload = ({
      channel_id, ts, content, updated_at, ...data,
    });
    next(action);
  } else {
    next(action);
  }
};

const normalizeAuthorsInMessages = R.compose(
  R.reduce(
    (accum, message) => ({
      authors: {
        ...accum.authors,
        ...{
          [message.created_by]: {
            id: message.created_by,
            first_name: message.first_name,
            last_name: message.last_name,
            avatar: R.pathOr(message.avatar, ['avatar', 'id'], message),
          },
        },
      },
      messages: { ...accum.messages, ...{ [message.ts]: R.omit(['first_name', 'last_name'], message) } },
    }),
    {
      messages: {},
      authors: {},
    },
  ), R.values,
);

const normalizeMessageTs = R.compose(
  R.map((message) => {
    const normalizedMessage = { ...message };
    const createdDay = R.prop('created_at', normalizedMessage);
    normalizedMessage.created_at = moment(createdDay).unix();
    normalizedMessage.createdDay = convertUtcTimeToStartDay(createdDay);
    return normalizedMessage;
  }),
);

export const messagesNormalize = ({ getState }) => next => (action) => {
  const { type } = action;
  if (type === messengerTypes.SET_LATEST_MESSAGES || type === messengerTypes.SET_MORE_MESSAGES) {
    const {
      payload: {
        data, count: totalCount, hasMore, meta: { channelId , ...meta},
      },
    } = action;
    const state = getState();
    const preCountMessages = messengerSelectors.getCountLoadedMessages(state)(channelId);
    const loadedMessageDates = messengerSelectors.getMessageDays(state)(channelId);

    const { result, entities } = data;

    const messagesResult = R.reverse(result);
    const messageEntities = entities[MESSAGES];
    let nextAction;
    if(R.prop('setAsClean', meta)){
      nextAction = messengerActions.setMessagesPuck;
    } else {
      nextAction = type === messengerTypes.SET_MORE_MESSAGES
        ? messengerActions.setMoreMessages : messengerActions.setLatestMessages;
    }

    const entitiesNormalize = normalizeAuthorsInMessages(messageEntities);

    const activeChannelId = messengerSelectors.getActiveChannel(state).id;


    const userId = userSelectors.getUserData(state).id;
    const isCreatedByEq = R.propEq('created_by');

    const storedLastUserMessageId = messengerSelectors.getLastUserOwnerMessage(state)(activeChannelId);

    const tuple = Tuple(storedLastUserMessageId, data);

    const eitherLastMessageId = R.cond([
      [R.compose(isNil, Tuple.fst), Either.Right],
      [R.T, Either.Left]
    ]);

    const maybeMessageEntities = data => maybeValueNotNil(pathMessageEntities(data));

    const getLastMessageId = R.compose(
      functorGetOrElse(null),
      map(getFirstMessageByCondition(isCreatedByEq(userId))),
      maybeMessageEntities,
      Tuple.snd,
    );

    const lastUserOwnerMessage = Either.either(Tuple.fst, getLastMessageId)(eitherLastMessageId(tuple));

    let count = R.length(result);
    if (type === messengerTypes.SET_MORE_MESSAGES) {
      count += preCountMessages;
    }
    const payloadWithAuthors = {
      entities: { messages: entitiesNormalize.messages, authors: entitiesNormalize.authors },
      totalCount,
      hasMore,
      count,
      channelId,
      result: messagesResult,
      lastUserOwnerMessage,
    };

    next(setUsersUpdateMiddleware(nextAction,
      {
        pathEntities: ['entities', 'authors'],
      })(payloadWithAuthors));

    if (messageEntities) {
      const messageWithDate = normalizeMessageTs(messageEntities);
      const newMessageTimestamps = getOldestMessageTimestamps(R.values(messageWithDate));
      const loadedTimestamps = getUniqueOldestTimestamp(newMessageTimestamps, loadedMessageDates);
      const timestamps = ({
        ...loadedTimestamps,
        ...newMessageTimestamps,
      });
      next(messengerActions.setTimestamps({ timestamps, channelId }));
    }
  } else {
    next(action);
  }
};

const normalizeMessage = message => normalizeAuthorsInMessages({ [message.ts]: message });

export const newMessageNormalize = props => next => (action) => {
  const { type } = action;
  if (!action.payload) next(action);
  if (type === messengerTypes.SET_NEW_MESSAGE) {
    const { payload: { message, isUserInSomeChannel = false,  isFromCallBack = null, isMember = true } } = action;

    const state = props.getState();
    const channel = R.path(['payload', 'channel', 'id'], action);
    const channelType = R.path(['payload', 'channel', 'type'], action);
    const unreadCount = R.path(['payload', 'unread_count'], action);
    const messagesResult = messengerSelectors.getMessageList(state)(channel);
    const { created_by, ts } = message;
    const userId = userSelectors.getUserData(state).id;
    const activeChannelId = messengerSelectors.getActiveChannel(state).id;
    const lastUserOwnerMessage = R.equals(created_by, userId)
      ? ts.toString()
      : messengerSelectors.getLastUserOwnerMessage(state)(activeChannelId);

    const loadedMessages = messengerSelectors.getCountLoadedMessages(state)(channel);
    const isWindowFocus = uiSelectors.getIsWindowFocus(state);
    const bootData = userSelectors.getUserData(state);

    const decryptClientMsgId = R.compose(decryptString, R.prop('client_msg_id'));

    const isMessageReplaced = R.cond([
      [R.compose(R.equals(true), val => R.prop('isPendingOfflineRender', val) ||  R.prop('isPending', val)) , Either.Left],
      [R.compose(
        isNotNil,
        id => messengerSelectors.getMessage(state)(id, channel),
        decryptClientMsgId,
      ), Either.Right],
      [R.T, Either.Left],
    ]);

    const saveNewMessage = payload => next(messengerActions.setMessage(payload));

    const deleteMessage = ts => props.dispatch(messengerActions.deleteMessage({
      message: ({ ts, channel_id: channel }),
      isNotDissCount: true,
    }));
      const curriedReadChannelRequest = curriedNext(next, messengerActions.readMessagesRequest);
      const readChannel = channelId => curriedReadChannelRequest({
        isUpdatingCount: true,
        isShowUnreadMessage: false,
      }, ({ channelId, count: unreadCount }));
    const eitherUserFocusOnChat = isUserFocusOnChat(isUserInSomeChannel, channel, R.prop('channel_id'))(message);
    const eitherUsersEqual = senderUserEqual(R.prop('id', bootData));

    const normalizeAuthor = normalizeMessage(message);
    const toStringProp = (prop, obj) => propOr('', prop, obj).toString();
    const replacedMessage = R.compose(
      deleteMessage,
      R.tap(() => saveNewMessage({
        entities: { ...normalizeAuthor },
        channel,
        result: [toStringProp('ts', message)],
        lastUserOwnerMessage,
      })),
      decryptClientMsgId,
    );
    Either.either(() => isFromCallBack !== true && saveNewMessage({
      entities: { ...normalizeAuthor },
      channel,
      count: !R.includes(toStringProp('ts', message), messagesResult || []) || (isFromCallBack === true && !window.navigator.onLine) ? R.inc(loadedMessages) : loadedMessages,
      result:  [toStringProp('ts', message)],
    }), replacedMessage)(isMessageReplaced(message));

    Either.either(R.compose(
      map(R.compose(readChannel || R.identity, getChannelId)),
      eitherUsersEqual,
    ), R.identity)(eitherUserFocusOnChat);
 //&& ifElse(()=>channelType === 0, ()=>isMember)
    if(!R.equals(created_by, userId) && !isUserInSomeChannel){
      const oldTotalUnreadCount =  messengerSelectors.getTotalUnreadCount(props.getState());
      const oldGeneralUnreadCount =  messengerSelectors.getGeneralUnreadCount(props.getState());
      const newUnreadCountThisChannel = unreadCount;
      const oldUnreadCountThisChannel = messengerSelectors.getChannelById(state)(channel).unread_count;
      let newTotalUnreadCount;
      let newGeneralUnreadCount;
      if (oldUnreadCountThisChannel){
        //newTotalUnreadCount = oldTotalUnreadCount + newUnreadCountThisChannel;
        newTotalUnreadCount = (oldTotalUnreadCount - oldUnreadCountThisChannel) + newUnreadCountThisChannel;
        newGeneralUnreadCount = newUnreadCountThisChannel;
      } else {
        newTotalUnreadCount = oldTotalUnreadCount + 1;
        newGeneralUnreadCount = oldGeneralUnreadCount + 1;
      }
      if (channel === 1) {
        next(messengerActions.setGeneralUnreadCount({ count: newGeneralUnreadCount }));
      } else {
        next(messengerActions.setTotalUnreadCount({ count: newTotalUnreadCount }));
      }
    }
    if (action.payload.channel && !isUserInSomeChannel) {
      props.dispatch(messengerActions.setUnreadCount({ channel: action.payload.channel,
        unread_count: action.payload.unread_count  }))
    }
  }
  next(action);
};

const normalizePinnedMessage = (message) => {
  const normalizedData = normalize([message], messengerSchemas.pinnedMessagesSchema);
  return {
    entities: normalizedData.entities.PINNED_MESSAGES,
    result: normalizedData.result,
  };
};

const removeMessageById = (state, id, channelId) => {
  const entities = messengerSelectors.getPinnedMessages(state)(channelId);
  const result = messengerSelectors.getPinnedMessagesId(state)(channelId);
  return {
    entities: R.omit([id], entities),
    result: result.filter(key => key !== id),
  };
};

export const pinMessageMiddleware = ({ getState }) => next => (action) => {
  const { type } = action;
  if (type === messengerTypes.SET_PIN_MESSAGE || type === messengerTypes.DELETE_PIN_MESSAGE) {
    const { payload: { message } } = action;
    const channelId = message.channel_id;
    if (type === messengerTypes.SET_PIN_MESSAGE) {
      const data = normalizePinnedMessage(message);
      action.payload = {
        channelId,
        message: data,
      };
    } else {
      const { id } = message;
      const state = getState();
      const data = removeMessageById(state, id, channelId);
      action.payload = {
        data,
        channelId,
      };
    }
    next(action);
  } else {
    next(action);
  }
};

export const unreadMessageMiddleware = ({ getState, dispatch }) => next => (action) => {
  if (action.type === messengerTypes.SET_UNREAD_COUNT) {
    /*
       ## This is logic for update unread count of channels and total unread count ##

        We don`t update anything when:
          1. isNotUnreadCount - when we have not prop `unread_count` in the action
          2. isUnreadCountNotEqualZero - sometimes action has unread_count equal 0.
             when action  has unread count 0, `isNotUnreadCount` will be ignore.

        We don`t update total unread count when:
          1. When this channel before dispatch the action, has not unread_count.
          2. When total unread count more ore equal then unread count in this channel.
          3. When the action has not unread count equal zero
     */
    const isNotUnreadCount = !R.pathOr(false,['payload', 'unread_count'], action);
    const isUpdateAfterDeleteMessage = R.pathOr(false,['payload', 'isAfterDeletedMessage'], action);
    const isUnreadCountNotEqualZero = R.pathOr(null,['payload', 'unread_count'], action) !==  0;
    const isNotSetUnreadCount = isNotUnreadCount && isUnreadCountNotEqualZero;
    if(isNotSetUnreadCount && !isUpdateAfterDeleteMessage) return;
    const id = R.pathOr(false,['payload','channel', 'id'], action);

    if(id) {
      const state = getState();
      const oldUnreadCount = propOr(0, 'unread_count', messengerSelectors.getChannelById(state)(id));
      const totalUnreadCount = messengerSelectors.getTotalUnreadCount(state);
      const generalUnreadCount = messengerSelectors.getGeneralUnreadCount(state);
      const unreadCount = pathOr(0, ['payload', 'unread_count'], action)
      const countNew = totalUnreadCount - oldUnreadCount;
      const isTotalUnreadCountMoreThenNewUnreadCount = (totalUnreadCount >= oldUnreadCount);
      const isUnreadCountEqualsZero = R.pathOr(null,['payload', 'unread_count'], action) === 0;
      const isUpdateTotalUnreadCount = oldUnreadCount && isTotalUnreadCountMoreThenNewUnreadCount &&  isUnreadCountEqualsZero;

      if(isUpdateAfterDeleteMessage) {
        if (id === 1) {
          next(messengerActions.setGeneralUnreadCount({ count: generalUnreadCount - 1 }));
        } else {
          next(messengerActions.setTotalUnreadCount({ count: totalUnreadCount -1 }));
        }
      } else if (isUpdateTotalUnreadCount){
        if (id === 1) {
          next(messengerActions.setGeneralUnreadCount({ count: unreadCount }));
        } else {
          next(messengerActions.setTotalUnreadCount({ count: countNew}));
        }
      }
      if (id === 1) {
        next(messengerActions.setGeneralUnreadCount({ count: unreadCount }));
      } else {
        dispatch(messengerActions.setUnreadCountChannel(action.payload));
      }
    }
  } else {
    next(action);
  }
};


export const deleteMessageMiddleware = ({ getState, dispatch }) => next => (action) => {
  if (action.type === messengerTypes.DELETE_MESSAGE && action.payload.message) {
    const { payload: { message } } = action;
    const state = getState();
    const channel_id = path(['channel_id'], message);
    const ts = path(['ts'], message);
    const id = path(['id'], message);
    const { messages } = messengerSelectors.getMessages(state)(channel_id);
    const messagesList = messengerSelectors.getMessageList(state)(channel_id);
    const loadedCount = messengerSelectors.getCountLoadedMessages(state)(channel_id);
    const updatedEntities = R.omit([ts], messages);
    const updatedList = R.without([ts], messagesList);
    const decMessageIfDelete = Maybe.maybe(loadedCount, () => R.dec(loadedCount))(safeNotNil(id));
    dispatch(messengerActions.resetMessage({
      updatedEntities,
      updatedList,
      count: decMessageIfDelete,
      channel_id,
    }));
  } else {
    next(action);
  }
};

export const updateFocusedChatMiddleware = ({ getState, dispatch }) => next => (action) => {
  if (action.type === uiTypes.SET_IS_FOCUS) {
    // const { payload } = action;
    // const state = getState();
    // const activeChannel = getActiveChannel(state);
    // const currentWindowState = uiSelectors.getIsWindowFocus(state);
    //
    // const updatedCountDebounce = debounce((channelId) => {
    //   mapSafeChannel(R.compose(
    //     curriedNext(dispatch, messengerActions.setUnreadCount, { isUpdatingCount: true }),
    //     objWithChannelIdAndUnread(0),
    //   ))(channelId);
    // }, 4000);
    //
    // if (!currentWindowState && payload) {
    //   updatedCountDebounce(activeChannel);
    // }
    //todo: we need it ?

    next(action);
  } else {
    next(action);
  }
};

export const subscribeChannelMiddleware = ({ dispatch }) => next => (action) => {
  const { type } = action;
  if (type === messengerTypes.CONNECT_USER_TO_CHANNEL) {
    const { payload: { channelId } } = action;
    dispatch(webSocketHelpers.actions.subscribeChannel({ topic: `channel:${channelId}` }));
    dispatch(webSocketHelpers.actions.listenEvent({
      topic: `channel:${channelId}`,
      event: 'updateChannel',
      action: () => messengerActions.getChannelRequest(channelId, { isSetActive: false }),
    }));
    dispatch(webSocketHelpers.actions.listenEvent({
      topic: `channel:${channelId}`,
      event: 'deleteChannel',
      action: () => messengerActions.deleteChannel({ channelId }),
    }));
    dispatch(webSocketHelpers.actions.listenEvent({
      topic: 'common',
      event: 'newChannel',
      action: () => messengerActions.getGroupChannelsRequest(null,
        { isUpdate: true }),
    }));
    dispatch(webSocketHelpers.actions.listenEvent({
      topic: `channel:${channelId}`,
      event: 'pinnedMessage',
      action: messengerActions.setPinMessage,
    }));
    dispatch(webSocketHelpers.actions.listenEvent({
      topic: `channel:${channelId}`,
      event: 'unpinnedMessage',
      action: messengerActions.deletePinMessage,
    }));
    dispatch(webSocketHelpers.actions.listenEvent({
      topic: `channel:${channelId}`,
      event: 'updateMessage',
      action: messengerActions.emitUpdateMessage,
    }));
  } else {
    next(action);
  }
};

export const deleteChannelMiddleWare = ({ getState }) => next => (action) => {
  if (action.type === messengerTypes.DELETE_CHANNEL || action.type === messengerTypes.LEAVE_OF_CHANNEL) {
    const { payload: { message } } = action;
    const channelId = pathOr(['channel_id'], message);
    const state = getState();
    const isActiveChannel = R.equals(R.pathOr(false, ['messenger', 'activeChannel', 'id'], state), channelId);
    deleteChannel(channelId, state, isActiveChannel, next);
  } else {
    next(action);
  }
};


export const deleteMessageMiddleWare = ({ dispatch, getState }) => next => (action) => {
  if (action.type === messengerTypes.EMIT_DELETE_MESSAGE) {
      const { payload: { message, unread_count } } = action;
      const channel_id = path(['channel_id'], message);
      const ts = path(['ts'], message);
      const id = path(['id'], message);
      const channelType = messengerSelectors.getChannelById(getState())(channel_id);
      dispatch(messengerActions.deleteMessage({
        message: ({ ts, channel_id, id }) }));
      dispatch(messengerActions.resetPinMessage({ channelId: channel_id, id }));
      dispatch(messengerActions.setUnreadCount({ channel: { id: channel_id,
          type: prop('type', channelType) }, unread_count, isUpdateAfterDeleteMessage: true }));
  } else {
    next(action);
  }
};

export const deleteChannelSocketWatcher = ({ getState, dispatch }) => next => (action) => {
  if (action.type === messengerTypes.DELETE_CHANNEL_BY_SOCKET_WATCHER) {
    const { payload: { channel_id, joinedManagerId } } = action;
    const state = getState();
    const currentUser = userSelectors.getUserData(state);
    const currentUserId = currentUser && currentUser.id;
    const isActiveChannel = R.equals(R.pathOr(false, ['messenger', 'activeChannel', 'id'], state), channel_id);
    next(messengerActions.getGroupChannelsRequest({}));
    if (isActiveChannel && (currentUserId !== joinedManagerId)) {
      localStorage.removeItem(LAST_CHANNEL_MESSENGER_STORAGE);
      dispatch(push('/messenger/1/'))
    }
    deleteChannel(channel_id, state, isActiveChannel, next);
  } else {
    next(action);
  }
};

export const readMessagesByWsMiddleware = ({ dispatch }) => next => (action) => {
  if(action.type ===  messengerTypes.READ_MESSAGES_WS) {
    const { payload } = action;
    dispatch(messengerActions.setUnreadCount(payload));
  }
  next(action)
};

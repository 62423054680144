import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';
import { getValidationError } from '../../../../utils/helpers/formHelpers';

const ErrorNotification = ({ notification, t }) => (
  <>
    {
      notification && (
        <div className="notifications-auth">
          <svg xmlns="http://www.w3.org/2000/svg" className="notifications-auth__icon">
            <g fill="none" fillRule="evenodd">
              <path stroke="#F66F5B" strokeLinecap="round" strokeLinejoin="round" d="M21.565 11.826c.087 5.261-4.26 9.652-9.565 9.74-5.26.086-9.478-4.131-9.565-9.392-.087-5.261 4.26-9.652 9.565-9.74 5.26-.086 9.478 4.131 9.565 9.392zM12 13.304V7.217" />
              <path fill="#F66F5B" d="M12 15.478a.872.872 0 0 0-.87.87c0 .478.392.87.87.87s.87-.392.87-.87a.872.872 0 0 0-.87-.87z" />
            </g>
          </svg>
          <div className="notifications-auth__message"><b>{t(getValidationError(notification))}</b></div>
        </div>
      )
    }
  </>
);

ErrorNotification.propTypes = {
  notification: PropTypes.shape({}),
  t: PropTypes.func.isRequired,
};

ErrorNotification.defaultProps = {
  notification: null,
};

export default ErrorNotification;

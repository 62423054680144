import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'ramda';

import { ConfirmModal } from '../../../../../components/index';
import './style.sass';

const UnpinMessageModal = ({
  isOpen, onClose, message, onUnpinMessageHandler, isPending, t,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    isPending={isPending}
    actionTitle={t('chat:Unpin')}
    onAction={onUnpinMessageHandler}
    onClose={onClose}
    title={t('chat:Unpin message')}
  >

    {t('Are you sure you want to unpin')}
    <b className="message-short">{prop('content', message)}</b>

    {t('message?')}
  </ConfirmModal>
);

UnpinMessageModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onUnpinMessageHandler: PropTypes.func.isRequired,
  message: PropTypes.instanceOf(Object),
  isPending: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

UnpinMessageModal.defaultProps = {
  isPending: false,
  message: {},
  isOpen: false,
};

export default UnpinMessageModal;

import {
  compose, lifecycle,
} from 'recompose';
import { connect } from 'react-redux';
import { history } from '../../store';
import { PAYMENTS_STATUSES } from '../../constants/subscription';
import { subscriptionActions, subscriptionSelectors } from '../../state/subscription';
import { uiActions } from '../../state/ui';

const mapStateToProps = state => ({
  status: subscriptionSelectors.getSubscriptionStatus(state),
});

const mapDispatchToProps = {
  setPageError: uiActions.setErrorPage,
  getSubscriptionStatus: subscriptionActions.getStatusSubscriptionRequest,
};

const withSubscription = () => compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const {
        getSubscriptionStatus, setPageError, t,
      } = this.props;
      getSubscriptionStatus({}, {
        callbacks: {
          success: ({ status: { status } }) => {
            if (status !== PAYMENTS_STATUSES.PAYMENT_STATUS_UNSUBSCRIBED
            && status !== PAYMENTS_STATUSES.PAYMENT_STATUS_PAID
            && status !== PAYMENTS_STATUSES.PAYMENT_STATUS_FREE) {
              setPageError({
                code: 403,
                title: 'Sorry, but you you need to pay a subscription',
                subTitle: 'Go to the subscription page',
                href: '/preferences/subscription',
                label: 'Subscription page',
                deletePage404: () => history.push('/preferences/subscription'),
              });
            }
          },
        },
      });
    },
    componentDidUpdate(prev) {
      if (prev.status !== this.props.status) {
        const { status, t, setPageError } = this.props;
        if (status !== PAYMENTS_STATUSES.PAYMENT_STATUS_UNSUBSCRIBED
          && status !== PAYMENTS_STATUSES.PAYMENT_STATUS_PAID
          && status !== PAYMENTS_STATUSES.PAYMENT_STATUS_FREE) {
          setPageError({
            code: 403,
            title: 'Sorry, but you you need to pay a subscription',
            subTitle: 'Go to the subscription page',
            href: '/preferences/subscription',
            label: 'Subscription page',
            deletePage404: () => history.push('/preferences/subscription'),
          });
        }
      }
    },
  }),
  // branch(({ status }) => !status, renderNothing),
);

export default withSubscription;

import React from 'react';
import PropTypes from 'prop-types';
import { equals, path, isNil } from 'ramda';

import Option from './components';
import { ScrollWrapper } from '../../..';
import './style.sass';

const Select = ({
  optionsList,
  name,
  onSelectOption,
  onToggle,
  placeholder,
  isOpen,
  value,
  setRef,
  error,
  touched,
  t,
  title,
  isToTop,
  isRequired,
  disabled,
  className,
}) => (
  <div
    className={`select-field
     ${value ? 'select-field--is-fill' : ''}
     ${isOpen ? 'select-field--open' : ''}
     ${isToTop ? 'select-field--top' : ''}
     ${disabled ? 'select-field--disabled' : ''}
     ${className}
    `}
    ref={e => setRef('field', e)}
  >
    <div
      className="select-field__current-value"
      onClick={onToggle}
      onKeyPress={onToggle}
      tabIndex="0"
      role="button"
    >
      <div className="row text--cut">
        { !isNil(value) && t(`user:${path([value, 'label'], optionsList)}`)}
        { isNil(value) && <span className="select-field__placeholder">{placeholder}</span>}
      </div>
      <span className="select-field__current-value-icon icon-expand-arrow-right" />
    </div>
    {error.length !== 0 && touched && (
      <span className="field-group__error field__error-text">
        {t(error, { key: title })}
      </span>
    )}
    {
      (isOpen && !disabled) && (
        <div
          className="select-field__drop-down dropDown-select"
          ref={e => setRef('body', e)}
        >
          <ScrollWrapper
            className="dropDown-select__scroll-wrapper"
            autoHeight
            renderTrackVertical={({ style, ...props }) => (
              <div style={{ ...style }} {...props} className="dropDown-select__track" />
            )}
            renderThumbVertical={props => <div {...props} className="dropDown-select__thumb" />}
          >
            {
              (!isRequired && placeholder) && (
                <Option
                  key={`name-${name}-placeholder`}
                  isActive={equals(value, null)}
                  onSelectOption={onSelectOption}
                  innerText={placeholder}
                  value={null}
                />
              )
            }
            {

              optionsList ? optionsList.map(option => (
                <Option
                  key={`name ${option.value}`}
                  isActive={equals(value, option.value)}
                  onSelectOption={onSelectOption}
                  innerText={t(`user:${option.label}`)}
                  value={option.value}
                />
              )) : (
                <div className="row text-align--center">
                  <i className="select-field__empty">Empty list</i>
                </div>
              )
            }
          </ScrollWrapper>
        </div>
      )
    }
  </div>
);


Select.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  isToTop: PropTypes.bool.isRequired,
  optionsList: PropTypes.arrayOf(PropTypes.shape).isRequired,
  onSelectOption: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  placeholder: PropTypes.string,
  title: PropTypes.string,
  setRef: PropTypes.func.isRequired,
  touched: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  isRequired: PropTypes.bool,
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Select.defaultProps = {
  error: '',
  title: '',
  value: '',
  className: '',
  disabled: false,
  placeholder: '',
  isRequired: false,
  touched: false,
};

export default Select;

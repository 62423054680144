import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const ButtonIcons = ({
  className, title, children, onClick, disabled,
}) => (
  <button
    type="button"
    className={`button-icons ${className}`}
    title={title}
    aria-label={title}
    onClick={e => !disabled && onClick(e)}
  >
    {children}
  </button>
);

ButtonIcons.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  disabled: PropTypes.bool,
};

ButtonIcons.defaultProps = {
  className: '',
  onClick: () => {},
  children: '',
  disabled: false,
};
export default ButtonIcons;

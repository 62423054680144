import { compose, pure } from 'recompose';

import { withNamespaces } from 'react-i18next';
import ButtonClose from './buttonClose';

const enhance = compose(
  pure,
  withNamespaces('common'),
);
export default enhance(ButtonClose);

import React from 'react';
import PropTypes from 'prop-types';

import { ScrollWrapper } from '../../components';
import BotsGrid from './components/botsGrid';
import './style.sass';

const SettingsBotsContainer = ({ t }) => (
  <ScrollWrapper>
    <div className="bots-messengers__header">
      <h2 className="bots-messengers__title">{t('Connecting messengers')}</h2>
    </div>
    <BotsGrid />
  </ScrollWrapper>
);

SettingsBotsContainer.propTypes = {
  t: PropTypes.func.isRequired,
};

export default SettingsBotsContainer;

import { compose } from 'recompose';
import { withNamespaces } from 'react-i18next';

import Profile from './profile';
import { withSubscription } from '../../utils/enchancers';

const enhancer = compose(
  withSubscription(),
  withNamespaces(['common']),
);

export default enhancer(Profile);

import {
  compose, lifecycle, withState, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import {
  propOr, pathOr, values, prop,
} from 'ramda';
import { push } from 'connected-react-router';

import { withNamespaces } from 'react-i18next';
import { helpers } from '../../../../utils';
import MessengerContainer from './messengerContainer';
import { LAST_CHANNEL_MESSENGER_STORAGE, GENERAL_CHANNEL_ID } from '../../../../constants/messenger';
import { messengerActions, messengerSelectors } from '../../../../state/messenger';
import { userSelectors } from '../../../../state/user';
import { settingBotsSelectors } from '../../../../state/settingBots';

const { webSocketHelpers } = helpers;

const channelIdFromUrl = pathOr(false, ['params', 'id']);

const mapStateToProps = (state, props) => {
  const channelId = channelIdFromUrl(props.match);
  const userId = prop('id', userSelectors.getUserData(state));
  const activeChannel = messengerSelectors.getActiveChannelEntity(state);
  const channel = messengerSelectors.getChannelById(state)(activeChannel.id);

  // const clientId = propOr(null, 'client_id', channel);
  // const client = clientsSelectors.getClientById(state)(clientId);
  const bot = channel && settingBotsSelectors.getBotByServiceId(state)(channel.service_bot_id);
  const botName = bot && bot.title;

  return ({
    loadedChannels: messengerSelectors.getLoadedChannels(state),
    groupChannels: messengerSelectors.getGroupChannels(state),
    activeChannel,
    isUserOwnsChannel: messengerSelectors.getIsUserOwnsChannel(state)(channelId)(userId),
    botName,
    ownChannelId: userSelectors.getOwnChannelId(state),
  });
};

const mapDispatchToProps = ({
  push,
  getLatestMessagesRequest: messengerActions.getLatestMessagesRequest,
  getChannelRequest: messengerActions.getChannelRequest,
  getDirectChannelsRequest: messengerActions.getDirectChannelsRequest,
  getPinnedMessages: messengerActions.getPinnedMessagesRequest,
  connectUserToChannel: messengerActions.connectUserToChannel,
  setActiveChannel: messengerActions.setActiveChannel,
  unsubscribeChannel: webSocketHelpers.actions.unsubscribeChannel,
});


const setLastChannelIdToStorage = (channelId) => {
  const event = new CustomEvent('storageChange');
  window.localStorage.setItem(LAST_CHANNEL_MESSENGER_STORAGE, channelId);
  window.dispatchEvent(event);
};

const getChannelId = ({ groupChannels, match }) => {
  const channelId = pathOr(propOr(null,
    'id', values(groupChannels)[0]), ['params', 'id'], match);
  return channelId ? parseInt(channelId, 10) : channelId;
};


const redirectToChannel = (channelId, actionPush) => channelId && actionPush(`/messenger/${channelId}`);

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces('chat'),
  withProps(({ botName, activeChannel: { id } }) => ({
    botName: !id || id === 1 ? '' : (botName || 'Unknown bot'),
  })),
  withState('loading', 'setLoading', true),
  withState('isRenderMessenger', 'setIsRenderMessenger', false),
  withState('channelId', 'setChannelId', null),
  lifecycle({
    componentDidMount() {
      const {
        match,
        setChannelId,
        connectUserToChannel,
        getPinnedMessages,
        groupChannels,
        getChannelRequest,
        ownChannelId,
      } = this.props;
      const urlChannelId = channelIdFromUrl(match);
      const channelId = urlChannelId || prop('id', values(groupChannels)[0]);
      const channelIdLast = window.localStorage.getItem(LAST_CHANNEL_MESSENGER_STORAGE);
      if (!urlChannelId) {
        redirectToChannel(channelIdLast || prop('id', values(groupChannels)[0]), this.props.push);
      } else {
        getChannelRequest(channelId, {
          callbacks: {
            success: () => {
              // // check for general (1) and own (ownChannelId) channel
              if (![GENERAL_CHANNEL_ID, ownChannelId].includes(parseInt(channelId, 10))) {
                setLastChannelIdToStorage(channelId);
              }
            },
          },
          isSetActive: true,
        });

        getPinnedMessages({ channelId });
        setChannelId(channelId);
        connectUserToChannel({ channelId });
      }
    },
    componentDidUpdate(prevProps) {
      const prevParamsChannelId = getChannelId(prevProps);
      const paramsChannelId = getChannelId(this.props);
      const {
        setChannelId, connectUserToChannel, unsubscribeChannel,
        getChannelRequest, channelId, getPinnedMessages,
      } = this.props;
      if ((!prevParamsChannelId || prevParamsChannelId !== paramsChannelId)) {
        if (parseInt(paramsChannelId, 10) !== 1) setLastChannelIdToStorage(paramsChannelId);
        setChannelId(parseInt(paramsChannelId, 0));
        getPinnedMessages({ channelId });
        getChannelRequest(paramsChannelId);
        connectUserToChannel({ channelId: paramsChannelId });
        const unsubscribeChannelId = prevParamsChannelId;
        if (unsubscribeChannelId !== paramsChannelId) {
          unsubscribeChannel({ topic: `channel:${unsubscribeChannelId}` });
        }
      }
    },
  }),
);

export default enhancer(MessengerContainer);

import {
  compose, getContext, withHandlers, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { prop } from 'ramda';
import TariffsContainer from './tariffsContainer';
import { subscriptionActions, subscriptionSelectors } from '../../../../state/subscription';
import { openModal } from '../../../../state/ui/actions';

const mapStateToProps = state => ({
  subscriber: subscriptionSelectors.getSubscriberData(state),
  payment: subscriptionSelectors.getSubscriberPayment(state),
});

const mapDispatchToProps = ({
  getPaymentData: subscriptionActions.sendPaymentInfoRequest,
  getSubscriberData: subscriptionActions.getInfoAboutSubscriber,
  setOpenModal: openModal,
});

const onChangeTariffHandler = ({ subscriber, getPaymentData, setOpenModal }) => (tariff) => {
  getPaymentData({
    tariff,
    name: subscriber.name,
  },
  {
    callbacks: {
      success: () => setOpenModal('payment-modal'),
    },
  });
};

const onCancelSubscriptionHandler = ({ setOpenModal }) => () => {
  setOpenModal('cancelSubscriptionModal');
};

const onGoBackHandler = ({ setOpenPricesList }) => () => {
  setOpenPricesList(false);
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common']),
  getContext({
    setOpenPricesList: PropTypes.func.isRequired,
  }),
  withHandlers({
    onChangeTariff: onChangeTariffHandler,
    onCancelSubscription: onCancelSubscriptionHandler,
    onGoBack: onGoBackHandler,
  }),
  withProps(({
    subscriber,
  }) => ({
    tariff: prop('tariff', subscriber),
    paymentStatus: prop('payment_status', subscriber),
  })),
);

export default enhance(TariffsContainer);

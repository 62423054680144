import React from 'react';
import PropTypes from 'prop-types';

import ModalViewFb from '../modalViewFb';

const AddBotsModalFb = ({
  isOpen, onCloseModalHandler, handleChange, handleSubmit, props, values, errors, touched,
  setFieldValue,
}) => (
  <ModalViewFb
    isOpen={isOpen}
    onClose={onCloseModalHandler}
    modalName="addBotsModalFb"
    title="Add bot"
    handleChange={handleChange}
    handleSubmit={handleSubmit}
    values={values}
    errors={errors}
    touched={touched}
    setFieldValue={setFieldValue}
    {...props}
  />
);

AddBotsModalFb.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModalHandler: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  touched: PropTypes.instanceOf(Object).isRequired,
  values: PropTypes.shape({
    title: PropTypes.string,
    token: PropTypes.string,
    page_id: PropTypes.string,
    page_access_token: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
    token: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
    page_id: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
    page_access_token: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  }),
};

AddBotsModalFb.defaultProps = {
  errors: PropTypes.shape({
    title: '',
    token: '',
    page_id: '',
    page_token: '',
  }),
};

export default AddBotsModalFb;

import {
  compose, withHandlers, getContext, withProps,
} from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { includes } from 'ramda';

import { withNamespaces } from 'react-i18next';
import PinnedItems from './pinnedItems';
import { messengerSelectors, messengerActions } from '../../../../../../state/messenger';
import { uiActions } from '../../../../../../state/ui';
import { withUserProfile, withWindowWidth } from '../../../../../../utils/enchancers';
import { userSelectors } from '../../../../../../state/user';
import { DIRECT_CHANNELS, GENERAL_CHANNEL } from '../../../../../../constants/messenger';

const { getPinnedMessages, getPinnedMessagesId } = messengerSelectors;
const { deletePinnedMessageRequest } = messengerActions;

const mapStateToProps = (state) => {
  const channelId = messengerSelectors.getActiveChannel(state);
  return {
    pinnedIds: getPinnedMessagesId(state)(channelId.id),
    pinnedMessages: getPinnedMessages(state)(channelId.id),
    channelId: channelId.id,
    channel: messengerSelectors.getChannelById(state)(channelId.id),
    user: userSelectors.getUserData(state),
  };
};

const mapDispatchToProps = ({
  unpinMessage: deletePinnedMessageRequest,
  setOpenModal: uiActions.openModal,
  closeRightSideBar: () => uiActions.changeRightSidebarStatus(false),
  setScrollToMessage: messengerActions.setScrollToMessage,
});

const onUnpinMessageHandler = ({ setSelectedMessageGlobal, setOpenModal }) => (message) => {
  setOpenModal('unpinMessageModal');
  setSelectedMessageGlobal(message);
};
const onShowUserProfileHandler = ({
  onSetUserProfile,
}) => onSetUserProfile;


const enhance = compose(
  getContext({
    setSelectedMessageGlobal: PropTypes.func,
  }),
  withUserProfile,
  withWindowWidth(),
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common', 'chat']),
  withProps(({ channel, user }) => ({
    isChannelOwner: channel.created_by === user.id
      || includes(channel.type, [...DIRECT_CHANNELS, GENERAL_CHANNEL]),
  })),
  withHandlers({
    onUnpinMessage: onUnpinMessageHandler,
    onShowUserProfile: onShowUserProfileHandler,
  }),
);
export default enhance(PinnedItems);

import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import ConfirmPaymentModal from './confirmPaymentModal';
import { subscriptionSelectors, subscriptionActions } from '../../../../state/subscription';
import { getModal } from '../../../../state/ui/selectors';
import { closeModal, openModal } from '../../../../state/ui/actions';

const onCloseModalHandler = ({ clearPayment, onCloseModal }) => () => {
  onCloseModal();
  clearPayment();
};

const mapStateToProps = state => ({
  payment: subscriptionSelectors.getSubscriberPayment(state),
  isOpen: getModal(state)('payment-modal'),
});

const mapDispatchToProps = {
  onCloseModal: () => closeModal('payment-modal'),
  setOpenModal: openModal,
  clearPayment: subscriptionActions.clearPaymentData,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common']),
  withHandlers({
    onClose: onCloseModalHandler,
  }),
);

export default enhance(ConfirmPaymentModal);

import telegramIcon from '../assets/headerAssets/telegram_icon.svg';
import viberIcon from '../assets/headerAssets/viber_icon.svg';
import facebookIcon from '../assets/headerAssets/facebook_icon.svg';

export const MESSENGER_TYPES = {
  TELEGRAM: 1,
  VIBER: 2,
  FB: 3,
};

export const MESSENGER_DETAIL = {
  [MESSENGER_TYPES.TELEGRAM]: {
    id: 1,
    nameSocial: 'Telegram',
    pathImage: telegramIcon,
  },
  [MESSENGER_TYPES.VIBER]: {
    id: 2,
    nameSocial: 'Viber',
    pathImage: viberIcon,
  },
  [MESSENGER_TYPES.FB]: {
    id: 3,
    nameSocial: 'Facebook',
    pathImage: facebookIcon,
  },
};

import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';
import { Label } from '../index';

const TabWrapper = ({
  title, children, changeStatusTabStateHandler, statusTab,
  customTitle, visible, isBotGroup, unreadCount, t,
}) => (
  visible
    ? (
      <div
        className={`tab-wrapper${statusTab ? ' tab-wrapper--open' : ''}`}
      >
        <div
          className="tab-wrapper__header"
          onClick={() => changeStatusTabStateHandler()}
          tabIndex="0"
          role="button"
          onKeyPress={() => changeStatusTabStateHandler()}
        >
          {title}
          <>
            {customTitle()}
          </>
          <button type="submit" title={`${statusTab ? t('Hide') : t('Show')} ${typeof title === 'string' ? title : t('common:Content')}`} className="tab-wrapper__toggle-button">
            <span className="icon-expand-arrow-dn" />
          </button>
          {unreadCount && isBotGroup ? (<Label className="label--xs label--fill">{unreadCount}</Label>) : ''}
        </div>
        <div className="tab-wrapper__body">
          {children}
        </div>
      </div>
    )
    : <></>
);

TabWrapper.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  customTitle: PropTypes.func,
  visible: PropTypes.bool,
  children: PropTypes.oneOfType(
    [PropTypes.element,
      PropTypes.string,
      PropTypes.array],
  ),
  changeStatusTabStateHandler: PropTypes.func.isRequired,
  statusTab: PropTypes.bool,
  isBotGroup: PropTypes.bool,
  unreadCount: PropTypes.number,
  t: PropTypes.func.isRequired,
};

TabWrapper.defaultProps = {
  customTitle: () => {},
  visible: true,
  statusTab: false,
  children: <></>,
  isBotGroup: false,
  unreadCount: 0,
};

export default TabWrapper;

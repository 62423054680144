import {
  branch, compose, renderNothing, withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { withRouter } from 'react-router';
import { prop } from 'ramda';
import { getModal } from '../../../../../state/ui/selectors';
import { closeModal } from '../../../../../state/ui/actions';
import PinMessageModal from './pinMessageModal';
import { messengerActions, messengerSelectors } from '../../../../../state/messenger/index';

const onPinMessage = ({
  addPinMessage, messageId, channel, onClose,
}) => () => {
  addPinMessage({ message_id: messageId.id, channelId: channel.id });
  onClose();
};

const mapStateToProps = (state, { messageId, match: { params: { id } } }) => ({
  isOpen: getModal(state)('pinMessageModal'),
  channel: messengerSelectors.getActiveChannel(state),
  message: messengerSelectors.getMessage(state)(prop('ts', messageId), id),
});

const mapDispatchToProps = ({
  onClose: () => closeModal('pinMessageModal'),
  addPinMessage: messengerActions.addPinMessageRequest,
});

const enhance = compose(
  withRouter,
  branch(({ messageId }) => !messageId, renderNothing),
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common', 'chat']),
  withHandlers({
    onPinMessageHandler: onPinMessage,
  }),
);
export default enhance(PinMessageModal);

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { path } from 'ramda';


import {
  ButtonsCollection,
  FormsCollection, Layouts,
} from '../../../components';
import NotificationsForAuthForm from '../components/errorNotification';

import './style.sass';

const SignIn = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,
  authNotification,
  t,
  pending,
}) => (
  <Layouts.Auth>
    <NotificationsForAuthForm />
    <div className="auth-wrapper__wrap">
      <FormsCollection.FormWrapper handleSubmit={handleSubmit}>
        <div className="auth-wrapper__form-contain">
          <h2 className="title weight--bold title--md text-align--center">
            {t('Sign in to Smartex')}
          </h2>
          <FormsCollection.Input
            onChange={handleChange}
            onBlur={(e) => { handleBlur(e); }}
            type="email"
            name="username"
            id="sign-in__username"
            touched={touched.username}
            value={values.username}
            error={!!path(['username'], authNotification)}
            placeholderJump={t('Email address')}
          />
          <FormsCollection.Input
            onChange={handleChange}
            onBlur={(e) => { handleBlur(e); }}
            value={values.password}
            touched={touched.password}
            error={!!path(['password'], authNotification)}
            type="password"
            name="password"
            id="sign-in__password"
            placeholderJump={t('Password')}
          />
          <div className="button-group">
            <ButtonsCollection.ButtonBrill
              className="auth-wrapper__button--with-border button--fill"
              borderColor="#26b5ed"
              pending={pending}
              type="submit"
              borderWidth="2"
            >
              {t('Sign In')}
            </ButtonsCollection.ButtonBrill>
          </div>
          <div className="auth-wrapper__nav-group">
            <nav className="auth-wrapper__nav">
              <ul className="auth-wrapper__list">
                <li className="auth-wrapper__item">
                  <Link to="/forgot-password" className="auth-wrapper__link">
                    {t('Forgot password')}
                    ?
                  </Link>
                </li>
                {/* <li className="auth-wrapper__item"> */}
                {/*  <Link to="/sign-up"
                 className="auth-wrapper__link">{t('Don’t have an Account?')}</Link> */}
                {/* </li> */}
              </ul>
            </nav>
          </div>
        </div>
      </FormsCollection.FormWrapper>
    </div>
  </Layouts.Auth>
);

SignIn.propTypes = {
  pending: PropTypes.bool,
  values: PropTypes.shape({
    username: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  touched: PropTypes.shape({
    username: PropTypes.bool,
    password: PropTypes.bool,
  }).isRequired,
  authNotification: PropTypes.shape({
    username: PropTypes.object,
    password: PropTypes.object,
  }),
};

SignIn.defaultProps = {
  authNotification: {},
  pending: false,
};

export default SignIn;

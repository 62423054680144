/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

import { Emoji } from 'emoji-mart';

const CategoryItem = ({ category, scrollToCategoryByElement }) => (
  <li className="emoji__picker-category-item">
    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
    <span
      // href={`#emoji__category-${category.id}`}
      onClick={() => scrollToCategoryByElement(document.querySelector(`#emoji__category-${category.id}`))}
      className="emoji__picker-category-button"
      title={category.name}
    >
      <Emoji
        emoji={category.emojis[0]}
        size={24}
      />
    </span>
  </li>
);

CategoryItem.propTypes = {
  category: PropTypes.instanceOf(Object).isRequired,
  scrollToCategoryByElement: PropTypes.func.isRequired,
};

export default CategoryItem;

import {
  compose, lifecycle, withStateHandlers, hoistStatics, defaultProps,
} from 'recompose';
import { withNamespaces } from 'react-i18next';
import withRefs from '../../../../utils/enchancers/withRefs';
import { debounceFunc } from '../../../../utils/helpers/commonHelpers';
import Search from './search';

const enhance = compose(
  withNamespaces('common'),
  withRefs(),
  defaultProps({
    onSearch: () => {},
  }),
  withStateHandlers(() => ({ inFocus: false }), {
    handlerInFocus: () => val => ({
      inFocus: val,
    }),
  }),
  lifecycle({
    componentDidMount() {
      const { onSearch, handlerInFocus } = this.props;
      const el = this.props.getRef('input');
      el.addEventListener('focus', () => {
        handlerInFocus(true);
      });
      el.addEventListener('blur', () => {
        handlerInFocus(false);
      });
      // eslint-disable-next-line prefer-arrow-callback
      const onSearchDebounced = element => debounceFunc(function onSearchCallBack() {
        onSearch(element);
      }, 500, false, 'onSearchCallBack');
      el.addEventListener('keydown', onSearchDebounced);
    },
  }),
);
export default hoistStatics(enhance)(Search);

import React, { memo } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  Messenger, PageError,
  Profile,
  Settings,
  Clients,
  Guide,
} from '../../../pages';
import { preloaderWhileLoading } from '../../../utils/enchancers';
import checkIsUserValid from '../../../utils/enchancers/checkIsUserValid';
import { userActions, userSelectors } from '../../../state/user';
import { uiActions } from '../../../state/ui';
import withDocumentVisibleChange from '../../../utils/enchancers/withDocumentVisibleChange';
import { CHANNELS_ROUTES } from '../../../constants/ui';


const mapDispatchToProps = ({
  checkCurrentUserRequest: userActions.checkCurrentUserRequest,
  setIsFocus: uiActions.setIsFocus,
});

const mapStateToProps = state => ({
  bootData: userSelectors.getUserData(state),
});

const Routes = ({ location }) => (
  <Switch>
    <Route
      exact
      strict
      path="/:url*"
      render={() => location.pathname.slice(-1) !== '/' && <Redirect to={`${location.pathname}/`} />}
    />
    <Route exact path="/">
      <Redirect to="/messenger/1/" />
    </Route>
    <Route exact path="/profile/" component={Profile} />
    <Route exact path="/messenger/:id?/" component={Messenger} />
    <Route exact path={CHANNELS_ROUTES.CHANNELS} component={Messenger} />
    <Route exact path={CHANNELS_ROUTES.DIRECTS} component={Messenger} />
    <Route exact path="/clients/" component={Clients} />
    <Route path="/guide/" component={Guide} />
    <Route path="/preferences/" component={Settings} />
    <Route exact component={PageError} />
  </Switch>
);

Routes.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  checkIsUserValid('checkCurrentUserRequest'),
  preloaderWhileLoading({
    delay: 600,
    fullScreen: true,
    isLoading: props => !props.bootData,
  }),
  withDocumentVisibleChange,
  memo,
);

export default enhance(Routes);

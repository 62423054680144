import React from 'react';

import PropTypes from 'prop-types';
import { ButtonBrill } from '../../../../components/buttonsCollection';

import './style.sass';

const BotsItem = ({
  title, token, editModalHandler, deleteModalHandler, pageId, pageAccessToken, messengerId, t,
}) => (
  <li className="bots-item__wrapper">
    {messengerId === 3
      ? (
        <div className="bots-item__info">
          <span className="bots-item__title text--cut text--breaker"><span className="bots-item__title--red"> {t('Title')}:</span> {' '}{title}</span>
          <span className="bots-item__token text--cut text--breaker"><span className="bots-item__title--red"> {t('Token')}:</span> {' '}{token}</span>
          <span className="bots-item__page-id text--cut text--breaker"><span className="bots-item__title--red"> {t('Page id')}:</span> {' '}{pageId}</span>
          <span className="bots-item__page-access-token text--cut text--breaker"><span className="bots-item__title--red">{t('Page access token')}:</span>{' '}{pageAccessToken}</span>
        </div>

      ) : (
        <div className="bots-item__info">
          <span className="bots-item__title text--cut text--breaker"><span className="bots-item__title--red"> {t('Title')}:</span> {' '}{title}</span>
          <span className="bots-item__token text--cut text--breaker"><span className="bots-item__title--red"> {t('Token')}:</span> {' '}{token}</span>
        </div>
      )}
    <div className="bots-item__buttons">
      <ButtonBrill className="bots-item__btn bots-item__btn--edit button--sm" onClick={editModalHandler} title={t('EDIT')}>{t('EDIT')}</ButtonBrill>
      <ButtonBrill className="bots-item__btn bots-item__btn--delete button--sm" onClick={deleteModalHandler} title={t('DELETE')}>{t('DELETE')}</ButtonBrill>
    </div>
  </li>
);
BotsItem.propTypes = {
  title: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  pageId: PropTypes.string,
  pageAccessToken: PropTypes.string,
  editModalHandler: PropTypes.func.isRequired,
  deleteModalHandler: PropTypes.func.isRequired,
  messengerId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

BotsItem.defaultProps = {
  pageId: '',
  pageAccessToken: '',
};


export default BotsItem;

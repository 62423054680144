import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmModal } from '../../../../components';
import './style.sass';


const CancelSubscriptionModal = ({
  isOpen, t, isPending, onCancelSubscription, onClose,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    isPending={isPending}
    actionTitle={t('Cancel')}
    onAction={onCancelSubscription}
    onClose={onClose}
    title={t('Cancel subscription')}
  >

    {t('Are you sure, you want to cancel subscription?')}
  </ConfirmModal>
);

CancelSubscriptionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  isPending: PropTypes.bool,
  onCancelSubscription: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

CancelSubscriptionModal.defaultProps = {
  isPending: false,
};

export default CancelSubscriptionModal;

import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const Preloader = ({
  fullScreen,
  alignContainerCenter,
  setRef,
  dimension,
  className,
}) => (
  <div
    style={{ fontSize: dimension }}
    ref={e => setRef('preloader', e)}
    className={`preloader ${className} ${fullScreen ? 'preloader--full-screen' : ''} ${alignContainerCenter ? 'preloader--container-center' : ''}`}
  >
    <div className="preloader__element">
      <div className="preloader__element-pseudo-wrap">
        <canvas className="preloader__canvas" ref={e => setRef('preloaderCanvas', e)} />
      </div>
      <div className="preloader__element-pseudo-wrap-center" ref={e => setRef('preloaderMiddlePart', e)}>
        <canvas className="preloader__canvas-center" ref={e => setRef('preloaderCanvasCentral', e)} />
      </div>
    </div>
  </div>
);

Preloader.propTypes = {
  fullScreen: PropTypes.bool,
  setRef: PropTypes.func.isRequired,
  dimension: PropTypes.number,
  alignContainerCenter: PropTypes.bool,
  className: PropTypes.string,
};

Preloader.defaultProps = {
  fullScreen: false,
  alignContainerCenter: false,
  dimension: 200,
  className: '',
};

export default Preloader;

import { compose } from 'recompose';
import { withNamespaces } from 'react-i18next';
import BotsWrapper from './botsWrapper';
import { withSubscription } from '../../../../utils/enchancers';

const enhance = compose(
  withSubscription(),
  withNamespaces(['common', 'bots']),
);

export default enhance(BotsWrapper);

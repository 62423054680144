import { compose } from 'recompose';
import React from 'react';
import { connect } from 'react-redux';
import { T, identity, is } from 'ramda';

const mapDispatchToProps = ({
  name, action, dataPath, params, searchField = 'q', appendOption, meta,
  appendOptionWhen, filterFunc,
}) => (dispatch, ownProps) => ({
  [name]: (inputValue, callback) => dispatch(action({
    [searchField]: inputValue,
    limit: 10,
    ...(params && params(ownProps)),
  }, {
    ...meta,
    callbacks: {
      success: (data) => {
        const appendOptions = (is(Function, appendOption) && appendOptionWhen(ownProps))
          ? [appendOption(ownProps)] : [];
        const filterFuncResult = filterFunc ? filterFunc(ownProps) : identity;
        callback(filterFuncResult([...appendOptions, ...dataPath(data)]));
      },
    },
  })),
});

const WithAutocomplete = ({
  name, action, dataPath, params, searchField, meta = null, appendOption,
  appendOptionWhen = T, filterFunc,
}) => (BaseComponent) => {
  const WrapperComponent = props => <BaseComponent {...props} />;
  const enhancer = compose(
    connect(null, mapDispatchToProps({
      name, action, dataPath, params, searchField, meta, appendOption, appendOptionWhen, filterFunc,
    })),
  );
  return enhancer(WrapperComponent);
};

export default WithAutocomplete;

import {
  values, mapObjIndexed, curry, pathOr, is,
} from 'ramda';

import { t } from './translateHelpers';

const getValidationError = (errors) => {
  try {
    const { message, params } = values(errors)[0];
    if (params) {
      const key = t('common', `${params.key}`);
      return t('validation', message, { key });
    }
    return t('validation', message, { ...params });
  } catch (e) {
    return '';
  }
};

const makeFormData = (data) => {
  const formData = new FormData();
  mapObjIndexed((value, key) => formData.append(key, value), data);
  return formData;
};

const getFile = e => e.currentTarget.files[0];

const getErrorsForField = curry((errors,
  fieldName) => {
  const messageBack = pathOr('', [fieldName, 'message'], errors);
  const messageFront = is(String, errors[fieldName])
    ? errors[fieldName]
    : '';
  return messageBack ? `${fieldName} ${messageBack}` : messageFront;
});

const convertNullIntoEmptyString = (obj) => {
  const newObj = { ...obj };
  if (obj) {
    // eslint-disable-next-line no-restricted-syntax
    for (const prop of Object.keys(newObj)) {
      if (newObj[prop] === null) newObj[prop] = '';
    }
  }
  return newObj;
};

export {
  getValidationError,
  makeFormData,
  getErrorsForField,
  getFile,
  convertNullIntoEmptyString,
};

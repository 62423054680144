import React from 'react';
import PropTypes from 'prop-types';
import { identity } from 'ramda';

import MessageTextArea from '../../containers/chat/components/messageInput';
import MessageButtons from '../messageButtons';
import EmojiPicker from '../messageTextArea/emojiPicker';
import EmojiAutoComplete
  from '../messageTextArea/emojiAutoComplete/emojiAutoComplete';
import { ReplayedMessage } from '../../containers/chat/components';
// import { isMobile } from '../../utils/helpers/commonHelpers';
import ButtonIcons from '../buttonsCollection/buttonIcons/buttonIcons';
import { translateHelpers } from '../../utils/helpers';

const TypeMessage = ({
  messageContent,
  onTextareaKeyDown,
  onChangeContent,
  onSetEmoji,
  onOpenEmoji,
  onCloseEmoji,
  isEmojiOpen,
  onClickSaveContent,
  className,
  setRef,
  discardButton,
  sendLabel,
  onDiscard,
  onTextAreaFocus,
  emojisAutocomplete,
  isEmojiAutocompleteOpen,
  onOpenEmojiAutocompleteOpen,
  onOpenEmojiAutocompleteClose,
  emojiSelected,
  emojiTyping,
  onSetFocusField,
  onSetEmojiByAutocomplete,
  onSetRefWrapTextArea,
  onRenderContent,
  replyMessage,
  onClickMention,
  onCloseReplyMessage,
  isWsError,
  onSetInputRef,
  filesListIds,
  resetFilesIdList,
}) => (
  <>
    {
      replyMessage && (
        <ReplayedMessage
          allowClose
          onClose={onCloseReplyMessage}
          content={onRenderContent(replyMessage.content, onClickMention, replyMessage.id)}
        />
      )
    }
    <div className="message-textArea__row" ref={onSetRefWrapTextArea}>
      <ul className="message-textArea__files-list">
        {
          filesListIds.map(file => (
            <li className="message-textArea__files-item" key={`file-for-send-${file.id}`}>
              <span className="icon-attach-file message-card__files-icon" />
              <span className="message-textArea__files-name">{file.original}</span>
              <ButtonIcons title="Remove file" onClick={() => resetFilesIdList([file.id])}><span className="icon-close" /></ButtonIcons>
            </li>
          ))
        }
      </ul>
      <EmojiAutoComplete
        onChange={onChangeContent}
        set="messenger"
        emojisAutocomplete={emojisAutocomplete}
        emojiSelected={emojiSelected}
        onSetEmoji={onSetEmoji}
        emojiTyping={emojiTyping}
        isOpen={isEmojiAutocompleteOpen}
        onSetEmojiByAutocomplete={onSetEmojiByAutocomplete}
        isEmojiPickerOpen={isEmojiOpen}
        title={isWsError ? 'Socket connection was broken' : ''}
        onOpen={onOpenEmojiAutocompleteOpen}
        onClose={onOpenEmojiAutocompleteClose}
      />
      <div className="message-textArea__col message-textArea__col--fill">
        <MessageTextArea
          value={messageContent}
          onKeyDown={onTextareaKeyDown}
          onFocusCallback={onTextAreaFocus}
          childrenRef={(ref) => { onSetInputRef(ref); setRef('typeMessage', ref); }}
          onChange={onChangeContent}
          placeholder="Type a message"
          className={className}
        />
      </div>
      <div className="message-textArea__col">
            <>
              <EmojiPicker
                data-emoji="data-emoji"
                onSetFocusField={onSetFocusField}
                onOpen={onOpenEmoji}
                onClose={onCloseEmoji}
                isOpen={isEmojiOpen}
                onSetEmoji={onSetEmoji}
              />
            </>
        <MessageButtons
          isDisabled={isWsError}
          title={isWsError ? 'Socket connection was broken' : ''}
          onClickSaveContent={onClickSaveContent}
          discardButton={discardButton}
          sendLabel={sendLabel}
          onClickDiscardButton={onDiscard}
        />
      </div>
    </div>
  </>
);

TypeMessage.propTypes = {
  onTextareaKeyDown: PropTypes.func.isRequired,
  onChangeContent: PropTypes.func.isRequired,
  onTextAreaFocus: PropTypes.func,
  onSetInputRef: PropTypes.func,
  onClickSaveContent: PropTypes.func.isRequired,
  onSetEmoji: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired,
  onOpenEmoji: PropTypes.func.isRequired,
  onCloseEmoji: PropTypes.func.isRequired,
  isEmojiOpen: PropTypes.bool.isRequired,
  isDropdownMentionsOpen: PropTypes.bool.isRequired,
  mentions: PropTypes.arrayOf(PropTypes.object).isRequired,
  messageContent: PropTypes.string,
  className: PropTypes.string.isRequired,
  discardButton: PropTypes.bool,
  onDiscard: PropTypes.func,
  sendLabel: PropTypes.string,
  emojisAutocomplete: PropTypes.instanceOf(Object).isRequired,
  isEmojiAutocompleteOpen: PropTypes.bool,
  onOpenEmojiAutocompleteOpen: PropTypes.func,
  onOpenEmojiAutocompleteClose: PropTypes.func,
  emojiSelected: PropTypes.number.isRequired,
  emojiTyping: PropTypes.string.isRequired,
  onSetFocusField: PropTypes.func.isRequired,
  onSetEmojiByAutocomplete: PropTypes.func.isRequired,
  onSetRefWrapTextArea: PropTypes.func.isRequired,
  onRenderContent: PropTypes.func.isRequired,
  onClickMention: PropTypes.func.isRequired,
  onCloseReplyMessage: PropTypes.func.isRequired,
  isBeginMention: PropTypes.bool.isRequired,
  isMouseOverMention: PropTypes.bool.isRequired,
  activeDropdownMember: PropTypes.number.isRequired,
  replyMessage: PropTypes.oneOfType([PropTypes.instanceOf(Object), () => null]),
  isWsError: PropTypes.bool.isRequired,
  onOpenUploadFilesModal: PropTypes.func.isRequired,
  filesListIds: PropTypes.instanceOf(Array).isRequired,
  resetFilesIdList: PropTypes.func.isRequired,
  isFilesUploadingFunctionality: PropTypes.bool,
  options: PropTypes.instanceOf(Object),
};

TypeMessage.defaultProps = {
  discardButton: false,
  sendLabel: translateHelpers.t('chat', 'Send'),
  isFilesUploadingFunctionality: true,
  options: { isFilesUploadingFunctionality: true },
  messageContent: '',
  isEmojiAutocompleteOpen: false,
  onOpenEmojiAutocompleteOpen: identity,
  onSetInputRef: identity,
  onTextAreaFocus: () => {},
  onOpenEmojiAutocompleteClose: identity,
  replyMessage: null,
};

export default TypeMessage;

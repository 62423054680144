import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';
import { Link } from 'react-router-dom';

const PlusButton = ({
  onClick, className, onMouseOver, onMouseLeave, title, asLink, href,
}) => (
  <>
    {
      asLink ? (
        <Link
          onMouseLeave={onMouseLeave}
          onMouseOver={onMouseOver}
          title={title}
          to={href}
          onClick={onClick}
          onFocus={() => {}}
          className={`plus-button ${className}`}
        >
          <span className="plus-button__hexagon icon-hexagon-shape" />
          <span className="plus-button__plus icon-plus-icon-2" />
        </Link>
      ) : (
        <button
          onMouseLeave={onMouseLeave}
          onMouseOver={onMouseOver}
          type="button"
          title={title}
          onClick={onClick}
          onFocus={() => {}}
          className={`plus-button ${className}`}
        >
          <span className="plus-button__hexagon icon-hexagon-shape" />
          <span className="plus-button__plus icon-plus-icon-2" />
        </button>
      )
    }
  </>
);

PlusButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  onMouseLeave: PropTypes.func,
  onMouseOver: PropTypes.func,
  title: PropTypes.string,
  asLink: PropTypes.bool,
  href: PropTypes.string,

};

PlusButton.defaultProps = {
  onClick: () => {},
  onMouseLeave: () => {},
  onMouseOver: () => {},
  className: '',
  href: '',
  asLink: false,
  title: '',
};
export default PlusButton;

import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import DeleteAccountModal from './deleteAccountModal';
import { subscriptionSelectors, subscriptionActions } from '../../../../state/subscription';
import { uiActions, uiSelectors } from '../../../../state/ui';

const mapStateToProps = state => ({
  subscriber: subscriptionSelectors.getSubscriberData(state),
  isOpen: uiSelectors.getModal(state)('deleteAccountModal'),
  isPending: subscriptionSelectors.deleteAccountPending(state),
});

const mapDispatchToProps = {
  deleteAccount: subscriptionActions.deleteAccountRequest,
  onCloseModal: () => uiActions.closeModal('deleteAccountModal'),
};

const onDeleteAccountHandler = ({
  deleteAccount,
  subscriber,
  onCloseModal,
}) => () => {
  deleteAccount({
    name: subscriber.name,
  }, {
    callbacks: {
      error: () => window.location.reload(true),
    },
  });
  onCloseModal();
};

const onCloseModalHandler = ({ onCloseModal }) => () => {
  onCloseModal();
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common']),
  withHandlers({
    onDeleteAccount: onDeleteAccountHandler,
    onClose: onCloseModalHandler,
  }),
);

export default enhance(DeleteAccountModal);

import React from 'react';
import PropTypes from 'prop-types';

import { ScrollWrapper } from '../../components';
import './style.sass';
import { UsersList, DeleteUserModal } from './components';

const SettingUsersContainer = ({
  gridName, pagination, onSortBy, isPending, usersList, usersCount, usersEntities,
  sort, order, onDeleteUser, selectedUser, usersHasMore,
}) => (
  <ScrollWrapper>
    <div className="settingUsers">
      <div className="row settingUsers__search-row" />
      <UsersList
        gridName={gridName}
        pagination={pagination}
        onSortBy={onSortBy}
        sort={sort}
        order={order}
        isLoading={isPending}
        usersList={usersList}
        usersHasMore={usersHasMore}
        usersCount={usersCount}
        usersEntities={usersEntities}
        onDeleteUser={onDeleteUser}
      />
    </div>
    <DeleteUserModal selectedUser={selectedUser} pagination={pagination} />
  </ScrollWrapper>
);

SettingUsersContainer.propTypes = {
  gridName: PropTypes.string.isRequired,
  pagination: PropTypes.instanceOf(Object).isRequired,
  onSortBy: PropTypes.func.isRequired,
  isPending: PropTypes.bool.isRequired,
  usersList: PropTypes.instanceOf(Array).isRequired,
  usersCount: PropTypes.number.isRequired,
  usersEntities: PropTypes.instanceOf(Object).isRequired,
  sort: PropTypes.string,
  order: PropTypes.string,
  onDeleteUser: PropTypes.func.isRequired,
  selectedUser: PropTypes.instanceOf(Object).isRequired,
  usersHasMore: PropTypes.bool.isRequired,
};

SettingUsersContainer.defaultProps = {
  sort: '',
  order: '',
};

export default SettingUsersContainer;

import { compose, withProps, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { propOr, prop } from 'ramda';

import { uiActions, uiSelectors } from '../../state/ui';
import PageError from './pageError';
import { userSelectors } from '../../state/user';

const mapDispatchToProps = ({
  goBackFromErrorPage: uiActions.goBackFromErrorPage,
  setErrorPage: uiActions.setErrorPage,
});

const mapStateToProps = state => ({
  errorPage: uiSelectors.getIsPageNotFoundStatus(state),
  user: userSelectors.getUserData(state),
});

const deletePage404Handler = ({ setErrorPage, goBackFromErrorPage, href }) => () => {
  setErrorPage(false);
  goBackFromErrorPage(href);
};

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common']),
  withProps(({ errorPage }) => ({
    label: prop('label', errorPage) || 'Go back',
    href: prop('href', errorPage) || '/',
    code: propOr(404, 'code', errorPage),
    title: propOr('Oops, seems like you got lost', 'title', errorPage),
    subTitle: propOr('', 'subTitle', errorPage),
  })),
  withHandlers({
    deletePage404: deletePage404Handler,
  }),
);

export default enhancer(PageError);

import React from 'react';

import PropTypes from 'prop-types';
import { ModalWindow } from '../../../components';

import './style.sass';

const ImageModal = ({
  isOpen,
  onCloseModalHandler,
  image,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={onCloseModalHandler}
    modalName="imageModal"
    title="Image"
    className="guide__modal"
  >
    <img src={image} alt="modal_image" className="guide__modal-image" />
  </ModalWindow>
);

ImageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModalHandler: PropTypes.func.isRequired,
  image: PropTypes.string,
};

ImageModal.defaultProps = {
  image: null,
};

export default ImageModal;

import * as yup from 'yup';

const rules = yup.object().shape({
  title: yup
    .string()
    .min(5, {
      field: 'title',
      message: 'Title is too short',
    })
    .required({
      field: 'title',
      message: 'Title is required',
    }),
  token: yup
    .string()
    .required({
      field: 'token',
      message: 'Webhook token is required',
    }),
  page_id: yup
    .string()
    .required({
      field: 'page_id',
      message: 'Page id is required',
    }),
  page_access_token: yup
    .string()
    .required({
      field: 'page_access_token',
      message: 'Page token is required',
    }),
});

export default rules;

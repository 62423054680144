import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ChannelItem, CreateChannel } from './components';
import './style.sass';
import AttachFilesModal from '../attachFilesModal';

const GroupChannels = ({
  groupChannelsList,
  t,
  onCloseLeftSideBarAfterClick,
}) => (
  <div className="chanel-nav-group">
    <div className="chanel-nav-group__header">
      <NavLink
        to="/messenger/channels/"
        className="chanel-nav-group__title"
        onClick={onCloseLeftSideBarAfterClick}
      >
        {t('chat:New clients')}
      </NavLink>
    </div>
    <nav className="chanel-nav-group__nav">
      { groupChannelsList.map((channel) => {
        if (channel !== 1) {
          return (
            <ChannelItem
              key={channel}
              channelId={channel}
              onCloseLeftSideBarAfterClick={onCloseLeftSideBarAfterClick}
            />
          );
        }
        return null;
      })}
    </nav>
    <CreateChannel />
    <AttachFilesModal />
  </div>
);

GroupChannels.propTypes = {
  groupChannelsList: PropTypes.arrayOf(PropTypes.number).isRequired,
  t: PropTypes.func.isRequired,
  onCloseLeftSideBarAfterClick: PropTypes.func.isRequired,
};

export default GroupChannels;

import React from 'react';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import { Portal } from 'react-portal';

import 'react-toastify/dist/ReactToastify.css';
import './style.sass';
import Banner from '../../banner';

const View = ({
  children, leftSideBarStatus, rightSideBarStatus, setRef, className,
  onEnableNotification, isNotificationPermissionDefault, t,
}) => (
  <div
    ref={e => setRef('main-container', e)}
    className={
      `page-wrapper${leftSideBarStatus ? ' js-left-side-bar--open' : ''}
      ${rightSideBarStatus ? ' js-right-side-bar--open' : ''} ${className}
      ${isNotificationPermissionDefault ? 'is-notification-banner' : ''}
      `}
  >
    <Banner
      isVisible={isNotificationPermissionDefault}
      onButtonClick={onEnableNotification}
      actionTitle={t('enable notifications')}
      title={t('Smartex needs your permission to')}
    />
    <main className={`active ${isNotificationPermissionDefault ? 'is-notification-banner' : ''}`}>
      {children}
    </main>
    <Portal>
      <ToastContainer />
    </Portal>
  </div>
);
View.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  leftSideBarStatus: PropTypes.bool.isRequired,
  onEnableNotification: PropTypes.func.isRequired,
  isNotificationPermissionDefault: PropTypes.bool.isRequired,
  rightSideBarStatus: PropTypes.bool.isRequired,
  setRef: PropTypes.func.isRequired,
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
};

View.defaultProps = {
  className: '',
};

export default View;

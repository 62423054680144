import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import { withNamespaces } from 'react-i18next';
import ToggleButtonLeftSideNav from './toggleButtonLeftSideNav';
import { withWindowWidth } from '../../../../utils/enchancers';
import { uiActions, uiSelectors } from '../../../../state/ui';

const { getLeftSidebar } = uiSelectors;

const mapStateToProps = state => ({
  leftSideBarStatus: getLeftSidebar(state),
  rightSideBarStatus: getLeftSidebar(state),
});

const mapDispatchToProps = {
  changeLeftSidebarStatus: uiActions.changeLeftSidebarStatus,
  changeRightSidebarStatus: uiActions.changeRightSidebarStatus,
};

const onToggleHandler = ({
  leftSideBarStatus,
  rightSideBarStatus,
  windowWidth,
  changeLeftSidebarStatus,
  changeRightSidebarStatus,
}) => () => {
  if (windowWidth < 768 && !leftSideBarStatus && rightSideBarStatus) {
    changeRightSidebarStatus(false);
  }
  changeLeftSidebarStatus(!leftSideBarStatus);
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces('chat'),
  withWindowWidth(),
  withHandlers({
    onToggle: onToggleHandler,
  }),
);
export default enhance(ToggleButtonLeftSideNav);

import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const Pagination = ({
  className, offset, count, limit, goNextPage, goPreviousPage, t,
}) => (
  <>
    <div className={`pagination ${className}`}>
      <div className="pagination__string">
        <div className="pagination__current-posts">
          {`${offset + 1}-${offset + limit > count ? count : offset + limit}`}
        </div>
        <div className="pagination__delimiter">
          {t('of')}
        </div>
        <div className="pagination__count-posts">
          {count}
        </div>
      </div>
      <div className="pagination__controls">
        <button
          type="button"
          onClick={goPreviousPage}
          disabled={offset === 0}
          className="pagination__button pagination__button-prev"
          title={t('Prev page')}
        >
          <span className="icon-pagination-arrow" />
        </button>
        <button
          type="button"
          onClick={goNextPage}
          disabled={offset + limit >= count}
          className="pagination__button"
          title={t('Next page')}
        >
          <span className="icon-pagination-arrow" />
        </button>
      </div>
    </div>
  </>
);

Pagination.propTypes = {
  className: PropTypes.string,
  offset: PropTypes.number,
  count: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  goPreviousPage: PropTypes.func,
  goNextPage: PropTypes.func,
};

Pagination.defaultProps = {
  className: '',
  offset: 0,
  goPreviousPage: () => {},
  goNextPage: () => {},
};

export default Pagination;

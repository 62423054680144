import { compose } from 'recompose';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';
import NavTabs from './navTabs';

const enhance = compose(
  withRouter,
  withNamespaces(['common']),
);

export default enhance(NavTabs);

import { compose } from 'recompose';
import { connect } from 'react-redux';

import { withNamespaces } from 'react-i18next';
import BreadCrumbs from './breadCrumbs';
import { userSelectors } from '../../../../state/user';
import { OWN_CHANNEL } from '../../../../constants/messenger';

const mapStateToProps = (state, { title, channelType }) => {
  const { first_name, last_name } = userSelectors.getUserData(state);
  return {
    // eslint-disable-next-line camelcase
    title: channelType === OWN_CHANNEL ? `${first_name} ${last_name}` : title,
  };
};

const enhance = compose(
  connect(mapStateToProps),
  withNamespaces('common'),
);
export default enhance(BreadCrumbs);

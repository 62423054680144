import {
  compose, withProps, withHandlers, withState,
} from 'recompose';
import { connect } from 'react-redux';

import { withFormik } from 'formik';
import { pathOr, propOr } from 'ramda';
import Profile from './profile';
import { userSelectors, userActions } from '../../state/user';
import rules from './rules';

const mapStateToProps = state => ({
  user: userSelectors.getUserData(state),
  isPending: userSelectors.getPendingUpdateUserProfileRequest(state),
});

const mapDispatchToProps = {
  updateUserProfileRequest: userActions.updateUserProfileRequest,
};

const formSend = (formValues, { props }) => {
  const { avatar, ...values } = formValues;
  props.updateUserProfileRequest(values);
};

const getInitialValues = ({
  user: {
    first_name, last_name, username, avatar, email,
  },
}) => ({
  first_name,
  last_name,
  username,
  avatar: propOr(null, 'id', avatar),
  email,
});

const avatarResetHandler = props => () => {
  const values = getInitialValues(props);
  props.updateUserProfileRequest({ ...values, avatar: null });
};

const avatarUploadHandler = props => (id) => {
  const values = getInitialValues(props);
  props.updateUserProfileRequest({ ...values, avatar: id });
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps(({ user }) => ({
    userAvatar: pathOr('', ['avatar', 'id'], user),
  })),
  withState('errorMessage', 'setErrorMessage', ''),
  withFormik({
    mapPropsToValues: getInitialValues,
    validateOnChange: true,
    validateOnBlur: false,
    validationSchema: rules,
    handleSubmit: formSend,
  }),
  withHandlers({ avatarResetHandler, avatarUploadHandler }),
);

export default enhance(Profile);

import React from 'react';
import PropTypes from 'prop-types';
import './style.sass';
import { ButtonBrill } from '../../../../components/buttonsCollection';

const TariffsItem = ({
  t,
  isFree,
  title,
  free,
  amount,
  btnText,
  onChangeTariff,
  children,
  isCover,
}) => (
  <div className="tariff__item">
    <h3 className="tariff__title">{t(title)}</h3>
    <div className="tariff__price">
      {isFree ? (
        <h4 className="tariff__free">{t(free)}</h4>
      ) : (
        <>
          <h4 className="tariff__currency">$</h4>
          <span className="tariff__amount">{amount}</span>
          <p className="tariff__period">/{t('month')}</p>
        </>
      )}
    </div>
    {children}
    <ButtonBrill className="button--sm, tariff__btn" onClick={onChangeTariff}>{t(btnText)}</ButtonBrill>
    {isCover && (<div className="tariff__cover" />)}
  </div>
);

TariffsItem.propTypes = {
  t: PropTypes.func.isRequired,
  isFree: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  free: PropTypes.string,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  btnText: PropTypes.string.isRequired,
  onChangeTariff: PropTypes.func.isRequired,
  children: PropTypes.node,
  isCover: PropTypes.bool.isRequired,
};

TariffsItem.defaultProps = {
  free: '',
  amount: '',
  children: '',
};

export default TariffsItem;

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { MessageElements } from '../../../../components';
import './style.sass';

const DescriptionChannel = ({
  createdAt,
  description,
  createdBy,
  t,
  children,
}) => (
  <div className="description-channel">
    <p className="description-channel__text">
      <MessageElements
        options={{ parseToPlainText: true }}
      >
        {description}
      </MessageElements>
    </p>
    <span className="description-channel__created">
      <span className="description-channel__created-helper">{t('Created')}</span>
      { createdBy && `${t('by')} ${createdBy} ` }
      {`${t('on')} ${t('formattedDate', moment(new Date(createdAt)))}`}
    </span>
    {children}
  </div>
);

DescriptionChannel.propTypes = {
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string.isRequired,
  description: PropTypes.string,
  t: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)]),
};

DescriptionChannel.defaultProps = {
  description: '',
  children: '',
};

export default React.memo(DescriptionChannel);

import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, propOr } from 'ramda';

import { BrillMask, Avatar, DropDown } from '..';
import './style.sass';

const itemLabel = item => (
  <>
    <BrillMask className="agenda-board__user-list-avatar" disableHighlight asLink to="/">
      <Avatar src={item.src} alt={item.alt} className="users-list__avatar" />
    </BrillMask>
    <span className="agenda-board__user-list-name">{item.alt}</span>
  </>
);

const UserListAvatar = ({
  onClick, displayAvatars, hiddenAvatars,
}) => (
  <>
    <ul className="users-list__list">
      {
        displayAvatars.map(avatar => (
          <li
            key={`${avatar.id} - ${avatar.alt}`}
            className={`users-list__item ${avatar.select ? 'users-list__item--active' : ''}`}
          >
            <BrillMask asLink>
              <Avatar
                id={avatar.id}
                src={avatar.src}
                alt={avatar.alt}
                onClick={onClick}
                importantBackground={propOr('', 'importantBackground', avatar)}
                className="users-list__avatar"
              />
            </BrillMask>
          </li>
        ))
      }
    </ul>
    {
      !isEmpty(hiddenAvatars) && (
        <DropDown
          className="agenda-board__user-list__drop-down"
          name="users"
          onChange={({ val }) => onClick(val.id)}
          isMulti
          label={(
            <BrillMask asLink className="agenda-board__user-list">
              <div className="user-list-count__wrapper">
                <span className="user-list-count__item">
                  {`+ ${hiddenAvatars.length} `}
                </span>
              </div>
            </BrillMask>
          )}
          itemLabel={itemLabel}
          list={hiddenAvatars}
        />
      )
    }
  </>
);

UserListAvatar.propTypes = {
  hiddenAvatars: PropTypes.arrayOf(PropTypes.object).isRequired,
  displayAvatars: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default UserListAvatar;

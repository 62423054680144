import {
  take,
  fork,
  put,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { sagasManager } from '../../utils';
import { uiTypes } from '.';

function* displayErrorPage() {
  while (true) {
    yield take(uiTypes.DISPLAY_ERROR_PAGE);
    yield put(push('/page-not-found'));
  }
}

function* goBackFromErrorPage() {
  while (true) {
    const { payload: href } = yield take(uiTypes.GO_BACK_FROM_ERROR_PAGE);
    yield put(push(href));
  }
}


sagasManager.addSagaToRoot(function* watcher() {
  yield fork(displayErrorPage);
  yield fork(goBackFromErrorPage);
});

import React from 'react';
import { Provider } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { ConnectedRouter } from 'connected-react-router';
import { I18nextProvider } from 'react-i18next';
import { PersistGate } from 'redux-persist/integration/react';
// import whyDidYouUpdate from 'why-did-you-update';

import moment from 'moment';
import Routes from './routes';

import { i18n } from '../../utils';
import store, { history, persistor } from '../../store';
import { LANGUAGES } from '../../constants/config';

const App = () => (
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <Routes />
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </I18nextProvider>
);


const enhance = compose(lifecycle({
  componentDidMount() {
    const savedLang = localStorage.getItem('i18nextLng');

    if (!LANGUAGES.includes(savedLang)) {
      i18n.changeLanguage('en-US');
    }

    moment.tz(Intl.DateTimeFormat().resolvedOptions().timeZone);
    if (process.env.NODE_ENV !== 'production') {
      // whyDidYouUpdate(React);
    }
  },
}));

export default enhance(App);

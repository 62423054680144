import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';

import BotGroupItem from './botGroupItem';
import { messengerSelectors } from '../../../../../../state/messenger';
import { LAST_CHANNEL_MESSENGER_STORAGE } from '../../../../../../constants/messenger';

const mapStateToProps = (state, { botId }) => {
  const directChannelsEntities = Object.values(messengerSelectors.getDirectChannels(state));
  const directChannels = directChannelsEntities.filter(channel => channel.service_bot_id === botId);

  return {
    directChannels,
  };
};

const enhance = compose(
  connect(mapStateToProps),
  withProps(({ directChannels }) => ({
    unreadCount: directChannels.reduce(
      (_unreadCount, currentDirectChannel) => _unreadCount + currentDirectChannel.unread_count,
      0,
    ),
    lastChannel: window.localStorage.getItem(LAST_CHANNEL_MESSENGER_STORAGE) || 1,
  })),
);

export default enhance(BotGroupItem);

import React from 'react';
import PropTypes from 'prop-types';

import { ButtonsCollection } from '../..';
import BgImage from '../../../assets/otherAssets/comming_soon_blur.jpg';
import './styles.scss';

const ComingSoon = ({ title, history }) => (
  <>
    <div className="main-container page-contacts__main-container ComingSoon">
      <img className="ComingSoon_bg" src={BgImage} alt="comingSoonImage" />
      <div className="ComingSoon_content">
        <h2 className="ComingSoon_content_title">
          <span>{title}</span>
          <span>Coming soon</span>
        </h2>
        <ButtonsCollection.ButtonBrill
          onClick={history.goBack}
          className="button--md"
        >

           Go back
        </ButtonsCollection.ButtonBrill>
      </div>
    </div>
  </>
);

ComingSoon.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string.isRequired,
};

export default ComingSoon;

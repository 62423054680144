import {
  branch, compose, renderNothing, withHandlers, getContext, lifecycle,
} from 'recompose';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { prop, propOr } from 'ramda';

import { getModal } from '../../../../../state/ui/selectors';
import { closeModal } from '../../../../../state/ui/actions';
import UnpinMessageModal from './unpinMessageModal';
import { messengerActions, messengerSelectors } from '../../../../../state/messenger/index';

const onUnpinMessage = ({
  resetPinMessage, messageId, channel, onClose, selectedMessage,
}) => () => {
  const idMessage = propOr(prop('id', selectedMessage), 'id', messageId);
  resetPinMessage({ message_id: idMessage, channelId: channel.id });
  onClose();
};

const mapStateToProps = (state, { messageId, selectedMessage, match: { params: { id } } }) => ({
  isOpen: getModal(state)('unpinMessageModal'),
  channel: messengerSelectors.getActiveChannel(state),
  message: messengerSelectors.getPinnedMessages(state)(id)[prop('id', prop('id', selectedMessage) ? selectedMessage : messageId)],
});

const mapDispatchToProps = ({
  onClose: () => closeModal('unpinMessageModal'),
  resetPinMessage: messengerActions.deletePinnedMessageRequest,
});

const enhance = compose(
  getContext({
    selectedMessage: PropTypes.shape({}),
    setSelectedMessageGlobal: PropTypes.func,
  }),
  withRouter,
  branch(({ messageId, selectedMessage }) => !messageId && !selectedMessage, renderNothing),
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common', 'chat']),
  withHandlers({
    onUnpinMessageHandler: onUnpinMessage,
  }),
  lifecycle({
    componentDidUpdate({ isOpen }) {
      if (isOpen && isOpen !== this.props.isOpen) {
        this.props.setSelectedMessageGlobal(null);
      }
    },
  }),

);
export default enhance(UnpinMessageModal);

import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { Label, ServiceIcon } from '../../../../../../components';
import { messenger } from '../../../../../../constants';

const ChannelItem = ({
  channelId, groupChannels, serviceId, style, onCloseLeftSideBarAfterClick,
}) => (
  <NavLink
    to={`/messenger/${channelId}/`}
    activeClassName="chanel-nav-group__item--active"
    className={`chanel-nav-group__item text--cut
    ${groupChannels[channelId].unread_count ? 'channel--has-unread chanel-nav-group__item--new' : ''}`}
    style={{ ...style }}
    onClick={onCloseLeftSideBarAfterClick}
  >
    {
      // eslint-disable-next-line no-nested-ternary
      groupChannels[channelId].type === messenger.PRIVATE_CHANNEL ? (
        <span className="chanel-nav-group__icon icon-chanel-lock-icon" />
      ) : (
        channelId === 1 ? (
          <span
            className="chanel-nav-group__icon icon-chanel-icon"
            style={{
              fontSize: '20px',
              marginRight: '12px',
              width: '20px',
            }}
          />
        ) : (
          <ServiceIcon serviceId={serviceId} />
        )
      )
    }
    <span className="text--cut">{groupChannels[channelId].name}</span>
    {groupChannels[channelId].unread_count ? (
      <Label
        className="label--xs label--fill"
      >
        {groupChannels[channelId].unread_count}
      </Label>
    ) : null}
  </NavLink>
);

ChannelItem.propTypes = {
  channelId: PropTypes.number.isRequired,
  groupChannels: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  serviceId: PropTypes.number.isRequired,
  style: PropTypes.shape({}),
  onCloseLeftSideBarAfterClick: PropTypes.func.isRequired,
};

ChannelItem.defaultProps = {
  style: {},
};

export default ChannelItem;

import React from 'react';
import { propOr, values as ramdaValues } from 'ramda';
import PropTypes from 'prop-types';

import './style.sass';
import { ButtonsCollection, FormsCollection } from '../../../../components';
import { AsyncAutoComplete } from '../../../../components/autoCompleteField';
import { USER_STATUSES } from '../../../../constants/users';

const UserEditForm = ({
  values, handleSubmit, handleChange, getErrorField, onResendInvite,
  isUpdatePending, isResendInvitePending, setFieldValue, getRolesAutocomplete, t,
}) => (
  <FormsCollection.FormWrapper classNameForm="userForm" handleSubmit={handleSubmit}>
    <div className="userForm__row">
      <div className="userForm__col">
        <label className="userForm__label" htmlFor="userEdit_email">{t('Email')}</label>
        <FormsCollection.Input
          value={propOr('', 'email', values)}
          id="userEdit_email"
          error={getErrorField('email')}
          touched
          name="email"
          onChange={handleChange}
          placeholder="example@gmail.com"
        />
      </div>
      <div className="userForm__col">
        <label className="userForm__label" htmlFor="userEdit_username">{t('Username')}</label>
        <FormsCollection.Input
          id="userEdit_username"
          value={propOr('', 'username', values)}
          error={getErrorField('username')}
          touched
          name="username"
          onChange={handleChange}
          placeholder={t('Username')}
        />
      </div>
    </div>
    <div className="userForm__row">
      <div className="userForm__col">
        <label className="userForm__label" htmlFor="userEdit_firstName">{t('First name')}</label>
        <FormsCollection.Input
          value={propOr('', 'first_name', values)}
          error={getErrorField('first_name')}
          id="userEdit_firstName"
          touched
          name="first_name"
          onChange={handleChange}
          placeholder={t('First name')}
        />
      </div>
      <div className="userForm__col">
        <label className="userForm__label" htmlFor="userEdit_lastName">{t('Last name')}</label>
        <FormsCollection.Input
          value={propOr('', 'last_name', values)}
          error={getErrorField('last_name')}
          id="userEdit_lastName"
          touched
          name="last_name"
          onChange={handleChange}
          placeholder={t('Last name')}
        />
      </div>
    </div>
    <div className="userForm__row">
      <div className="userForm__col">
        <span className="userForm__label">{t('Role')}</span>
        <AsyncAutoComplete
          name="role_id"
          placeholder={t('Role')}
          error={getErrorField('role_id')}
          closeMenuOnSelect
          touched
          value={values.role_id}
          defaultValue={values.role_id}
          loadOptions={getRolesAutocomplete}
          onChange={value => setFieldValue('role_id', value)}
        />
      </div>
      <div className="userForm__col">
        <span className="userForm__label">{t('Status')}</span>
        <FormsCollection.Select
          value={propOr(null, 'status', values)}
          error={getErrorField('status')}
          onChange={handleChange}
          name="status"
          data={ramdaValues(USER_STATUSES)}
        />
      </div>
    </div>
    {
       false ? (
         <div className="userForm__row userForm__row--buttons">
           <ButtonsCollection.ButtonBrill
             type="button"
             onClick={onResendInvite}
             isPending={isResendInvitePending}
             className="userForm__submit-button button-brill--fill"
           >

             {t('Resend invite')}
           </ButtonsCollection.ButtonBrill>
         </div>
       ) : (
         <div className="userForm__row userForm__row--buttons">
           <ButtonsCollection.ButtonBrill
             type="submit"
             delay={800}
             isPending={isUpdatePending}
             className="userForm__submit-button button-brill--fill"
           >

             {t('Save')}
           </ButtonsCollection.ButtonBrill>
         </div>
       )
    }
  </FormsCollection.FormWrapper>
);

UserEditForm.propTypes = {
  values: PropTypes.instanceOf(Object).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  getRolesAutocomplete: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  getErrorField: PropTypes.func.isRequired,
  onResendInvite: PropTypes.func.isRequired,
  isUpdatePending: PropTypes.bool.isRequired,
  isResendInvitePending: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};


export default UserEditForm;

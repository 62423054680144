import { handleActions } from 'redux-actions';
import types from './types';
import { mergeDeep, mergeIn } from '../../utils/helpers/ramdaStateHelpers';

const reducer = handleActions({
  [types.SET_CLIENTS]: mergeIn(({
    payload: {
      data: { entities, result }, count, hasMore,
    },
  }) => ({
    entities: entities.clients,
    result,
    count,
    hasMore,
  })),
  [types.EDIT_CLIENT_SUCCESS]: mergeDeep(({
    payload: client,
  }) => ({
    entities: {
      [client.id]: client,
    },
  })),
}, {});

export default reducer;

import React from 'react';

import PropTypes from 'prop-types';

import {
  eighthImage,
  eleventhImage,
  fifthImage,
  firstImage,
  fourthImage,
  ninthImage,
  sixthImage,
  secondImage,
  tenthImage,
  thirdImage,
  twelfthImage,
} from '../../../../constants/telegramImages';

import './style.sass';

const GuideInfo = ({ openModalHandler, t }) => (
  <div className="guide__wrapper">
    <div className="guide__content">
      <span className="guide__text">{t('telegram-sentence-first')}</span>
      <button onClick={() => openModalHandler(firstImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={firstImage} alt="firstImage" />
      </button>
      <span className="guide__text">{t('telegram-sentence-second')}</span>
      <button onClick={() => openModalHandler(secondImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={secondImage} alt="secondImage" />
      </button>
      <span className="guide__text">{t('telegram-sentence-third')}</span>
      <button onClick={() => openModalHandler(thirdImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={thirdImage} alt="thirdImage" />
      </button>
      <span className="guide__text">{t('telegram-sentence-fourth')}</span>
      <button onClick={() => openModalHandler(fourthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={fourthImage} alt="fourthImage" />
      </button>
      <span className="guide__text">{t('telegram-sentence-fifth')}</span>
      <button onClick={() => openModalHandler(fifthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={fifthImage} alt="fifthImage" />
      </button>
      <span className="guide__text">{t('telegram-sentence-sixth')}</span>
      <button onClick={() => openModalHandler(sixthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={sixthImage} alt="sixthImage" />
      </button>
      <span className="guide__text">{t('telegram-sentence-seventh')}</span>
      <button onClick={() => openModalHandler(eighthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={eighthImage} alt="eighthImage" />
      </button>
      <span className="guide__text">{t('telegram-sentence-eighth')}</span>
      <button onClick={() => openModalHandler(ninthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={ninthImage} alt="ninthImage" />
      </button>
      <span className="guide__text">{t('telegram-sentence-ninth')}</span>
      <button onClick={() => openModalHandler(tenthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={tenthImage} alt="tenthImage" />
      </button>
      <span className="guide__text">{t('telegram-sentence-tenth')}</span>
      <button onClick={() => openModalHandler(eleventhImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={eleventhImage} alt="eleventhImage" />
      </button>
      <span className="guide__text">{t('telegram-sentence-eleventh')}</span>
      <button onClick={() => openModalHandler(twelfthImage)} type="button" className="guide__container-btn">
        <img className="guide__image" src={twelfthImage} alt="twelfthImage" />
      </button>
    </div>
  </div>
);

GuideInfo.propTypes = {
  openModalHandler: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default GuideInfo;

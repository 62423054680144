import React from 'react';
import PropTypes from 'prop-types';

import ChannelsItem from '../directsItem';
import './style.sass';

const DirectsList = ({
  title, entities, result, t,
}) => (
  <div className="directs-list">
    <div className="directs-list__head">
      <h2 className="directs-list__title">{title}</h2>
      <h2 className="directs-list__manager">{t('Manager')}</h2>
      <h2 className="directs-list__bot">{t('chat:Bot title')}</h2>
    </div>
    <ul className="directs-list__list">
      {
        result.length !== 0
          ? result.map(channelId => (
            <ChannelsItem
              channel={entities[channelId]}
              key={entities[channelId].id}
            />
          ))
          : (
            <li className="directs-list__empty">
              {t('chat:There are no channels yet')}
            </li>
          )
      }
    </ul>
  </div>
);

DirectsList.propTypes = {
  title: PropTypes.string.isRequired,
  entities: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
  result: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default DirectsList;

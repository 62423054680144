import React from 'react';
import PropTypes from 'prop-types';
import { ModalWindow, ButtonsCollection } from '../../../../components';
import './style.sass';

const ConfirmPaymentModal = ({
  isOpen, onClose, t, payment,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={onClose}
    modalName="payment-modal"
    title={t('Confirm redirect to LiqPay')}
    className="modal-window__payment"
  >
    <div className="payment-modal__row">
      <form
        className="payment-modal__form"
        method="POST"
        action="https://www.liqpay.ua/api/3/checkout"
        acceptCharset="utf-8"
      >
        <input type="hidden" name="data" value={payment.data} />
        <input type="hidden" name="signature" value={payment.signature} />
        <ButtonsCollection.ButtonBrill type="submit" className="payment-modal__btn-pay">
          {t('LiqPay page')}
        </ButtonsCollection.ButtonBrill>
      </form>
      <button type="button" className="payment-modal__btn-cancel" onClick={onClose}>
        {t('Cancel')}
      </button>
    </div>
  </ModalWindow>
);

ConfirmPaymentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  payment: PropTypes.instanceOf(Object).isRequired,
};

export default ConfirmPaymentModal;

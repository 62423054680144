import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const Label = ({ children, className, title }) => (
  <div className={`label ${className}`}>
    <span className="label__wrapper"><span className="label__text">{children}</span></span>
    <span className="label__pseudo-label" title={title}>
      <svg
        version="1.1"
        stroke="red"
        fill="none"
        strokeWidth="2"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 19.4 48"
        style={{ enableBackground: 'new 0 0 19.4 48' }}
        className="label__left-part"
      >

        <path
          className="st0"
          d="M21.1,46.1c1.6-4.3,1.2-8.8,1-13.3c-0.2-4.6-0.1-9.1-0.2-13.7C21.7,13.1,21.1,7,21.1,1h-3.5
c-2.8,0-5.4,1.5-6.8,3.9L2.4,19.4c-1.4,2.4-1.4,5.4,0,7.9l8.4,15.8c1.4,2.4,4,3.9,6.8,3.9h3.2C20.9,46.7,20.9,46.4,21.1,46.1z"
        />
      </svg>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        stroke="red"
        strokeWidth="2"
        fill="none"
        y="0px"
        viewBox="0 0 227.3 48"
        style={{ enableBackground: 'new 0 0 227.3 48' }}
        className="label__middle-part"
        preserveAspectRatio="none"
      >
        <g>
          <path className="st0" d="M-9.1,47h243" />
          <path className="st0" d="M236.7,1H-9.5" />
        </g>
      </svg>
      <svg
        version="1.1"
        stroke="red"
        fill="none"
        strokeWidth="2"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 19.4 48"
        style={{ enableBackground: 'new 0 0 19.4 48' }}
        className="label__right-part"
      >

        <path
          className="st0"
          d="M21.1,46.1c1.6-4.3,1.2-8.8,1-13.3c-0.2-4.6-0.1-9.1-0.2-13.7C21.7,13.1,21.1,7,21.1,1h-3.5c-2.8,0-5.4,1.5-6.8,3.9L2.4,19.4c-1.4,2.4-1.4,5.4,0,7.9l8.4,15.8c1.4,2.4,4,3.9,6.8,3.9h3.2C20.9,46.7,20.9,46.4,21.1,46.1z"
        />
      </svg>
    </span>
  </div>
);

Label.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.element,
  ]),
  className: PropTypes.string,
  title: PropTypes.string,
};

Label.defaultProps = {
  className: '',
  children: 0,
  title: '',
};

export default Label;

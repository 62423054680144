import React from 'react';
import PropTypes from 'prop-types';

import { ButtonsCollection } from '../../../../components';

import './style.sass';

const ToggleRightSideBarButton = ({ toggleRightSideBarHandler, status, t }) => (
  <ButtonsCollection.ButtonIcons
    title={t('Toggle right side bar')}
    className={`toggle-right-aside ${status ? 'toggle-right-aside--active' : ''}`}
    onClick={() => toggleRightSideBarHandler()}
  >
    <span className="icon-toggle-right-sidebar" />
  </ButtonsCollection.ButtonIcons>
);
ToggleRightSideBarButton.propTypes = {
  toggleRightSideBarHandler: PropTypes.func.isRequired,
  status: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};
export default ToggleRightSideBarButton;

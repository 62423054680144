import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withNamespaces } from 'react-i18next';
import { uiSelectors } from '../../../../state/ui';
import ChannelsContainer from './channelsContainer';

const mapStateToProps = state => ({
  urlBeforeChannels: uiSelectors.getLastUrlBeforeChannels(state),
});

const onGoBackHandler = ({
  urlBeforeChannels, history,
}) => () => history.push(urlBeforeChannels);

const enhancer = compose(
  connect(mapStateToProps),
  withNamespaces(['common']),
  withRouter,
  withHandlers({
    onGoBack: onGoBackHandler,
  }),
);
export default enhancer(ChannelsContainer);

/* eslint-disable jsx-a11y/interactive-supports-focus,jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';
import { identity } from 'ramda';
import moment from 'moment';
import { ButtonsCollection, FormsCollection, MessageElements } from '../../../../../../components';

const SearchMessages = ({
  onChangeSearch, searchValue, result, entities, setScrollToMessage, windowWidth,
  closeRightSideBar, onShowUserProfile, hasMore, onLoadMoreSearchMessages,
  isPendingSearchMessagesMore, t,
}) => (
  <div className="searchMessages">
    <FormsCollection.Search
      className="search-field--circle searchMessages__search-field"
      name="search"
      placeholder={t('Search')}
      withoutControl
      value={searchValue}
      onChange={onChangeSearch}
    />
    {
      (result.length && searchValue)
        ? (
          <ul className="pinned-items__list">
            {result.map((id) => {
              const message = entities[id];
              return (
                <li key={id} className="pinned-items__item">
                  <div
                    className="pinned-items__message pinned-message"
                    role="button"
                    onClick={() => {
                      // eslint-disable-next-line no-unused-expressions
                      windowWidth < 768 && closeRightSideBar();
                      setScrollToMessage(message);
                    }}
                  >
                    <div className="pinned-message__header">
                      <div
                        tabIndex={0}
                        role="button"
                        onFocus={identity}
                        className="pinned-message__author text--cut"
                        onClick={(e) => {
                          e.stopPropagation();
                          onShowUserProfile(message.created_by);
                        }}
                      >
                        {`${message.first_name} ${message.last_name}`}
                      </div>
                      <div className="pinned-message__date">
                        {moment(message.created_at).format('DD MMM YYYY')}
                      </div>
                    </div>
                    <div className="pinned-message__body">
                      <span>
                        <MessageElements messageId={message.id}>{message.content}</MessageElements>
                      </span>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        )
        : (
          <>
            {
            searchValue && (
              <div className="pinned-items__empty">

                {t('chat:Nothing search')}
              </div>
            )

          }
          </>
        )
    }
    {
      (hasMore && result.length && searchValue) && (
        <div className="searchMessages__button-row">
          <ButtonsCollection.ButtonBrill
            onClick={onLoadMoreSearchMessages}
            pending={isPendingSearchMessagesMore}
            type="button"
            className="button__load-more button-brill--fill"
          >

            {t('chat:Show older messages')}
          </ButtonsCollection.ButtonBrill>
        </div>
      )
    }
  </div>
);

SearchMessages.propTypes = {
  result: PropTypes.instanceOf(Array).isRequired,
  entities: PropTypes.instanceOf(Object).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  message: PropTypes.instanceOf(Object),
  onChangeSearch: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  onShowUserProfile: PropTypes.func.isRequired,
  setScrollToMessage: PropTypes.func.isRequired,
  closeRightSideBar: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
  hasMore: PropTypes.bool.isRequired,
  onLoadMoreSearchMessages: PropTypes.func.isRequired,
  isPendingSearchMessagesMore: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

SearchMessages.defaultProps = {
  message: {},
};

export default SearchMessages;

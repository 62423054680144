import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { curry, reject, isNil } from 'ramda';

import { openModal } from '../../../../state/ui/actions';
import DirectChannels from './directChannels';
import { messengerSelectors } from '../../../../state/messenger';
import { settingBotsSelectors } from '../../../../state/settingBots';
import { clientsSelectors } from '../../../../state/clients';

const mapStateToProps = state => ({
  channels: messengerSelectors.getDirectChannelsList(state),
  clients: Object.values(clientsSelectors.getClientsEntities(state)),
  channelsEntities: Object.values(messengerSelectors.getDirectChannels(state)),
  activeChannel: messengerSelectors.getActiveChannel(state),
  botIds: settingBotsSelectors.getBotsList(state),
  bots: settingBotsSelectors.getBotsEntities(state),
});

const mapDispatchToProps = ({
  openModal,
});

const getAvailableBots = channels => reject(isNil, channels.map(channel => channel.service_bot_id));

const getDirectChannelsByBotId = curry((channelsEntities, botId) => channelsEntities.filter(
  channelsEntity => channelsEntity.service_bot_id === botId,
));

const mapBotIdsToDirectChannels = (channelsEntities, availableBotIds) => {
  const res = {};

  availableBotIds.forEach((botId) => {
    res[botId] = getDirectChannelsByBotId(channelsEntities, botId);
  });

  return res;
};

const remapBotsIds = (bots, ids) => {
  const res = {};
  // eslint-disable-next-line no-underscore-dangle
  const _bots = Object.values(bots);

  if (!_bots.length) return {};

  ids.forEach((id) => {
    const bot = _bots.find(_bot => _bot.service_bot_id === id);
    if (bot) res[id] = bot;
  });

  return res;
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common', 'chat']),
  withProps(({ channels, channelsEntities }) => ({
    channelsList: channels,
    botIds: [...new Set(getAvailableBots(channelsEntities))],
  })),
  withProps(({ channelsEntities, botIds, bots }) => ({
    mappedBots: mapBotIdsToDirectChannels(channelsEntities, botIds),
    bots: remapBotsIds(bots, botIds),
  })),
);

export default enhance(DirectChannels);

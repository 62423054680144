import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'ramda';

import { ConfirmModal } from '../../../../../components/index';
import './style.sass';

const PinMessageModal = ({
  isOpen, onClose, message, onPinMessageHandler, isPending, t,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    isPending={isPending}
    actionTitle={t('chat:Pin')}
    onAction={onPinMessageHandler}
    onClose={onClose}
    title={t('chat:Pin message')}
  >

    {t('chat:Are you sure you want to pin')}
    <b className="message-short">{prop('content', message)}</b>

    {t('message?')}
  </ConfirmModal>
);

PinMessageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onPinMessageHandler: PropTypes.func.isRequired,
  message: PropTypes.instanceOf(Object).isRequired,
  isPending: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

PinMessageModal.defaultProps = {
  isPending: false,
};

export default PinMessageModal;

import {
  compose, withState, withHandlers, lifecycle,
} from 'recompose';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { withFormik } from 'formik';
import { withNamespaces } from 'react-i18next';

import { helpers } from '../../../utils';
import { userSelectors, userActions } from '../../../state/user';
import { uiActions, uiSelectors } from '../../../state/ui';

import SignUp from './signUp';

const { translateHelpers } = helpers;
const { signUpFetch } = userActions;
const { displayNotification } = uiActions;

const mapDispatchToProps = ({
  signUpRequest: signUpFetch,
  displayNotification,
});

const mapStateToProps = state => ({
  signUpErrors: userSelectors.getSignUpErrors(state),
  pending: userSelectors.getPendingSignUpRequest(state),
  authNotification: uiSelectors.getAuthNotification(state),
});

const rules = yup.object().shape({
  email: yup
    .string()
    .email({
      field: 'email',
      message: 'email',
      params: {
        key: translateHelpers.t('common', 'Email address'),
      },
    })
    .required({
      field: 'email',
      message: 'required',
      params: {
        key: translateHelpers.t('common', 'Email address'),
      },
    }),
});

const saveEmailWithDomain = ({ setValues, handleChange, prefix }) => (e) => {
  setValues({ email: e.target.value + prefix });
  handleChange(e);
};

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common', 'validation']),
  withState('prefixForTitle', 'setPrefixForTitle', 'Wetelo'),
  withState('prefix', 'setPrefix', '@wetelo.com'),
  withFormik({
    validate: async (values, props) => {
      const { displayNotification: showNotification } = props;
      try {
        await rules.validate(values);
        showNotification(null);
      } catch (fail) {
        const { message } = fail;
        showNotification({
          [message.field]: {
            message: message.message,
            params: message.params,
          },
        });
      }
    },
    mapPropsToValues: () => ({ emailWithoutPrefix: '', email: '' }),
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: rules,
    handleSubmit: (values, { props: { signUpRequest } }) => {
      const { email } = values;
      signUpRequest({ email });
    },
  }),
  withHandlers({
    saveEmailWithDomain,
  }),
  lifecycle({
    componentWillUnmount() {
      this.props.displayNotification(null);
    },
  }),
);

export default enhancer(SignUp);

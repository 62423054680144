import React from 'react';
import PropTypes from 'prop-types';
import { translateHelpers } from '../../utils/helpers';

import './style.sass';
import { ButtonsCollection, ModalWindow } from '../index';

const ConfirmModal = ({
  onClose, onAction, isPending, title, isOpen, actionTitle, className, children, zIndex, t,
}) => (
  <ModalWindow
    isOpen={isOpen}
    onClose={onClose}
    className={`confirm-modal ${className}`}
    modalName={title}
    title={title}
    zIndex={zIndex}
  >
    <>
      <div className="row  text-align--left">
        <span className="confirm-modal__title title-md">
          {children}
        </span>
      </div>
      <div className="confirm-modal__button-row">
        <ButtonsCollection.ButtonBrill
          type="button"
          onClick={onAction}
          pending={isPending}
          className="button--md confirm-modal__button-control button-brill--fill"
        >
          {t(actionTitle)}
        </ButtonsCollection.ButtonBrill>
        <button
          type="button"
          className="button--cancel"
          onClick={onClose}
        >

          {t('Cancel')}
        </button>
      </div>
    </>
  </ModalWindow>
);

ConfirmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
  isPending: PropTypes.bool,
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  actionTitle: PropTypes.string,
  zIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.array,
  ]),
  t: PropTypes.func.isRequired,
};

ConfirmModal.defaultProps = {
  className: '',
  zIndex: '99',
  actionTitle: 'Yes',
  isPending: false,
  children: translateHelpers.t('common', 'Are you sure?'),
};

export default ConfirmModal;

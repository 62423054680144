import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const addClassForSortingArrow = (sort, value, order) => {
  if (sort === value && order === 'desc') {
    return 'filter-button--active--down';
  } if (sort === value && order === 'asc') {
    return 'filter-button--active';
  }
  if (!value) {
    return 'filter-button--inactive';
  }
  return '';
};

const ButtonFilter = ({
  onClick, children, title, className, sort, value, order,
}) => (
  <div className="filter-button__container">
    <button
      type="button"
      title={title}
      onClick={onClick}
      className={`${className} filter-button ${addClassForSortingArrow(sort, value, order)}`}
    >
      <span className="filter-button__body">
        {children}
      </span>
      <span className="icon icon-argon-arrow" />
    </button>
  </div>
);

ButtonFilter.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string.isRequired,
  sort: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
};
ButtonFilter.defaultProps = {
  onClick: () => {},
  title: '',
  className: '',
};

export default ButtonFilter;

import { createActions } from 'redux-actions';
import { identity } from 'ramda';

import types from './types';
import { usersSchemas } from '.';
import { setUsersUpdateMiddleware } from '../../utils/helpers/stateHelpers';
import { getSettingUsersRequest } from '../settingUsers/actions';

export const {
  getUsersListFetch,
  getUsersListAutocompleteRequest,
  setUsersInviteRequest,
  getCoworkersRequest,
  getUserProfileRequest,
  setUsersList,
  setUsersListAutocomplete,
  setUsersInviteSuccess,
  emitUsersStatus,
  setUsersStatus,
  updateUsers,
  setUsers,
  setCoworkers,
  setUserProfile,
  setUserProfileId,
} = createActions(
  {
    [types.GET_USERS_LIST_FETCH]: [
      identity,
      (params, meta) => ({
        async: true,
        params: params || { limit: 100 },
        route: '/users',
        selectorName: 'getUsersList',
        method: 'GET',
        schema: {
          rules: usersSchemas.usersListSchema,
          pathData: ['data'],
        },
        actions: {
          success: setUsersList,
        },
        ...meta,
      }),
    ],
    [types.GET_USERS_LIST_AUTOCOMPLETE_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        params,
        route: '/users/autocomplete',
        selectorName: 'getUsersListAutocomplete',
        method: 'GET',
        actions: {
          success: action => setUsersListAutocomplete({ ...action, meta }),
        },
        ...meta,
      }),
    ],
    [types.SET_USERS_INVITE_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        params,
        route: '/users/invite',
        selectorName: 'setUsersInviteRequest',
        method: 'POST',
        actions: {
          success: [setUsersInviteSuccess, () => getSettingUsersRequest()],
        },
        ...meta,
      }),
    ],
    [types.GET_COWORKERS_REQUEST]: [
      identity,
      (params, meta) => ({
        async: true,
        params,
        route: '/projects/coworkers',
        selectorName: 'getCoworkersRequest',
        method: 'GET',
        schema: {
          rules: usersSchemas.coworkersListSchema,
          pathData: ['data'],
        },
        actions: {
          success: setUsersUpdateMiddleware(setCoworkers,
            {
              pathEntities: ['data', 'entities', 'coworkers'],
            }),
        },
        ...meta,
      }),
    ],
    [types.GET_USER_PROFILE_REQUEST]: [
      identity,
      ({ userId, ...params }, meta) => ({
        async: true,
        params,
        route: `/users/${userId}`,
        selectorName: 'getUserProfileRequest',
        method: 'GET',
        actions: {
          success: setUserProfile,
        },
        ...meta,
      }),
    ],
  },
  types.SET_USERS_LIST,
  types.SET_USERS_LIST_AUTOCOMPLETE,
  types.SET_USERS_INVITE_SUCCESS,
  types.EMIT_USERS_STATUS,
  types.SET_USERS_STATUS,
  types.UPDATE_USERS,
  types.SET_USERS,
  types.SET_USER_PROFILE,
  types.UPDATE_USERS,
  types.SET_USER_PROFILE_ID,
  types.SET_COWORKERS,
);

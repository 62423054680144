import * as clientsActions from './actions';
import * as clientsSelectors from './selectors';
import * as clientsOperations from './operations';
import * as clientsSchemas from './schema';
import clientsTypes from './types';
import reducer from './reducers';

export {
  clientsActions,
  clientsSelectors,
  clientsOperations,
  clientsSchemas,
  clientsTypes,
};

export default reducer;

import { compose, getContext, withHandlers } from 'recompose';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import GuideInfo from './guideInfo';
import { openModal } from '../../../../state/ui/actions';

const openModalHandler = ({ setOpenModal, setImage }) => (image) => {
  setImage(image);
  setOpenModal('imageModal');
};

const mapDispatchToProps = ({
  setOpenModal: openModal,
});


const enhance = compose(
  connect(null, mapDispatchToProps),
  withNamespaces(['guides']),
  getContext({
    setImage: PropTypes.func,
  }),
  withHandlers({
    openModalHandler,
  }),
);

export default enhance(GuideInfo);

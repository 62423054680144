import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';
import { ButtonsCollection, FormsCollection } from '../../../../components';
import { subscription } from '../../../../constants';
import './style.sass';

const SubscriberEditForm = ({
  handleSubmit, values, handleChange, t, isUpdatePending, errors,
}) => (
  <FormsCollection.FormWrapper classNameForm="subscriber-form" handleSubmit={handleSubmit}>
    <div className="subscriber-form__row">
      <div className="subscriber-form__col">
        <label className="subscriber-form__label" htmlFor="subscriber_firstName">{t('First name')}</label>
        <FormsCollection.Input
          value={propOr('', 'first_name', values)}
          error={propOr('', 'first_name', errors)}
          id="subscriber_firstName"
          touched
          name="first_name"
          onChange={handleChange}
          placeholder={t('First name')}
        />
      </div>
      <div className="subscriber-form__col">
        <label className="subscriber-form__label" htmlFor="subscriber_lastName">{t('Last name')}</label>
        <FormsCollection.Input
          value={propOr('', 'last_name', values)}
          error={propOr('', 'last_name', errors)}
          id="subscriber_lastName"
          touched
          name="last_name"
          onChange={handleChange}
          placeholder={t('Last name')}
        />
      </div>
    </div>
    <div className="subscriber-form__row">
      <div className="subscriber-form__col">
        <label className="subscriber-form__label" htmlFor="subscriber_email">{t('Email')}</label>
        <FormsCollection.Input
          value={propOr('', 'email', values)}
          id="subscriber_email"
          error={propOr('', 'email', errors)}
          touched
          name="email"
          onChange={handleChange}
          placeholder="example@gmail.com"
          disabled
        />
      </div>
      <div className="subscriber-form__col">
        <label className="subscriber-form__label" htmlFor="subscriber_username">{t('Username')}</label>
        <FormsCollection.Input
          id="subscriber_username"
          value={propOr('', 'username', values)}
          error={propOr('', 'username', errors)}
          touched
          name="username"
          onChange={handleChange}
          placeholder={t('Username')}
        />
      </div>
    </div>
    <div className="subscriber-form__row">
      <div className="subscriber-form__col">
        <label className="subscriber-form__label" htmlFor="subscriber_phone">{t('Phone')}</label>
        <FormsCollection.Input
          value={propOr('', 'phone', values)}
          id="subscriber_phone"
          error={propOr('', 'phone', errors)}
          touched
          name="phone"
          onChange={handleChange}
          placeholder={t('Phone')}
        />
      </div>
      <div className="subscriber-form__col">
        <label className="subscriber-form__label" htmlFor="subscriber_country">{t('Country')}</label>
        <FormsCollection.Input
          id="subscriber_country"
          value={propOr('', 'country', values)}
          error={propOr('', 'country', errors)}
          touched
          name="country"
          onChange={handleChange}
          placeholder={t('Country')}
        />
      </div>
    </div>
    <div className="subscriber-form__row">
      <div className="subscriber-form__col">
        <label className="subscriber-form__label" htmlFor="subscriber_address">{t('Address')}</label>
        <FormsCollection.Input
          value={propOr('', 'address', values)}
          id="subscriber_address"
          error={propOr('', 'address', errors)}
          touched
          name="address"
          onChange={handleChange}
          placeholder={t('Address')}
        />
      </div>
      <div className="subscriber-form__col">
        <label className="subscriber-form__label" htmlFor="subscriber_title">{t('Company')}</label>
        <FormsCollection.Input
          id="subscriber_title"
          value={propOr('', 'title', values)}
          error={propOr('', 'title', errors)}
          touched
          name="title"
          onChange={handleChange}
          placeholder={t('Company')}
        />
      </div>
    </div>
    <div className="subscriber-form__row">
      <div className="subscriber-form__col">
        <label className="subscriber-form__label" htmlFor="subscriber_name">{t('Subdomain')}</label>
        <FormsCollection.Input
          value={propOr('', 'name', values)}
          id="subscriber_name"
          error={propOr('', 'name', errors)}
          touched
          name="name"
          onChange={handleChange}
          placeholder={t('Subdomain')}
          disabled
        />
      </div>
      <div className="subscriber-form__col">
        <label className="subscriber-form__label" htmlFor="subscriber_tariff">{t('Tariff')}</label>
        <FormsCollection.Input
          id="subscriber_tariff"
          value={t(subscription.TARIFFS[propOr('', 'tariff', values)].title)}
          error={propOr('', 'tariff', errors)}
          touched
          name="tariff"
          onChange={handleChange}
          placeholder={t('Tariff')}
          disabled
        />
      </div>
    </div>
    <div className="subscriber-form__row subscriber-form__row--buttons">
      <ButtonsCollection.ButtonBrill
        type="submit"
        pending={isUpdatePending}
        className="subscriber-form__submit-button button-brill--fill"
      >

        {t('Save')}
      </ButtonsCollection.ButtonBrill>
    </div>
  </FormsCollection.FormWrapper>
);

SubscriberEditForm.propTypes = {
  values: PropTypes.instanceOf(Object).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  isUpdatePending: PropTypes.bool.isRequired,
};

export default SubscriberEditForm;

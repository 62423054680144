import React from 'react';

import { DropDown, BrillMask, Avatar } from '../../../../components';
import testAsset from '../../../../assets/testAssets/avatar.jpeg';
import './style.sass';

const SharedMedia = () => (
  <div className="shared-medias">
    <div className="shared-medias__filter">
      <DropDown
        label="Sort"
        list={
      [
        {
          id: 0,
          title: 'Name',
          element: () => (
            <div className="shared-medias__item-dropDown">
              <span className="shared-medias__icon-dropDown icon-name-singe" />

              Name
            </div>
          ),
        },
        {
          id: 2,
          title: 'Author',
          element: () => (
            <div className="shared-medias__item-dropDown">
              <span className="shared-medias__icon-dropDown icon-author-user" />

              Author
            </div>
          ),
        },
        {
          id: 3,
          title: 'Images',
          element: () => (
            <div className="shared-medias__item-dropDown">
              <span className="shared-medias__icon-dropDown icon-image-file" />

              Images
            </div>
          ),
        },
        {
          id: 4,
          title: 'Documents',
          element: () => (
            <div className="shared-medias__item-dropDown">
              <span className="shared-medias__icon-dropDown icon-document-file" />

Documents
            </div>
          ),
        },
        {
          id: 5,
          title: 'Links',
          element: () => (
            <div className="shared-medias__item-dropDown">
              <span className="shared-medias__icon-dropDown icon-link-icon" />

Links
            </div>
          ),
        },
      ]}
      />
    </div>
    <div className="shared-medias__time-line">

      1 January 2018
    </div>
    {
      [{ id: 'lorem-222' }, { id: 'lorem2-q3' }, { id: 'hello-workd' }].map(el => (
        <div className="shared-medias__image-item" key={el.id}>
          <BrillMask>
            <Avatar src={testAsset} />
          </BrillMask>
          <div className="shared-medias__image-row">
            <b className="shared-medias__image-name">

              IMG_lorem-ipsum-sit-111
            </b>
            <div className="shared-medias__image-info">
              <div className="shared-medias__image-size">(1.2 MB)</div>
              <div className="shared-medias__image-author">
                <span className="shared-medias__image-icon icon-author-user" />

Hemchandra Chakravorty
              </div>
            </div>
          </div>
        </div>
      ))
    }
    <div className="shared-medias__time-line">

      29 January 2018
    </div>
    {
      [{ id: 'lorem-222' }, { id: 'lorem2-q3' }, { id: 'hello-workd' }].map(el => (
        <div
          className="shared-medias__image-item"
          key={el.id}
        >
          <BrillMask>
            <Avatar src={testAsset} />
          </BrillMask>
          <div className="shared-medias__image-row">
            <b className="shared-medias__image-name">

              IMG_lorem-ipsum-sit-111
            </b>
            <div className="shared-medias__image-info">
              <div className="shared-medias__image-size">(1.2 MB)</div>
              <div className="shared-medias__image-author">
                <span className="shared-medias__image-icon icon-author-user" />

Hemchandra Chakravorty
              </div>
            </div>
          </div>
        </div>
      ))
    }
    <div className="shared-medias__time-line">

      29 January 2018
    </div>
    {
      [{ id: 'lorem-222' }, { id: 'lorem2-q3' }, { id: 'hello-workd' }].map(el => (
        <div
          className="shared-medias__image-item"
          key={el.id}
        >
          <BrillMask>
            <Avatar src={testAsset} />
          </BrillMask>
          <div className="shared-medias__document-row">
            <b className="shared-medias__document-name">

              IMG_lorem-ipsum-sit-111
            </b>
            <div className="shared-medias__document-info">
              <div className="shared-medias__document-size">(1.2 MB)</div>
              <div className="shared-medias__document-author">
                <span className="shared-medias__document-icon icon-author-user" />

Hemchandra Chakravorty
              </div>
            </div>
          </div>
        </div>
      ))
    }
    <div className="pinned-items__empty">

      There are no shared media yet
    </div>
  </div>
);
export default SharedMedia;

import React from 'react';
import PropTypes from 'prop-types';

import { FormsCollection, ButtonsCollection, Layouts } from '../../../components';
import './style.sass';

const ForgotPasswordSuccess = ({ t }) => (
  <Layouts.Auth>
    <div className="auth-wrapper__wrap">
      <FormsCollection.FormWrapper>
        <div className="auth-wrapper__form-contain forgot-password__wrapper">
          <h2 className="title weight--bold title--md text-align--center">
            {t('Email has been sent successfully!')}
          </h2>
          <div className="auth-wrapper__helper-text--to-field">{t('Check your inbox for further instructions')}</div>
          <div className="button-group">
            <ButtonsCollection.ButtonBrill
              className="auth-wrapper__button--with-border button--fill"
              borderColor="#26b5ed"
              asLink
              href="/"
              borderWidth="2"
            >
              {t('close')}
            </ButtonsCollection.ButtonBrill>
          </div>
        </div>

      </FormsCollection.FormWrapper>
    </div>
  </Layouts.Auth>
);

ForgotPasswordSuccess.propTypes = {
  t: PropTypes.func.isRequired,
};

export default ForgotPasswordSuccess;

import {
  compose, withProps, withHandlers, withContext, lifecycle, withState,
} from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pickBy, prop, without } from 'ramda';
import { withFormik } from 'formik';
import { withNamespaces } from 'react-i18next';

import Channels from './channels';
import { messengerSelectors, messengerActions } from '../../state/messenger';
import { uiActions } from '../../state/ui';
import rules from './rules';
import { CHANNELS_YOU_BELONG_TO, CHANNELS_YOU_CAN_JOIN } from './constants';
import { userSelectors } from '../../state/user';

const { getGroupChannelsRequest, setYouBelongToChannels, setYouCanJoinChannels } = messengerActions;
const { resetPagination } = uiActions;

const getChannelsAction = ({ type }) => {
  if (type === CHANNELS_YOU_CAN_JOIN) return setYouCanJoinChannels;
  return setYouBelongToChannels;
};

const getObjectForSend = ({
  type, search, limit = 10, offset = 0, orderByTitle,
}) => ({
  sortBy: 'name',
  order: prop('value', orderByTitle),
  limit,
  offset,
  search,
  notMember: prop('value', type) === CHANNELS_YOU_BELONG_TO ? null : true,
  success: getChannelsAction({ type: prop('value', type) }),
});

const setFieldValueWithSubmit = ({
  setFieldValue, values, getChannelsRequest, resetPagination: resetPages, setPagination, pagination,
}) => (name, value) => {
  setFieldValue(name, value);
  const newValues = {
    ...values,
    [name]: value,
  };
  setPagination({ ...pagination, offset: 0 });
  getChannelsRequest(null, getObjectForSend({
    search: newValues.search,
    orderByTitle: newValues.orderByTitle,
    type: newValues.type,
  }));
  resetPages();
};

const mapStateToProps = state => ({
  youBelongToChannelsList: messengerSelectors.getYouBelongToChannelsList(state),
  youCanJoinChannelsList: messengerSelectors.getYouCanJoinChannelsList(state),
  youBelongToChannelsCount: messengerSelectors.getYouBelongToChannelsCount(state),
  youCanJoinChannelsCount: messengerSelectors.getYouCanJoinChannelsCount(state),
  entities: messengerSelectors.getGroupChannels(state),
  ownChannelId: userSelectors.getOwnChannelId(state),
});

const mapDispatchToProps = ({
  getChannelsRequest: getGroupChannelsRequest,
  resetPagination,
});

const onPageChangeHandler = ({
  setPagination,
  pagination, getChannelsRequest, paginationRequestParams,
}) => (meta) => {
  const nextPagination = { ...pagination, ...meta };
  setPagination(nextPagination);
  getChannelsRequest(null, { ...paginationRequestParams, ...nextPagination });
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common', 'chat']),
  withState('pagination', 'setPagination', { limit: 10, offset: 0 }),
  withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: () => ({
      search: '',
      type: {
        id: 1,
        label: 'Channels you can join',
        value: 1,
      },
      sortBy: 0,
    }),
    validationSchema: rules,
    handleSubmit: (
      { search, type, orderByTitle },
      { props: { getChannelsRequest, pagination } },
    ) => {
      getChannelsRequest(null, getObjectForSend({
        search, type, orderByTitle, ...pagination,
      }));
    },
  }),
  withProps(({
    youCanJoinChannelsCount,
    youBelongToChannelsCount,
    youCanJoinChannelsList,
    youBelongToChannelsList,
    values: { type, search, orderByTitle },
    entities,
  }) => ({
    countEntities: prop('value', type) ? youCanJoinChannelsCount : youBelongToChannelsCount,
    result: prop('value', type) ? youCanJoinChannelsList : youBelongToChannelsList,
    paginationRequestParams: getObjectForSend({ ...{ type }, name: search, orderByTitle }),
    title: 'New clients',
    entities: pickBy(val => val.type !== 0, entities),
  })),
  withProps(({ result, ownChannelId }) => ({
    result: without([1, ownChannelId], result),
  })),
  withHandlers({
    setFieldValueWithSubmit,
    onPageChange: onPageChangeHandler,
  }),
  withContext({
    values: PropTypes.instanceOf(Object),
    setFieldValue: PropTypes.func,
  },
  ({ values, setFieldValueWithSubmit: setFieldValue }) => ({
    values,
    setFieldValue,
  })),
  lifecycle({
    componentDidMount() {
      const { getChannelsRequest, values: { search, type, orderByTitle }, pagination } = this.props;
      getChannelsRequest(null, getObjectForSend({
        search, type, orderByTitle, ...pagination,
      }));
    },
  }),
);

export default enhance(Channels);

import React from 'react';
import PropTypes from 'prop-types';

import { FormsCollection } from '../../../../../../components';
import './style.sass';

const FieldItem = ({
  error,
  value,
  name,
  touched,
  onBlur,
  onChange,
  onDelete,
  t,
}) => (
  <div className="invite-users__row">
    <FormsCollection.Input
      placeholderJump={t('Email address')}
      name={name}
      id="invite-users__email"
      error={error}
      value={value}
      touched={touched}
      onChange={onChange}
      onBlur={onBlur}
    />
    {
      onDelete && (
        <button
          type="button"
          className="invite-users__button-remove"
          title={t('Delete')}
          onClick={onDelete}
        >
          <span className="icon-close" />
        </button>
      )
    }
  </div>
);

FieldItem.propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  value: PropTypes.string,
  touched: PropTypes.bool,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
  t: PropTypes.func.isRequired,
};

FieldItem.defaultProps = {
  onDelete: false,
  error: '',
  touched: false,
  value: '',
};


export default FieldItem;

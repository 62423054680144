import {
  compose, lifecycle, withStateHandlers, hoistStatics, defaultProps, withHandlers, pure,
} from 'recompose';
import { memo } from 'react';
import { withNamespaces } from 'react-i18next';
import withRefs from '../../../../utils/enchancers/withRefs';
import Input from './input';

const getTypeHandler = ({ hiddenEye, statusVisible, type = 'text' }) => () => {
  if (hiddenEye) {
    if (statusVisible) return 'text';
    return 'password';
  }
  return type;
};

const enhance = compose(
  pure,
  withRefs(),
  withNamespaces(['validation']),
  defaultProps({
    handlerRefFieldElement: () => {
    },
  }),
  withStateHandlers(() => ({ inFocus: false, statusVisible: false }), {
    handlerInFocus: () => val => ({
      inFocus: val,
    }),
    handlerVisibleStatus: ({ statusVisible }) => () => ({
      statusVisible: !statusVisible,
    }),
  }),
  withHandlers({
    getType: getTypeHandler,
    setInFocusTrue: ({ handlerInFocus }) => () => handlerInFocus(true),
    setInFocusFalseIfHasNotValue: ({ handlerInFocus, getRef }) => () => {
      const el = getRef('input');
      if (!el.value.length) {
        handlerInFocus(false);
      }
    },
  }),
  memo,
  lifecycle({
    componentDidMount() {
      const { getRef, setInFocusTrue, setInFocusFalseIfHasNotValue } = this.props;
      const el = getRef('input');

      el.addEventListener('change', setInFocusTrue);
      el.addEventListener('focus', setInFocusTrue);
      el.addEventListener('blur', setInFocusFalseIfHasNotValue);

      this.props.handlerRefFieldElement(el);

      if (this.props.value) {
        this.props.handlerInFocus(true);
      }
    },
    componentWillUnmount() {
      const { getRef, setInFocusTrue, setInFocusFalseIfHasNotValue } = this.props;
      const el = getRef('input');

      el.removeEventListener('change', setInFocusTrue);
      el.removeEventListener('focus', setInFocusTrue);
      el.removeEventListener('blur', setInFocusFalseIfHasNotValue);
    },
  }),
);
export default hoistStatics(enhance)(Input);

import React from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import { ButtonsCollection } from '..';
import './style.sass';

const MobileFiltersContainer = ({
  title,
  isOpen,
  className,
  changeContainerStatusStateHandler,
  children,
  onWidth,
  windowWidth,
}) => (
  <React.Fragment>
    {
      windowWidth > onWidth ? (
        children
      ) : (
        <>
          <ButtonsCollection.ButtonIcons
            title="Filters"
            type="button"
            className="filters-container-mobile__open-container"
            onClick={() => changeContainerStatusStateHandler(!isOpen)}
          >
            <span className="icon-filter" />
          </ButtonsCollection.ButtonIcons>
          <Portal>
            {isOpen
            && (
              <div className={`filters-container-mobile${className}`}>
                <div
                  className="filters-container-mobile__container"
                >
                  <div className="filters-container-mobile__header">
                    <ButtonsCollection.ButtonIcons
                      title="Close"
                      onClick={() => changeContainerStatusStateHandler(!isOpen)}
                    >
                      <span className="icon-expand-arrow-left" />
                    </ButtonsCollection.ButtonIcons>
                    <h3 className="filters-container-mobile__title text--center title--big">
                      {title}
                    </h3>
                  </div>
                  <div className="filters-container-mobile__body">{children}</div>
                </div>
              </div>
            )
            }
          </Portal>
        </>
      )
    }
  </React.Fragment>

);

MobileFiltersContainer.propTypes = {
  title: PropTypes.string.isRequired,
  onWidth: PropTypes.number.isRequired,
  windowWidth: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  changeContainerStatusStateHandler: PropTypes.func.isRequired,
  className: PropTypes.string,
};
MobileFiltersContainer.defaultProps = {
  className: '',
};
export default MobileFiltersContainer;

import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'ramda';

import {
  ConfirmModal,
} from '../../../../components';

const DeleteChannelModal = ({
  isOpen,
  isPending,
  onClose,
  channel,
  onDelete,
}) => (
  <ConfirmModal
    isOpen={isOpen}
    isPending={isPending}
    actionTitle="Delete"
    onAction={onDelete}
    onClose={onClose}
    title="Delete Channel"
  >

    Are you sure you want to delete
    <b>{prop('name', channel)}</b>

    channel?
  </ConfirmModal>
);

DeleteChannelModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  channel: PropTypes.shape({}).isRequired,
};

export default DeleteChannelModal;

import {
  compose, lifecycle,
} from 'recompose';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { history } from '../../store';
import { userSelectors } from '../../state/user';
import { uiActions } from '../../state/ui';

const SUPER_ADMIN_ID = 1;

const mapStateToProps = state => ({
  userData: userSelectors.getUserData(state),
});

const mapDispatchToProps = {
  setPageError: uiActions.setErrorPage,
};

const withSuperAdmin = () => compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common']),
  lifecycle({
    componentDidMount() {
      const {
        setPageError, t, userData,
      } = this.props;
      if (userData.id !== SUPER_ADMIN_ID) {
        setPageError({
          code: 403,
          title: t('Sorry, but you do not have access to this page'),
          subTitle: t('You are not access as a super admin'),
          label: t('Go back to preferences'),
          href: '/preferences',
          deletePage404: () => history.push('/preferences/'),
        });
      }
    },
  }),
);

export default withSuperAdmin;

// eslint-disable-next-line import/no-duplicates
import * as R from 'ramda';
import moment from 'moment';

import { Either, Maybe } from 'ramda-fantasy';
// eslint-disable-next-line import/no-duplicates
import {
  curry, cond, T, set, omit, pathOr,
// eslint-disable-next-line import/no-duplicates
} from 'ramda';
import SERVER_TIMEZONE from '../../constants/config';

const { Left, Right } = Either;

const capitalize = R.compose(
  R.join(''),
  R.juxt([R.compose(R.toUpper, R.head), R.tail]),
);

const capitalizeEverySecondWord = (word, key) => {
  const index = parseInt(key, 10);
  const toLowerWord = R.toLower(word);
  if (index === 0) {
    return toLowerWord;
  }
  return capitalize(toLowerWord);
};

const camelCase = R.compose(
  R.join(''),
  R.values,
  R.mapObjIndexed(capitalizeEverySecondWord),
  R.split('_'),
);

const convertUtcTimeToStartDay = (date) => {
  const unixDate = moment(date).unix();
  return unixDate - (unixDate % 86400);
};

const recalculateOffset = offset => R.compose(
  R.add(offset),
  R.length,
);

const renameKeys = R.curry((keysMap, obj) => R.reduce((acc, key) => R.assoc(keysMap[key]
    || key, obj[key], acc), {}, R.keys(obj)));

const toTitle = R.compose(
  R.join(''),
  R.over(R.lensIndex(0), R.toUpper),
);

const getValueBy = R.curry((key, items) => (R.is(Array, items) ? R.map(item => item[key], items)
  : R.prop(key, items)));

const getPercentFromNumber = ({ from, to }) => (from / 100) * to;

const concatProps = R.curry((separator, prop1, prop2, data) => R.join(
  separator, [R.prop(prop1, data), R.prop(prop2, data)],
));

const move = R.curry((fromIdx, toIdx, list) => R.compose(
  R.insert(toIdx, R.nth(fromIdx, list)),
  R.remove(fromIdx, 1),
)(list));

const getItem = R.curry((prop, item, list) => R.find(R.propEq(prop, item), list));

const getOppositeValue = R.curry((opposite1, opposite2, value) => R.compose(
  isOppositeEqual => (isOppositeEqual ? opposite2 : opposite1),
  R.equals(opposite1),
)(value));

const renameEachObjectKeys = R.curry((output, data) => R.map(renameKeys(output))(data));

const setNewPropertyData = R.curry((property, callback, data) => R.over(
  R.lensProp(property), callback, data,
));

const clearContentEditableValue = (value) => {
  const regAllHtml = /<(\/)?([a-zA-Z]*)(\s[a-zA-Z]*=(("|').*?("|'))?)?\s?>/gi;
  const regBreaker = /<\/?br>/gi;
  return value.replace(regBreaker, '[br]').replace(regAllHtml, '');
};

const addBreakerToContentEditableValue = (value) => {
  const regBreaker = /\[br\]/gi;
  return value.replace(regBreaker, '<br>');
};

const filterAndTransformCondition = R.curry((filterAcc, acc, data) => R.compose(
  R.map(acc),
  R.filter(filterAcc),
  R.toPairs,
)(data));

const filterParamKey = (item) => {
  const value = item[1];
  return !R.isEmpty(value) && !R.isNil(value);
};

const makeNewFilterValue = R.curry((merger, name, addNewValue, value) => R.compose(
  merger,
  R.objOf(name),
  addNewValue,
)(value));

const checkIfValueIncluded = R.curry((array, value) => R.includes(value, array));

const handlerFilter = (condition, errorHandler, successHandler, value) => R.cond([
  [condition, errorHandler],
  [R.T, successHandler],
])(value);

const updateFilterAndUrlParams = R.curry((setFilters, actionUrl, filters, urlParam) => {
  setFilters(filters);
  actionUrl(urlParam);
  return filters;
});

const isConditionRight = curry(condition => R.cond([
  [condition, Right],
  [R.T, Left],
]));

const isNotNil = R.compose(
  R.not,
  R.isNil,
);

const formatDate = R.curry((date, format = 'YYYY MMM DD') => moment(date).add('m', moment.tz('Europe/Kiev').utcOffset() - moment.tz(SERVER_TIMEZONE).utcOffset()).format(format));
const sortByPredicate = curry((pred, list) => R.sortBy(a => (pred(a) ? 1 : 2), list));

function debounce(func, wait, immediate) {
  let timeout; let args; let context; let timestamp; let
    result;
  // eslint-disable-next-line no-param-reassign
  if (wait == null) wait = 100;

  function later() {
    const last = Date.now() - timestamp;

    if (last < wait && last >= 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      if (!immediate) {
        result = func.apply(context, args);
        // eslint-disable-next-line no-multi-assign
        context = args = null;
      }
    }
  }

  const debounced = function () {
    context = this;
    // eslint-disable-next-line prefer-rest-params
    args = arguments;
    timestamp = Date.now();
    const callNow = immediate && !timeout;
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      // eslint-disable-next-line no-multi-assign
      context = args = null;
    }

    return result;
  };

  debounced.clear = function () {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
  };

  debounced.flush = function () {
    if (timeout) {
      result = func.apply(context, args);
      // eslint-disable-next-line no-multi-assign
      context = args = null;

      clearTimeout(timeout);
      timeout = null;
    }
  };
  return debounced;
}

const size = R.pipe(R.values, R.length);
const map = curry((f, functor) => functor.map(f));
const functorGetOrElse = curry((elseValue, functor) => functor.getOrElse(elseValue));

const maybeValueNotNil = R.cond([
  [isNotNil, Maybe.Just],
  [R.T, () => Maybe.Nothing()],
]);
const curriedNext = curry((next, action, meta, data) => next(action(data, meta)));
const of = curry((value, functor) => functor.of(value));
const getOrElse = curry((value, functor) => functor.getOrElse(value));

const safeNotNil = cond([
  [isNotNil, Maybe.Just],
  [T, () => Maybe.Nothing()],
]);

const setObjLens = curry((lensProp, getValue, obj) => set(lensProp, getValue(obj), obj));

const either = curry((left, right, functor) => functor.either(left, right));
const chain = curry((f, functor) => functor.chain(f));

const alwaysFalse = R.always(false);

const checkSafariWithCb = (cb) => {
  const userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.indexOf('safari') !== -1 && userAgent.indexOf('chrome') === -1) {
    cb(true);
    return true;
  }
  cb(false);
  return false;
};

const propIdOrNull = R.propOr(null, 'id');

const debounceFunc = (func, time, immediately, n) => {
  const name = n || func.name;
  const waiterFunc = () => {
    window.debounceState[name] = setTimeout(() => {
      func();
      window.debounceState = omit([name], window.debounceState);
    }, time);
  };
  window.debounceState = window.debounceState || {};
  if (immediately && window.debounceState[name] === undefined) {
    func();
  }
  if (window.debounceState[name]) {
    clearTimeout(window.debounceState[name]);
    waiterFunc();
  }
  if (!window.debounceState[name]) waiterFunc();
};

const isMobile = () => (/iphone|ipod|android|ie|blackberry|fennec/).test(navigator.userAgent.toLowerCase());

// eslint-disable-next-line consistent-return
const notPureCond = args => (val) => {
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < args.length - 1; i++) {
    const item = args[i];
    if (item[0](val)) {
      return item[1](val);
    }
    if (item[0](val)) {
      return item[1](val);
    }
  }
};

const getSafeAvatarId = user => pathOr(pathOr(null, ['avatar'], user), ['avatar', 'id'], user);

export {
  getSafeAvatarId,
  debounceFunc,
  alwaysFalse,
  size,
  safeNotNil,
  setObjLens,
  camelCase,
  setNewPropertyData,
  capitalize,
  chain,
  of,
  getOrElse,
  sortByPredicate,
  map,
  curriedNext,
  functorGetOrElse,
  maybeValueNotNil,
  either,
  isConditionRight,
  convertUtcTimeToStartDay,
  renameKeys,
  getValueBy,
  toTitle,
  debounce,
  getItem,
  isNotNil,
  move,
  updateFilterAndUrlParams,
  filterAndTransformCondition,
  makeNewFilterValue,
  checkIfValueIncluded,
  handlerFilter,
  recalculateOffset,
  filterParamKey,
  concatProps,
  getOppositeValue,
  getPercentFromNumber,
  renameEachObjectKeys,
  clearContentEditableValue,
  formatDate,
  addBreakerToContentEditableValue,
  checkSafariWithCb,
  propIdOrNull,
  isMobile,
  notPureCond,
};

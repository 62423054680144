import {
  compose, lifecycle, withHandlers, withProps, withState,
} from 'recompose';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';
import { i18n } from '../../../../utils';

import FormProfile from './formProfile';
import { LANGUAGES } from '../../../../constants/config';

const onRemoveDisabledHandler = ({ setIsDisabled }) => () => setIsDisabled(false);

const onCancelHandler = ({ history }) => () => history.goBack();

const onChangeLanguageHandler = () => async (lng) => {
  await i18n.changeLanguage(lng);
  await i18n.reloadResources();
};

const langList = [
  {
    id: 1,
    label: 'English',
    value: 'en-US',
  },
  {
    id: 2,
    label: 'Українська',
    value: 'uk-UA',
  },
  {
    id: 3,
    label: 'Русский',
    value: 'ru-RU',
  },
];

const setLangOrDefault = (defaultLang, langs) => {
  const savedLang = localStorage.getItem('i18nextLng');
  const lang = LANGUAGES.includes(savedLang) ? savedLang : defaultLang;

  return langs.find(l => l.value === lang);
};

const enhance = compose(
  withRouter,
  withNamespaces(['common']),
  withState('isDisabled', 'setIsDisabled', false),
  withHandlers({
    onRemoveDisabled: onRemoveDisabledHandler,
    onCancel: onCancelHandler,
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { pending, setIsDisabled } = this.props;
      if (pending && prevProps.pending !== pending) {
        setIsDisabled(true);
      }
    },
  }),
  withProps(() => ({
    langList,
    defaultLang: setLangOrDefault('en-US', langList),
  })),
  withHandlers({
    changeLanguage: onChangeLanguageHandler,
  }),
);
export default enhance(FormProfile);

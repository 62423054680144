import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push as routerPush } from 'connected-react-router';
import { withNamespaces } from 'react-i18next';
import UserWrapper from './userWrapper';
import { settingUsersSelectors } from '../../../../state/settingUsers';
import { getFullNameOrUserName } from '../../../../utils/helpers/userHelpers';
import { withSubscription } from '../../../../utils/enchancers';

const mapStateToProps = (state, { match: { params: { userId } } }) => ({
  user: settingUsersSelectors.getSettingUsersEntities(state)[userId],
});

const mapDispatchToProps = {
  push: routerPush,
};

const enhance = compose(
  withSubscription(),
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces('common'),
  withRouter,
  withProps(({ user }) => ({
    userName: getFullNameOrUserName(user),
  })),
);

export default enhance(UserWrapper);

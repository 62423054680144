import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { withFormik } from 'formik';
import { withNamespaces } from 'react-i18next';
import { filter, includes } from 'ramda';

import { uiActions } from '../../../../state/ui';
import { getModal } from '../../../../state/ui/selectors';
import { usersActions } from '../../../../state/users';
import AssignManagerDropdown from './assignManagerDropdown';
import { messengerActions, messengerSelectors } from '../../../../state/messenger';

const rules = yup.object().shape({
  members: yup
    .string()
    .required('Field is required'),
});

const initialValues = { members: '' };

const getAutocompleteHandler = ({
  getUsersAutocompleteAction, membersList,
}) => (fieldValue, callback) => {
  getUsersAutocompleteAction({
    q: fieldValue,
    limit: 10,
  }, {
    callbacks: {
      success: ({ users }) => {
        const usersResult = filter(
          ({ value }) => !includes(value, membersList),
          users,
        );
        callback(usersResult);
      },
    },
  });
};

const mapStateToProps = state => ({
  isOpen: getModal(state)('inviteMembers'),
  isPending: messengerSelectors.addMembersToChannelPending(state),
  membersList: messengerSelectors.getActiveChannelMembers(state),
  activeChannel: messengerSelectors.getActiveChannel(state),
});

const mapDispatchToProps = ({
  setUsersAutocomplete: usersActions.setUsersListAutocomplete,
  closeModal: uiActions.closeModal,
  getUsersAutocompleteAction: usersActions.getUsersListAutocompleteRequest,
  assignManagerRequest: messengerActions.assignManagerRequest,
});

const onCloseModalHandler = ({ resetForm, closeModal }) => () => {
  resetForm(initialValues);
  closeModal('inviteMembers');
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces(['common', 'validation', 'chat']),
  withFormik({
    mapPropsToValues: () => initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    validationSchema: rules,
    handleSubmit: ({ members }, {
      resetForm,
      props: {
        assignManagerRequest,
        activeChannel,
      },
    }) => {
      assignManagerRequest({
        channelId: activeChannel.id,
        manager_id: members.value,
      });
      resetForm(initialValues);
    },
  }),
  withHandlers({
    getAutocomplete: getAutocompleteHandler,
    onCloseModal: onCloseModalHandler,
  }),
);
export default enhance(AssignManagerDropdown);

import React from 'react';
import PropTypes from 'prop-types';
import { identity, prop } from 'ramda';

import { ButtonsCollection } from '..';
import './style.sass';

const ReplayedMessage = ({
  content, allowClose, onClose, userName, onScrollToMessage, message, t,
}) => (
  // eslint-disable-next-line max-len
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
  <div
    className="row message__replayed-message-wrapper"
    title={t('Scroll to message')}
    onClick={prop('id', message) ? onScrollToMessage : identity}
  >
    <h4 className="message__replayed-message__user-name">{userName}</h4>
    <div className="message__replayed-message">
      <div className="message__replayed-message-content">
        {content}
      </div>
      {
        allowClose && (
          <ButtonsCollection.ButtonIcons
            title={t('Cancel reply')}
            className="message__replayed-message-content-close icon-close"
            onClick={onClose}
          />
        )
      }
    </div>
  </div>
);

ReplayedMessage.propTypes = {
  content: PropTypes.element.isRequired,
  allowClose: PropTypes.bool,
  onClose: PropTypes.func,
  onScrollToMessage: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
  message: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
};

ReplayedMessage.defaultProps = {
  allowClose: false,
  onClose: () => {},
};

export default ReplayedMessage;

import React from 'react';
import { Route, Switch } from 'react-router';
import PropTypes from 'prop-types';
import {
  Header, GuideFacebookContainer, GuideTelegramContainer, GuideViberContainer, LeftSideBar,
} from '../../containers';
import { Layouts, NoInternetConnection } from '../../components';

import './style.sass';
import ImageModal from './imageModal/index';

const Guide = ({ t }) => (
  <Layouts.Main>
    <LeftSideBar />
    <div className="main-container">
      <Header hideToggleRightSideBar>
        <h1 className="weight--medium title--xl title-page guide__nav-title">
          {t('Guide')}
        </h1>
      </Header>
      <NoInternetConnection>
        <Switch>
          <Route exact path="/guide/facebook/" component={GuideFacebookContainer} />
          <Route exact path="/guide/telegram/" component={GuideTelegramContainer} />
          <Route exact path="/guide/viber/" component={GuideViberContainer} />
          <Route component={GuideFacebookContainer} />
        </Switch>
      </NoInternetConnection>
    </div>
    <ImageModal />
  </Layouts.Main>
);

Guide.propTypes = {
  t: PropTypes.func.isRequired,
};

export default Guide;

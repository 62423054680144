import { compose } from 'recompose';
import { connect } from 'react-redux';

import ChannelItem from './channelItem';
import { messengerSelectors } from '../../../../../../state/messenger';

const mapStateToProps = (state, { channelId }) => ({
  groupChannels: messengerSelectors.getGroupChannels(state),
  serviceId: messengerSelectors.getGroupChannels(state)[channelId].service_id,
});

const enhance = compose(
  connect(mapStateToProps),
);

export default enhance(ChannelItem);

import { createActions } from 'redux-actions';
import { identity } from 'ramda';
import types from './types';
import { customFieldsSchema } from './schema';
import { getClientsRequest } from '../clients/actions';

export const {
  addCustomFieldRequest,
  addCustomFieldSuccess,
  getCustomFieldsRequest,
  getCustomFieldsSuccess,
  editCustomFieldRequest,
  editCustomFieldSuccess,
  deleteCustomFieldRequest,
  editCustomFieldValueRequest,
  editCustomFieldValueSuccess,
} = createActions({
  [types.GET_CUSTOM_FIELDS_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/getCustomFields',
      selectorName: 'getCustomFieldsRequest',
      method: 'GET',
      params,
      schema: {
        rules: customFieldsSchema,
        pathData: ['data'],
      },
      actions: {
        success: getCustomFieldsSuccess,
      },
      ...meta,
    }),
  ],
  [types.ADD_CUSTOM_FIELD_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/addCustomField',
      selectorName: 'addCustomFieldRequest',
      method: 'POST',
      params,
      actions: {
        success: [addCustomFieldSuccess, () => getClientsRequest()],
      },
      ...meta,
    }),
  ],
  [types.EDIT_CUSTOM_FIELD_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/editCustomField',
      selectorName: 'editCustomFieldRequest',
      method: 'PUT',
      params,
      actions: {
        success: editCustomFieldSuccess,
      },
      ...meta,
    }),
  ],
  [types.EDIT_CUSTOM_FIELD_VALUE_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/editCustomFieldValue',
      selectorName: 'editCustomFieldValueRequest',
      method: 'PUT',
      params,
      actions: {
        success: () => getClientsRequest(),
      },
      ...meta,
    }),
  ],
  [types.DELETE_CUSTOM_FIELD_REQUEST]: [
    identity,
    (params, meta) => ({
      async: true,
      route: '/deleteCustomField',
      selectorName: 'deleteCustomFieldRequest',
      method: 'DELETE',
      params,
      actions: {
        success: [() => getCustomFieldsRequest(), () => getClientsRequest()],
      },
      ...meta,
    }),
  ],
},
types.GET_CUSTOM_FIELDS_SUCCESS,
types.ADD_CUSTOM_FIELD_SUCCESS,
types.EDIT_CUSTOM_FIELD_SUCCESS,
types.EDIT_CUSTOM_FIELD_VALUE_SUCCESS);

import { compose } from 'recompose';
import { connect } from 'react-redux';

import { withNamespaces } from 'react-i18next';
import ChannelsItem from './channelsItem';
import { uiActions } from '../../../../state/ui';
import { usersActions } from '../../../../state/users';
import { settingBotsSelectors } from '../../../../state/settingBots';

const mapStateToProps = state => ({
  botsEntities: settingBotsSelectors.getBotsEntities(state),
});

const mapDispatchToProps = {
  setUserProfileId: uiActions.setUserProfileId,
  changeRightSidebarStatus: uiActions.changeRightSidebarStatus,
  getUserProfileRequest: usersActions.getUserProfileRequest,
};


const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withNamespaces('common'),
);

export default enhance(ChannelsItem);

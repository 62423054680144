import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { path } from 'ramda';

import { Label } from '../../../../components';
import './style.sass';
import PermissionElement from '../../../../components/permissionElement';
import { ACTIONS, MODULES } from '../../../../constants/pemissions';

const MainNav = ({
  lastChannelId,
  totalUnreadCount,
  generalUnreadCount,
  match,
  ownChannelId,
  allChannels,
  t,
  onCloseLeftSideBarAfterClick,
}) => (
  <nav className="main-nav">
    <NavLink
      to="/messenger/1"
      exact
      activeClassName="main-nav__item--active"
      className="main-nav__item"
      isActive={() => parseInt(path(['params', 'id'], match), 10) === 1}
      onClick={onCloseLeftSideBarAfterClick}
    >
      <span
        className="chanel-nav-group__icon icon-chanel-icon main-nav__icon"
        style={{
          fontSize: '25px',
          marginRight: 0,
          width: '20px',
        }}
      />
      <span className="hide-element--flex main-nav__text">{t('General chat')}</span>
      {
        generalUnreadCount > 0 && (
          <Label
            className="label--xs label--fill main-nav__total-unread-count"
          >
            {generalUnreadCount}
          </Label>
        )
      }
    </NavLink>
    <NavLink
      to={`/messenger/${ownChannelId}`}
      exact
      activeClassName="main-nav__item--active"
      className="main-nav__item"
      isActive={() => parseInt(path(['params', 'id'], match), 10) === ownChannelId}
      onClick={onCloseLeftSideBarAfterClick}
    >
      <span
        className="chanel-nav-group__icon icon-chanel-lock-icon main-nav__icon"
        style={{
          fontSize: '25px',
          marginRight: 0,
          width: '20px',
        }}
      />
      <span className="hide-element--flex main-nav__text">{t('Own (you)')}</span>
    </NavLink>
    <NavLink
      isActive={() => {
        const id = parseInt(path(['params', 'id'], match), 10);
        return (lastChannelId !== 1 && id && id !== 1)
          && (lastChannelId !== ownChannelId && id && id !== ownChannelId);
      }}
      to={`/messenger/${lastChannelId}`}
      onClick={e => (allChannels.length !== 0 ? '' : e.preventDefault())}
      activeClassName="main-nav__item--active"
      className={allChannels.length !== 0 ? 'main-nav__item' : 'main-nav__item main-nav__item--disabled'}

    >
      <span className="icon-talk-cloud-icon main-nav__icon" />
      <span className="hide-element--flex main-nav__text">{t('Chats')}</span>
      {
        totalUnreadCount > 0 && (
          <Label
            className="label--xs label--fill main-nav__total-unread-count"
          >
            {totalUnreadCount}
          </Label>
        )
      }
    </NavLink>
    <NavLink
      to="/clients/"
      activeClassName="main-nav__item--active"
      className="main-nav__item"
      onClick={onCloseLeftSideBarAfterClick}
    >
      <span className="icon-people main-nav__icon" />
      <span className="hide-element--flex main-nav__text">{t('clients:Clients')}</span>
    </NavLink>
    <PermissionElement module={MODULES.SETTINGS} action={ACTIONS.READ}>
      <NavLink
        to="/preferences/"
        activeClassName="main-nav__item--active"
        className="main-nav__item"
        onClick={onCloseLeftSideBarAfterClick}
      >
        <span className="icon-settings main-nav__icon" />
        <span className="hide-element--flex main-nav__text">{t('Preferences')}</span>
      </NavLink>
    </PermissionElement>
  </nav>
);
MainNav.propTypes = {
  lastChannelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  totalUnreadCount: PropTypes.number.isRequired,
  generalUnreadCount: PropTypes.number.isRequired,
  ownChannelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  allChannels: PropTypes.instanceOf(Array).isRequired,
  t: PropTypes.func.isRequired,
  onCloseLeftSideBarAfterClick: PropTypes.func.isRequired,
};
export default MainNav;

import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const Copyright = ({ t, currentYear, changeLanguage }) => (
  <div className="copyright">
    <div className="row text-align--center">
      {t('All right reserved')}{` SmartEx © ${currentYear}`}
      <div className="lang-toggle-links">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="" onClick={changeLanguage('en-US')}>
          English
        </a>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="" onClick={changeLanguage('uk-UA')}>
          Українська
        </a>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="" onClick={changeLanguage('ru-RU')}>
          Русский
        </a>
      </div>
    </div>
  </div>
);

Copyright.propTypes = {
  t: PropTypes.func.isRequired,
  currentYear: PropTypes.string.isRequired,
  changeLanguage: PropTypes.func.isRequired,
};

export default Copyright;

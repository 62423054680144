import React from 'react';
import PropTypes from 'prop-types';

import './style.sass';

const ServiceIcon = ({ serviceTitle, className, imagePath }) => (
  <>
    <img
      className={`service-icon__img ${className} ${serviceTitle.toLowerCase()}`}
      src={imagePath}
      alt={serviceTitle}
    />
  </>
);

ServiceIcon.propTypes = {
  serviceTitle: PropTypes.string.isRequired,
  className: PropTypes.string,
  imagePath: PropTypes.string.isRequired,
};
ServiceIcon.defaultProps = {
  className: '',
};

export default ServiceIcon;

import React from 'react';
import PropTypes from 'prop-types';

import { UserProfile } from './components';
import { ButtonsCollection } from '../../components';

import './style.sass';

const RightSideBar = ({
  children,
  className,
  title,
  titleComponentRender,
  onToggle,
  userProfileId,
  setRightSideBarRef,
  t,
}) => (
  <div className={`right-side-bar ${className}`} ref={e => setRightSideBarRef('rightSideBar', e)}>
    <div className="right-side-bar__header">
      {titleComponentRender ? titleComponentRender()
        : (
          <h2 className="title--xl weight--bold right-side-bar__title">
            { userProfileId ? t('About User') : title }
          </h2>
        )}
      <ButtonsCollection.ButtonClose
        title={t('Right sidebar')}
        className="right-side-bar__close-button"
        onClick={() => onToggle(true)}
      />
    </div>
    <div className="right-side-bar__body">
      {
        userProfileId ? (<UserProfile />) : (<>{children}</>)
      }
    </div>
  </div>
);
RightSideBar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.array,
  ]),
  className: PropTypes.string,
  title: PropTypes.string,
  titleComponentRender: PropTypes.func,
  onToggle: PropTypes.func.isRequired,
  userProfileId: PropTypes.number,
  setRightSideBarRef: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
RightSideBar.defaultProps = {
  children: '',
  title: '',
  className: '',
  userProfileId: null,
  titleComponentRender: null,
};

export default RightSideBar;

import { compose, getContext } from 'recompose';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

import FiltersRow from './filtersRow';

const enhance = compose(
  getContext({
    values: PropTypes.shape({}),
    setFieldValue: PropTypes.func,
  }),
  withNamespaces(['common', 'chat']),
);
export default enhance(FiltersRow);

import { createSelector } from 'reselect';
import {
  pathOr, identity,
} from 'ramda';

const getRolesEntities = createSelector(pathOr({}, ['permissions', 'entities', 'roles']), identity);


// eslint-disable-next-line import/prefer-default-export
export { getRolesEntities };

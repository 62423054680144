import React from 'react';
import PropTypes from 'prop-types';

const Row = ({ children, className, onClick }) => (
  <div role="presentation" onClick={onClick} className={className}>
    { children }
  </div>
);

Row.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

Row.defaultProps = {
  onClick: () => null,
};

export default Row;
